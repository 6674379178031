(function () {
    'use strict';

    var user = ['$log', 'Principal', function ($log, Principal) {
        return Principal.identity().then(function (user) {
            return user;
        }, function (error) {
            $log.error('Couldn\'t load user!', error);
        });
    }];

    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            }
            else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };

    var currentUser = ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
        return Principal.identity().then(function (user) {
            $log.debug('Loaded userInfo: ', user);
            if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_ANALYSIS')) {
                License.get().then(function (response) {
                    var license = response.data;
                    if (isLicensed(license, 'analysis')) {
                        return user;
                    }
                    else {
                        $state.go("accessdenied");
                    }
                }, function (error) {
                    $log.error('Couldn\'t load license!', error);
                });
            }
            else {
                $state.go("accessdenied");
            }
        }, function (error) {
            $state.go("notfound");
            $log.error('Couldn\'t load user: ', error);
        });
    }];

    var aggregationOptions = ['$log', 'digiChart', function ($log, digiChart) {
        return digiChart.getAggregationOptions().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error('Couldn\'t load aggragtion options!', error);
        });
    }];

    var groupingOptions = ['$log', 'digiChart', function ($log, digiChart) {
        return digiChart.getGroupingOptions().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error('Couldn\'t load grouping options!', error);
        });
    }];

    var isAdmin = ['$log', '$q', 'Principal', 'currentUser', function ($log, $q, Principal, currentUser) {
        // Load currentUser before requesting status
        return $q.when(Principal.isInRole('ROLE_ADMIN'));
    }];

    var groupList = ['$log', 'Group', function ($log, Group) {
        return Group.getAllUserGroup().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error('Couldn\'t load groups!', error);
        });
    }];

    var currentAnalysis = ['$log', '$stateParams', 'Analysis', '$state', function ($log, $stateParams, Analysis, $state) {
        return Analysis.load($stateParams.analysisId).then(function (response) {
            return response.data;
        }, function (error) {
            $state.go("notfound");
            $log.error('Couldn\'t load analysis ' + $stateParams.analysisId, error);
        });
    }];

    var locationsAndDevices = ['$log', 'Location', function ($log, Location) {
        return Location.analysisLocations().then(function (response) {
            var i;
            for (i = 0; i < response.data.value1.length; i++) {
                var location = response.data.value1[i];
                location.devices = response.data.value2.filter(function (el) {
                    return el.locationId === location.id;
                });
            }
            return response.data;
        }, function (error) {
            $log.error('Couldn\'t load locations & devices!', error);
        });
    }];

    var returnNull = ['$q', function ($q) {
        return $q.when(null);
    }];

    function stateConfiguration($stateProvider) {
        $stateProvider
            .state('analysis', {
                abstract: false,
                parent: 'site',
                url: '/analysis',
                redirectTo: 'analysis.overview',
                data: {
                    roles: ['ROLE_ANALYSIS', 'ROLE_ADMIN'],
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.analysis',
                    license: 'analysis'
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('analysis');
                        $translatePartialLoader.addPart('dashboard');
                        $translatePartialLoader.addPart('calendar');
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('rsqltable');
                        $translatePartialLoader.addPart('room');
                        return $translate.refresh();
                    }],
                    user: user,
                    isAdmin: isAdmin,
                    groupList: groupList,
                    aggregationOptions: aggregationOptions,
                    groupingOptions: groupingOptions,
                    locationsAndDevices: locationsAndDevices,
                    currentUser: currentUser
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.overview', {
                parent: 'analysis',
                url: '/overview',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.overview'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/overview.html',
                        controller: 'AnalysisOverviewController',
                        controllerAs: 'analysis'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.quick', {
                parent: 'analysis',
                url: '/quick',
                params: {
                    locationId: null,
                    datapointId: null,
                    livedatapointHash: null
                },
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.quick'
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/quick/analysis.html',
                        controller: 'AnalysisQuickController',
                        controllerAs: 'analysis'
                    }
                },
                resolve: {
                    currentAnalysis: returnNull,

                    license: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getAnalysisLimitReached().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }]
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.create', {
                parent: 'analysis',
                url: '/create',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.createDetailed',
                    edit: false,
                    delete: false
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/detailed/analysis.html',
                        controller: 'AnalysisDetailedController',
                        controllerAs: 'analysis'
                    }
                },
                resolve: {
                    currentAnalysis: returnNull,

                    license: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getAnalysisLimitReached().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('Couldn\'t retrieve license!', error);
                        });
                    }],

                    licenseExpired: ['$log', 'License', '$state', function ($log, License, $state) {
                        return License.getExpired().then(function (response) {
                            if (response.data) {
                                $state.go("accessdenied");
                            }
                        }, function (error) {
                            $log.error('License is expired!', error);
                        });
                    }]
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.detailed', {
                parent: 'analysis',
                url: '/detailed/:analysisId',
                abstract: false,
                redirectTo: 'analysis.detailed.view',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: '{{currentAnalysis.title}}'
                },
                resolve: {
                    currentAnalysis: currentAnalysis
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.detailed.view', {
                parent: 'analysis.detailed',
                url: '/view',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.view',
                    edit: false,
                    delete: false
                },
                resolve: {
                    currentAnalysis: currentAnalysis
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/detailed/analysis.html',
                        controller: 'AnalysisDetailedController',
                        controllerAs: 'analysis'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.detailed.edit', {
                parent: 'analysis.detailed',
                url: '/edit',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.edit',
                    edit: true,
                    delete: false,
                    datapoints: null,
                    dateranges: null
                },
                resolve: {
                    currentAnalysis: currentAnalysis
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/detailed/analysis.html',
                        controller: 'AnalysisDetailedController',
                        controllerAs: 'analysis'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            })
            .state('analysis.detailed.delete', {
                parent: 'analysis.detailed',
                url: '/delete',
                data: {
                    pageTitle: 'analysis.title.pageTitle',
                    breadcrumbsTitle: 'analysis.title.delete',
                    edit: false,
                    delete: true
                },
                views: {
                    'content@': {
                        templateUrl: 'scripts/app/analysis/detailed/analysis.html',
                        controller: 'AnalysisDetailedController',
                        controllerAs: 'analysis'
                    }
                },
                onEnter: function ($timeout, Principal) {
                    'ngInject';
                    $timeout(function () {
                        if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                    });
                }
            });
    }

    angular
        .module('emsv2App')
        .config(stateConfiguration);

})();
