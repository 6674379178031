(function () {
    'use strict';

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('logfiles', {
                    parent: 'admin',
                    url: '/logfiles',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_LOGS'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'logfiles.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/logfiles/logfiles.html',
                            controller: 'LogfilesController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('logfiles');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        currentLicense: ['$log', 'License', function ($log, License) {
                            return License.reload().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load logfiles!', error);
                                return {};
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', function ($log, Principal, $state) {
                            return Principal.identity().then(function (user) {
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_LOGS')) {
                                    return user;
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('logfiles.auditlog', {  // audit log tab
                    parent: 'logfiles',
                    url: '/audit',
                    data: {
                        roles: ['ROLE_ADMIN'],
                        pageTitle: 'logfiles.audit.title',
                        breadcrumbsTitle: 'logfiles.audit.title'
                    },
                    views: {
                        'auditLog': {
                            templateUrl: 'scripts/app/admin/logfiles/audit/auditlog.html',
                            controller: 'auditLogController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('logfiles');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        currentLicense: ['$log', 'License', function ($log, License) {
                            return License.reload().then(function (response) {
                                return response.data;
                            }, function (error) {
                                $log.error('Couldn\'t load logfiles!', error);
                                return {};
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', function ($log, Principal, $state) {
                            return Principal.identity().then(function (user) {
                                if (user.hasRole('ROLE_ADMIN')) {
                                    return user;
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error("Couldn't load user ", error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('logfiles.view', {
                    parent: "logfiles",
                    url: '/:filename',
                    data: {
                        roles: ['ROLE_ADMIN'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'logfiles.detailView',
                        edit: false,
                        create: false
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/logfiles/detail/logfilesDetail.html',
                            controller: 'logfilesDetailController',
                            controllerAs: 'Logctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('logfiles');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                });
        });

})();
