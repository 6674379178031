(function () {

    'use strict';

    var isLicensed = function (license, module) {
        if (license !== null
            && license !== undefined
            && license.data !== null
            && license.data !== undefined) {
            if (module.indexOf('*') !== -1) {
                var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                var keys = Object.keys(license.data);
                for (var i in keys) {
                    if (regex.test(keys[i]) && keys[i]) return true;
                }
            }
            else if (license.data[module] !== null) {
                return license.data[module];
            }
        }
        return false;
    };

    angular.module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('dashboard', {
                    parent: 'site',
                    url: '/dashboard',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_DASHBOARD'],
                        pageTitle: 'dashboard.pageTitle',
                        breadcrumbsTitle: 'dashboard.title',
                        license: 'dashboard',
                        isHome: true
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/dashboard/dashboard.html',
                            controller: 'DashBoardController',
                            controllerAs: 'dashboardCtrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('dashboard');
                            $translatePartialLoader.addPart('ebalance');
                            $translatePartialLoader.addPart('analysis');
                            $translatePartialLoader.addPart('room');
                            $translatePartialLoader.addPart('location');
                            $translatePartialLoader.addPart('rsqltable');
                            return $translate.refresh();
                        }],
                        currentWidgets: ['$log', 'WidgetData', function ($log, WidgetData) {
                            return WidgetData.getWidgets().then(function (data) {
                                return data.data;
                            }, function (error) {
                                $log.error("Couldn't load widgets!", error);
                            });
                        }],
                        AnalysisWidgetLimit: ['$log', 'Analysis', function ($log, Analysis) {
                            return Analysis.getAnWidgetLimit().then(function (data) {
                                return data.data;
                            }, function (error) {
                                $log.error("No configuration found, set default value to 4", error);
                                return 4;
                            });
                        }],
                        userInfo: ['$log', 'Principal', function ($log, Principal) {
                            return Principal.identity().then(function (account) {
                                return account;
                            }, function (error) {
                                $log.error('Couldn\'t load user!', error);
                            });
                        }],
                        currentUser: ['$log', 'Principal', '$state', 'License', function ($log, Principal, $state, License) {
                            return Principal.identity().then(function (user) {
                                $log.debug('Loaded userInfo: ', user);
                                if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_DASHBOARD')) {
                                    License.get().then(function (response) {
                                        var license = response.data;
                                        if (isLicensed(license, 'dashboard')) {
                                            return user;
                                        }
                                        else {
                                            $state.go("accessdenied");
                                        }
                                    }, function (error) {
                                        $log.error('Couldn\'t load license!', error);
                                    });
                                }
                                else if(user.hasRole('ROLE_ALARM_MANAGEMENT')){
                                    $state.go("alarm");
                                }
                                else if(user.hasRole('ROLE_ANALYSIS')){
                                    $state.go("analysis");
                                }
                                else if(user.hasRole('ROLE_CONTACT')){
                                    $state.go("contacts");
                                }
                                else if(user.hasRole('ROLE_DASHBOARD')){
                                    $state.go("dashboard");
                                }
                                else if(user.hasRole('ROLE_GROUP')){
                                    $state.go("groupOverview");
                                }
                                else if(user.hasRole('ROLE_LICENSING')){
                                    $state.go("license");
                                }
                                else if(user.hasRole('ROLE_LOCATION_CREATE') || user.hasRole('ROLE_LOCATION_VIEW') || user.hasRole('ROLE_LOCATION_DELETE') || user.hasRole('ROLE_USER') ){
                                    $state.go("location");
                                }
                                else if(user.hasRole('ROLE_LOGS')){
                                    $state.go("logfiles");
                                }
                                else if(user.hasRole('ROLE_REPORT')){
                                    $state.go("reports");
                                }
                                else if(user.hasRole('ROLE_USER_MANAGEMENT')){
                                    $state.go("userOverview");
                                }
                                else {
                                    $state.go("accessdenied");
                                }
                            }, function (error) {
                                $state.go("notfound");
                                $log.error('Couldn\'t load user: ', error);
                            });
                        }]
                    },
                    onEnter: function ($timeout, Principal) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });

})();
