(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name RoomService
     * @description Basic service to handle some general aspects for rooms
     */

    angular.module('emsv2App')
        .service('RoomService', function ($http, $log, $state, $translate, HeatmapService
            , OIDService, Tools) {
            var colorizeRoomTemp = function (room, room3d, tempHeatmap) {
                var infoObj = HeatmapService.createGradient(tempHeatmap, 512, 1);
                var canvas = infoObj[0];
                if (room == null) return;
                for (var i = 0; i < room.sensors.length; i++) {
                    var dvTemp = room.sensors[i].driverValues.filter(function (dv) {
                        return dv.physicalType == 1 && dv.value !== undefined && dv.value !== null;
                    })[0];
                    if (dvTemp) {
                        room3d.traverse(function (o) {
                            if (o.name === "sensor" && o.userData.id === room.sensors[i].id) {
                                if (isNaN(dvTemp.value) || dvTemp.value < -2000000) {
                                    o.material.color.setHex(0xffffff);
                                } else {
                                    var colorRGB = HeatmapService.getColorForValue(canvas, tempHeatmap.minValue, tempHeatmap.maxValue, dvTemp.value);
                                    o.material.color.setRGB(colorRGB.r, colorRGB.g, colorRGB.b);
                                }
                            }
                        });
                    }
                    dvTemp = null;
                }
                canvas = null;
                infoObj = null;
            };

            /**
             * @ngdoc function
             * @description - Function to colorize sensors according to the provided temperature heatmap
             * @param room  - room object to colorize
             * @param room3d - three room object
             * @param tempHeatmap - temperature heatmap
             */
            var colorizeRoomTempV2 = function (room, room3d, tempHeatmap) {
                if (room === null) return;
                var map = angular.copy(tempHeatmap);
                map.heatmapValues.sort(function (a, b) {
                    return a.key - b.key;
                });
                for (var i = 0; i < room.sensors.length; i++) {
                    var dvTemp = room.sensors[i].driverValues.filter(function (dv) {
                        return dv.physicalType === 1 && dv.value !== undefined && dv.value !== null;
                    })[0];
                    if (dvTemp && dvTemp.hasOwnProperty("value")) {
                        room3d.traverse(function (o) {
                            if (o.name === "sensor" && o.userData.id === room.sensors[i].id) {
                                if (isNaN(dvTemp.value) || dvTemp.value < -2000000) {
                                    o.material.color.setHex(0xffffff);
                                } else {
                                    var colorRGB = getColorForValue(tempHeatmap, dvTemp.value);
                                    o.material.color.setRGB(colorRGB.r, colorRGB.g, colorRGB.b);
                                    // o.material.color.setHex(0x000000);
                                }
                            }
                        });
                    }
                    dvTemp = null;
                }
            };

            /**
             * @ngdoc function
             * @description - return the color for a given heatmap and value
             * @param heatmap - heatmap to get color from for provided value
             * @param value - the value to get a color for
             * @returns {{r, g, b}}
             */
            var getColorForValue = function (heatmap, value) {
                var botValue, topValue;
                if (value <= heatmap.heatmapValues[0].key) return splitHexStringIntoRGB(heatmap.heatmapValues[0].color, true);
                if (value >= heatmap.heatmapValues[heatmap.heatmapValues.length - 1].key) return splitHexStringIntoRGB(heatmap.heatmapValues[heatmap.heatmapValues.length - 1].color, true);

                heatmap.heatmapValues.sort(function (a, b) {
                    return a.key - b.key;
                });

                for (var i = 0; i < heatmap.heatmapValues.length - 1; i++) {
                    if (heatmap.heatmapValues[i].key <= value && heatmap.heatmapValues[i + 1].key >= value) {
                        botValue = heatmap.heatmapValues[i];
                        topValue = heatmap.heatmapValues[i + 1];
                        break;
                    }
                }
                var percentage = (value - botValue.key) / (topValue.key - botValue.key);
                return interpolateRGB(splitHexStringIntoRGB(botValue.color, true), splitHexStringIntoRGB(topValue.color, true), percentage);
            };

            var rgb2hsv = function (r, g, b) {
                var ch = 0;
                var cs = 0;
                var cv = 0;
                r /= 255;
                g /= 255;
                b /= 255;
                var minRGB = Math.min(r, Math.min(g, b));
                var maxRGB = Math.max(r, Math.max(g, b));
                if (minRGB === maxRGB) return [0, 0, minRGB];
                var d = (r === minRGB) ? g - b : ((b === minRGB) ? r - g : b - r);
                var h = (r === minRGB) ? 3 : ((b === minRGB) ? 1 : 5);
                ch = 60 * (h - d / (maxRGB - minRGB));
                cs = (maxRGB - minRGB) / maxRGB;
                cv = maxRGB;
                return [ch, cs, cv];
            };

            var hsv2rgb = function (h, s, v) {
                var r, g, b, i, f, p, q, t;
                i = Math.floor(h * 6);
                f = h * 6 - i;
                p = v * (1 - s);
                q = v * (1 - f * s);
                t = v * (1 - (1 - f) * s);
                switch (i % 6) {
                    case 0:
                        r = v, g = t, b = p;
                        break;
                    case 1:
                        r = q, g = v, b = p;
                        break;
                    case 2:
                        r = p, g = v, b = t;
                        break;
                    case 3:
                        r = p, g = q, b = v;
                        break;
                    case 4:
                        r = t, g = p, b = v;
                        break;
                    case 5:
                        r = v, g = p, b = q;
                        break;
                }
                return {
                    r: Math.round(r * 255),
                    g: Math.round(g * 255),
                    b: Math.round(b * 255)
                };
            };

            /**
             * @ngdoc function
             * @param hexString - the color hex coded in a string
             * @param float {Boolean} - true if returned values should be in the range 0 .. 1 or false if returned values should be in the range 0 .. 255
             * @returns {{r: Number, g: Number, b: Number}} - returns r,g,b values from string according to specified range by 'float' param
             */
            var splitHexStringIntoRGB = function (hexString, float) {
                var str;
                if (hexString.length === 7 && hexString[0] === '#') {
                    str = hexString.substring(1);
                } else {
                    str = hexString.substring(0);
                }

                var r = parseInt(str.substring(0, 2), 16);
                var g = parseInt(str.substring(2, 4), 16);
                var b = parseInt(str.substring(4), 16);

                if (float) {
                    r /= 255;
                    g /= 255;
                    b /= 255;
                }

                return {r: r, g: g, b: b};
            };

            /**
             * @ngdoc function
             * @description Function to interpolate between two rgb-color-coded colors and a factor
             * @param color0 {{r:Number, g:Number, b:Number}} - first color
             * @param color1 {{r:Number, g:Number, b:Number}} - second color
             * @param factor {Number} - number in range 0 .. 1
             * @returns {{r: Number, g: Number, b: Number}} object representing the interpolated color value as rgb-coded color
             */
            var interpolateRGB = function (color0, color1, factor) {
                var r = color0.r + factor * (color1.r - color0.r);
                var g = color0.g + factor * (color1.g - color0.g);
                var b = color0.b + factor * (color1.b - color0.b);

                return {r: r, g: g, b: b};
            };

            //Backend

            /**
             * Helper function calls resetIds and parseSizeRotPosForBackend methods of provided object
             * @param {Object} obj obj to call functions on
             */
            var resetIdsAndParseSizeRotPosForBackend = function (obj) {
                obj.parseSizeRotPosForBackend();
            };

            /**
             * Helper function calls resetIds, parseSizeRotPosForBackend and modifyDriverValueLimitsForBackend methods of provided object
             * @param obj
             */
            var resetIdsParseSizeRotPosModifyDriverValueLimitsForBackend = function (obj) {
                resetIdsAndParseSizeRotPosForBackend(obj);
            };
            /**
             * @description Runs resetIds, parseSizeRotPos and modifyDriverValueLimits for each element of the array, if childCallback is provided childCallback will be call for each element of the array with this object as parameter
             * @param {Array} objs elements to run specified functions on
             * @param {function=} childCallback callback function with one parameter (element from objs)
             */
            var runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray = function (objs, childCallback) {
                for (var i = 0; i < objs.length; i++) {
                    //This was added as a Hotfix for Ticket CGLT-905 to clean up the .json before it gets send to BE
                    // && objs[i].driverValues.length
                    if (Tools.isDefinedNotNull(objs[i].driverValues)) {
                        if (objs[i].isRack) {
                            objs[i].driverValues = [];
                        } else {
                            for (var j = 0; j < objs[i].driverValues.length; j++) {
                                if (!Tools.isDefinedNotNull(objs[i].driverValues[j].driver.id)) {
                                    objs[i].driverValues = [];
                                }
                            }
                        }
                    }
                    //CGLT-905 hotfix end
                    resetIdsParseSizeRotPosModifyDriverValueLimitsForBackend(objs[i]);
                    if (childCallback) childCallback(objs[i]);
                }
            };

            /**
             * @ngdoc function
             * @description Function to modify a room before it gets send to the backend (reset of negative ids etc.)
             * @param room {Room} the room to modify
             */
            var modifyRoomForBackend = function (room) {
                room.parseSizeRotPosForBackend();
                room.floor = parseInt(room.floor) * room.floorType;
                Room.buildCornerArrayFromOuterWalls(room, true);
                for (var innerWall in room.innerWalls) {
                    room.innerWalls[innerWall].resetIds();
                    room.innerWalls[innerWall].parseForBackend();
                }
                for (var obj = 0; obj < room.roomObjs.length; obj++) {
                    resetIdsAndParseSizeRotPosForBackend(room.roomObjs[obj]);
                }
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.sensors);
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.assets);
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.ups);
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.floorTiles);
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.coolings);
                runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(room.racks, function (rack) {
                    runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(rack.slots, function (slot) {
                        runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(slot.blades, function (blade) {
                            runResetIdsParseSizeRotPosModifyDriverValueLimitsForArray(blade.cpus, function (cpu) {
                                if (cpu.id > 0 && cpu.type !== null) cpu.type = null;
                            });
                        });
                    });
                });
            };

            /**
             * @ngdoc function
             * @description helper function to handle modify of driver values for backend
             * @param obj {Object} - the object to process driver values from
             */
            var handleDriverValues = function (obj) {
                for (var i in obj.driverValues) {
                    if (obj.driverValues[i].id < 0) obj.driverValues[i].id = null;
                    if (obj.driverValues[i].driver.id < 0) obj.driverValues[i].driver.id = null;
                    if (obj.driverValues[i].driver.uniqueId < 0) obj.driverValues[i].driver.uniqueId = null;
                    for (var j in obj.driverValues[i].limits) {
                        if (obj.driverValues[i].limits[j].id < 0) {
                            obj.driverValues[i].limits[j].id = null;
                            obj.driverValues[i].limits[j].driverValueId = obj.driverValues[i].id;
                        }
                    }
                }
            };

            /**
             * @ngdoc function
             * @description Function to get all rooms for specified location by its id from backend
             * @param locationId {Number} - the id a location
             * @returns {HttpPromise}
             */
            var getRooms = function (locationId) {
                return $http.get('/api/rooms/' + locationId);
            };

            /**
             * @ngdoc function
             * @description Function to get all rooms for specified location by its id from backend but without driver values etc.
             * @param locationId {Number} - the id a location
             * @returns {HttpPromise}
             */
            var getSimpleRooms = function (locationId) {
                return $http.get('/api/rooms/simple/' + locationId);
            };

            /**
             * Get rooms by building id
             * @param buildingId is the id that we use to search the rooms
             * @returns {HttpPromise}
             */
            var getSimpleRoomsByBuilding = function (buildingId) {
                return $http.get('/api/rooms/roomsByBuilding/' + buildingId);
            };

            /**
             * @ngdoc function
             * @description Function to get all rooms of a location by given location id and floor id - does preprocessing to parse returned rooms to Room objects
             * @param locationId {Number} - id of the location to get rooms for
             * @param floorId {Number} - id of the floor to get rooms for
             */
            var getFloorRooms = function (locationId, floorId) {
                return $http.post("/api/rooms/floor", {lid: locationId, fid: floorId}).then(function (response) {
                    var rooms = [];
                    for (var i in response.data) {
                        var room = Room.parseFromHtmlObject(response.data[i]);
                        rooms.push(room);
                    }
                    return rooms;
                }, function (error) {
                    $log.error("Error retrieving rooms for floor: " + floorId + " in location: " + locationId + "\n" + error);
                });
            };

            /**
             * @ngdoc function
             * @description Function to get a room from backend by location id and room id
             * @param locationId {Number} id of location
             * @param roomId {Number} id of room
             * @returns {HttpPromise} returns preprocessed data in promise
             */
            var getRoom = function (locationId, roomId) {
                return $http.get('/api/rooms/room/' + roomId);
            };

            /**
             * @ngdoc function
             * @description Function to save a room
             * @param room {Room} room to be saved
             * @returns {HttpPromise}
             */
            var saveRoom = function (room) {
                modifyRoomForBackend(room);
                return $http.post('/api/rooms', room);
            };

            /**
             * @ngdoc function
             * @description Function to update roomJson
             * @param room {Room} to be saved to update driverValueGroup
             * @returns {HttpPromise}
             */
            var updateRoomJson = function (room) {
                return $http.put('/api/rooms/updateRoomJson', room);
            };

            /**
             * @ngdoc function
             * @description Function to delete a room
             * @param roomId {Number} id of the to be deleted room
             * @returns {HttpPromise}
             */
            var deleteRoom = function (roomId) {
                return $http.delete("/api/rooms/" + roomId);
            };

            /**
             * @description Function to delete a saved object
             * @param {object} obj the entity object to delete
             * @returns {HttpPromise}
             */
            var deleteCpuEntity = function (obj, roomJson) {
                if (Tools.isDefinedNotNull(roomJson)) {
                    var room = JSON.parse(roomJson);
                    return $http.post("/api/entity/cpu/" + obj.id, room);
                }
            };

            var tempSetDriverValueDeletedFlag = function (deleteDriverValueIds) {
                return $http.post("api/entity/tempSetDriverValueDeletedFlag", deleteDriverValueIds);
            };

            var deleteAllReferenceForDeletedDriverValueIds = function (deleteDriverValueIds) {
                return $http.post("api/entity/deleteAllReferenceForDeletedDriverValueIds", deleteDriverValueIds);
            };

            var deleteAllReferenceForDeletedDriverValueModbusUids = function (deleteDriverValueModbusUids) {
                return $http.post("api/dataPointValueHolder/deleteAllByIds", deleteDriverValueModbusUids);
            };

            var restoreAllDriverValue = function (deleteDriverValueIds) {
                return $http.post("api/entity/restoreAllDriverValue", deleteDriverValueIds);
            };

            /**
             * @description Function to delete a saved object
             * @param {object} obj the entity object to delete
             * @returns {HttpPromise}
             */
            var deleteDriverValue = function (id, obj, roomJson) {
                var room = JSON.parse(roomJson);
                return $http.post("/api/entity/drivervalue/" + id + "/" + obj.constructor.name.toLowerCase(), room);
            };

            /**
             * @description Function to delete a unsaved entity
             * @param {object} obj the object to delete
             * @param {object} parent the parent object to delete from
             * @param {THREE.Object3D} obj3d the 3d-object representing the object to delete
             */
            var deleteUnsavedEntity = function (obj, parent, obj3d) {
                if (Tools.isDefinedNotNull(obj) || Tools.isDefinedNotNull(parent)) {
                    if (obj instanceof Asset) {
                        deleteObjectFromArray(obj, parent.assets);
                    }
                    if (obj instanceof Cooling) {
                        deleteObjectFromArray(obj, parent.coolings);
                    }
                    if (obj instanceof Rack) {
                        deleteObjectFromArray(obj, parent.racks);
                    }
                    if (obj instanceof Sensor) {
                        deleteObjectFromArray(obj, parent.sensors);
                    }
                    if (obj instanceof FloorTile) {
                        deleteObjectFromArray(obj, parent.floorTiles);
                    }
                    if (obj instanceof Ups) {
                        deleteObjectFromArray(obj, parent.ups);
                    }
                    if (obj instanceof Containment) {
                        deleteObjectFromArray(obj, parent.roomObjs);
                    }
                    if (obj instanceof Slot) {
                        deleteObjectFromArray(obj, parent.slots, obj3d);
                    }
                    if (obj instanceof Cpu) {
                        deleteObjectFromArray(obj, parent.cpus);
                    }
                }
            };

            /**
             * @ngdoc function
             * @description Function to remove a object from an array. Object is identified by its id
             * @param obj {Entity} - object to be removed from object
             * @param array {Array} - array of objects
             * @param obj3d {THREE.Object3D} - threejs object to search and remove object representation from
             */
            var deleteObjectFromArray = function (obj, array, obj3d) {
                var idx = null;
                for (var i = 0; i < array.length; i++) {
                    if (array[i].id == obj.id) {
                        idx = i;
                        break;
                    }
                }
                if (idx !== null) {
                    array.splice(idx, 1);
                    if (obj.type === 3) {
                        if (Tools.isDefinedNotNull(obj3d)) {
                            for (var i = 0; i < obj3d.children.length; i++) {
                                if (obj.pos.y === obj3d.children[i].position.y) {
                                    obj3d.children[i].children.splice(1, obj3d.children[i].children.length);
                                }
                            }
                        }
                    }
                }
            };

            var getEntity = function (type, roomId, id) {
                switch (type) {
                    case "Asset" :
                        return $http.get("/api/entity/asset/" + id).then(function (response) {
                            return Asset.parseFromHtmlObject(response.data, response.data.roomId);
                        }, function (error) {
                            $log.debug("Error retrieving asset with id " + id + "\n" + error);
                            return error;
                        });
                    case "Cooling" :
                        return $http.get("/api/entity/cooling/" + id).then(function (response) {
                            return Cooling.parseFromHtmlObject(response.data, response.data.roomId);
                        }, function (error) {
                            $log.debug("Error retrieving cooling with id " + id + "\n" + error);
                            return error;
                        });
                    case "FloorTile" :
                        return $http.get("/api/entity/floortile/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving floortile with id " + id + "\n" + error);
                            return error;
                        });
                    case "Rack" :
                        return $http.get("/api/entity/rack/" + roomId + "/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving rack with id " + id + "\n" + error);
                            return error;
                        });
                    case "Sensor" :
                        return $http.get("/api/entity/sensor/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving sensor with id " + id + "\n" + error);
                            return error;
                        });
                    case "Ups":
                        return $http.get("/api/entity/ups/" + id).then(function (response) {
                            return Ups.parseFromHtmlObject(response.data, response.data.roomId);
                        }, function (error) {
                            $log.debug("Error retrieving ups with id " + id + "\n" + error);
                            return error;
                        });
                    case "Slot" :
                        return $http.get("/api/entity/slot/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving slot with id " + id + "\n" + error);
                            return error;
                        });
                    case "Blade" :
                        return $http.get("/api/entity/blade/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving blade with id " + id + "\n" + error);
                            return error;
                        });
                    case "Cpu" :
                        return $http.post("/api/entity/cpu/" + id).then(function (response) {
                            return response;
                        }, function (error) {
                            $log.debug("Error retrieving cpu with id " + id + "\n" + error);
                            return error;
                        });
                }
            };

            var sendEntityValue = function (driver, dv, obj, value) {
                return $http.post("/api/entity/sendValueEntityDriver", {
                    driverValueId: dv.id,
                    community: driver.community,
                    oid: driver.oid,
                    ipAddress: driver.snmpDomain,
                    port: driver.snmpPort,
                    driverType: driver.driverType,
                    value: value
                });
            };

            var sendEntityValueSNMPv3 = function (driver, dv, obj, value) {
                return $http.post("/api/entity/sendValueSNMPv3", {
                    driverValueId: dv.id,
                    oid: driver.oid,
                    ipAddress: driver.snmpDomain,
                    port: driver.snmpPort,
                    driverType: driver.driverType,
                    value: value,
                    userName: driver.userName,
                    authenticationProtocol: driver.authenticationProtocol,
                    authenticationPassphrase: driver.authenticationPassphrase,
                    privacyProtocol: driver.privacyProtocol,
                    privacyPassphrase: driver.privacyPassphrase
                });
            };

            //region AlarmInfoData

            /**
             * @ngdoc function
             * @description Function to retrieve alarm info data for given room and time
             * @param {number} roomId the id of the room to get info for
             * @param {number} stamp the timestamp to use
             * @param {object} requestCanceller function to cancel running request if needed
             * @returns {*}
             */
            var getAlarmInfoData = function (roomId, stamp, requestCanceller) {
                return $http.post("/api/rooms/alarmData", {
                    roomId: roomId,
                    stamp: stamp
                }, {timeout: requestCanceller.promise}).then(function (response) {
                    return response;
                }, function (error) {
                    $log.warn("Error retrieving alarm info data\n", error);
                });
            };
            //endregion

            var importRoom = function (data, driversIncluded, locationId, buildingId) {
                var header = {
                    transformRequest: angular.identity,
                    'Content-Type': undefined
                };
                return $http.post("api/rooms/import/" + locationId + "/" + buildingId + "/" + driversIncluded,
                    data, {headers: header}
                );
            };

            var getRoomInfo = function (locationId) {
                return $http.get("api/rooms/info/" + locationId);
            };

            /**
             * @ngdoc function
             * @description Function to build data structure for a tree control
             * @param arr {Array} - array to hold the tree structure
             * @param room {Room} - room object for which the tree structure should be built
             * @param rackFolderName {String} - translation for rack folder name
             * @param coolingFolderName {String} - translation for cooling folder name
             * @param sensorFolderName {String} - translation for sensor folder name
             * @param assetFolderName {String} - translation for asset folder name
             * @param floorTileFolderName {String} - translation for floortile folder name
             * @param ventductFolderName {String} - translation for vent duct folder name
             * @param upsFolderName {String} - translation for ups folder name
             * @param containmentFolderName {String} - translation for containment folder name
             * @param slotFolderName {String} - translation for slot folder name
             * @param bladeFolderName {String} - translation for blade folder name
             * @param cpuFolderName  {String} - translation for cpu folder name
             * @param includeDriverValue {Boolean} - true if driver value should be included in tree structure, false if not
             * @param parentNode {Object} - parent tree structure
             * @returns {Array} returns tree structure
             */
            var buildRoomTreeContent = function (arr, room, rackFolderName, coolingFolderName, sensorFolderName, assetFolderName, floorTileFolderName, ventductFolderName, upsFolderName, containmentFolderName, slotFolderName, bladeFolderName, cpuFolderName, includeDriverValue, parentNode) {
                var ret = arr && arr instanceof Array ? arr : [];
                if (ret.length) ret = [];

                if (parentNode === undefined || parentNode === null) parentNode = {tid: null};

                var buildEntityNodeSimple = function (entity, idPrefix, typeName, allowChildren, parent, parentNodeObj) {
                    var obj = {
                        name: $translate.instant(entity.name),
                        tid: idPrefix + entity.id,
                        id: entity.id,
                        uid: entity.uniqueId,
                        typeName: typeName,
                        path: parent + " / " + entity.name
                    };
                    if (allowChildren) obj.children = [];
                    if (Tools.isDefinedNotNull(parentNodeObj)) obj.parent = parentNodeObj.tid;
                    return obj;
                };

                var addDriverValuesToObj = function (obj, dvs, parent) {
                    if (dvs === undefined || dvs === null || dvs.length === 0) return;
                    obj.folder = true;
                    for (var dv = 0; dv < dvs.length; dv++) {
                        obj.children.push(buildEntityNodeSimple(dvs[dv], "dv", "driverValue", false, parent, obj));
                    }
                };

                var handleNonNestedObject = function (obj, subObjects, prefix, typeName, parent) {
                    for (var i = 0; i < subObjects.length; i++) {
                        var so = buildEntityNodeSimple(subObjects[i], prefix, typeName, includeDriverValue, parent, obj);
                        if (includeDriverValue) addDriverValuesToObj(so, subObjects[i].driverValues, (parent + " / " + subObjects[i].name), so);
                        obj.children.push(so);
                    }
                };

                var r, s, b, c, rackFolder, slotFolder, bladeFolder, cpuFolder, rackObj, slotObj, bladeObj, cpuObj;

                if (room.racks.length && !includeDriverValue) {
                    rackFolder = {
                        name: rackFolderName,
                        id: room.id + "|Rack",
                        tid: room.id + "|Rack",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    };
                    ret.push(rackFolder);
                    for (r = 0; r < room.racks.length; r++) {
                        rackObj = buildEntityNodeSimple(room.racks[r], "ra", "rack", true, room.name, rackFolder);
                        rackFolder.children.push(rackObj);
                        for (s = 0; s < room.racks[r].slots.length; s++) {
                            slotObj = buildEntityNodeSimple(room.racks[r].slots[s], "sl", "slot", true, room.name + " / " + room.racks[r].name, rackObj);
                            rackObj.children.push(slotObj);
                            if (room.racks[r].slots[s].blades.length > 1) {
                                for (b = 0; b < room.racks[r].slots[s].blades.length; b++) {
                                    bladeObj = buildEntityNodeSimple(room.racks[r].slots[s].blades[b], "bl", "blade", true, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s], slotObj);
                                    slotObj.children.push(bladeObj);
                                    for (c = 0; c < room.racks[r].slots[s].blades[b].cpus.length; c++) {
                                        cpuObj = buildEntityNodeSimple(room.racks[r].slots[s].blades[b].cpus[c], "cpu", "cpu", false, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name + " / " + room.racks[r].slots[s].blades[b].name, bladeObj);
                                        bladeObj.children.push(cpuObj);
                                    }
                                }
                            } else {
                                for (b = 0; b < room.racks[r].slots[s].blades.length; b++) {
                                    for (c = 0; c < room.racks[r].slots[s].blades[b].cpus.length; c++) {
                                        cpuObj = buildEntityNodeSimple(room.racks[r].slots[s].blades[b].cpus[c], "cpu", "cpu", false, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name, slotObj);
                                        slotObj.children.push(cpuObj);
                                    }
                                }
                            }
                        }
                    }
                }

                if (room.racks.length && includeDriverValue) {
                    rackFolder = {
                        name: rackFolderName,
                        tid: room.id + "|Rack",
                        id: room.id + "|Rack",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    };
                    ret.push(rackFolder);
                    for (r = 0; r < room.racks.length; r++) {
                        rackObj = buildEntityNodeSimple(room.racks[r], "ra", "rack", true, room.name, rackFolder);
                        addDriverValuesToObj(rackObj, room.racks[r].driverValues, room.name + " / " + room.racks[r].name, rackObj);
                        slotFolder = {
                            name: slotFolderName,
                            id: room.racks[r].id + "|Slot",
                            tid: room.racks[r].id + "|Slot",
                            children: [],
                            folder: true,
                            parent: rackObj.tid
                        };
                        for (s = 0; s < room.racks[r].slots.length; s++) {
                            slotObj = buildEntityNodeSimple(room.racks[r].slots[s], "sl", "slot", true, room.name + " / " + room.racks[r].name, slotFolder);
                            addDriverValuesToObj(slotObj, room.racks[r].slots[s].driverValues, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name, slotObj);
                            slotFolder.children.push(slotObj);
                            if (room.racks[r].slots[s].blades.length > 1) {
                                bladeFolder = {
                                    name: bladeFolderName,
                                    id: room.racks[r].slots[s].id + "|Blade",
                                    tid: room.racks[r].slots[s].id + "|Blade",
                                    children: [],
                                    folder: true,
                                    parent: slotObj.tid
                                };
                                for (b = 0; b < room.racks[r].slots[s].blades.length; b++) {
                                    bladeObj = buildEntityNodeSimple(room.racks[r].slots[s].blades[b], "bl", "blade", true, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name, bladeFolder);
                                    addDriverValuesToObj(bladeObj, room.racks[r].slots[s].blades[b].driverValues, room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name + " / " + room.racks[r].slots[s].blades[b].name, bladeObj);
                                    cpuFolder = {
                                        name: cpuFolderName,
                                        id: room.racks[r].slots[s].blades[b].id + "|Cpu",
                                        tid: room.racks[r].slots[s].blades[b].id + "|Cpu",
                                        children: [],
                                        folder: true,
                                        parent: bladeObj.tid
                                    };
                                    handleNonNestedObject(cpuFolder, room.racks[r].slots[s].blades[b].cpus, "cpu", "cpu", room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name + " / " + room.racks[r].slots[s].blades[b].name, cpuFolder);
                                    bladeObj.children.push(cpuFolder);
                                    bladeFolder.children.push(bladeObj);
                                }
                                slotObj.children.push(bladeFolder);
                            } else {
                                for (b = 0; b < room.racks[r].slots[s].blades.length; b++) {
                                    cpuFolder = {
                                        name: cpuFolderName,
                                        id: room.racks[r].slots[s].id + "|Cpu",
                                        tid: room.racks[r].slots[s].id + "|Cpu",
                                        children: [],
                                        folder: true,
                                        parent: slotObj.tid
                                    };
                                    handleNonNestedObject(cpuFolder, room.racks[r].slots[s].blades[b].cpus, "cpu", "cpu", room.name + " / " + room.racks[r].name + " / " + room.racks[r].slots[s].name, cpuFolder);
                                    slotObj.children.push(cpuFolder);
                                }
                            }
                        }
                        rackObj.children.push(slotFolder);
                        ret[0].children.push(rackObj);
                    }
                }

                if (room.coolings.length) {
                    ret.push({
                        name: coolingFolderName,
                        id: room.id + "|Cooling",
                        tid: room.id + "|Cooling",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.coolings, "co", "cooling", room.name);
                }
                if (room.sensors.length) {
                    ret.push({
                        name: sensorFolderName,
                        id: room.id + "|Sensor",
                        tid: room.id + "|Sensor",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.sensors, "sen", "sensor", room.name);
                }
                if (room.assets.length) {
                    ret.push({
                        name: assetFolderName,
                        id: room.id + "|Asset",
                        tid: room.id + "|Asset",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.assets, "ass", "asset", room.name);
                }
                if (room.floorTiles.length) {
                    ret.push({
                        name: floorTileFolderName,
                        id: room.id + "|FloorTile",
                        tid: room.id + "|FloorTile",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.floorTiles, "ft", "floortile", room.name);
                }
                if (room.ventducts.length) {
                    ret.push({
                        name: ventductFolderName,
                        id: room.id + "|Ventduct",
                        tid: room.id + "|Ventduct",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.ventducts, "vd", "ventduct", room.name);
                }
                if (room.ups.length) {
                    ret.push({
                        name: upsFolderName,
                        id: room.id + "|Ups",
                        children: [],
                        folder: true,
                        parent: parentNode
                    });
                    handleNonNestedObject(ret[ret.length - 1], room.ups, "ups", "ups", room.name);
                }
                if (room.roomObjs.length && room.hasContainments() && containmentFolderName) {
                    ret.push({
                        name: containmentFolderName,
                        id: room.id + "|Containment",
                        tid: room.id + "|Containment",
                        children: [],
                        folder: true,
                        parent: parentNode.tid
                    });
                    // var containmentArray = room.roomObjs.filter(function(elem){
                    //     return Containment.isContainment(elem.type);
                    // });
                    //
                    // handleNonNestedObject(ret[ret.length - 1], containmentArray, "con", "containment", room.name);

                    for (var ro in room.roomObjs) {
                        if (Containment.isContainment(room.roomObjs[ro].type)) {
                            ret[ret.length - 1].children.push(buildEntityNodeSimple(room.roomObjs[ro], "con", "containment", room.name));
                        }
                    }
                }
                return ret;
            };

            /**
             * @ngdoc function
             * @description Function to remove driver values which can not provide live data
             * @param room {Room} - the room object to remove "non-live-data" driver values from
             * @param allowIndividualPhysType {Boolean} - true if individual type driver values are to be allowed, false if individual type driver values should be excluded
             */
            var removeNonLiveDataEntitiesFromRoom = function (room, allowIndividualPhysType) {
                var driverValueEligible = function (dv) {
                    var stage0 = (dv.deviceMac !== null && dv.deviceMac !== undefined && dv.deviceMac > 0);
                    if (allowIndividualPhysType || !stage0) return stage0;
                    return dv.parameter.physType !== 6;
                };

                var processArray = function (arr) {
                    for (var i = arr.length - 1; i >= 0; i--) {
                        if (arr[i].driverValues.length === 0) {
                            arr.splice(i, 1);
                            continue;
                        }
                        var eligibleDvs = arr[i].driverValues.filter(function (dv) {
                            return driverValueEligible(dv);
                        });
                        if (eligibleDvs.length) {
                            arr[i].driverValues = eligibleDvs;
                        } else {
                            arr.splice(i, 1);
                        }
                    }
                };

                processArray(room.assets);
                processArray(room.coolings);
                processArray(room.floorTiles);
                processArray(room.sensors);
                processArray(room.ups);

                var eligibleDvs;

                for (var i = room.racks.length - 1; i >= 0; i--) {
                    for (var j = room.racks[i].slots.length - 1; j >= 0; j--) {
                        for (var k = room.racks[i].slots[j].blades.length - 1; k >= 0; k--) {
                            processArray(room.racks[i].slots[j].blades[k].cpus);
                            if (room.racks[i].slots[j].blades[k].driverValues.length === 0 && room.racks[i].slots[j].blades[k].cpus.length === 0) {
                                room.racks[i].slots[j].blades.splice(k, 1);
                                continue;
                            }
                            eligibleDvs = room.racks[i].slots[j].blades[k].driverValues.filter(function (dv) {
                                return driverValueEligible(dv);
                            });
                            if (eligibleDvs.length) room.racks[i].slots[j].blades[k].driverValues = eligibleDvs;
                            if (eligibleDvs.length === 0 && room.racks[i].slots[j].blades[k].cpus.length === 0) room.racks[i].slots[j].blades.splice(k, 1);
                        }
                        if (room.racks[i].slots[j].driverValues.length === 0 && room.racks[i].slots[j].blades.length === 0) {
                            room.racks[i].slots.splice(j, 1);
                            continue;
                        }
                        eligibleDvs = room.racks[i].slots[j].driverValues.filter(function (dv) {
                            return driverValueEligible(dv);
                        });
                        if (eligibleDvs.length) room.racks[i].slots[j].driverValues = eligibleDvs;
                        if (eligibleDvs.length === 0 && room.racks[i].slots[j].blades.length === 0) room.racks[i].slots.splice(j, 1);
                    }
                    if (room.racks[i].driverValues.length === 0 && room.racks[i].slots.length === 0) {
                        room.racks.splice(i, 1);
                        continue;
                    }
                    eligibleDvs = room.racks[i].driverValues.filter(function (dv) {
                        return driverValueEligible(dv);
                    });
                    if (eligibleDvs.length) room.racks[i].driverValues = eligibleDvs;
                    if (eligibleDvs.length === 0 && room.racks[i].slots.length === 0) room.racks.splice(i, 1);
                }

                if (room.assets.length === 0 && room.coolings.length === 0 && room.floorTiles.length === 0 && room.racks.length === 0 && room.sensors.length === 0 && room.ups.length === 0) room.ignoreInDvList = true;
            };

            /**
             * @ngdoc function
             * @description Function to a object in a list of rooms by its unique id
             * @param rooms {Array} - array of rooms to search
             * @param uniqueId {Number} - unique id to serach for
             * @returns {Object} - returns null if nothing was found, otherwise returns the found object
             */
            var findObjectByUniqueId = function (rooms, uniqueId) {
                for (var i = 0; i < rooms.length; i++) {
                    var result = rooms[i].findObjectByUniqueID(uniqueId);
                    if (result !== null) return result;
                }
                return null;
            };

            var isDriverAlreadySet = function (currentWirelessSensorId) {
                return $http.get("/api/rooms/isDriverAlreadySet/" + currentWirelessSensorId);
            };

            var getIdsOfGatewaySensors = function (list) {
                return $http.post("/api/rooms/getIdOfGatewaySensor/", list);
            };

            return {
                importRoom: importRoom,
                updateRoomJson: updateRoomJson,
                getRoomInfo: getRoomInfo,
                colorizeRoomTemp: colorizeRoomTempV2,
                getRooms: getRooms,
                getSimpleRooms: getSimpleRooms,
                getSimpleRoomsByBuilding: getSimpleRoomsByBuilding,
                getFloorRooms: getFloorRooms,
                getRoom: getRoom,
                saveRoom: saveRoom,
                deleteRoom: deleteRoom,
                getEntity: getEntity,
                deleteCpuEntity: deleteCpuEntity,
                restoreAllDriverValue: restoreAllDriverValue,
                tempSetDriverValueDeletedFlag: tempSetDriverValueDeletedFlag,
                deleteAllReferenceForDeletedDriverValueIds: deleteAllReferenceForDeletedDriverValueIds,
                deleteAllReferenceForDeletedDriverValueModbusUids: deleteAllReferenceForDeletedDriverValueModbusUids,
                deleteDriverValue: deleteDriverValue,
                deleteUnsavedEntity: deleteUnsavedEntity,
                sendEntityValue: sendEntityValue,
                sendEntityValueSNMPv3: sendEntityValueSNMPv3,
                getAlarmInfoData: getAlarmInfoData,
                buildRoomTreeContent: buildRoomTreeContent,
                removeNonLiveDataEntitiesFromRoom: removeNonLiveDataEntitiesFromRoom,
                findObjectByUniqueId: findObjectByUniqueId,
                isDriverAlreadySet: isDriverAlreadySet,
                getIdsOfGatewaySensors: getIdsOfGatewaySensors
            };
        });
})();
