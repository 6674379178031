(function () {
    'use strict';
    angular.module('emsv2App').service('OIDValueService', function ($log, $http, Notify) {

        function _getSnmpValueForDriverValueId(driverValueId) {
            return $http.get("api/snmpInterface/" + driverValueId);
        }

        function _getValueForSnmp(drivervalue) {
            return $http.post("api/snmpInterface/getValueForSnmp", drivervalue).then(
                function (response) {
                    return response.data
                },
                function (error) {
                    Notify.error("global.notification.error.title", "room.driver.getAndSet.getValueMessage.badConfig", 5000);
                }
            )
        }

        function _setValueForSnmp(drivervalue, value) {
            return $http.post("api/snmpInterface/setValueForSnmp/" + value, drivervalue).then(
                function (response) {
                    return response.data
                },
                function (error) {
                    if (error.status === 500 && (error.data.message === "Argument must be an unsigned 32bit value" || error.data.message.includes("Error: Request Failed") || error.data.message === "No such name")) {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 5000);
                    } else {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.badConfig", 5000);
                    }
                }
            )
        }

        function _setAndReset(dv, ip, oid, value) {
            return $http.post("api/snmpInterface/setReset/" + ip + "/" + oid + "/" + value, dv).then(
                function (response) {
                    return response.data
                },
                function (error) {
                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.badConfig", 5000);
                }
            )
        }

        function _restart(driverValue, delay, value) {
            return $http.post("api/snmpInterface/restart/" + delay + "/" + value, driverValue);
        }

        return {
            getSnmpValueForDriverValueId: _getSnmpValueForDriverValueId,
            getValueForSnmp: _getValueForSnmp,
            setAndReset: _setAndReset,
            restart: _restart,
            setValueForSnmp: _setValueForSnmp
        }
    })
})();

