(function () {
    'use strict';

    var translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('token');
        $translatePartialLoader.addPart('rsqltable');
        return $translate.refresh();
    }];

    var roles = ['$log', 'Authority', function ($log, Authority) {
        return Authority.getAll().then(function (response) {
            var roles = response.data;
            Object.freeze(roles);
            return roles;
        }, function (error) {
            $log.error("Couldn't load roles!", error);
            return [];
        });
    }];

    var currentUser = ['$log', 'Principal', '$state', function ($log, Principal, $state) {
        return Principal.identity().then(function (user) {
            if (user.hasRole('ROLE_ADMIN')) {
                return user;
            }
            else {
                $state.go("accessdenied");

            }
        }, function (error) {
            $state.go("notfound");
            $log.error('Couldn\'t load user: ', error);
        });
    }];

    angular
        .module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('token', {
                    parent: 'admin',
                    abstract: true
                })
                .state('tokenOverview', {
                    parent: 'token',
                    url: '/token',
                    data: {
                        roles: ['ROLE_ADMIN'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'token.management.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/externalapi/overviewtoken/overview.token.html',
                            controller: 'OverviewTokenController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        currentUser: currentUser
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('newToken', {
                    parent: 'tokenOverview',
                    url: '/new',
                    data: {
                        roles: ['ROLE_ADMIN'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'token.management.new.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/externalapi/newtoken/new.token.html',
                            controller: 'NewTokenController',
                            controllerAs: 'ctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        currentUser: currentUser
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
        });
})();
