(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('SettingsController', function ($log, $scope, $state, $stateParams, $translate, $cookies, $window, $timeout, Notify, $q,
                                                    License, Principal, Auth, Tools, Language, LANGUAGES, EndPoint,
                                                    GFXSettingsService, ThemeService, EMSConstants, MessageService,
                                                    selectedLanguage, userInfo, rsqlQueries, GenDialogService, localStorageService) {
            var ctrl = this;
            var initSettings = null;
            ctrl.lang = {
                list: LANGUAGES,
                saved: selectedLanguage,
                selected: selectedLanguage
            };
            ctrl.settings = {
                title: '',
                account: userInfo,
                emailExists: false,
                filterlist: rsqlQueries,
                count: 0,
                playAlarmSound: null,
                temp: null,
                theme: null,
                weight: null,
                save: _saveSettings
            };
            ctrl.password = {
                doNotMatch: null,
                title: '',
                change: _changePassword,
                check: _checkPassword,
                form: {
                    currentPassword: '',
                    password: '',
                    confirmPassword: ''
                }
            };
            ctrl.temperatureUnit = {
                title: '',
                selectedUnit: '',
                save: _saveSettings
            };
            ctrl.graphicSettings = {
                title: '',
                displayMode: '',
                zoomSteps: '',
                antiAliasing: '',
                save: _saveSettings
            };
            ctrl.themeSettings = {
                title: '',
                save: _saveSettings
            };
            ctrl.startroute = {
                routes: [],
                selected: null
            };

            ctrl.statusHostedPublicly = false;

            ctrl.$onInit = function () {
                _getHostedPublicly();
                _loadStartroutes();
                loadSettings(ctrl.settings.account.settings);
            };

            function _getHostedPublicly() {
                License.get().then(function (response) {
                    ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                })
            }

            function loadSettings(settings) {
                loadSettingTranslation(settings);
                initSettings = angular.copy(ctrl.settings);
            }

            ctrl.compareSettingsValues = function (settingsA, settingsB) {

                var aProps = Object.getOwnPropertyNames(settingsA);
                var bProps = Object.getOwnPropertyNames(settingsB);

                //check for account
                if (settingsA.account.contact["firstname"] !== settingsB.account.contact["firstname"]) return true;
                if (settingsA.account.contact["lastname"] !== settingsB.account.contact["lastname"]) return true;
                if (settingsA.account.contact["email"] !== settingsB.account.contact["email"]) return true;
                if (settingsA.account.contact["mobile"] !== settingsB.account.contact["mobile"]) return true;

                //check for start route
                if (settingsA.account.startroute !== ctrl.startroute.selected.value) {
                    if (ctrl.startroute.selected.value !== null) return true;
                }

                //check for selected language
                if (settingsA.account.langKey !== ctrl.lang.selected) return true;
                //check notification checked
                if (settingsA.account.notify !== settingsB.account.notify) return true;

                //check Alarm Sound checked
                if (settingsA.playAlarmSound !== settingsB.playAlarmSound) return true;

                //check for weight settings
                if (settingsA.weight["id"] !== settingsB.weight["id"]) return true;

                //check for temp settings
                if (settingsA.temp["id"] !== settingsB.temp["id"]) return true;

                //check alarm settings
                // if (settingsA.countQuitedAlarms["id"] !== settingsB.countQuitedAlarms["id"]) return true;

                //check theme
                if (settingsA.theme["id"] !== settingsB.theme["id"]) return true;
                // check graphic settings
                if (settingsA.account.graphics["aa"] !== settingsB.account.graphics["aa"]) return true;
                if (settingsA.account.graphics["zoom"] !== settingsB.account.graphics["zoom"]) return true;
                return (settingsA.account.graphics["displayMode"] !== settingsB.account.graphics["displayMode"]);
            };

            $window.onbeforeunload = function (e) {
                if (ctrl.evalSettingsChange()) {
                    e.preventDefault();
                    return $translate.instant('location.modal.changedValues');
                }
            };

            ctrl.evalSettingsChange = function () {
                var currSettings = angular.copy(ctrl.settings);
                if (ctrl.compareSettingsValues(initSettings, currSettings)) return true
            };

            var ignoreNextStateChange = false;
            $scope.$on("$stateChangeStart", function (event, toState, toParams) {
                if (!ignoreNextStateChange && ctrl.evalSettingsChange() && toState.name !== "account.settings") {
                    event.preventDefault();
                    GenDialogService.showDialog(false, {
                        headText: $translate.instant('global.dialog.head.warning'),
                        headIcon: 'glyphicon glyphicon-warning-sign',
                        messageText: $translate.instant('location.modal.changedValues'),
                        showClose: false,
                        textButton1: $translate.instant('location.modal.empty.yes'),
                        textButton0: $translate.instant('location.modal.empty.no'),
                        iconButton1: 'glyphicon glyphicon-trash',
                        iconButton0: 'glyphicon glyphicon-chevron-left',
                        classButton1: 'btn-danger',
                        classButton0: 'btn-default',
                        callbackButton1: function () {
                            GenDialogService.hideDialog();
                            ignoreNextStateChange = true;
                            initSettings = null;
                            $state.go(toState.name, toParams);
                        },
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        }
                    });
                }
            });

            ctrl.isCustomRoute = function () {
                return ctrl.startroute.selected !== null && ctrl.startroute.selected.custom === true;
            };

            ctrl.tempChange = function (temp) {
                var tempSetting = findSetting(ctrl.settings.account.settings, 'temp');
                if (parseInt(tempSetting.value) !== temp.id) {
                    updateAccountSetting("temp", temp.id)
                }
            };

            ctrl.weightChange = function (weight) {
                var weightSetting = findSetting(ctrl.settings.account.settings, 'weight');
                if (parseInt(weightSetting.value) !== weight.id) {
                    updateAccountSetting("weight", weight.id)
                }
            };

            ctrl.themeChange = function (theme) {
                var themeSetting = findSetting(ctrl.settings.account.settings, 'theme');
                if (parseInt(themeSetting.value) !== theme.id) {
                    updateAccountSetting("theme", theme.id)
                }
            };

            ctrl.changePlayAlarmSound = function () {
                updateAccountSetting("playAlarmSound", ctrl.settings.playAlarmSound);
            }

            function updateAccountSetting(key, value) {
                for (var i = 0; i < ctrl.settings.account.settings.length; i++) {
                    if (ctrl.settings.account.settings[i].key === key) {
                        ctrl.settings.account.settings[i].value = value;
                    }
                }
            }

            ctrl.onMailChanged = function (email, form) {
                ctrl.settings.emailExists = false;
                if (!email || email < 3) {
                    return;
                }

                Auth.checkEmailExists(email).then(function (result) {
                    ctrl.settings.emailExists = result.data;
                    form.email = result.data;
                }, function (error) {
                    $log.error('Couldn\'t check email.', error);
                });
            };

            ctrl.onSecondaryMailChanged = function (secondaryEmail, form) {
                ctrl.settings.secondaryEmailExists = false;
                if (!secondaryEmail || secondaryEmail < 3) {
                    return;
                }
            };

            function hasRole(role) {
                return Principal.isInRole(role);
            }

            function loadSettingTranslation(userSettings) {

                let playAlarmSoundSetting = findSetting(userSettings, 'playAlarmSound');
                if (playAlarmSoundSetting !== undefined) {
                    let result = playAlarmSoundSetting.value === 'true' || playAlarmSoundSetting.value === true;
                    ctrl.settings.playAlarmSound = result;
                } else {
                    ctrl.settings.playAlarmSound = true;
                    ctrl.settings.account.settings.push(createSetting('playAlarmSound', ctrl.settings.playAlarmSound));
                }

                ctrl.tempOptions = [
                    {id: 0, name: $translate.instant('settings.select.optionsTemp.celsius')},
                    {id: 1, name: $translate.instant('settings.select.optionsTemp.fahrenheit')},
                    {id: 2, name: $translate.instant('settings.select.optionsTemp.kelvin')}
                ];

                var tempSetting = findSetting(userSettings, 'temp');
                if (tempSetting !== undefined) {
                    ctrl.settings.temp = ctrl.tempOptions[tempSetting.value];
                } else {
                    ctrl.settings.temp = ctrl.tempOptions[0];
                    ctrl.settings.account.settings.push(createSetting('temp', ctrl.settings.temp.id));
                }

                ctrl.themeOptions = [
                    {id: 0, name: $translate.instant('settings.select.optionsTheme.light')},
                    {id: 1, name: $translate.instant('settings.select.optionsTheme.dark')},
                ];

                var themeSetting = findSetting(userSettings, 'theme');
                if (themeSetting !== undefined) {
                    ctrl.settings.theme = ctrl.themeOptions[themeSetting.value];
                } else {
                    ctrl.settings.theme = ctrl.themeOptions[0];
                    ctrl.settings.account.settings.push(createSetting('theme', ctrl.settings.theme.id));
                }

                ctrl.weightOptions = [
                    {id: 0, name: $translate.instant('settings.select.optionsWeight.std')},
                    {id: 1, name: $translate.instant('settings.select.optionsWeight.us')},
                ];

                var weightSetting = findSetting(userSettings, 'weight');
                if (weightSetting !== undefined) {
                    ctrl.settings.weight = ctrl.weightOptions[weightSetting.value];
                } else {
                    ctrl.settings.weight = ctrl.weightOptions[0];
                    ctrl.settings.account.settings.push(createSetting('weight', ctrl.settings.weight.id));
                }
            }

            function _loadStartroutes() {
                EndPoint.get().then(function (response) {
                    var key = Object.keys(response.data);
                    var value = Object.values(response.data);
                    ctrl.startroute.routes.push(_createStartroute(null, "global.url.default"));
                    for (var i = 0; i < key.length; i++) {
                        if (hasRole(key[i])) {
                            for (var j = 0; j < value[i].length; j++) {
                                if (!checkRouteExist(value[i][j])) {
                                    ctrl.startroute.routes.push(_createStartroute(value[i][j], "global.url." + value[i][j]));
                                }
                            }
                        }
                    }
                    for (var i in ctrl.startroute.routes) {
                        // ctrl.startroute.routes.sort((a, b) => a.label.localeCompare(b.label));
                        ctrl.startroute.routes.sort(function (a, b) {
                            if(a.label === b.label) return 0;
                            if (a.label === 'Automatically (Default)') return -1;
                            if (b.label === 'Automatically (Default)') return 1;
                            if (a.label === 'Automatisch (Standard)') return -1;
                            if (b.label === 'Automatisch (Standard)') return 1;
                            if (a.label < b.label)
                                return -1;
                            if (a.label > b.label)
                                return 1;
                            return 0;
                        });
                        if (ctrl.startroute.routes[i].value === "/benchmark") {
                            var idx = i;
                            ctrl.startroute.routes.splice(idx, 1);
                            break;
                        }
                    }
                    ctrl.startroute.selected = _getLinkByValue(userInfo.startroute);
                });
            }

            function checkRouteExist(val) {
                for (var j = 0; j < ctrl.startroute.routes.length; j++) {
                    if (ctrl.startroute.routes[j].value === val) return true;
                }
                return false;
            }

            function _getLinkByValue(val) {
                for (var i = 0; i < ctrl.startroute.routes.length; i++) {
                    if (ctrl.startroute.routes[i].value === val) return ctrl.startroute.routes[i];
                }
                return ctrl.startroute.routes[0];
            }

            function _createStartroute(val, label) {
                return {
                    value: val,
                    translationId: label,
                    label: $translate.instant(label)
                }
            }

            function _translateStartroutes() {
                for (var i = 0; i < ctrl.startroute.routes.length; i++) {
                    ctrl.startroute.routes[i].label = $translate.instant(ctrl.startroute.routes[i].translationId);
                }
            }

            function _changePassword() {
                Auth.changePasswordSecure(ctrl.password.form.password, ctrl.password.form.currentPassword).then(function (response) {
                    if (response.status === 204) {
                        Notify.error("global.notification.error.title", "settings.messages.password.error", 3000);
                    } else if (response.status === 200) {
                        Notify.success("global.notification.success.title", "settings.messages.password.success", 2000);
                    }
                }, function () {
                    Notify.error("global.notification.error.title", "settings.messages.password.error", 3000);
                });
            }

            function _checkPassword() {
                return Tools.isDefinedNotNull(ctrl.password.form.password)
                    && Tools.isDefinedNotNull(ctrl.password.form.confirmPassword)
                    && ctrl.password.form.password != ''
                    && ctrl.password.form.confirmPassword != ''
                    && ctrl.password.form.currentPassword != ''
                    && ctrl.password.form.password.length >= 5
                    && ctrl.password.form.password !== ctrl.password.form.currentPassword
                    && ctrl.password.form.password === ctrl.password.form.confirmPassword;
            }

            function _saveSettings() {
                //TODO: ANBINDUNG ANS BACKEND, falls gewünscht. Derzeit werden die Werte nicht in Tabellen abgelegt
                GFXSettingsService.setSettingsForUser(ctrl.settings.account.login, ctrl.settings.account.graphics);
                var userCopy = angular.copy(ctrl.settings.account);
                userCopy.langKey = ctrl.lang.selected;
                userCopy.startroute = ctrl.startroute.selected.value || null;

                if (ctrl.lang.selected !== ctrl.lang.saved) {
                    $translate.use(ctrl.lang.selected).then(_translateStartroutes);
                    $scope.$broadcast('$translateChangeStart', {language: userCopy.langKey});
                    $translate.storage().set('NG_TRANSLATE_LANG_KEY', ctrl.lang.selected);
                }
                var expiresDate = new Date();
                expiresDate.setSeconds(60 * 60 * 24 * 365);


                if (userCopy.settings === undefined || userCopy.settings === null) {
                    userCopy.settings = [];
                }
                var themeSetting = findSetting(userCopy.settings, 'theme');
                localStorageService.set('THEME', themeSetting.value);
                loadSettings(userCopy.settings);
                Auth.updateAccount(userCopy).then(function () {
                    Principal.identity().then(function (account) {
                        Notify.defaultSuccess();
                        ctrl.settings.account = account;
                        ctrl.lang.saved = account.langKey;
                        MessageService.publish("userSettingsUpdated");
                        loadSettings(ctrl.settings.account.settings);
                    });
                }, function () {
                    Notify.error("global.notification.error.title", "global.notification.error.text", 3000);
                });
                if (initSettings.theme.id !== ctrl.settings.theme) {
                    ThemeService.reloadPage();
                }
            }

            function findSetting(settings, key) {
                function _findItem(item) {
                    return item.key === key;
                }

                function _hasSettings() {
                    return settings !== undefined
                        && settings !== null
                        && Array.isArray(settings);
                }

                return _hasSettings()
                    ? settings.find(_findItem)
                    : undefined;
            }

            function createSetting(key, value) {
                ctrl.settings.count--;
                return {
                    id: ctrl.settings.count,
                    userId: ctrl.settings.account.id,
                    key: key,
                    value: value
                };
            }

        });
})();
