(function () {
    'use strict';

    function auditLogController($state, $log, $window, $scope, $translate, $uibModal, AuditLogService) {

        var ctrl = this;
        var detailsModal;
        ctrl.logs = [];
        ctrl.filtered = false;
        ctrl.selectedPage = 0;
        ctrl.selectedFilterColumn = '';
        ctrl.filterQuery = '';
        ctrl.pageNums = [];
        ctrl.selectedDetails;
        ctrl.tableModel = {
            count: 0,
            tableName: 'audit_log',
            translation: 'logfiles.audit',
            sortBy: 'CREATED_AT',
            direction: 'DESC',
            orderDir: false,
            head: [
                {name: 'createdAt', sortBy: 'CREATED_AT', translation: 'logfiles.audit.table.timestamp', style: 'cursor: pointer; max-width:150px; min-width: 150px;'},
                {name: 'username', sortBy: 'USERNAME', translation: 'logfiles.audit.table.username', style: 'cursor: pointer; max-width:100px; min-width: 100px;'},
                {name: 'sessionId', sortBy: 'SESSION_ID', translation: 'logfiles.audit.table.sessionid', style: 'cursor: pointer; max-width:275px; min-width: 275px;'},
                {name: 'status', sortBy: 'STATUS', translation: 'logfiles.audit.table.status', style: 'cursor: pointer; max-width:100px; min-width: 100px;'},
                {name: 'action', sortBy: 'MESSAGE', translation: 'logfiles.audit.table.action', style: 'cursor: pointer; max-width:250px; min-width: 250px;'},
                {name: 'requestUri', sortBy: 'REQUEST_URI', translation: 'logfiles.audit.table.requesturi', style: 'cursor: pointer; max-width:300px; min-width: 300px;'},
                {name: 'requestBody', sortBy: 'REQUEST_BODY', translation: 'logfiles.audit.table.requestbody', style: 'cursor: pointer; pointer; max-width:250px; min-width: 250px;'},
                {name: 'responseBody', sortBy: 'RESPONSE_BODY', translation: 'logfiles.audit.table.responsebody', style: 'cursor: pointer; max-width:250px; min-width: 250px;'}
            ],
            filterables: [
                {value:'USERNAME', label: 'logfiles.audit.table.username'},
                {value: 'SESSION_ID', label: 'logfiles.audit.table.sessionid'},
                {value: 'STATUS', label: 'logfiles.audit.table.status'},
                {value: 'MESSAGE', label: 'logfiles.audit.table.action'},
            ]
        };


        $scope.$on('$destroy', function () {
        });

        ctrl.$onInit = function(){
           loadLogs(ctrl.tableModel.sortBy, ctrl.tableModel.direction);
        };

        ctrl.changeTab = function (tabStateName) {
            if (tabStateName != null) {
                $state.go(tabStateName);
            }
        };

        ctrl.sortTable = function(sortBy){
            ctrl.tableModel.direction = ctrl.tableModel.direction === 'ASC' ? 'DESC' : 'ASC';
            ctrl.tableModel.sortBy = sortBy;
            loadLogs();
        };

        ctrl.filter = function(){
            ctrl.filtered = true;
            ctrl.selectedPage = 0;
            loadLogs();
        };

        ctrl.selectPage = function(page){
            if(page >= 0 && page <= ctrl.pageNums.length){
                ctrl.selectedPage = page;
                loadLogs();
            }
        };
        var loadLogs = function(){
            AuditLogService.get(ctrl.tableModel.sortBy, ctrl.tableModel.direction, ctrl.filterQuery, ctrl.selectedFilterColumn, ctrl.selectedPage).then(function(response){
                ctrl.logs = [];
                if(response.data){
                   // response.data
                    ctrl.selectedPage = response.data.page;
                    ctrl.pageNums = calculatePageNumbers(response.data.totalRows);
                    response.data.data.forEach(function(log){
                        var timestamp = parseFloat(log.timestamp).toFixed(3);
                        var addLog = log;
                        addLog.timestamp = moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
                        ctrl.logs.push(addLog);
                    });
                }
            });
        };

        ctrl.cutText = function(text){
            if(text.length < 25){
                return text;
            }
            return text.substring(0,25);
        }

        ctrl.loadDetails = function(details){
            delete ctrl.selectedDetails;
            if(details){
                ctrl.selectedDetails = JSON.stringify(JSON.parse(details), false,"\t");
                ctrl.openSettings();
            }
        };

        ctrl.openSettings = function() {
            detailsModal = $uibModal.open({
                templateUrl: 'scripts/app/admin/logfiles/audit/audit-details.modal.html',
                animation: false,
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
            detailsModal.closed.then(function(){
                ctrl.closeDetails();
            });
        };

        ctrl.closeDetails = function () {
            if (detailsModal){
                detailsModal.close();
            }
        };

        var calculatePageNumbers = function(totalRows){
            ctrl.tableModel.count = totalRows;
            var pageNums = [];
            var maxPage = Math.ceil(ctrl.tableModel.count / 35);
            for(var i = 0; i < maxPage; i++){
                pageNums.push(i);
            }

            return pageNums;
        };

    }
    angular
        .module('emsv2App')
        .controller('auditLogController', auditLogController);
})();
