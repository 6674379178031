'use strict';

function alarmController($scope, $log, $interval, $uibModal, $q, Tools, AlarmService, IntervalService, Device, Alarm, user, Notify) {
    var ctrl = this;
    var currentModal = null;
    var warnModal = null;
    var timeoutId;
    ctrl.currentDevices = [];
    ctrl.quitReason = "";

    // Table loading/sorting
    ctrl.data = [];
    ctrl.restriction = '';
    ctrl.page = 0;
    ctrl.filterQuery = '';
    ctrl.itemsPerPage = 50;
    ctrl.doQuery = function () {
        return _query(false, false, true);
    };
    ctrl.hasMore = true;
    ctrl.tableModel = {
        orderBy: 'stamp',
        orderDir: true,
        tableName: 'alarm_view',
        translation: 'alarm.rsql',
        head: [
            {name: 'priority', translation: 'alarm.priority', sortable: true},
            {name: 'location_name', translation: 'alarm.location', sortable: true},
            // {name: 'device_name', translation: 'alarm.device_name', sortable: true},
            {name: 'stamp', translation: 'alarm.stamp'},
            {name: 'hash_text', translation: 'alarm.hash_text', sortable: false},
            {name: 'active', translation: 'alarm.active', sortable: true},
            {name: 'auto_quit', translation: 'alarm.autoquit', sortable: true},
            {name: 'mail_sent', translation: 'alarm.mailsent', sortable: true},
            {name: 'sms_sent', translation: 'alarm.smsSent', sortable: true},
            {name: 'quit', translation: 'alarm.quit', sortable: true}
        ]
    };

    ctrl.currentPrio = 'reset';
    ctrl.currentAlarm = null;
    ctrl.counts = {
        info: 0,
        warning: 0,
        error: 0
    };

    /**
     * Initialize function
     */
    ctrl.$onInit = function () {
        getCurrentErrorCount();

        // start the UI update process; save the timeoutId for canceling
        timeoutId = $interval(function () {
            getCurrentErrorCount();
            var input = angular.element('[ng-model="rsqlSearchText"]')[0].value;
            _query(true, true, true, Tools.isDefinedNotNull(input) && input !== "");
            $log.debug("Alarm: Updating error Count.");
        }, 5000);
    };

    $scope.$on('$destroy', function () {
        $interval.cancel(timeoutId);
    });

    $scope.$on("$stateChangeStart", function (event, next, current) {
        AlarmService.killRenderer();
    });

    /**
     * Sorting function
     * @param sortField represent the "orderBy"
     * @param sortReverse represent the "orderDir"
     */
    ctrl.doSort = function (sortField, sortReverse) {
        ctrl.tableModel.orderBy = sortField;
        ctrl.tableModel.orderDir = sortReverse;
        _query(true, false, true);
    };

    ctrl.doFilter = function (params, textSearch) {
        ctrl.filterQuery = params.split("?orderby")[0] || "";
        var input = angular.element('[ng-model="rsqlSearchText"')[0].value;
        _query(true, true, true, Tools.isDefinedNotNull(input) && input !== "" && textSearch);
    };

    function _query(reset, isUpdate, flush, fullTextSearch) {
        isUpdate = isUpdate === undefined ? false : isUpdate;
        flush = flush === undefined ? false : flush;
        var pageMultiplication = _preparePageMultiplication(ctrl.page, reset, isUpdate);
        var query = _buildQuery();
        var params =  getSorting();
        fullTextSearch = Tools.isDefinedNotNull(fullTextSearch) && typeof fullTextSearch === 'boolean' ? fullTextSearch : false;
        if (params === "orderby=stamp&direction=asc") fullTextSearch = false;
        query = query.length ? '/' + query : query;
        return Alarm.rsql(0, query + '?' + params , pageMultiplication * ctrl.itemsPerPage, fullTextSearch).then(function (response) {
            ctrl.hasMore = response.data.length === pageMultiplication * ctrl.itemsPerPage;
            if (!flush) {
                for (var i = 0; i < response.data.length; i++) {
                    ctrl.data.push(response.data[i]);
                }
            } else {
                ctrl.data = response.data;
                initializeDevices(ctrl.data);
            }
        }, function (error) {
            $log.error('Couldn\'t load alarms!', error);
            ctrl.data = [];
        });
    }

    function _preparePageMultiplication(page, reset, isUpdate) {
        if (!isUpdate) {
            ctrl.page = reset ? 0 : ctrl.page + 1;
        }
        return ctrl.page + 1;
    }

    function _buildQuery() {
        var tmp = [];
        if (ctrl.restriction) {
            tmp.push(ctrl.restriction);
        }
        if (ctrl.filterQuery) {
            tmp.push(ctrl.filterQuery);
        }
        if(tmp.length){
            var query = tmp.join(" and ");
            return _prepareQuery(query);
        }
            return '';
    }

    function _prepareQuery(query) {
        return query.replace(/mac_hex==([\w:\-]+)/g, function (old, mac) {
            var plainHex = mac.replace(/[:\-]/g, '');
            if (/^[0-9A-Fa-f]*$/.test(plainHex)) {
                return 'mac_hex==' + plainHex;
            }
            return old;
        });
    }

    function getSorting() {
        return 'orderby=' + ctrl.tableModel.orderBy
            + '&direction=' + (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '');
    }

    function initializeDevices(data) {
        var passed = [];
        for (var i in data) {
            var mac = data[i].mac;
            if (passed.indexOf(mac) !== -1) continue;
            getDevice(mac);
            passed.push(mac);
        }
    }

    ctrl.getAlarmIcon = function (value) {
        var level = parseInt(value / 75);
        switch (level) {
            case 0 :
                return "text-info glyphicon glyphicon-info-sign"; //fa fa-info-circle
            case 1 :
                return "text-warning fa fa-warning";
            default:
                return "text-danger glyphicon glyphicon-remove-sign"; //fa fa-bell
        }
    };

    ctrl.getRowDecoration = function (priority) {
        switch (IntervalService.getPriorityLevel(priority)) {
            case 0 :
                return "row-info";
            case 1 :
                return "row-warning";
            default:
                return "row-danger";
        }
    };

    ctrl.quit = function (alarm) {
        if (alarm.version === 4 || alarm.version === 5) {
            ctrl.alarmToQuit = alarm;
            warnModal = $uibModal.open({
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                templateUrl: 'scripts/app/alarm/alarm.modal.quickQuit.html'
            });
        } else if (alarm.version <= 3 && alarm.version > 0) {
            ctrl.alarmToQuit = alarm;
            warnModal = $uibModal.open({
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                templateUrl: 'scripts/app/alarm/alarm.quit.all.warn.html'
            });
        } else {
            Notify.error("global.notification.error.title", "alarm.notifications.noQuit", 2000);
        }
    };

    ctrl.sendQuit = function (alarmId, message) {
        AlarmService.quitAlarm(alarmId, message).then(function (response) {
            if (response.data) {
                if (response.data === true) {
                    ctrl.markAsQuit(alarmId);
                }
            }
        });
    };

    ctrl.quitV3 = function () {
        ctrl.closeWarnModal();
        ctrl.sendQuit(ctrl.alarmToQuit.idAlarm, "");
        ctrl.alarmToQuit = null;
    };

    ctrl.quitWithReason = function () {
        ctrl.closeWarnModal();
        ctrl.sendQuit(ctrl.alarmToQuit.idAlarm, ctrl.quitReason);
        ctrl.alarmToQuit = null;
        ctrl.quitReason = "";
    };

    ctrl.updateQueryPrio = function (prio) {
        ctrl.currentPrio = prio;
        if (prio === 'error') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().errorPrioStart + ')';
        } else if (prio === 'warn') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().warnPrioStart + ')' +
                ' and priority<=(' + IntervalService.getPriorityInterval().warnPrioEnd + ')';
        } else if (prio === 'info') {
            ctrl.restriction = 'priority>=(' + IntervalService.getPriorityInterval().infoPrioStart + ')' +
                ' and priority<=(' + IntervalService.getPriorityInterval().infoPrioEnd + ')';
        } else if (prio === 'reset') {
            ctrl.restriction = '';
        }
        _query(true, false, true);
    };

    function getCurrentErrorCount() {
        Alarm.getGlobalCount().then(function (response) {
            ctrl.counts = response.data;
        }, function (error) {
            $log.error('Couldn\'t update alarm counts!', error);
        });
    }

    ctrl.closeModal = function () {
        if (currentModal) currentModal.close();
        AlarmService.killRenderer();
    };

    ctrl.closeWarnModal = function () {
        if (warnModal) warnModal.close();
    };

    // Cache devices for this controller session
    function getDevice(mac) {
        var device = ctrl.currentDevices[mac];
        if (device !== null && device !== undefined) {
            return $q.when(device);
        } else {
            return Device.get(mac).then(function (response) {
                ctrl.currentDevices[response.data.mac] = response.data;
                return response.data;
            });
        }
    }

    ctrl.markAsQuit = function (idAlarm) {
        for (var alarmIndex in ctrl.data) {
            if (ctrl.data[alarmIndex].idAlarm === idAlarm) {
                ctrl.data[alarmIndex].quit = true;
                break;
            }
        }
    };

    ctrl.showDetails = function (data) {
        var isVirtualSNMP = false;
        $log.debug("data:", data);

        getDevice(data.mac).then(function (requestedDevice) {
            if (requestedDevice !== null) {
                if (requestedDevice.hwtyp.id === 0) {
                    isVirtualSNMP = true;
                }
                ctrl.closeModal();
                currentModal = $uibModal.open({
                    animation: false,
                    component: 'alarmModal',
                    windowClass: 'animated fadeInDown',
                    size: (isVirtualSNMP) ? 'lg' : '',
                    resolve: {
                        currentDevice: function () {
                            return requestedDevice;
                        },
                        currentAlarm: function () {
                            return data;
                        },
                        isVirtualSNMP: function () {
                            return isVirtualSNMP;
                        },
                        isCurrentAlarm: function () {
                            return true;
                        },
                        stamp: function () {
                            return data.stamp;
                        },
                        user: user
                    }
                });
                // Callback for modal close action
                currentModal.result.then(function (quitAlarm) {
                    ctrl.markAsQuit(quitAlarm.idAlarm);
                }, function (dismissReason) {

                });
            }
        }, function () {
            $log.error('Could not get Device by Mac');
        });
    };
}

angular.module('emsv2App').controller('AlarmController', alarmController);

