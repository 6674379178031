(function () {

    'use strict';

    function locationController($scope, $state, $stateParams, $uibModal, $log, $q, $interval, $translate, Notify,
                                Rsql, Location, Tools, Language, EMSConstants, RoomService,
                                baseUrl, userInfo, hwTypes, definitionList, currentLocale, Analysis, $timeout, License) {
        var ctrl = this;
        var currentModal; // Current modal window
        ctrl.createMode = $stateParams.mode !== 'edit';
        ctrl.isAdmin = userInfo.isAdmin();
        ctrl.hasViewRight = userInfo.hasRole('ROLE_LOCATION_VIEW');
        ctrl.hasEditRight = userInfo.hasRole('ROLE_LOCATION_EDIT');
        ctrl.hasCreateRight = userInfo.hasRole('ROLE_LOCATION_CREATE');
        ctrl.hasDeleteRight = userInfo.hasRole('ROLE_LOCATION_DELETE');
        ctrl.isFreeVersion = false;
        ctrl.limitReached = false;

        ctrl.constants = {};
        ctrl.timeZones = [];
        ctrl.locale = currentLocale;

        ctrl.data = [];
        ctrl.query = '';
        ctrl.page = 0;

        ctrl.hasMore = false;
        ctrl.tableModel = {
            tableName: 'location',
            translation: 'location',
            orderBy: 'location.id',
            orderDir: false,
            doFilter: _doFilter
        };
        ctrl.definitions = definitionList;
        ctrl.doQuery = function () {
            return _query(false);
        };
        // Shared data for modal dialogs
        ctrl.modal = {
            data: {},
            user: userInfo,
            buttons: [],
            locationQuery: '',
            hwTypes: hwTypes,
            toHex: Tools.intToHex,
            formatMac: Tools.formatMac,
            close: _closeModal,
            generateQr: _generateQrCode,
            viewEditDevice: _viewEditDevice
        };

        ctrl.$onInit = function () {
            ctrl.constants = EMSConstants.constants;
            License.getIsFreeVersion().then(function (response) {
                if (response.data === "freeVersion") {
                    ctrl.isFreeVersion = true;
                } else {
                    ctrl.isFreeVersion = false;
                }
            });
        };

        function _doFilter(params) {
            ctrl.query = params;
            _query(true);
        }

        function _query(reset) {
            ctrl.page = reset ? 0 : ctrl.page + 1;
            ctrl.query = _prepareQuery(ctrl.query);
            return Rsql.find('location', ctrl.page, ctrl.query).then(function (response) {
                ctrl.hasMore = response.data.length === 20;
                if (!reset) {
                    ctrl.data = ctrl.data.concat(response.data);
                    ctrl.checkLicense();
                } else {
                    ctrl.data = response.data;
                    ctrl.checkLicense();
                }
            }, function (error) {
                $log.error('Couldn\'t load locations!', error);
                ctrl.data = [];
                ctrl.checkLicense();
            });
        }

        function _prepareQuery(query) {
            return query.replace(/devices==([\w:\-]+)/g, function (old, mac) {
                var plainHex = mac.replace(/[:\-]/g, '');
                if (/^[0-9A-Fa-f]*$/.test(plainHex)) {
                    return 'devices==' + plainHex;
                }
                return old;
            });
        }


        ctrl.checkLicense = function () {
            var button = document.getElementById("addLocationButton");
            var tooltip = $translate.instant('global.btn.freeVersion');
            if(ctrl.isFreeVersion){
                if (ctrl.data.length >= 1) {
                    ctrl.limitReached = true;
                    button.setAttribute("title", tooltip);
                }
            }
        }

        ctrl.editLocation = function (id, event) {
            event.stopPropagation(); // Don't trigger row event
            $state.go('location.overview', {id: id, mode: 'edit'});
        };

        ctrl.viewLocation = function (id, event) {
            event.stopPropagation(); // Don't trigger row event
            $state.go('location.overview', {id: id, mode: 'view'});
        };

        ctrl.selectRow = function (id, def, event) {
            event.stopPropagation();
            if (def.type !== 'contact') {
                Location.get(id).then(function (response) {
                    ctrl.modal.data.location = response.data;
                    ctrl.modal.delete = false;
                    var queries = [];

                    queries.push(RoomService.getRoomInfo(id).then(function (response) {
                        ctrl.modal.data.rooms = response.data.rooms || [];
                    }));

                    $q.all(queries).then(function () {
                        _showModal('scripts/app/location/location.modal.html', {
                            location: ctrl.modal.data.location,
                            rooms: ctrl.modal.data.rooms
                        });
                    });
                }, function (error) {
                    $log.error('Couldn\'t load location preview!', error);
                });
            }
        };

        // Show modal deletion window
        ctrl.delete = function (id, event) {
            event.stopPropagation();
            var modalData = {
                location: null
            };

            ctrl.modal.delete = true;
            ctrl.modal.deleteLocation = _deleteLocation;
            ctrl.modal.buttons.delete = '';

            Location.get(id).then(function (response) {
                modalData.location = response.data;
                Analysis.getAllByLocationExcludeDatapoints(id).then(function (analysisresponse) {
                    modalData.analysislist = analysisresponse.data;
                });
                RoomService.getRoomInfo(id).then(function (response) {
                    modalData.rooms = response.data.rooms;
                    _showModal('scripts/app/location/location.modal.html', modalData);
                }, function (error) {
                    $log.error('Couldn\'t load devices for location ' + id, error);
                })
            }, function (error) {
                $log.error('Couldn\'t load location preview!', error);
            });
        };

        // Open the QR-Code modal window
        function _generateQrCode(mac, locationId, event) {
            event.stopPropagation();
            if (Tools.isDefinedNotNull(currentModal)) {
                currentModal.close();
            }
            currentModal = $uibModal.open({
                animation: false,
                component: 'qrCodeModal',
                windowClass: 'animated fadeInDown',
                close: _closeModal,
                resolve: {
                    data: function () {
                        return {
                            mac: mac,
                            location: locationId,
                            user: ctrl.modal.user.login,
                            pwd: '',
                            duser: '',
                            dpwd: '',
                            link: '',
                            page: '/'
                        };
                    },
                    baseUrl: function () {
                        return baseUrl;
                    }
                }
            });
        }

        // Show a modal dialog
        function _showModal(page, data) {
            if (Tools.isDefinedNotNull(currentModal)) {
                currentModal.close();
            }
            ctrl.modal.data = data;
            currentModal = $uibModal.open({
                animation: false,
                templateUrl: page,
                windowClass: 'animated fadeInDown',
                scope: $scope,
                size: 'lg',
                controllerAs: 'lctrl'
            });
        }

        function _closeModal() {
            if (currentModal !== null) {
                currentModal.close();
                currentModal = null;
            }
        }

        // Delete the location permanently
        function _deleteLocation() {
            _removeLocation(ctrl.modal.data.location.id);
        }

        function _removeLocation(lid) {
            Location.delete(lid).then(function () {
                if (ctrl.modal.data.analysislist.length > 0) {
                    var idList = [];
                    for (var i = 0; i < ctrl.modal.data.analysislist.length; i++) {
                        idList.push(ctrl.modal.data.analysislist[i].id);
                    }
                    _delete(idList);
                }
                _query(true); // Refresh table
                Notify.success("global.notification.success.title", "location.notification.locationDeleted", 3000);
                _closeModal();
                $state.reload();
            }, function () {
                $log.error('Couldn\'t delete location!', ctrl.modal);
            });
        }

        function _delete(idList) {
            return Analysis.deleteSelection(idList).then(function () {
                    $timeout(function () {
                        Notify.success("global.notification.success.title", "analysis.notification.success.locationDeleted", 3000);
                    }, 300);
                }, function (error) {
                    $timeout(function () {
                        Notify.error("global.notification.error.title", "analysis.notification.error.locationDeleted", 3000);
                    }, 300);
                    $log.error('Couldn\'t delete associated analysis', error);
                }
            )
        }

        ctrl.notEmpty = function (string) {
            return string !== null & string !== undefined & string !== '';
        };

        function _viewEditDevice(locationId, deviceMac, edit, event) {
            event.stopPropagation();
            $state.go('location.devices.edit', {
                id: locationId,
                mode: 'view',
                device: deviceMac,
                routeState: edit ? 'edit' : 'view'
            });
        }
    }

    angular
        .module('emsv2App')
        .controller('LocationController', locationController);

})();
