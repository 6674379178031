/**
 * Created by RR on 15.02.22.
 */
(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name SnmpV2cConfigurationOfSmartEventService
     * @requires $http
     */
    angular.module('emsv2App').service('SnmpV3ConfigurationOfSmartEventService', function ($http) {

        var save = function (entity) {
            return $http.post('api/snmpV3ConfigurationOfSmartEvent', entity).then(function (response) {
                return response.data;
            });
        };


        /************************************************
         *             FOREIGN KEY REFERENCE            *
         ************************************************/

        var getByFkId = function (smartEventSnmpId) {
            return $http.get('api/snmpV3ConfigurationOfSmartEvent/getByFkId/' + smartEventSnmpId).then(function (response) {
                return response.data;
            });
        };


        return {
            save: save,
            getByFkId: getByFkId,
        };

    });
})();