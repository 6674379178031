(function () {

    'use strict';

    /**
     * @ngdoc service
     * @name Contacts
     * @requires $http
     */
    angular.module('emsv2App').service('AuditLogService', function ($http) {
        return {
            get: function (sort, order, filterQuery, filterColumn, page) {
                return $http.get('api/logs/audit?sortBy=' + sort + '&direction=' + order + '&query=' + filterQuery + '&filterColumn=' + filterColumn + '&page=' + page);
            },
            count: function () {
                return $http.get('api/logs/audit/count');
            }

        }
    });

})();
