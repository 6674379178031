(function () {
    'use strict';

    function newUserController($translate, $state, $scope, $window, $log, $timeout, $q, ArrayHelper, Authority, RandomPassword, Auth, notify,
                               roles, countries, locations, currentUser, Notify, GenDialogService, Tools, License) {
        let ctrl = this;
        const EDIT_LOCATION_RIGHT = "ROLE_LOCATION_EDIT";
        const CREATE_LOCATION_RIGHT = "ROLE_LOCATION_CREATE";
        let initUser = null;
        ctrl.state = $state;
        ctrl.saveBtn = 'init';
        ctrl.forms = {};
        ctrl.roles = roles.filter(function (value) { return value !== "ROLE_LOCATION_VIEW"; });
        ctrl.countries = countries;
        ctrl.locationList = locations;
        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.user = {
            login: '',
            password: RandomPassword.generate(10),
            notify: false,
            roles: ['ROLE_LOCATION_VIEW'],
            locations: [],
            langKey: $translate.use(),
            sendActivationMail: false,
            contact: {
                gender: 0,
                firstname: '',
                lastname: '',
                company: '',
                email: '',
                secEmail: '',
                street: '',
                zip: '',
                location: '',
                country: null,
                phone: '',
                fax: ''
            }
        };
        ctrl.PresetRole = null;
        ctrl.isAdminRole = false;
        ctrl.statusHostedPublicly = false;

        ctrl.$onInit = function () {
            _getHostedPublicly();
            initUser = angular.copy(ctrl.user);
            if (currentUser !== null) {
                if (currentUser.roles.includes("ROLE_ADMIN")) {
                    ctrl.isAdminRole = true;
                }
            }
        };

        function _getHostedPublicly() {
            License.get().then(function (response) {
                ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
            })
        }

        ctrl.compareContactValues = function (userA, userB) {

            let aProps = Object.getOwnPropertyNames(userA);
            let bProps = Object.getOwnPropertyNames(userB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            if (userA["company"] !== userB["company"]) return true;
            if (userA["country"] !== userB["country"]) return true;
            if (userA["email"] !== userB["email"]) return true;
            if (userA["secEmail"] !== userB["secEmail"]) return true;
            if (userA["fax"] !== userB["fax"]) return true;
            if (userA["firstname"] !== userB["firstname"]) return true;
            if (userA["gender"] !== userB["gender"]) return true;
            if (userA["lastname"] !== userB["lastname"]) return true;
            if (userA["location"] !== userB["location"]) return true;
            if (userA["phone"] !== userB["phone"]) return true;
            if (userA["mobile"] !== userB["mobile"]) return true;
            if (userA["street"] !== userB["street"]) return true;
            if (userA["zip"] !== userB["zip"]) return true;
        };

        ctrl.compareRoleValues = function (userA, userB) {
            let aProps = Object.getOwnPropertyNames(userA);
            let bProps = Object.getOwnPropertyNames(userB);
            if (aProps.length !== bProps.length) {
                return true;
            }
        };

        ctrl.compareUserValues = function (userA, userB) {
            let aProps = Object.getOwnPropertyNames(userA);
            let bProps = Object.getOwnPropertyNames(userB);
            if (aProps.length !== bProps.length) {
                return true;
            }
            if (userA["login"] !== userB["login"]) return true;
            if (ctrl.compareContactValues(userA["contact"], userB["contact"])) return true;
            if (ctrl.compareRoleValues(userA["roles"], userB["roles"])) return true;

            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalUserChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalUserChange = function () {
            let currUser = angular.copy(ctrl.user);
            if (ctrl.compareUserValues(initUser, currUser)) return true;
        };

        let ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && ctrl.evalUserChange() && toState.name !== "editUser") {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initUser = null;
                        currentUser = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        let toggleAll = function (adminChecked) {
            ctrl.roles = roles.filter(function (value) { return value !== "ROLE_LOCATION_VIEW"; });
            for (let role of ctrl.roles) {
                let roleId = "input-" + role;
                let domElement = document.getElementById(roleId);

                if (adminChecked) {
                    if (roleId !== "input-ROLE_REMOTE_CONTROL" && Tools.isDefinedNotNull(domElement)) {
                        domElement.checked = true;
                        ArrayHelper.addValue(ctrl.user.roles, role);
                    }
                    if (roleId === "input-ROLE_REMOTE_CONTROL" && Tools.isDefinedNotNull(domElement)) {
                        domElement.checked = false;
                        ArrayHelper.removeValue(ctrl.user.roles, role);
                    }
                } else {
                    ArrayHelper.removeValue(ctrl.user.roles, role);
                }
            }
        };

        let switchToTechnical = function () {
            for (let role of ctrl.roles) {
                let roleId = "input-" + role;
                let domElement = document.getElementById(roleId);
                let nonTechnicalRoles = ["input-ROLE_ADMIN","input-ROLE_CONTACT","input-ROLE_GROUP","input-ROLE_LICENSING","input-ROLE_LOCATION_CREATE","input-ROLE_LOCATION_DELETE","input-ROLE_LOCATION_EDIT","input-ROLE_LOGS","input-ROLE_USER_MANAGEMENT","input-ROLE_REMOTE_CONTROL"];
                let technicalRoles = ["input-ROLE_ALARM_MANAGEMENT" , "input-ROLE_ANALYSIS", "input-ROLE_DASHBOARD", "input-ROLE_REPORT" ];

                if (Tools.isDefinedNotNull(domElement) && nonTechnicalRoles.includes(roleId)) {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, role);
                }

                if (Tools.isDefinedNotNull(domElement) && technicalRoles.includes(roleId)) {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, role);
                }
            }
        };

        let switchToOperator = function () {
            for (let role of ctrl.roles) {
                let roleId = "input-" + role;
                let domElement = document.getElementById(roleId);
                let nonOperatorRoles = ["input-ROLE_ADMIN", "input-ROLE_CONTACT", "input-ROLE_GROUP", "input-ROLE_LICENSING", "input-ROLE_LOGS", "input-ROLE_REMOTE_CONTROL"];
                let operatorRoles =["input-ROLE_ALARM_MANAGEMENT", "input-ROLE_ANALYSIS", "input-ROLE_DASHBOARD", "input-ROLE_LOCATION_CREATE", "input-ROLE_LOCATION_DELETE", "input-ROLE_LOCATION_EDIT", "input-ROLE_REPORT", "input-ROLE_USER_MANAGEMENT"];

                if (Tools.isDefinedNotNull(domElement) && nonOperatorRoles.includes(roleId)) {
                    domElement.checked = false;
                    ArrayHelper.removeValue(ctrl.user.roles, role);
                }

                if (Tools.isDefinedNotNull(domElement) && operatorRoles.includes(roleId)) {
                    domElement.checked = true;
                    ArrayHelper.addValue(ctrl.user.roles, role);
                }
            }
        };


        let checkPresetRole = function (roles) {
            let filteredroles = [];

            angular.forEach(roles, function (role) {
                if (role !== "ROLE_LOCATION_VIEW" && role !== "ROLE_USER") {
                    filteredroles.push(role);
                }

            })

            let administratorValues = ["ROLE_ADMIN",
                "ROLE_ALARM_MANAGEMENT",
                "ROLE_ANALYSIS",
                "ROLE_CONTACT",
                "ROLE_DASHBOARD",
                "ROLE_GROUP",
                "ROLE_LICENSING",
                "ROLE_LOCATION_CREATE",
                "ROLE_LOCATION_DELETE",
                "ROLE_LOCATION_EDIT",
                "ROLE_LOGS",
                "ROLE_REPORT",
                "ROLE_USER_MANAGEMENT"];

            let technicalValues = ["ROLE_ALARM_MANAGEMENT",
                "ROLE_ANALYSIS",
                "ROLE_DASHBOARD",
                "ROLE_REPORT"];

            let operatorValues = ["ROLE_ALARM_MANAGEMENT",
                "ROLE_ANALYSIS",
                "ROLE_DASHBOARD",
                "ROLE_LOCATION_CREATE",
                "ROLE_LOCATION_DELETE",
                "ROLE_LOCATION_EDIT",
                "ROLE_REPORT",
                "ROLE_USER_MANAGEMENT"];

            //easy way to check if 2 arrays (string or number elements) are equals
            if(filteredroles.sort().join(',') === administratorValues.sort().join(',')) return ctrl.PresetRole = "0";
            if(filteredroles.sort().join(',') === technicalValues.sort().join(',')) return ctrl.PresetRole = "1";
            if (filteredroles.sort().join(',') === operatorValues.sort().join(',')) return ctrl.PresetRole = "2";

            ctrl.PresetRole = null;

        } ;

        ctrl.isLocationBlockDisabled = function () {
            return ctrl.user.roles.includes("ROLE_ADMIN");
        }

        ctrl.toggleAuthority = function (value) {
            if (value === EDIT_LOCATION_RIGHT) {
                ArrayHelper.toggleValue(ctrl.user.roles, CREATE_LOCATION_RIGHT);
            }
            if (value === CREATE_LOCATION_RIGHT) {
                ArrayHelper.toggleValue(ctrl.user.roles, EDIT_LOCATION_RIGHT);
            }
            ArrayHelper.toggleValue(ctrl.user.roles, value);
            checkPresetRole(ctrl.user.roles);
        }

        ctrl.changePresetRole = function () {
            switch (ctrl.PresetRole) {
                case "0":
                    toggleAll(true);
                    break;

                case "1":
                    switchToTechnical();
                    break;

                case "2":
                    switchToOperator();
                    break;

            }
        };

        ctrl.checkPwd = function () {
            if (ctrl.pwdForm.password !== ''
                && ctrl.pwdForm.confirmPassword !== ''
                && ctrl.pwdForm.password !== ctrl.pwdForm.confirmPassword) {
                ctrl.pwdForm.noMatch = 'noMatch';
                return;
            }
            ctrl.pwdForm.noMatch = '';
        };

        ctrl.onLoginChanged = function (login) {
            ctrl.loginExists = false;
            if (!login || login.length < 2) {
                return;
            }

            Auth.checkLoginExists(login).then(function (exists) {
                ctrl.loginExists = exists.data;
            }, function (error) {
                $log.error('Couldn\'t check login.', error);
            });
        };

        ctrl.hasAuthority = function (authority) {
            if (currentUser !== null) {
                return currentUser.roles.includes(authority) || currentUser.roles.includes('ROLE_ADMIN');
            }
            return false;
        };

        ctrl.create = function () {
            let user = ctrl.user;
            ctrl.saveBtn = 'load';
            let locations = angular.copy(ctrl.userLocations);
            let roles = angular.copy(user.roles);
            user.sendActivationMail = false; // Activationmail is always false with admin created accounts
            user.ldap = false;
            user.password = ctrl.pwdForm.password;
            Auth.createAccount(user).then(function (response) {
                let newUser = response.data;
                var promises = [];
                promises.push(Authority.setUserLocations(newUser.id, locations).catch(function (error) {
                    $log.debug('Couldnt save location permissions for: ' + newUser.login, error);
                }));
                promises.push(Authority.set(newUser.id, roles).catch(function (error) {
                    $log.debug('Couldnt save authorities for: ' + newUser.login, error);
                }));
                $q.all(promises).then(function () {
                    ctrl.saveBtn = 'success';
                    Notify.defaultSuccess();
                    $state.go('editUser', {userId: newUser.id});
                });
            }, function (error) {
                ctrl.saveBtn = 'error';
                $log.warn(error);
                $timeout(function () {
                    ctrl.saveBtn = 'init';
                }, 2000);
            });
        };
    }

    angular
        .module('emsv2App')
        .controller('NewUserController', newUserController);

})();
