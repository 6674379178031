(function () {

    'use strict';

    function WidgetDryContactController($scope, $uibModal, $log, $timeout, $interval, $http, $state, Notify, RoomService, WidgetData, Location,
                                        Tools, LiveDataService, TreeSelectionService) {

        let ctrl = this;
        let currentModal;
        let initLocation = null;
        let initDP;
        let isIntervalRequestIsInProgress = false;
        let latestLiveData = null;
        let liveDataQueryInterval = null;

        ctrl.locations = null;
        ctrl.selectedLocationId = null;
        ctrl.selectedDriverValue = null;
        ctrl.selectedDriverValueId = null;
        ctrl.selectedDriverValueUniqueId = null;
        ctrl.selectedDatapoint = null;
        ctrl.dryContactState = 'UNSET';
        ctrl.dpArray = [];
        ctrl.filterTreeNodeId = null;


        ctrl.tree = {
            model: [],
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down', iCollapsed: 'fa fa-caret-right'
                }
            },
            filter: "",
            expandedNodes: [],
            selectedNode: null
        };

        ctrl.spinnerDisplay = false;
        ctrl.isReset = false;
        ctrl.locationSelectionBox = null;
        ctrl.isLocationSelected = true;
        ctrl.previousSelectedLocationId = null;
        ctrl.loadDatapoint = null;
        ctrl.isDpFilterSearchTerm = true;
        ctrl.showImage = null;
        ctrl.dryContactOverviewDp = null;
        ctrl.liveValue = null;


        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl.configure);
            getLocations();
            _loadSettings();
            initLocation = ctrl.selectedLocationId;
            initDP = ctrl.selectedDatapoint;
        };

        /**
         * Function to open the configuration modal
         */
        ctrl.configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/drycontact/widget.drycontact.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
            ctrl.setupSelectedEntity();
        };

        function getLocations() {
            Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            });
        }

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (const widgetSetting of $scope.widget.settings) {
                    switch (widgetSetting.key) {
                        case 'selectedLocationId':
                            ctrl.selectedLocationId = parseInt(widgetSetting.value);
                            break;
                        case 'selectedDriverValue':
                            ctrl.selectedDriverValue = JSON.parse(widgetSetting.value);
                            break;
                        case 'drycontactDatapoint':
                            ctrl.selectedDatapoint = JSON.parse(widgetSetting.value);
                            break;
                    }
                }
                if (ctrl.selectedLocationId !== null) {
                    initLocation = ctrl.selectedLocationId;
                }
                if (ctrl.selectedDatapoint) {
                    initDP = ctrl.selectedDatapoint;
                }
                if (ctrl.selectedDriverValue !== null) {
                    ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    ctrl.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;
                }
                if (ctrl.selectedLocationId !== null && ctrl.selectedDatapoint) {
                    loadDryContactDatapointLivedata();
                    liveDataQueryInterval = $interval(function () {
                        loadDryContactDatapointLivedata();
                    }, 5000);
                }
            }
            ctrl.isReset = false;
            ctrl.tree.filter = "";
        }

        let stopLiveDataQuery = function () {
            if (liveDataQueryInterval !== null) $interval.cancel(liveDataQueryInterval);
        };


        let queryLiveData = function (dp) {
            LiveDataService.getDriverValueLatestLiveData(dp.dataPointId).then(function (response) {
                    if (response.status === 200) {
                        latestLiveData = [];

                        if (dp.protocolType === 5 && dp.registerValueType === 1) {
                            let value = parseFloat(response.data[dp.dataPointId]);
                            latestLiveData.push(value);

                        } else {
                            let value = parseFloat(response.data[dp.dataPointId] / 1000).toFixed(2);
                            latestLiveData.push(value);
                        }
                        setLiveDataForDryContactWidgetOverview(dp);
                        isIntervalRequestIsInProgress = false;
                    }
                }, function
                    (error) {
                    isIntervalRequestIsInProgress = false;
                    $log.error("Error retrieving DriverValues!" + error);
                }
            )
        };


        let setLiveDataForDryContactWidgetOverview = function (selectedDPToSetLiveData) {

            if (ctrl.selectedDatapoint.dataPointId === selectedDPToSetLiveData.dataPointId) {
                ctrl.selectedDatapoint.latestLiveValue = latestLiveData[0];
                if (ctrl.selectedDatapoint.latestLiveValue === parseFloat(selectedDPToSetLiveData.openParameter.value).toFixed(2) && selectedDPToSetLiveData.isSelected === true) {
                    ctrl.showImage = true;
                    ctrl.dryContactState = 'OPEN';
                    ctrl.dryContactOverviewDp = ctrl.selectedDatapoint;
                } else if (ctrl.selectedDatapoint.latestLiveValue === parseFloat(selectedDPToSetLiveData.closedParameter.value).toFixed(2) && selectedDPToSetLiveData.isSelected === true) {
                    ctrl.showImage = true;
                    ctrl.dryContactState = 'CLOSED';
                    ctrl.dryContactOverviewDp = ctrl.selectedDatapoint;

                } else {
                    ctrl.dryContactState = 'UNMATCHED';
                    ctrl.showImage = null;
                    ctrl.latestLiveData = ctrl.selectedDatapoint.latestLiveValue;
                }
            }
        };

        function loadDryContactDatapointLivedata() {
                if (Tools.isDefinedNotNull(ctrl.selectedDatapoint) && !isIntervalRequestIsInProgress) {
                    isIntervalRequestIsInProgress = true;
                    queryLiveData(ctrl.selectedDatapoint);
                }
        }

        function findNodeInTreeModel() {
            if (ctrl.tree.expandedNodes === undefined || ctrl.tree.expandedNodes.length > 0) ctrl.tree.expandedNodes = [];

            if (ctrl.selectedDriverValueUniqueId !== null) {
                let foundNode = searchTreeByProperty(ctrl.selectedDriverValueUniqueId);
                if (Tools.isDefinedNotNull(foundNode)) {
                    ctrl.tree.selectedNode = foundNode;
                }
            }
        }

        let searchTreeByProperty = function (value) {
            let foundNode = null;
            let traverse = function (nodes) {
                let newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    var node = newNodes[index];
                    if (node.driverValueUniqueId === value) {
                        foundNode = node;
                    }
                    if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        traverse(node.children);
                    }
                }
            };
            traverse(ctrl.tree.model);

            if(foundNode){
                searchChildToExpand(ctrl.tree.model, value);
            }
            return foundNode;
        }

        let searchChildToExpand = function (nodes, childId) {

            ctrl.tree.expandedNodes = [];
            let travers = function (nodes, childId) {

                for (const node of nodes) {
                    if (node.driverValueUniqueId === childId) {
                        return node;
                    } else if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        let child = travers(node.children, childId);
                        if(child){
                            ctrl.tree.expandedNodes.push(child);
                            let nodeToExpand = {
                                id: node.id,
                                title: node.title,
                                children:[child]
                            };
                            return nodeToExpand;
                        }
                    }
                }
            };

            ctrl.tree.expandedNodes.push(travers(nodes, childId));
        };

        ctrl.setupSelectedEntity = function () {
            ctrl.rooms = [];
            if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                RoomService.getRooms(ctrl.selectedLocationId).then(function (rooms) {
                    if (rooms.data.length > 0) {
                        for (let index = 0; index < rooms.data.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                        }
                        ctrl.tree.model = ctrl.tree.model = TreeSelectionService.createSelectionTree(ctrl.rooms, [driverValueFilter]);
                        ctrl.loadDatapoint = ctrl.tree.model;
                    }
                    if (ctrl.selectedDriverValueId !== null) {
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.selectedDriverValueUniqueId);
                        findNodeInTreeModel();
                    }
                });
            }
        }

        var driverValueFilter = function(reference){
            return reference.parameter.dryContactEnable;
        }


        ctrl.handleTreeSelect = function (node, selected) {
            if (selected) {
                ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, node.driverValueUniqueId);
                ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                ctrl.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;

                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {

                    ctrl.selectedParameter = ctrl.selectedDriverValue.parameter;

                    let dp = {};

                    dp.id = ctrl.selectedDriverValue.id;
                    dp.path = node.path;
                    dp.title = node.title;
                    dp.protocolType = ctrl.selectedDriverValue.driver.driverType;
                    dp.registerValueType = ctrl.selectedDriverValue.driver.registerValueType;
                    dp.dataPointId = ctrl.selectedDriverValue.id;
                    dp.isSelected = true;


                    for (const parameterValue of ctrl.selectedParameter.parameterValues) {
                        if (parameterValue.order === 1) {
                            dp.openParameter = {
                                    id: parameterValue.id,
                                    parameterValueId: parameterValue.parameterId,
                                    text: parameterValue.description,
                                    value: parameterValue.value,
                                    order: parameterValue.order,
                                    showImage: false
                                }

                        } else {
                            dp.closedParameter = {
                                    id: parameterValue.id,
                                    parameterValueId: parameterValue.parameterId,
                                    text: parameterValue.description,
                                    value: parameterValue.value,
                                    order: parameterValue.order,
                                    showImage: false
                                }

                        }
                    }

                    if (ctrl.selectedDatapoint && ctrl.selectedDatapoint.id === dp.id) {
                        return;
                    }

                    if (ctrl.selectedParameter.parameterValues.length === 2) {
                        ctrl.selectedDatapoint = dp;
                        loadDryContactDatapointLivedata();
                    }

                    ctrl.dpListEmpty = ctrl.selectedDatapoint == null;
                } else {
                    Notify.warning("global.notification.warning.warn", "dashboard.widget.speedometer.modal.warningAvailable", 4000);
                }

            }else {
                ctrl.removeDatapoint();
            }
        };


        ctrl.inputChanged = function (searchTerm) {
            if (searchTerm === undefined || searchTerm === null || searchTerm === "" || ctrl.selectedLocationId === undefined || ctrl.selectedLocationId === null) {
                ctrl.isDpFilterSearchTerm = true;
                ctrl.tree.model = ctrl.loadDatapoint;
            } else {
                ctrl.isDpFilterSearchTerm = false;
            }
        }

        ctrl.filteredTreeNode = function (searchTerm) {
            let array = JSON.parse(JSON.stringify(ctrl.loadDatapoint));
            ctrl.tree.model = filter(array, searchTerm.toLowerCase());
            searchChildToExpand(ctrl.tree.model, ctrl.filterTreeNodeId);
        }


        function filter(array, text) {
            let getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    ctrl.filterTreeNodeId = object.driverValueUniqueId;
                    return result;
                }
                if (Array.isArray(object.children)) {
                    let children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }

        ctrl.removeDatapoint = function () {
            ctrl.selectedDatapoint = null;
        }


        ctrl.saveSettings = function () {
            let newSettings = [];

            if (ctrl.selectedDatapoint != null) {
                if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                    newSettings.push({key: 'selectedLocationId', value: ctrl.selectedLocationId});
                    newSettings.push({key: 'selectedDriverValue', value: JSON.stringify(ctrl.selectedDriverValue)});
                    newSettings.push({key: 'drycontactDatapoint', value: JSON.stringify(ctrl.selectedDatapoint)});
                    $scope.widget.settings = newSettings;
                    $log.debug("Saving Settings:", $scope.widget.settings);
                } else {
                    $scope.widget.settings = [];
                }

                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                        Notify.defaultSuccess();
                        ctrl.closeModal();
                        $state.go($state.current, {}, {reload: true});
                    }, function (error) {
                        Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                    });
                }
            }
        };


        ctrl.cancelModal = function () {
            if (ctrl.selectedLocationId !== initLocation) {
                ctrl.selectedLocationId = initLocation;
            }
            if (ctrl.selectedDatapoint !== initDP) {
                ctrl.selectedDatapoint = initDP;
            }
            if (ctrl.selectedLocationId === null && ctrl.selectedDatapoint === null) {
                ctrl.tree.model = [];
                ctrl.previousSelectedLocationId = ctrl.selectedLocationId;
            }
            currentModal.close();
        };


        ctrl.closeModal = function () {
            if (currentModal) {

                if (ctrl.selectedLocationId !== initLocation) {
                    ctrl.selectedLocationId = initLocation;
                }
                if (ctrl.selectedDatapoint !== initDP) {
                    ctrl.selectedDatapoint = initDP;
                }
                if (ctrl.selectedLocationId === null && ctrl.selectedDatapoint === null) {
                    ctrl.tree.model = [];
                    ctrl.previousSelectedLocationId = ctrl.selectedLocationId;
                }
                currentModal.close();
                setTimeout(function () {
                    loadDryContactDatapointLivedata();
                }, 500);
            }
        };


        ctrl.removeLoc = function () {
            ctrl.isReset = true;
            ctrl.selectedLocationId = null;
            ctrl.selectedDatapoint = null;
            ctrl.tree.model = [];
            ctrl.tree.filter = "";
        }

        $scope.$on('$destroy', function () {
            stopLiveDataQuery();
        });

    }

    angular.module('emsv2App')
        .controller('WidgetDryContactController', WidgetDryContactController)
})();
