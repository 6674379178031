(function () {
    'use strict';

    function paramController(PhysicalTypeService, Parameters, User, $translate, $state, $stateParams, OIDService
        , EMSConstants, Notify, Tools, GenDialogService, $window, $scope) {
        var pctrl = this;

        var initPhysType;
        var initType;
        var initAccess;
        var initName;
        var initGroup;
        var paramsSaved;
        var initNewValue;
        var initPhysTypeUnit;
        var initDryContactEnable;

        pctrl.isStulz = false;  // is a STULZ Parameter
        pctrl.editMode = false;
        pctrl.createMode = false;
        pctrl.activeTab = 0;
        pctrl.selectedOid = null;

        pctrl.availableOids = [];
        pctrl.indivudialPhysId = null;
        pctrl.doubledValues = false;
        pctrl.physicalTypeUnit = null;
        pctrl.isUsed = false;

        pctrl.newValue = {
            description: null,
            isDescriptionInValid: false,
            value: null
        };

        pctrl.newDryContactOpenValue = {
            description: null,
            value: null,
            order: 1
        };

        pctrl.newDryContactClosedValue = {
            description: null,
            value: null,
            order: 0
        };

        pctrl.values = [];
        pctrl.physicalTypes = [];
        pctrl.groups = [];

        pctrl.selectedPhysType = null;
        pctrl.selectedType = null;
        pctrl.selectedAccess = null;
        pctrl.selectedParamType = 0;
        pctrl.parameterName = null;
        pctrl.isParameterNameInvalid = false;
        pctrl.dryContactEnable = false;
        pctrl.selectedGroup = null;
        pctrl.uniqueId = null;
        pctrl.paramTypes = [];
        pctrl.types = [];
        pctrl.StulzParamValues = [];
        pctrl.optionVarInValidChar = ["'", "\""];
        pctrl.placeholderString = $translate.instant("parameters.placeholder.description");
        pctrl.optionVarErrorMgs = $translate.instant("room.errorList.optionVarErrorMgs");
        pctrl.access = [
            {
                id: 0,
                name: 'parameters.access.read', // name: "Read Only",
                value: false
            },
            {
                id: 1,
                name: 'parameters.access.write',    // name: "Read & Write"
                value: true
            }
        ];

        var PARAMETER = {
            CUSTOM: {value: 0, name: "Custom"},
            STULZ: {value: 1, name: "STULZ"}
        };

        var KEY = {
            ENTER: {value: 13},
            ZERO: {value: 48},
            NINE: {value: 57}
        };

        var NOTIFY_TIMEOUT = 4000;
        pctrl.$onInit = function () {
            initPhysType = angular.copy(pctrl.selectedPhysType);
            initType = angular.copy(pctrl.selectedType);
            initAccess = angular.copy(pctrl.selectedAccess);
            initName = angular.copy(pctrl.parameterName);
            initGroup = angular.copy(pctrl.selectedGroup);
            initNewValue = angular.copy(pctrl.values);
            initPhysTypeUnit = angular.copy(pctrl.physicalTypeUnit);
            initDryContactEnable = angular.copy(pctrl.dryContactEnable);
            paramsSaved = false;
        };

        pctrl.paramTypes = [
            {
                id: 0,
                name: $translate.instant('parameters.tabs.parameter')
            },
            {
                id: 1,
                name: $translate.instant('parameters.tabs.stulz')
            },
        ];

        pctrl.handleParamTypeChange = function () {
            pctrl.resetForm();
            pctrl.isStulz = pctrl.selectedParamType;
        }

        pctrl.resetForm = function () {
            if (!pctrl.isStulz || pctrl.isStulz === 0) {
                pctrl.selectedPhysType = initPhysType;
                pctrl.selectedType = initType;
                pctrl.selectedAccess = initAccess;
                pctrl.parameterName = initName;
                pctrl.selectedGroup = initGroup;
                pctrl.values = initNewValue;
                pctrl.physicalTypeUnit = initPhysTypeUnit;
                pctrl.dryContactEnable = initDryContactEnable;
            }
        }

        pctrl.compareParamValues = function (paramA, paramB) {

            if (paramA !== paramB) return true;
            return false;
        };

        $window.onbeforeunload = function (e) {
            if (pctrl.evalParamsChange() && !pctrl.isUsed) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        pctrl.evalParamsChange = function () {
            if (pctrl.editMode === true && !pctrl.isUsed) {
                var currPhysType = angular.copy(pctrl.selectedPhysType);
                var currType = angular.copy(pctrl.selectedType);
                var currAccess = angular.copy(pctrl.selectedAccess);
                var currName = angular.copy(pctrl.parameterName);
                var currGroup = angular.copy(pctrl.selectedGroup);
                var currNewValue = angular.copy(pctrl.values);
                var currPhysTypeUnit = angular.copy(pctrl.physicalTypeUnit);
                if (pctrl.compareParamValues(initPhysType, currPhysType)) return true;
                if (pctrl.compareParamValues(initType, currType)) return true;
                if (pctrl.compareParamValues(initAccess, currAccess)) return true;
                if (pctrl.compareParamValues(initName, currName)) return true;
                if (pctrl.compareParamValues(initGroup, currGroup)) return true;
                if ((initPhysTypeUnit !== null &&
                    initPhysTypeUnit !== "") ||
                    (currPhysTypeUnit !== null &&
                        currPhysTypeUnit !== "")) {
                    if (pctrl.compareParamValues(initPhysTypeUnit, currPhysTypeUnit)) return true;
                }
                if (Tools.isDefinedNotNull(currNewValue) && initNewValue === null) return true;
                if (Tools.isDefinedNotNull(initNewValue) && currNewValue === null) return true;
                if (Tools.isDefinedNotNull(currNewValue) && Tools.isDefinedNotNull(initNewValue)) {
                    if (pctrl.compareParamValues(initNewValue.length, currNewValue.length)) return true;
                    for (var i = 0; i < currNewValue.length; i++) {
                        if (pctrl.compareParamValues(initNewValue[i].description, currNewValue[i].description)) return true
                        if (pctrl.compareParamValues(initNewValue[i].value, currNewValue[i].value)) return true
                    }
                }
            }
        };

        var ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && pctrl.evalParamsChange() && !paramsSaved) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('location.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('location.modal.empty.yes'),
                    textButton0: $translate.instant('location.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initPhysType = null;
                        initType = null;
                        initAccess = null;
                        initName = null;
                        initGroup = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });

        pctrl.alerts = [];

        pctrl.deleteValue = function (valueToDelete) {
            if (valueToDelete != null && valueToDelete.hasOwnProperty("description") && valueToDelete.hasOwnProperty("value")) {
                for (var i = 0; i < pctrl.values.length; i++) {
                    if (pctrl.values[i].$$hashKey === valueToDelete.$$hashKey) {
                        pctrl.values.splice(i, 1);
                    }
                }
            }
        };

        pctrl.goBack = function () {
            $state.go('parameters', {activeTab: 0}, {reload: true});
        };

        pctrl.keyPressed = function (keyPressed) {
            if (keyPressed.which === KEY.ENTER.value) {
                pctrl.addNewValue();
            }
        };

        pctrl.saveParameter = function () {
            let parameter = {};
            if (!pctrl.isUsed) {
                if (pctrl.editMode === true && $stateParams.parameterId != null && $stateParams.parameterId !== "") {
                    parameter.id = $stateParams.parameterId;
                }

                switch (pctrl.selectedParamType) {
                    case PARAMETER.CUSTOM.value:
                        pctrl.saveNewParameter(parameter);
                        break;
                    case PARAMETER.STULZ.value:
                        pctrl.saveStulzParameter(parameter);
                        break;
                    default:
                        break;
                }
            } else {
                Notify.error("global.notification.error.title", "global.notification.warning.parameterIsInUse", 4000);
            }
        };

        // Speichert allgemeine, neue Parameter
        pctrl.saveNewParameter = function (parameter) {
            if (!pctrl.inputsHaveErrors()) {
                if (pctrl.selectedPhysType === 6) {

                    handleDryContactValues();

                    //check if values are doubled
                    let valuesSoFar = [];
                    for (var i = 0; i < pctrl.values.length; i++) {
                        var value = pctrl.values[i].value;
                        if (valuesSoFar.indexOf(value) !== -1) {
                            Notify.warning("global.notification.warning.info", "global.notification.warning.doubledValues", 4000);
                            pctrl.doubledValues = true;
                            return true;
                        } else {
                            valuesSoFar.push(value);
                            pctrl.doubledValues = false;
                        }

                    }

                }

                if (!pctrl.doubledValues) {
                    paramsSaved = true;
                    parameter.name = pctrl.parameterName;
                    parameter.entityType = pctrl.selectedType;
                    for (var i = 0; i < pctrl.access.length; i++) {
                        if (pctrl.access[i].id === pctrl.selectedAccess) {
                            parameter.writePermission = pctrl.access[i].value;
                        }
                    }
                    pctrl.saveButton = document.getElementById("saveBtn");
                    pctrl.backBtn = document.getElementById("backBtn");
                    if (pctrl.saveButton !== null && pctrl.backBtn !== null) {
                        pctrl.saveButton.disabled = true;
                        pctrl.backBtn.disabled = true;
                    }
                    pctrl.createMode = false;
                    parameter.groupId = pctrl.selectedGroup;
                    parameter.physType = pctrl.selectedPhysType;
                    parameter.uniqueLabel = pctrl.physicalTypeUnit;
                    parameter.customEntity = true;
                    parameter.dryContactEnable = pctrl.dryContactEnable;
                    parameter.parameterValues = pctrl.values;
                    parameter.uniqueId = pctrl.uniqueId;
                    Parameters.put(parameter).then(function (response) {
                        if (response.status === 200) {
                            Notify.success("global.notification.success.title", "global.notification.success.parameter", 4000);
                            $state.go('parameters', {activeTab: 0}, {reload: true});
                        } else if (response.status === 226) {
                            Notify.warning("global.notification.warning.info", "global.notification.warning.parameterIsInUse", 4000);
                            paramsSaved = false;
                        } else {
                            Notify.error("global.notification.error.title", "global.notification.error.parameter", 4000);
                        }
                        pctrl.saveButton.disabled = false;
                        pctrl.backBtn.disabled = false;
                    }, function () {
                        Notify.error("global.notification.error.title", "global.notification.error.parameter", 4000);
                        pctrl.saveButton.disabled = false;
                        pctrl.backBtn.disabled = false;
                    });
                }
            }
        };

        let handleDryContactValues = function () {
            if (pctrl.dryContactEnable) {
                let parentOpenInputDiv = angular.element(document.querySelector('#openContactInput > input'));
                let parentOpenValueDiv = angular.element(document.querySelector('#openContactValue > input'));

                if (parentOpenInputDiv[0] !== undefined && parentOpenValueDiv[0] !== undefined) {

                    pctrl.newDryContactOpenValue = {
                        description: (parentOpenInputDiv[0].value !== "") ? parentOpenInputDiv[0].value : "Open",
                        value: (parentOpenValueDiv[0].value !== null || parentOpenValueDiv[0].value !== "") ? parentOpenValueDiv[0].value : null,
                        order: 1
                    };

                }

                let parentClosedInputDiv = angular.element(document.querySelector('#closedContactInput > input'));
                let parentClosedValueDiv = angular.element(document.querySelector('#closedContactValue > input'));


                if (parentClosedInputDiv[0] !== undefined && parentClosedValueDiv[0] !== undefined) {
                    pctrl.newDryContactClosedValue = {
                        description: (parentClosedInputDiv[0].value !== "") ? parentClosedInputDiv[0].value : "Closed",
                        value: (parentClosedValueDiv[0].value !== null || parentClosedValueDiv[0].value !== "") ? parentClosedValueDiv[0].value : null,
                        order: 0
                    };
                }

                if (pctrl.newDryContactOpenValue.value !== null && pctrl.newDryContactClosedValue.value !== null) {
                    pctrl.values = [];
                    pctrl.values.push(pctrl.newDryContactOpenValue);
                    pctrl.values.push(pctrl.newDryContactClosedValue);
                }
            } else {
                pctrl.values.forEach(function (value) {
                    value.order = null;
                });
            }
        }

        // Speichert STULZ-Spezifische Parameter
        pctrl.saveStulzParameter = function (parameter) {
            if (Tools.isDefinedNotNull(pctrl.selectedOid)) {
                parameter.name = pctrl.selectedOid.name;
                parameter.entityType = 1;
                parameter.writePermission = !pctrl.selectedOid.readOnly;
                parameter.groupId = pctrl.selectedGroup;
                parameter.physType = pctrl.selectedOid.physTypeId;
                parameter.customEntity = true;
                parameter.values = pctrl.selectedOid.values;
                parameter.oidId = pctrl.selectedOid.id;
                parameter.uniqueId = pctrl.uniqueId;
                Parameters.put(parameter).then(function () {
                    Notify.success("global.notification.success.title", "global.notification.success.parameter", 4000);
                    $state.go('parameters', {activeTab: 1}, {reload: true});

                }, function () {
                    Notify.error("global.notification.error.title", "global.notification.error.parameter", 4000);
                });
            } else {
                Notify.warning("global.notification.warning.info", "global.notification.warning.selectStulzParam", 4000);
            }
        };

        pctrl.goToState = function (stateString) {
            $state.go('parameters.' + stateString, {actionType: stateString, parameterId: $stateParams.parameterId});
        };

        pctrl.resetDoubleValue = function () {
            if (pctrl.selectedPhysType !== 6) {
                pctrl.doubledValues = false;
            }
            pctrl.values = [];
            pctrl.dryContactEnable = false;
        }

        pctrl.inputsHaveErrors = function () {
            let input = null;
            let hasErrors = false;
            if (pctrl.selectedType === null) {
                input = angular.element(document.querySelector('#parameterType'));
                input.addClass("has-error");
                hasErrors = true;
                Notify.error("global.notification.error.title", "global.notification.error.parameter", 4000);
            } else {
                input = angular.element(document.querySelector('#parameterType'));
                input.removeClass("has-error");
            }
            if (pctrl.parameterName === null || pctrl.parameterName === "" || pctrl.isParameterNameInvalid) {
                input = angular.element(document.querySelector('#parameterName'));
                input.addClass("has-error");
                hasErrors = true;
            } else {
                input = angular.element(document.querySelector('#parameterName'));
                input.removeClass("has-error");
            }
            if (pctrl.selectedAccess === null) {
                input = angular.element(document.querySelector('#parameterAccess'));
                input.addClass("has-error");
                hasErrors = true;
            } else {
                input = angular.element(document.querySelector('#parameterAccess'));
                input.removeClass("has-error");
            }

            if (pctrl.selectedPhysType === null) {
                input = angular.element(document.querySelector('#parameterPhysicalType'));
                input.addClass("has-error");
                hasErrors = true;
            } else {
                input = angular.element(document.querySelector('#parameterPhysicalType'));
                input.removeClass("has-error");
            }

            let parentOpenValueDiv = angular.element(document.querySelector('#openContactValue > input'));

            if (pctrl.dryContactEnable && (parentOpenValueDiv[0].value === null || parentOpenValueDiv[0].value === "")) {
                input = angular.element(document.querySelector('#openContactValue'));
                input.addClass("has-error");
                hasErrors = true;
            } else {
                input = angular.element(document.querySelector('#openContactValue'));
                input.removeClass("has-error");
            }

            let parentClosedValueDiv = angular.element(document.querySelector('#closedContactValue > input'));

            if (pctrl.dryContactEnable && (parentClosedValueDiv[0].value === null || parentClosedValueDiv[0].value === "")) {
                input = angular.element(document.querySelector('#closedContactValue'));
                input.addClass("has-error");
                hasErrors = true;
            } else {
                input = angular.element(document.querySelector('#closedContactValue'));
                input.removeClass("has-error");
            }

            return hasErrors;
        };

        pctrl.addNewValue = function () {
            let newValue = angular.element(document.querySelector('#newValueGrp'));
            if (Tools.isDefinedNotNull(pctrl.newValue.description) && Tools.isDefinedNotNull(pctrl.newValue.value)) {
                if (pctrl.newValue.isDescriptionInValid != true) {
                    let value = angular.copy(pctrl.newValue);
                    pctrl.newValue.value = null;
                    pctrl.newValue.description = null;
                    pctrl.values.push(value);
                    newValue.removeClass("has-error");
                    var descriptionInput = angular.element(document.querySelector('#newDescInput'));
                    descriptionInput.focus();
                } else {
                    var newDescInput = angular.element(document.querySelector('#newDescInput'));
                    newDescInput.addClass("has-error");
                }
            } else {
                newValue.addClass("has-error");
            }
        };


        pctrl.closeAlert = function (index) {
            pctrl.alerts.splice(index, 1);
        };

        pctrl.getData = function () {
            if ($stateParams.parameterId !== null && $stateParams.parameterId > 0) {
                Parameters.get($stateParams.parameterId).then(function (response) {
                    let parameter = response.data;
                    if (parameter !== null && parameter !== undefined) {
                        pctrl.selectedType = parseInt(parameter.entityType);
                        pctrl.physicalTypeUnit = parameter.uniqueLabel;

                        // lade STULZ Parameter
                        if (response.data.oidId > 0) {
                            pctrl.isStulz = true;
                            pctrl.selectedParamType = 1;

                            OIDService.getAllOids().then(function (response) {
                                if (response !== null && response.data.length > 0) {
                                    pctrl.allOids = response.data;
                                    for (var i = 0; i < pctrl.allOids.length; i++) {
                                        if (pctrl.allOids[i].id === parameter.oidId) {
                                            pctrl.stulzParamName = pctrl.allOids[i].name;
                                            pctrl.inMin = pctrl.allOids[i].inMin;
                                            pctrl.inMax = pctrl.allOids[i].inMax;
                                            pctrl.outMin = pctrl.allOids[i].outMin;
                                            pctrl.outMax = pctrl.allOids[i].outMax;
                                            pctrl.readOnly = pctrl.allOids[i].readOnly;
                                            pctrl.oid = pctrl.allOids[i].oid;
                                            pctrl.StulzParamValues = pctrl.allOids[i].values;
                                            break;
                                        }
                                    }
                                }
                            });

                        } else {
                            pctrl.isStulz = false;
                            pctrl.selectedParamType = 0;
                            pctrl.dryContactEnable = parameter.dryContactEnable;

                            if(pctrl.dryContactEnable) {
                                for (const parameterValue of parameter.parameterValues) {
                                    if (parameterValue.order === 1) {
                                        pctrl.newDryContactOpenValue = parameterValue;
                                    }
                                    if (parameterValue.order === 0) {
                                        pctrl.newDryContactClosedValue = parameterValue;
                                    }
                                }
                            }
                        }

                        pctrl.selectedGroup = parameter.groupId;
                        pctrl.parameterName = parameter.name;

                        if (parameter.writePermission === false) {
                            pctrl.selectedAccess = 0;
                        } else {
                            pctrl.selectedAccess = 1;
                        }
                        pctrl.selectedPhysType = parameter.physType;
                        pctrl.uniqueId = parameter.uniqueId;

                        pctrl.values = parameter.parameterValues;

                        initPhysType = angular.copy(pctrl.selectedPhysType);
                        initType = angular.copy(pctrl.selectedType);
                        initAccess = angular.copy(pctrl.selectedAccess);
                        initName = angular.copy(pctrl.parameterName);
                        initNewValue = angular.copy(pctrl.values);
                        initGroup = angular.copy(pctrl.selectedGroup);
                        initPhysTypeUnit = angular.copy(pctrl.physicalTypeUnit);
                        paramsSaved = false;

                        OIDService.getUnusedOids().then(function (response) {
                            if (response !== null && response.data.length > 0) {
                                pctrl.availableOids = response.data;
                                for (var i = 0; i < pctrl.availableOids.length; i++) {
                                    if (pctrl.availableOids[i].id === parameter.oidId) {
                                        pctrl.selectedOid = pctrl.availableOids[i];
                                    }
                                }
                            }
                        });
                    }
                    Parameters.checkIfUsed($stateParams.parameterId).then(function (response) {
                        pctrl.isUsed = response.data;
                    });
                }, function (error) {
                    if (error.status === 401) {
                        $state.go('accessdenied');
                    } else {
                        $state.go('notfound');
                    }
                });
            } else {
                $state.go("notfound");
            }
        };

        pctrl.mapPhysTypeUnit = function () {

            PhysicalTypeService.getPhysicalTypes().then(function (response) {
                if (response !== null && response.length > 0) {
                    pctrl.physicalTypes = response;
                    for (var i = 0; i < pctrl.physicalTypes.length; i++) {
                        if (pctrl.selectedPhysType === pctrl.physicalTypes[i].id)
                            pctrl.physicalTypeUnit = pctrl.physicalTypes[i].physicalType;
                    }
                }
            });
        };

        (function () {
            if ($state.current.name.indexOf("edit") !== -1) {
                pctrl.editMode = true;
                pctrl.createMode = false;
                pctrl.getData();
            } else if ($state.current.name.indexOf("view") !== -1) {
                pctrl.editMode = false;
                pctrl.createMode = false;
                pctrl.getData();

            } else if ($state.current.name.indexOf("create") !== -1) {
                pctrl.editMode = true;
                pctrl.createMode = true;
                OIDService.getUnusedOids().then(function (response) {
                    if (response !== null && response.data.length > 0) {
                        pctrl.availableOids = response.data;
                        // pctrl.getData();
                    }
                });
            }

            PhysicalTypeService.getPhysicalTypes().then(function (response) {
                if (response !== null && response.length > 0) {
                    pctrl.physicalTypes = response;
                    for (var i = 0; i < pctrl.physicalTypes.length; i++) {
                        if (pctrl.physicalTypes[i].name.contains("individual")) {
                            pctrl.indivudialPhysId = pctrl.physicalTypes[i].id;
                        }
                    }
                }
            });

            pctrl.entityTypes = angular.copy(EMSConstants.constants.EntityType);
            pctrl.entityTranslation = angular.copy(EMSConstants.constants.EntityTranslation);
            pctrl.types = [
                {
                    id: pctrl.entityTypes.ASSET,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.ASSET]])[pctrl.entityTranslation[pctrl.entityTypes.ASSET]]
                },
                {
                    id: pctrl.entityTypes.COOLING,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.COOLING]])[pctrl.entityTranslation[pctrl.entityTypes.COOLING]]
                },
                {
                    id: pctrl.entityTypes.UPS,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.UPS]])[pctrl.entityTranslation[pctrl.entityTypes.UPS]]
                },
                {
                    id: pctrl.entityTypes.CPU,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.CPU]])[pctrl.entityTranslation[pctrl.entityTypes.CPU]]
                },
                {
                    id: pctrl.entityTypes.SENSOR,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.SENSOR]])[pctrl.entityTranslation[pctrl.entityTypes.SENSOR]]
                },
                {
                    id: pctrl.entityTypes.FLOORTILE,
                    name: $translate.instant([pctrl.entityTranslation[pctrl.entityTypes.FLOORTILE]])[pctrl.entityTranslation[pctrl.entityTypes.FLOORTILE]]
                }
            ];

            User.getGroups().then(function (response) {
                if (response.data !== null && response.data.length > 0) {
                    pctrl.groups = response.data;
                }
            });
        })();
    }

    angular.module('emsv2App')
        .controller('CreateEditParamController', paramController);
})();
