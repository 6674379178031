(function () {

    'use strict';

    window.dcSidebarInitialAlarmNotification = true;
    function SidebarController($scope, $rootScope, $state, $http, $log, $interval, $window, $q, Tools,
                               Principal, Auth, License, BRANCH, VERSION, EMSConstants, localStorageService, MessageService, $uibModal,
                               notify, $cookies, $translate) {
        var ctrl = this;
        ctrl.errorAvailable = false;
        ctrl.priority = 0;
        ctrl.state = $state;
        ctrl.user = {};
        ctrl.isDevmode = false;
        ctrl.errorCount = 0;
        ctrl.version = '';
        ctrl.systemInfo = '';
        ctrl.parser = new UAParser();
        ctrl.versionModal = null;
        ctrl.targetHelp = '';
        ctrl.diskSpacePercent = 100;
        var timeoutId;
        var license;
        var cancelErrorCountHttp = null;

        var notifySound = null;

        // Add body-small class if window less than 768px
        if ($(window).width() < 769) {
            $('body').addClass('body-small')
        } else {
            $('body').removeClass('body-small')
        }

        ctrl.logout = function logout() {
            Auth.logout();
        };

        ctrl.hasRole = function (role) {
            return Principal.isInRole('ROLE_' + role);
        };

        // TODO: Use user startpage for this?
        ctrl.home = function () {
            Principal.redirectToHomepage();
        };

        ctrl.$onInit = function () {
            cancelErrorCountHttp = $q.defer();
            updateErrorCount();
            timeoutId = $interval(updateErrorCount, 5000);

            getVersionInformation();

            updateUserLocale();

            License.get().then(function (response) {
                ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
                if (!Tools.isDefinedNotNull(response.data.address) && !Tools.isDefinedNotNull(response.data.company)) {
                    $state.go('unlicensed');
                }
                license = response.data;
            }, function (error) {
                $log.error('Couldn\'t load license!', error);
            });

            var devMode = localStorageService.get('devmode');
            if (devMode !== undefined && devMode) {
                ctrl.isDevmode = true;
            }

        };

        $rootScope.$on('$translateChangeStart', function (event, data) {
            setUserManualLanguage(data.language);
        });

        $scope.$on('$destroy', function () {
            ctrl.$onDestroy();
        });


        ctrl.$onDestroy = function () {
            $interval.cancel(timeoutId);
        };

        function updateUser(){
            return Principal.identity().then(function (account) {
                return account;
            }, function (error) {
                $log.error('Couldn\'t update user!', error);
            });
        }


        function updateUserLocale() {
            Principal.identity().then(function (user) {
                ctrl.user = user;
                if (user) {
                    if (ctrl.user.langKey){
                        setUserManualLanguage(ctrl.user.langKey);
                    }
                    if (!user.terms) {
                        $state.go("legalConfirm", {}, {reload: true});
                    }
                }

            }, function (error) {
                $log.error('Couldn\'t load user: ', error);
            });
        }

        function setUserManualLanguage(lang){
            switch(lang){
                case "de": ctrl.targetHelp = $window.location.origin + "/help/cyberhub-manual-de.pdf"; break;
                case "zh-cn": ctrl.targetHelp = $window.location.origin + "/help/cyberhub-manual-cn.pdf";
                default: ctrl.targetHelp = $window.location.origin + "/help/cyberhub-manual-en.pdf";
            }
        }

        function updateErrorCount() {
            $http.get('api/alarm/sidebar').then(function (response) {
                ctrl.errorCount = response.data.error_count;
                ctrl.errorAvailable = response.data.priority > 0 && ctrl.errorCount > 0;
                ctrl.priority = response.data.priority;
                if(ctrl.errorCount && ctrl.priority > 223){
                    handleErrorNotification(response.data.latestAlarm);
                }

                ctrl.diskSpacePercent = response.data.diskSpacePercent;

            }, function (error) {
                $log.error('Couldn\'t update alarm count!', error);
                ctrl.errorAvailable = false;
            });
        }

        function handleErrorNotification(timestampLatestAlarm) {
            if (!timestampLatestAlarm) {
                return
            }
            const alarmNotificationTimestamp = $cookies.get('alarm_notification_timestamp') || '0';
            const userAlreadyNotified = timestampLatestAlarm === parseInt(alarmNotificationTimestamp, 10)
            const expireDate = new Date(Date.now() + 15 * 60000); // 15min
            $cookies.put('alarm_notification_timestamp', timestampLatestAlarm, {expires: expireDate});
            if (!window.dcSidebarInitialAlarmNotification && userAlreadyNotified) {
                return
            }

            updateUser().then(function (user) {
                ctrl.user = user;
                if(ctrl.user){
                    ctrl.user.settings.forEach(setting => {
                        if (setting.key === 'playAlarmSound' && (setting.value === 'true' || setting.value === true) && window.userInteraction) {
                            getNotifySound().play();
                        }
                    })
                    alarmNotification();
                    window.dcSidebarInitialAlarmNotification = false;
                }
            })
        }

        function alarmNotification(){
            notify({
                message: $translate.instant('global.notification.alarm'),
                templateUrl: 'scripts/components/notify/alarm_notification.html',
                position: 'right',
                classes: 'alert-danger',
                duration: 25000
            });
        }


        // Load the file only once from the server
        function getNotifySound() {
            if (!notifySound) {
                notifySound = new Audio("/assets/sounds/notify.mp3");
            }
            return notifySound
        }

        function getVersionInformation() {
                ctrl.version = VERSION;
        }

        function _setNavOffset(id) {
            var offset = angular.element(document.querySelector(id)).offset().top + 'px';
            angular.element(id + ' > ul').css('top', offset);
        }

        ctrl.offsetAlarms = function () {
            _setNavOffset('#nav-alarm');
        };
        ctrl.offsetAnalysis = function () {
            _setNavOffset('#nav-analysis');
        };
        ctrl.offsetAccount = function () {
            _setNavOffset('#nav-account');
        };
        ctrl.offsetAdmin = function () {
            _setNavOffset('#nav-admin');
        };
        ctrl.offsetLegal = function () {
            _setNavOffset('#nav-legal');
        };

        ctrl.isLicensed = function (module) {
            if (license !== null
                && license !== undefined
                && license.data !== null
                && license.data !== undefined) {
                if (module.indexOf('*') !== -1) {
                    var regex = new RegExp("^" + module.replace(/\*/g, '.*?') + "$", "g");
                    var keys = Object.keys(license.data);
                    for (var i in keys) {
                        if (regex.test(keys[i]) && keys[i]) return true;
                    }
                } else if (license.data[module] !== null) {
                    return license.data[module];
                }
            }
            return false;
        };

        MessageService.subscribe('userSettingsUpdated', function () {
            Principal.identity(true).then(function (response) {
                ctrl.user = response;
            });
        });

        ctrl.openHelp = function () {
            var target = "/help_en/allgemeines/index.html";
            if (ctrl.user.langKey === "de") target = "/help_de/general/index.html";
            if (ctrl.user.langKey === "en") target = "/help_en/general/index.html";
            if (ctrl.user.langKey === "zh-cn") target = "/help_en/general/index.html";
            $window.open($window.location.origin + target, "_blank");
        };

        /**
         * Opens modal with version and system information
         */

        ctrl.showVersionDetails = function () {

            ctrl.getUAInfo();

            ctrl.versionModal = $uibModal.open({
                templateUrl: 'scripts/components/sidebar/sidebar.versionModal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        };

        /**
         * Close modal with version and system information
         */

        ctrl.cancelModal = function () {
            ctrl.versionModal.close();
            ctrl.versionModal = null;
        };

        /**
         * Function to pre-fill version and system information textarea
         */

        ctrl.getUAInfo = function () {
            $scope.systemInfo = "Application Version (" + ctrl.version + ") \n";
            $scope.systemInfo += "\n";
            $scope.systemInfo += "Browser name: " + ctrl.parser.getBrowser().name + "\n";
            $scope.systemInfo += "Browser version: " + ctrl.parser.getBrowser().version + "\n";
            $scope.systemInfo += "\n";
            $scope.systemInfo += "OS name: " + ctrl.parser.getOS().name + "\n";
            $scope.systemInfo += "OS version: " + ctrl.parser.getOS().version + "\n";
            $scope.systemInfo += "\n";
            var device = ctrl.parser.getDevice();
            if (device && device.name !== undefined) {
                $scope.systemInfo += "Device model: " + device.name + "\n";
            }
            if (device && device.type !== undefined) {
                $scope.systemInfo += "Device type: " + device.type + "\n";
            }
            if (device && device.vendor !== undefined) {
                $scope.systemInfo += "Device vendor: " + device.vendor + "\n";
            }
            $scope.systemInfo += "\n";
            $scope.systemInfo += "Engine name: " + ctrl.parser.getEngine().name + "\n";
            $scope.systemInfo += "Engine version: " + ctrl.parser.getEngine().version + "\n";
            $scope.systemInfo += "\n";
            $scope.systemInfo += "CPU architecture: " + ctrl.parser.getCPU().architecture + "\n";

        }
    }

    angular
        .module('emsv2App')
        .controller('SidebarController', SidebarController);
})();
