var myApp = angular.module('emsv2App');

myApp.service('TreeSelectionService', function ($translate) {

    function createTreeFromRooms(rooms, driverValueFilters) {
        let tree = [];

        for (const room of rooms) {

            let roomNode = {
                title: room.name,
                id: room.id,
                children: []
            };

            if (room.ups) {
                generateDeviceNode(room.ups, roomNode, $translate.instant("room.tree.upsFolder"), driverValueFilters);
            }

            if (room.coolings) {
                generateDeviceNode(room.coolings, roomNode, $translate.instant("room.tree.coolingFolder"), driverValueFilters);
            }

            if (room.sensors) {
                generateDeviceNode(room.sensors, roomNode, $translate.instant("room.tree.sensorFolder"), driverValueFilters);
            }

            if (room.floorTiles) {
                generateDeviceNode(room.floorTiles, roomNode, $translate.instant("room.tree.floorTileFolder"), driverValueFilters);
            }

            if (room.assets) {
                generateDeviceNode(room.assets, roomNode, $translate.instant("room.tree.assetFolder"), driverValueFilters);
            }

            if (room.racks) {

                let type = {
                    children: [],
                    title: $translate.instant("room.tree.rackFolder"),
                };

                for (const rack of room.racks) {
                    let rackNode = {
                        children: [],
                        id: rack.id,
                        title: rack.name,
                    };

                    if (rack.slots) {

                        for (const slot of rack.slots) {
                            let slotNode = {
                                children: [],
                                id: slot.id,
                                title: slot.name,
                            };

                            if (slot.driverValues) {
                                for (const driverValue of slot.driverValues) {
                                    if (validateDriverValueFilters(driverValue, driverValueFilters)) {

                                        let driverValueNode = {
                                            children: null,
                                            id: driverValue.id,
                                            title: $translate.instant(driverValue.name),
                                            driverValueUniqueId: driverValue.uniqueId
                                        };
                                        driverValueNode.path = "Livedata: " + roomNode.title + " / " + slotNode.title + " / ";
                                        slotNode.children.push(driverValueNode);
                                    }
                                }
                            }

                            if (slot.blades) {

                                for (const blade of slot.blades) {

                                    if (blade.cpus) {

                                        for (const cpu of blade.cpus) {
                                            let cpuNode = {
                                                children: [],
                                                id: cpu.id,
                                                title: cpu.name,
                                            }

                                            if (cpu.driverValues) {

                                                for (const driverValue of cpu.driverValues) {

                                                    if (validateDriverValueFilters(driverValue, driverValueFilters)) {
                                                        let driverValueNode = {
                                                            children: null,
                                                            id: driverValue.id,
                                                            title: driverValue.name,
                                                            driverValueUniqueId: driverValue.uniqueId
                                                        };
                                                        driverValueNode.path = "Livedata: " + roomNode.title + " / " + rackNode.title + " / " + slotNode.title + " / " + cpuNode.title + " / ";
                                                        cpuNode.children.push(driverValueNode);
                                                    }
                                                }
                                            }

                                            if (cpuNode.children.length) {
                                                slotNode.children.push(cpuNode);
                                            }
                                        }
                                    }
                                }
                            }
                            if (slotNode.children.length) {
                                rackNode.children.push(slotNode);
                            }
                        }
                    }
                    if (rackNode.children.length) {
                        type.children.push(rackNode)
                    }
                }

                if(type.children.length){
                    roomNode.children.push(type);
                }
            }

            if(roomNode.children.length){
                tree.push(roomNode);
            }

        }
        return tree;
    }


    let generateDeviceNode = function (devices, roomNode, deviceType, driverValueFilters) {
        let type = {
            children: [],
            title: deviceType,
        };

        for (const device of devices) {
            let deviceNode = {
                children: [],
                id: device.id,
                title: device.name,
            };
            for (const driverValue of device.driverValues) {
                if (validateDriverValueFilters(driverValue,driverValueFilters)) {
                    let driverValueNode = {
                        children: null,
                        id: driverValue.id,
                        title: $translate.instant(driverValue.name),
                        driverValueUniqueId: driverValue.uniqueId
                    };
                    driverValueNode.path = "Livedata: " + roomNode.title + " / " + $translate.instant(deviceNode.title) + " / ";
                    deviceNode.children.push(driverValueNode);
                }
            }

            if(deviceNode.children.length){
                type.children.push(deviceNode);
            }

        }
        if(type.children.length){
            roomNode.children.push(type);
        }
    }


    var validateDriverValueFilters = function(driverValue, functionQueue){
        if(functionQueue) {
        
            var valid = true;
            for(var i = 0; i < functionQueue.length; i++){
                valid = valid && functionQueue[i](driverValue);
                if(!valid){
                    return false;
                }
            }
        }
        return true;
    }

    return {
        createSelectionTree: createTreeFromRooms,
    };
});