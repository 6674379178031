/**
 * Component for entity details
 */

(function () {
    'use strict';

    /**
     * @ngdoc component
     * @name entityDetailComponent
     * @param {Object} entity - the entity to display details of
     * @param {Object} user - user object (temp type)
     */

    angular
        .module('emsv2App')
        .component('entityDetailComponent', {
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            templateUrl: 'scripts/app/location/tabs/room/entity.detail.component.html',
            controller: function ($stateParams, $scope, $log, $interval, $window, LiveDataService, Alarm, PartlibService, DetailPictureService, OIDService, RoomService, EMSConstants, Notify, Tools) {
                var ctrl = this;
                var isCooling = false;

                ctrl.entity = ctrl.resolve.entity;
                ctrl.user = ctrl.resolve.user;

                ctrl.alarms = null;
                ctrl.alarmCount = null;
                ctrl.edit = ctrl.resolve.edit;
                ctrl.allowGetSet = ctrl.user.roles.indexOf('ROLE_REMOTE_CONTROL') !== -1;
                ctrl.sendDvRegX = /^-?\d*?(\.\d*)?$/;

                ctrl.partInfoFile = ctrl.resolve.partInfoFile;
                ctrl.showPicture = false;
                // ctrl.hasValidImgPath = false;

                var queryIntervalUnitAlarms = null;



                var getPhysTypes = function () {
                    LiveDataService.getPysTypesForEntity();
                };

                var startUnitAlarmsQuery = function () {
                    if (queryIntervalUnitAlarms === null) queryIntervalUnitAlarms = $interval(queryUnitAlarms, 5000);
                };

                var stopUnitAlarmsQuery = function () {
                    if (queryIntervalUnitAlarms !== null) $interval.cancel(queryIntervalUnitAlarms);
                };

                var queryUnitAlarms = function () {
                    // console.log("query unit alarms");
                    Alarm.getAlarmsForEntity(ctrl.entity.constructor.name, ctrl.entity.id).then(function (response) {
                        ctrl.alarms = response;
                        ctrl.alarmCount = response.filter(function (el) {
                            return el.priority >= 150
                        }).length;
                    }, function (error) {
                        $log.error("Could not load alarms for " + ctrl.entity.constructor.name + " with id " + ctrl.entity.id + "\n" + error);
                    });
                };

                ctrl.$onInit = function () {
                    queryUnitAlarms();
                    startUnitAlarmsQuery();
                    getPhysTypes();
                    // validateImgPath();
                    ctrl.edit = ctrl.edit && (ctrl.user.roles.indexOf("ROLE_LOCATION_EDIT") !== -1);
                    if (ctrl.partInfoFile !== undefined && ctrl.partInfoFile !== null && ctrl.partInfoFile !== false) {
                        ctrl.showPicture = true;
                    }
                    if (ctrl.entity instanceof Cooling) {
                        isCooling = true;
                    }
                };

                ctrl.$onDestroy = function () {
                    stopUnitAlarmsQuery();
                    ctrl.alarms = null;
                };

                ctrl.modValue = function (dv) {
                    return LiveDataService.modDVValue(dv, ctrl.user);
                };

                ctrl.displaySetFunctionality = function (dv) {
                    if (ctrl.edit && dv.parameter.oidId !== null && !dv.parameter.oidDataPoint.readOnly && dv.deviceMac) {
                        return true;
                    } else return !!(ctrl.edit && dv.parameter.oidId === null);
                }

                ctrl.handleMouseOver = function (dv) {
                    if (dv.parameter.oidId !== null) DetailPictureService.markComponent(dv.parameter.oidDataPoint.componentEnum);
                };

                ctrl.handleMouseLeave = function (dv) {
                    if (dv.parameter.oidId !== null) DetailPictureService.unmarkComponent(dv.parameter.oidDataPoint.componentEnum);
                };

                ctrl.showDetailPicture = function () {
                    return ctrl.showCoolingDetailPicture() && ctrl.entity.type >= 2 && ctrl.showPicture;
                };

                ctrl.showCoolingDetailPicture = function () {
                    const excludedCoolingUnits = ['ASRD 581 CW', 'ASD 201 A', 'CCD 90 CW'];
                    return ctrl.entity.isCooling && excludedCoolingUnits.indexOf(ctrl.entity.name) < 0;
                }

                ctrl.getPartLibraryImageSource = function() {
                  return ctrl.partInfoFile !== null && ctrl.partInfoFile !== false ? 'assets/images/detailview/'+ctrl.partInfoFile.name+'.'+ctrl.partInfoFile.type : 'assets/images/detailview/ALD_412_GE.png'
                }

                ctrl.sendValue = function (dv) {
                    if (dv.driver.driverType === 5) {
                        Notify.error("global.notification.error.title", "room.notifications.modbustcpNotImplemented", 2000);
                        return;
                    }
                    if (Tools.isDefinedNotNull(dv.driver.community)) {
                        if (dv.driver.community !== "private" || (dv.driver.driverType !== 8 && dv.driver.driverType !== 12 && dv.driver.driverType !== 13 && dv.driver.driverType !== 14)) {
                            Notify.error("global.notification.error.title", "room.notifications.snmpNotPrivate", 2000);
                            return;
                        }
                    }
                    if (dv.sendValue !== undefined && !isNaN(dv.sendValue)) {
                        ctrl.disableControls = true;
                        var mappedValue = parseInt(dv.sendValue);
                        if (dv.driver.driverType === 12 || dv.driver.driverType === 13) {
                            RoomService.sendEntityValue(dv.driver, dv, ctrl.entity, mappedValue).then(function (response) {
                                ctrl.disableControls = false;
                                if (response.data) {
                                    Notify.success("global.notification.success.title", "room.notifications.sendSuccess", 2000);
                                } else {
                                    Notify.error("global.notification.error.title", "room.notifications.sendError", 2000);
                                }
                            }, function (error) {
                                ctrl.disableControls = false;
                                if (error.status === 500 && (error.data.message === "Argument must be an unsigned 32bit value" || error.data.message.includes("Error: Request Failed") || error.data.message === "No such name")) {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 5000);
                                } else {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.badConfig", 5000);
                                }
                            });
                        } else if (dv.driver.driverType === 14) {
                            RoomService.sendEntityValueSNMPv3(dv.driver, dv, ctrl.entity, mappedValue).then(function (response) {
                                ctrl.disableControls = false;
                                if (response.data) {
                                    Notify.success("global.notification.success.title", "room.notifications.sendSuccess", 2000);
                                } else {
                                    Notify.error("global.notification.error.title", "room.notifications.sendError", 2000);
                                }
                            }, function (error) {
                                ctrl.disableControls = false;
                                if (error.status === 500 && (error.data.message === "Argument must be an unsigned 32bit value" || error.data.message.includes("Error: Request Failed") || error.data.message === "No such name")) {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 5000);
                                } else {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.badConfig", 5000);
                                }
                            });
                        }
                    } else {
                        Notify.error("global.notification.error.title", "room.notifications.invalidInputFields", 2000);
                    }
                };
            },
            controllerAs: "ctrl"
        });
})();
