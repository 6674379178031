(function () {
    'use strict';

    function logfilesController($state, $window, $translate, $log, Logfiles, GenDialogService, Notify) {

        var ctrl = this;
        ctrl.logfiles = [];
        ctrl.logfile = null;
        ctrl.tableModel = {
            head: [
                {name: 'filename', translation: 'logfiles.table.title'},
                {name: 'date', translation: 'logfiles.table.date'},
                {name: 'kiloByteSize', translation: 'logfiles.table.size'},
                {empty: true}
            ],
            table: ctrl.logfiles,
            orderBy: 'date',
            orderDir: true
        };

        ctrl.$onInit = function () {

            ctrl.activeTab = $state.current.name === 'logfiles' ? 0 : 1;

            Logfiles.getAllLogfiles().then(function (response) {
                ctrl.logfiles = response.data;
            });
        };

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            _sortLogfiles(sortField, sortReverse);
        };

        function _sortLogfiles(sortField, sortReverse) {
            if (sortReverse) {
                ctrl.logfiles.sort(function (a, b) {
                    return (a[sortField]) - (b[sortField]);
                })
                ctrl.logfiles.sort(function (a, b) {
                    if (sortField !== "kiloByteSize") {
                        return (a[sortField]).localeCompare(b[sortField]);
                    }
                })
            } else {
                ctrl.logfiles.sort(function (a, b) {
                    return (b[sortField]) - (a[sortField]);
                })
                ctrl.logfiles.sort(function (a, b) {
                    if (sortField !== "kiloByteSize") {
                        return (b[sortField]).localeCompare(a[sortField]);
                    }
                })
            }
        }

        ctrl.view = function (filename) {
            $state.go('logfiles.view', {filename: filename});
        };

        ctrl.changeTab = function (tabStateName) {
            if (tabStateName != null) {
                $state.go(tabStateName);
            }
        };

        ctrl.download = function (filename) {
            $('<iframe/>').attr({
                src: 'api/logs/download/' + filename,
                style: 'visibility:hidden;display:none'
            }).appendTo($('#download-logfile'));
        };

        ctrl.deleteModal = function (filename) {
            $translate(['logfiles.delete', 'logfiles.askDelete', 'global.btn.cancel', 'global.btn.delete']).then(function (trans) {
                GenDialogService.showDialog(true, {
                    headText: trans['logfiles.delete'],
                    messageText: $translate.instant('logfiles.askDelete', {value: filename}),
                    showClose: false,
                    textButton0: trans['global.btn.cancel'],
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton0: 'btn-close',
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    },
                    textButton1: trans['global.btn.delete'],
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    classButton1: 'btn-delete',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ctrl.delete(filename);
                    }
                });
            })

        };

        ctrl.delete = function (filename) {
            console.warn("delete btn clicked for " + filename);
            Logfiles.deleteLog(filename).then(function () {
                Notify.success("global.notification.success.title", "logfiles.modal.deleteSuccess", 3000);
                setTimeout(function () {
                    $state.go($state.current, {}, {reload: true});
                }, 3000);
            }, function (error) {
                Notify.error("global.notification.error.title", "logfiles.modal.deleteError", 3000);
                $log.error('Couldn\'t delete logfile', error);
            })
        }

        ctrl.reload = function () {
            $window.location.reload();
        };
    }

    angular
        .module('emsv2App')
        .controller('LogfilesController', logfilesController);
})
();
