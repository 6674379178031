(function () {
    'use strict';

    function systemPropsController($log, $interval, Notify, $scope, SystemProps, User) {

        let ctrl = this;
        ctrl.dbCapacity = 100;
        ctrl.cpuUsage = 0;
        ctrl.ramUsage = 0;
        ctrl.users =[];
        ctrl.selectedUsers = [];
        let togglePassword = document.querySelector('#togglePassword');
        let icon = document.querySelector('#showPWIcon');
        let password = document.querySelector('#dbUserPwassword');
        let timeoutId = $interval(function () {
            ctrl.updateDate();
        }, 1000);
        let systemInfoInterval;

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
            $interval.cancel(systemInfoInterval);
        });

        ctrl.$onInit = function(){
            ctrl.getDBInfo();
            ctrl.getLiveDataInfo();
            ctrl.serverTime();

            refreshSystemInfos(true, true, true);
            systemInfoInterval = $interval(function () {
                refreshSystemInfos(true, true, false);
            }, 5000);
            refreshUsers();
        };

        ctrl.tableModel = {
            orderDir: false,
            head: [
                {name: 'select', translation: 'systemprops.disk.table.select'},
                {name: 'firstname', translation: 'systemprops.disk.table.firstname'},
                {name: 'lastname', translation: 'systemprops.disk.table.lastname'},
                {name: 'email', translation: 'systemprops.disk.table.email'},
            ]
        };

        ctrl.db = {};
        ctrl.livedata = {};
        ctrl.sTime = null;
        ctrl.timeFormat = [
            {label: '24h', value: 'HH:mm:ss'},
            {label: '12h AM / PM', value: 'hh:mm:ss a'}
        ];
        ctrl.currentTimeFormat = ctrl.timeFormat[0];

        togglePassword.addEventListener('click', function (e) {
            // toggle the type attribute
            let type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
            // toggle the eye slash icon
            icon.classList.toggle('glyphicon-eye-close');
        });

        ctrl.getDBInfo = function () {
            SystemProps.getDatabaseInfo().then(function (response) {
                ctrl.db = response.data
            });
        };

        ctrl.getLiveDataInfo = function () {
            SystemProps.getLivedataInfo().then(function (response) {
                ctrl.livedata = response.data
            });
        };

        ctrl.serverTime = function () {
            SystemProps.getServerTime().then(function (response) {
                ctrl.serverTimezone = response.data;

            });
        };

        ctrl.updateDate = function () {
            let out = moment().tz(ctrl.serverTimezone);
            ctrl.currentTime = out.format(ctrl.currentTimeFormat.value);
        };

        ctrl.saveMemoryAlarmSettings = function (){

            const settings = [];
            for(const user of ctrl.users){
              let value = ctrl.selectedUsers.includes(user.id);

              let alarmOnSystemMemory = {
                    userId: user.id,
                    key: 'alarmOnSystemMemory',
                    value: value
                };

              for(const setting of user.settings){
                    if(setting.key === 'alarmOnSystemMemory'){
                      alarmOnSystemMemory.id = setting.id;
                    }
                }

              settings.push(alarmOnSystemMemory);
            }

            User.updateSettings(settings).then(function (response) {
                refreshUsers();
                Notify.defaultSuccess();
            });
        };

        let refreshUsers = function () {
            User.query().then(function (response) {
                ctrl.users = response.data;

                ctrl.selectedUsers = [];
                for(const user of ctrl.users){
                    for(const setting of user.settings){
                        if(setting.key === 'alarmOnSystemMemory' && setting.value === 'true'){
                            ctrl.toggleSelection(user.id);
                        }
                    }
                }

            }, function (error) {
                $log.error("Couldn't load users!", error);
                ctrl.users = [];
            });
        };

        let refreshSystemInfos = function (cpu, ram, hdd) {
            SystemProps.getHostSystemInfo(cpu, ram, hdd).then(function (response) {
                if(response.data && response.data.hdd){
                    ctrl.dbCapacity = response.data.hdd;
                    document.getElementById("db-capacity").style.width = ctrl.dbCapacity + '%';
                    if (ctrl.dbCapacity <= 10) {
                        Notify.error("systemprops.disk.notification.title", "systemprops.disk.notification.alert", 5000);
                    }
                }
                if(response.data && response.data.cpu){
                    ctrl.cpuUsage = response.data.cpu;
                    document.getElementById("cpu-usage-bar").style.width = ctrl.cpuUsage + '%';
                }
                if(response.data && response.data.ram){
                    ctrl.ramUsage = response.data.ram;
                    document.getElementById("ram-usage-bar").style.width = ctrl.ramUsage + '%';
                }
            });
        }


        ctrl.toggleSelection = function toggleSelection(userId) {
            let uid = ctrl.selectedUsers.indexOf(userId);

            // Is currently selected
            if (uid > -1) {
                ctrl.selectedUsers.splice(uid, 1);
            } else {
                ctrl.selectedUsers.push(userId);
            }
        };

    }
    angular
        .module('emsv2App')
        .controller('systemPropsController', systemPropsController);
})();
