(function () {
    'use strict';
    angular.module('emsv2App').controller('RoomEditController',
        function ($scope, $translate, $log, $interval, $state, $stateParams, $rootScope, $window, $uibModal, $q, Device,
                  Principal, MessageService, WebGLService, Object3DFactory, PartlibService, NumberService, MathService,
                  AssetService, GenDialogService, FileReaderService, DragDropService, GFXSettingsService,
                  Ping, TrackingDivLabelFactory, RoomService, RoomEditorService,
                  GLMeasureService, HeatmapService, HeatMapGLService, EMSConstants, Parameters, LiveDataService,
                  Notify, OIDService, ClipboardService, DetailPictureService, localStorageService,
                  object, user, heatmaps, room, physicalTypes, parts, alarmOids,
                  consumer, license, lang, buildings, Tools, QuickBarSettingsService, quickbarSettings, License, OIDValueService,
                  SNMPv3authProtocols, SNMPv3privProtocols, errorConfigs) {

            var ctrl = this;
            ctrl.trans = null;
            ctrl.virtualDeviceMac = [];
            ctrl.roomJson = null;
            ctrl.driverType = EMSConstants.constants.DriverType;
            ctrl.SNMPv3authProtocols = SNMPv3authProtocols;
            ctrl.SNMPv3privProtocols = SNMPv3privProtocols;
            ctrl.license = license;
            ctrl.saveInProcess = false;
            ctrl.roomHeightExceeded = false;
            ctrl.wallThicknessExceeded = false;
            ctrl.roomConfigurationValueUndefined = false;
            ctrl.temperatureSetpointExceeded = false;
            ctrl.temperatureThresholdExceeded = false;
            ctrl.pressureSetpointExceeded = false;
            ctrl.pressureThresholdExceeded = false;
            ctrl.InvalidRackHuNumber = false;
            ctrl.roomSizeValueUndefined = false;
            ctrl.isFreeVersion = false;
            ctrl.limitReached = false;
            ctrl.hideDragImg = false;
            ctrl.isGetClicked = false;
            ctrl.isSetClicked = false;

            var lastChangeTime = new Date().getTime();
            ctrl.overlayMessage = "room.glCtrl.loadingData";
            ctrl.allowEdit = user.roles.indexOf('ROLE_LOCATION_EDIT') !== -1;
            ctrl.allowGetSet = user.roles.indexOf('ROLE_REMOTE_CONTROL') !== -1;
            var currLang = lang === "de" ? 1 : 0;
            ctrl.urlValidtionPattern = /(((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-]*)?\??(?:[\-\+=&;%@\.\w]*)#?(?:[\.\!\/\\\w]*))?))|(\b(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\b)/;
            ctrl.windowDoorHightWidthPattern = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
            ctrl.wallHightPattern = /^(([2-9]|1[0-9]|20)|(([2-9]|1[0-9])((\,|\.)[0-9]{1,2}))|(20(\,|\.)00))$/;
            ctrl.wallThicknessPattern = /^([0-2]|(([0-1])((\,|\.)[0-9]{1,2}))|(2(\,|\.)00))$/;
            ctrl.editHuNumberPattern = /^(100|[1-9][0-9]?)$/;
            ctrl.editNumberPattern = /^-?\d*?(\.\d*)?$/;
            ctrl.roomConfigPattern = /^(?!-1-1$)(?:100(?:\.00?)?|\d{1,2}(?:\.\d{1,2})?)$/;
            ctrl.showSensorInput = false;
            ctrl.optionVarInValidChar = ["'", "\""];
            ctrl.optionVarErrorMgs = $translate.instant("room.errorList.optionVarErrorMgs");
            var httpCanceller = $q.defer();

            var roomLifeDataQueryBusy = false;
            var translations = {
                autodetect: {
                    offline: "",
                    running: "",
                    nodeviceavailable: "",
                    incomplete: "",
                    finishedSuccess: "",
                    finishedError: ""
                }
            };

            var detailModal = null;
            var errorListModal = null;
            var oldHuNumber = 0;

            ctrl.buildings = buildings;

            ctrl.availableDevices = [];

            ctrl.newDriverValueGroup = {};

            ctrl.newSelectedDevice = null;
            ctrl.editSelectedDevice = null;
            ctrl.chosenGroupForDriverValue = null;

            ctrl.name = "RoomEditController";
            ctrl.gfxSettings = null;
            ctrl.online = true;

            // basic config object
            ctrl.config = {
                edit: false,
                create: false,
                sidebarActive: true,
                sidebarState: 0,
                editState: 0,
                modObject: null,
                editStateName: 'room.edit.editstate.device',
                pathEditPanelEditor: './scripts/app/roomedit/roomedit.editor.partial.html',
                pathEditPanelPartlib: './scripts/app/roomedit/roomedit.partlib.partial.html',
                pathEditPanelDriverGroups: './scripts/app/roomedit/roomedit.driverGroups.partial.html',
                pathEditPanelTree: "./scripts/app/roomedit/roomedit.tree.partial.html",
                pathEditPanelDetail: "./scripts/app/roomedit/roomedit.detail.partial.html",
                pathEditPanelInfo: "./scripts/app/roomedit/roomedit.info.partial.html",
                mouseDown: false,
                alignToGrid: false,
                allowInfoLabels: true,
                fullScreen: false,
                allowFullScreen: true,
                allowDetails: false,
                explorerSearchTerm: "Test",
                acc: {
                    genOpen: true,
                    toolsOpen: true,
                    gridOpen: true,
                    roomConfigurationsOpen: true
                },
                tools: {
                    active: -1,
                    gridSize: 20,
                    cellSize: 0.5,
                    alignCursor: true,
                    gridColor: '#e30613',
                    mouseDownPos: null,
                    mouseUpPos: null,
                    validRoom: false,
                    substate: 0
                },
                subtools: {
                    active: -1,
                    snapActive: false,
                    snapDistance: 0,
                    pointMode: false,
                    modifierSelected: false,
                    allowRemove: false,
                    allowSplit: false
                },
                explorer: {
                    treeActive: false,
                    showFilterPanel: false,
                    searchTerm: "",
                    filters: []
                },
                autodetect: {
                    opts: {
                        ip: "",
                        port: "",
                        unit: "",
                        bus: "",
                        globalAdr: "",
                        slave: "",
                        baud: "",
                        parity: "",
                        stopbits: ""
                    },
                    devices: null,
                    running: false,
                    device: null,
                    msg: "",
                    type: null
                },
                stulzAlerts: {
                    alerts: alarmOids,
                    filter: ""
                },
                partlib: {
                    items: null,
                    select: {
                        rack: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null,
                            single: true,
                            racknumber: null,
                            dragRackBlock: true
                        },
                        crack: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        sensor: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        csensor: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        ccooling: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        cooling: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null,
                            version: "",
                            csystem: "",
                            airDirection: "",
                            circuits: "",
                            size: ""
                        },
                        floortile: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        cfloortile: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        ups: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        cups: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        asset: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        casset: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        slot: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        cslot: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            obj: null
                        },
                        grack: {
                            manufacturer: "",
                            model: "",
                            id: null,
                            pos: {
                                x: null,
                                y: null,
                                z: null
                            },
                            size: {
                                x: null,
                                y: null,
                                z: null
                            },
                            rot: {
                                x: null,
                                y: null,
                                z: null
                            },
                            racks: []
                        }
                    },
                    qbItems: [],
                    insertType: 0,
                    isDefault: false
                },
                containment: {
                    selectByObjects: false,
                    selectBySpacing: false,
                    valid: false
                },
                selectedCpu: null,
                errorConfigs: errorConfigs,
            };
            // settings for editor roomConfigurations
            ctrl.roomConfigurations = {
                temperatureSetpoint: 0,
                temperatureThreshold: 0,
                pressureSetpoint: 0,
                pressureThreshold: 0,
                useForLimits: false,
            };
            var obj3d = {
                grid: null,
                editorMarker: null,
                dummyObj: null,
                room: null,
                selectedObj: null,
                selectedObjGrabPos: null,
                selectedObjData: null,
                layoutObj: null,
                scaleMeasure: null
            };

            ctrl.spOpts = {
                showInput: true,
                preferredFormat: 'hex',
                allowEmpty: false,
                clickoutFiresChanges: true,
                cancelText: $translate.instant("global.btn.cancel"),
                chooseText: $translate.instant("global.btn.choose")
            };

            ctrl.config.partlib.items = parts;

            var wallObjects = [];

            var tempHeatMap = null;
            ctrl.user = user;
            ctrl.object = object;
            ctrl.viewObject = ctrl.object;
            var clipboardDeepCopy = false;
            ctrl.config.edit = $state.current.data.edit;
            ctrl.account = user;
            ctrl.sensorsLeft = 0;
            ctrl.sensorsInQueue = 0;
            var avoid_collision_gap = 0.002; //minimal gap to avoid collisions between objects

            //TODO move to init func
            if (ctrl.object === null) {
                ctrl.create = true;
                ctrl.config.edit = true;
            }
            if (ctrl.object !== null && ctrl.config.edit) ctrl.config.editState = 1;
            //TODO end
            // not used anymore
            ctrl.toggleAcc = function (val) {
                ctrl.config.acc[val] = !ctrl.config.acc[val];
            };

            ctrl.tempSettings = function () {
                var settings = ctrl.user.settings.filter(function (settings) {
                    return settings.key === "temp";
                });
                return Tools.isDefinedNotNull(settings) && settings.length === 1 ? settings[0].value : "0";
            };
            /**
             * @description function to set the side bar active
             */
            ctrl.activeSideBar = function () {
                ctrl.config.sidebarActive = true;
            };

            /**
             * @description function to toggle the state of the side bar (open or closed)
             */
            ctrl.toggleSideBarState = function () {
                if (ctrl.config.sidebarState === 0) {
                    ctrl.config.sidebarState = 1;
                    $('#movBar').css('width', '100%');
                } else {
                    ctrl.config.sidebarState = 0;
                    $('#movBar').css('width', '50px');
                }
            };

            /**
             * @description function to switch side bar active or inactive
             */
            ctrl.toggleSideBar = function () {
                ctrl.config.sidebarActive = !ctrl.config.sidebarActive;
            };

            /**
             * @description function to get display mode based on user settings
             * @returns {string} returns view type string
             */
            var getDisplayMode = function () {
                return ctrl.account.graphics.displayMode === "1" ? 'simple' : 'full';
            };

            ctrl.toggleEditModeDevice = function () {
                if (ctrl.config.tools.active >= 0) ctrl.toggleTool(ctrl.config.tools.active);
                ctrl.config.editState = 1;
                deactivateEditTool();
                WebGLService.unmarkObject(null, true);
                ctrl.config.editStateName = 'room.edit.editState.device';
                WebGLService.cleanAll();
                WebGLService.remove3DObjectFromScene(obj3d.dummys);
                obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                connectDragDropListener();
                WebGLService.enableControls();
                handleRoomRotation();
                RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);

                ctrl.gl.showGhosts = false;
                ctrl.gl.allowHeatMapButton = true;
            };
            ctrl.toggleEditModeRoom = function () {
                if (ctrl.config.editState !== 0) {
                    ctrl.config.editState = 0;
                    ctrl.config.editStateName = 'room.edit.editState.roomedit';
                    ctrl.gl.show3D();
                    setupEmptyEditor();
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                    obj3d.dummys = Object3DFactory.buildDummys(ctrl.room);
                    WebGLService.add3DObjectToScene(obj3d.dummys);
                    ctrl.gl.showGhosts = true;
                    ctrl.gl.allowHeatMapButton = false;
                    updateInnerWallMaxHeight();
                    ctrl.gl.showVisibilityPanel = false;
                    ctrl.gl.showHeatmapPanel = false;
                    ctrl.gl.showSettingsPanel = false;
                    ctrl.gl.showSensorOverlayOpts = false;

                    ctrl.gl.heatmapActive = false;
                    if (ctrl.gl.showSensorOverlay) ctrl.gl.toggleSensorOverlay();

                    if (infoLabel !== null) {
                        TrackingDivLabelFactory.removeLabel("objInfoLabel");
                        infoLabel = null;
                    }
                    RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                }
            };

            ctrl.toggleTree = function () {
                if (ctrl.config.explorer.treeActive) {
                    ctrl.config.explorer.treeActive = false;
                } else {
                    ctrl.config.explorer.treeActive = true;
                    ctrl.config.sidebarActive = true;
                }
            };

            /**
             * @description function to toggle full screen mode
             * @param {boolean} force if true the controller variable will be hard set
             */
            ctrl.toggleFullScreen = function (force) {
                if (ctrl.config.fullScreen) {
                    RoomEditorService.disableFullScreenMode();
                    ctrl.config.fullScreen = false;
                    if (force) ctrl.config.allowFullScreen = false;
                    WebGLService.handleSize(false);
                } else {
                    RoomEditorService.enableFullScreenMode();
                    ctrl.config.fullScreen = true;
                    if (force) ctrl.config.allowFullScreen = true;
                    WebGLService.handleSize(true);
                }
            };

            //region Info
            /**
             * @description function to modify driver value data based on user settings (for temperature)
             * @param {DriverValue} dv the driver value to modify data for
             * @returns {*} returns the modified driver value data
             */
            ctrl.modDVValue = function (dv) {
                return LiveDataService.modDVValue(dv, user);
            };
            //endregion
            //region Details
            // configuration object for detail view
            ctrl.detail = {
                availableParams: [],
                selectedUsedParam: null,
                selectedUnusedParam: null,
                unusedParams: [],
                usedParams: [],
                selectedDriverValue: null,
                availableDrivers: [],
                selectedLimit: null,
                limitTypes: RoomEditorService.getStdLimits(),
                limitPriorities: [
                    {value: 74, name: $translate.instant("room.driver.limit.priorities.info")},
                    {value: 149, name: $translate.instant("room.driver.limit.priorities.warn")},
                    {value: 224, name: $translate.instant("room.driver.limit.priorities.error")}],
                dragObject: false,
                selectedCpuId: null,
                slotDirections: [
                    {id: 0, name: $translate.instant('room.edit.orientationFront')},
                    {id: 1, name: $translate.instant('room.edit.orientationBack')}
                ],
                consumer: consumer,
                airFlowDirections: [],
                slotHeightType: 0,
                slotHeightTypeName: $translate.instant("room.edit.slotHeightTypeNormal")
            };

            /**
             * @description function to open url of current active object in new tab/window
             */
            ctrl.jumpToUrl = function () {
                var pattern = new RegExp(ctrl.urlValidtionPattern);
                if (pattern.test(ctrl.object.url)) {
                    if (ctrl.object.url.toLowerCase().indexOf("http://") === -1 && ctrl.object.url.toLowerCase().indexOf("https://") === -1) {
                        $window.open("http://" + ctrl.object.url, '_blank');
                    } else {
                        $window.open(ctrl.object.url, '_blank');
                    }
                }
            };

            /**
             * @description function to setup air flow directions for cooling unit
             */
            var setupAirFlowDirections = function () {
                if (ctrl.object.isCooling) {
                    ctrl.detail.airFlowDirections = RoomEditorService.getAirFlowDirections();
                    if (ctrl.object.isStulz()) {
                        if (ctrl.object.type == 3) ctrl.detail.airFlowDirections = RoomEditorService.getAirFlowDirections().filter(function (ad) {
                            return ad.type == 0 || ad.type == 3
                        });
                        if (ctrl.object.type == 2) ctrl.detail.airFlowDirections = RoomEditorService.getAirFlowDirections().filter(function (ad) {
                            return ad.type == 0 || ad.type == 2
                        });
                    } else {
                        ctrl.detail.airFlowDirections = RoomEditorService.getAirFlowDirections().filter(function (ad) {
                            return ad.type == 0 || ad.type == 2
                        });
                    }
                }
            };

            /**
             * @description function to get the entity type provided by backend for the provided object
             * @param {object} obj the object to get entity type for
             * @returns {*} returns null if object does not match any entity type, otherwise the found entity type will be returned
             */
            var getEntityTypeForObject = function (obj) {
                if (obj instanceof Cpu) return EMSConstants.constants.EntityType.CPU;
                if (obj instanceof Sensor) return EMSConstants.constants.EntityType.SENSOR;
                if (obj instanceof Cooling) return EMSConstants.constants.EntityType.COOLING;
                if (obj instanceof Asset) return EMSConstants.constants.EntityType.ASSET;
                if (obj instanceof Ups) return EMSConstants.constants.EntityType.UPS;
                if (obj instanceof Slot && obj.type == 5) return EMSConstants.constants.EntityType.UPS;
                if (obj instanceof FloorTile) return EMSConstants.constants.EntityType.FLOORTILE;
                return null;
            };

            /**
             * @description function to setup information for current selected object about usable driver types
             */
            var getAvailableDriversForObject = function () {
                var param = [];
                if (ctrl.detail.usedParams !== undefined) {
                    param = ctrl.detail.usedParams.filter(function (param) {
                        return param.id == ctrl.detail.selectedUsedParam;
                    });
                }
                var paramPhysType;
                if (param && param.length > 0) paramPhysType = param[0].physType;
                if (ctrl.object instanceof Sensor) {
                    ctrl.detail.availableDrivers = buildDriverArray(Sensor.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                }
                if (ctrl.object instanceof Cooling) {
                    ctrl.detail.availableDrivers = buildDriverArray(ctrl.object.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType, param[0]));
                }
                if (ctrl.object instanceof FloorTile) ctrl.detail.availableDrivers = buildDriverArray(FloorTile.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                if (ctrl.object instanceof Asset) ctrl.detail.availableDrivers = buildDriverArray(Asset.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                if (ctrl.object instanceof Cpu) ctrl.detail.availableDrivers = buildDriverArray(Cpu.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                if (ctrl.object instanceof Ups) ctrl.detail.availableDrivers = buildDriverArray(Ups.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                if (ctrl.object instanceof Slot && ctrl.object.type == 5) ctrl.detail.availableDrivers = buildDriverArray(Ups.getAvailableDrivers(EMSConstants.constants.DriverType, paramPhysType));
                for (var i in ctrl.detail.availableDrivers) {
                    ctrl.detail.availableDrivers[i].name = $translate.instant(ctrl.detail.availableDrivers[i].name);
                }
            };

            /**
             * @description function to get array of usable driver types
             * @param {object} driverObject object containing information about usable driver types
             * @returns {Array} returns array of usable driver types
             */
            var buildDriverArray = function (driverObject) {
                var ret = [];
                for (var i in driverObject) {
                    ret.push(driverObject[i]);
                }
                ret.sort(function (a, b) {
                    return b.id - a.id;
                });
                return ret;
            };

            /**
             * @description function to find rack object containing the provided object
             * @param {Slot|Blade|Cpu} object the object to find parent rack for
             * @returns array of length 2, 1st element is the found rack or null, 2nd element is the object to mark or null
             */
            var getRackObjectForRackObject = function (object) {
                var rack = null;
                var markObject = null;
                if (object instanceof Slot) {
                    rack = ctrl.room.findObjectByTypeAndID('rack', object.rackId);
                    markObject = object;
                }
                if (object instanceof Blade) {
                    var slot = ctrl.room.findObjectByTypeAndID("slot", object.slotId);
                    rack = ctrl.room.findObjectByTypeAndID('rack', slot.rackId);
                    markObject = slot;
                }
                if (object instanceof Cpu) {
                    var blade = ctrl.room.findObjectByTypeAndID('blade', object.bladeId);
                    var slot = ctrl.room.findObjectByTypeAndID('slot', blade.slotId);
                    rack = ctrl.room.findObjectByTypeAndID('rack', slot.rackId);
                    markObject = slot;
                }
                return [rack, markObject];
            };

            /**
             * @description function to handle select in tree view
             * @param {string} msg the message that was published
             * @param {object} opts the options that where published
             */
            var handleTreeSelect = function (msg, opts) {
                ctrl.object = ctrl.room.findObjectByTypeAndID(opts.typeName, opts.id);
                ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                var viewType = ctrl.config.edit ? 'edit' : 'view';
                if (ctrl.viewObject instanceof Rack) {
                    if (ctrl.object instanceof Rack) {
                        if (ctrl.object.id == ctrl.viewObject.id) {
                            ctrl.object = null;
                        } else {
                            $state.go('location.rooms.' + viewType + '.rack', {
                                rackId: ctrl.object.id,
                                rackMode: ctrl.config.edit ? 'edit' : 'view'
                            });
                        }
                        return;
                    }
                    if (ctrl.object instanceof Slot || ctrl.object instanceof Blade || ctrl.object instanceof Cpu) {
                        var info = getRackObjectForRackObject(ctrl.object);
                        if (info[0] == null) return;
                        if (info[0].id == ctrl.viewObject.id) {
                            WebGLService.unmarkObject(undefined, true);
                            WebGLService.markObject(WebGLService.findObjectByUniqueId(info[1].uniqueId).children[0], true);
                        } else {
                            $state.go('location.rooms.' + viewType + '.rack', {
                                rackid: info[0].id,
                                rooomid: ctrl.viewObject.roomId,
                                rackMode: ctrl.config.edit ? 'edit' : 'view',
                                rauuid: ctrl.object.uniqueId
                            });
                        }
                    }
                    if (ctrl.object instanceof Asset || ctrl.object instanceof Cooling || ctrl.object instanceof FloorTile || ctrl.object instanceof Sensor || ctrl.object instanceof Ups) {
                        $state.go('location.rooms.' + viewType, {
                            roomid: ctrl.object.roomId,
                            roomMode: ctrl.config.edit ? 'edit' : 'view',
                            ruuid: ctrl.object.uniqueId
                        });
                    }
                }
                if (ctrl.viewObject instanceof Room) {
                    if (ctrl.object instanceof Rack || ctrl.object instanceof Cooling || ctrl.object instanceof Asset
                        || ctrl.object instanceof Sensor || ctrl.object instanceof FloorTile || ctrl.object instanceof Ups
                        || ctrl.object instanceof Containment) {

                        obj3d.selectedObj = WebGLService.findObjectByTypeAndID(opts.typeName, opts.tid);
                        WebGLService.markObject(obj3d.selectedObj, undefined, true);
                        RoomEditorService.updateURLForObject(ctrl.object);

                    }
                    if (ctrl.object instanceof Slot || ctrl.object instanceof Blade || ctrl.object instanceof Cpu) {
                        // TODO function rename
                        var rack = getRackObjectForRackObject(ctrl.object)[0];
                        if (rack != null) {
                            $state.go('location.rooms.' + viewType + '.rack', {
                                rackid: rack.id,
                                roomid: ctrl.room.id,
                                rackMode: ctrl.config.edit ? 'edit' : 'view',
                                rauuid: ctrl.object.uniqueId
                            });
                        }
                        return;
                    }
                    if (ctrl.room.usesRaisedFloorIdents && isFloorPanelObject(ctrl.object)) {
                        ctrl.room.computeStdFloorPanelPosition(ctrl.object);
                        ctrl.handleFloorPositionInput();
                    }
                }
                ctrl.config.explorer.treeActive = false;
                if (ctrl.object) {
                    handleParameterSetup();
                    ctrl.config.allowDetails = true;
                }
                GLMeasureService.clearMeasure();
            };
            MessageService.subscribe("user:selectedItemTree", handleTreeSelect);

            /**
             * @description function to setup used and unused parameters for current selected object
             */
            var handleParameterSetup = function () {
                var et = getEntityTypeForObject(ctrl.object);
                // clearAutodetection();
                ctrl.checkDPLimit();
                if (et !== null) {
                    Parameters.getAllSelectableForEntityType(et).then(function (response) {
                        for (var i in response.data) response.data[i].name = $translate.instant(response.data[i].name);
                        ctrl.detail.availableParams = response.data;

                        if (ctrl.object) {
                            setupParams(ctrl.detail.availableParams);
                            if (ctrl.object.isCooling && ctrl.object.isStulz()) {
                                setupOidAlerts(ctrl.object, ctrl.config.stulzAlerts.alerts);
                            }
                        }
                    }, function (error) {
                        $log.error(error);
                    });
                }
            };

            /**
             * @description function to setup parameters for current selected object
             */
            var setupParams = function () {
                var params = [];

                if (ctrl.object !== null && ctrl.object.setupParameters !== undefined) {
                    params = ctrl.object.setupParameters(ctrl.detail.availableParams);
                }

                ctrl.detail.usedParams = params[0];
                if (ctrl.detail.usedParams !== undefined && ctrl.detail.usedParams.length !== undefined && ctrl.detail.usedParams.length > 0) {
                    ctrl.detail.selectedUsedParam = ctrl.detail.usedParams[0].id;
                    ctrl.handleUsedParamChange();
                } else {
                    ctrl.detail.selectedUsedParam = null;
                }
                getAvailableDriversForObject();
                ctrl.detail.unusedParams = params[1];
                if (ctrl.object instanceof Cooling && !ctrl.object.isStulz()) ctrl.detail.unusedParams = ctrl.detail.unusedParams.filter(function (p) {
                    return p.oidId === null;
                })
                if (ctrl.detail.unusedParams !== undefined && ctrl.detail.unusedParams.length) ctrl.detail.selectedUnusedParam = ctrl.detail.unusedParams[0].id;
            };

            /**
             * @description function to setup oid alerts for provided object
             * @param {object} object the object to setup oid alerts for
             * @param {array} alerts array of possible alert parameters from oid list
             */
            var setupOidAlerts = function (object, alerts) {
                OIDService.setupAlertsForObject(alerts, object);
            };

            /**
             * @description function to detail modal for provided object
             * @param {object?} obj the object to show detail modal for, if undefined current selected object will be used
             */
            ctrl.showEntityDetail = function (obj) {
                if (obj === undefined && ctrl.object) obj = ctrl.object;
                ctrl.closeDetailModal();
                var partDetails = null;
                if (obj instanceof Cooling || obj instanceof Asset || obj instanceof Ups) {
                    TrackingDivLabelFactory.clearAll();
                    infoLabel = null;
                    partDetails = PartlibService.getPartLibInfoByTypeAndIdLocal(obj.constructor.name, obj.getPartLibraryId(), parts);
                }
                detailModal = $uibModal.open({
                    animation: false,
                    component: 'entityDetailComponent',
                    windowClass: 'animated fadeInDown',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return obj;
                        },
                        user: function () {
                            return user;
                        },
                        edit: function () {
                            return ctrl.config.edit;
                        },
                        partInfoFile: function () {
                            return partDetails !== null && partDetails.stulzInfo ? DetailPictureService.getInfoObject(partDetails.stulzInfo) : null;
                        }
                    }
                });
            };

            /**
             * @description function to close the detail modal
             */
            ctrl.closeDetailModal = function () {
                if (detailModal !== null) detailModal.close();
            };

            /**
             * @description handler switch selected used datapoints
             */
            ctrl.handleUsedParamChange = function () {
                if (!ctrl.detail.selectedUsedParam) return;
                for (var i = 0; i < ctrl.object.driverValues.length; i++) {
                    if (ctrl.object.driverValues[i].parameter.id == ctrl.detail.selectedUsedParam) {
                        ctrl.detail.selectedDriverValue = i;
                        if (ctrl.object.driverValues[i].driver === null) {
                            ctrl.object.driverValues[i].driver = new Driver();
                        }
                        if (ctrl.object.driverValues[i].limits.length > 0) {
                            ctrl.detail.selectedLimit = ctrl.object.driverValues[i].limits[0].id;
                            ctrl.detail.selectedLimitNum = 0;
                        } else {
                            ctrl.detail.selectedLimit = null;
                            ctrl.detail.selectedLimitNum = null;
                        }
                        getAvailableDriversForObject();
                        return;
                    }
                }
                getAvailableDriversForObject();
                ctrl.detail.selectedDriverValue = null;
            };

            /**
             * @description change handler selected limit chaange
             */
            ctrl.handleSelectedLimitChange = function () {
                if (ctrl.detail.selectedDriverValue === null) return;
                if (ctrl.object.driverValues.length <= 0) return;
                var selectedDriverValue = ctrl.object.driverValues[ctrl.detail.selectedDriverValue];
                if (selectedDriverValue !== null && selectedDriverValue !== undefined) {
                    var selectedDriverValueLimits = selectedDriverValue.limits;
                    if (Tools.isDefinedNotNull(selectedDriverValueLimits)) {
                        if (selectedDriverValueLimits.length > 0) {
                            for (var i = 0; i < selectedDriverValueLimits.length; i++) {
                                if (selectedDriverValueLimits[i].id === ctrl.detail.selectedLimit) {
                                    ctrl.detail.selectedLimitNum = i;
                                    return;
                                }
                            }
                        }
                    }
                }
                ctrl.detail.selectedLimitNum = null;
            };

            ctrl.wirelessGateways = [];
            ctrl.wirelessSensors = [];

            ctrl.handleDriverTypeChange = function () {
                if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 12
                    || ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 13
                    || ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 14) {
                    Driver.setDefaultValuesforSNMP(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver);
                }
                if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 14) {
                    Driver.setDefaultValuesforSNMPv3(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver);
                }
                if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 5) {
                    Driver.setDefaultValuesforModBusTCP(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver);
                }
                var pos = ctrl.detail.availableDrivers.map(function (e) {
                    return e.id;
                }).indexOf(0);
                if (pos > -1) ctrl.detail.availableDrivers.splice(pos, 1);
            };

            ctrl.handleWirelessGatewayChange = function () {
                var dv = ctrl.object.driverValues[ctrl.detail.selectedDriverValue];
                var gateway = ctrl.wirelessGateways.filter(function (elem) {
                    return elem.id === ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewayId;
                })[0];
                ctrl.showSensorInput = gateway !== null && gateway !== undefined;
                ctrl.showSensorTypeahead = ctrl.showSensorInput;
                if (dv.physicalType === 1) {//temp
                    ctrl.wirelessSensors = gateway.tempSensors.concat(gateway.tempHumiSensors);
                }
                if (dv.physicalType === 2) {//humi
                    ctrl.wirelessSensors = gateway.tempHumiSensors;
                }
                if (dv.physicalType === 5) {//pressure
                    ctrl.wirelessSensors = gateway.pressureSensors;
                }
                if (dv.driver.gatewaySensorId && ctrl.wirelessSensors.filter(function (s) {
                    return s.id === dv.driver.gatewaySensorId
                }).length === 0) dv.driver.gatewaySensorId = null;

                if (ctrl.wirelessSensors !== undefined && ctrl.wirelessSensors !== null) {
                    for (var i = 0; i < ctrl.wirelessSensors.length; i++) {
                        if (ctrl.wirelessSensors[i].customSensorId === "") ctrl.wirelessSensors[i].customSensorId = ctrl.wirelessSensors[i].sensorId;
                    }
                }
            };

            $scope.$watch("ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewayId", function (n, o) {
                if (n !== null && n !== undefined && n !== o) {
                    ctrl.handleWirelessGatewayChange();
                }
            });

            ctrl.formatSensorTypeAhead = function (modelValue) {
                var label = "";
                angular.forEach(ctrl.wirelessSensors, function (sensor) {
                    if (modelValue === sensor.id) {
                        label = sensor.customSensorId;
                    }
                });
                return label;
            };

            function setValidatedSensors(response) {
                var filteredsensor = ctrl.room.sensors.filter(function (sensor) {
                    return sensor.id === response.data.sensorId
                })[0];
                var validDVs = filteredsensor.driverValues.filter(function (dv) {
                    return Tools.isDefinedNotNull(dv)
                });
                if (validDVs.length <= 0) {
                    RoomEditorService.showSaveErrorDialog();
                    return false;
                }
                for (var dvIndex in validDVs) {
                    var driverValue = validDVs[dvIndex];
                    if (driverValue.id === response.data.driverValueId && Tools.isDefinedNotNull(driverValue.driver)) {
                        if (Tools.isDefinedNotNull(driverValue.parameter)) {
                            setWirelessSensorsByType(driverValue)
                        }
                        if (ctrl.wirelessSensors.filter(function (sensor) {
                                return sensor.id === response.data.gatewayDbId
                            }).length !== 0
                            && Tools.isDefinedNotNull(driverValue.driver) && driverValue.driver.driverType === 11 && driverValue.driver.id === response.data.driverId) {
                            driverValue.driver.gatewaySensorId = response.data.gatewayDbId;
                            return true;
                        } else {
                            driverValue.driver.gatewaySensorId = null;
                            WebGLService.hideWaitingContainer();
                            RoomEditorService.showSaveErrorDialog("room.edit.dialog.messages.noSensorFound");
                            return false;
                        }
                    }
                }
                return false;
            }

            ctrl.handleWirelessSensorIdChange = function () {
                if (!Tools.isDefinedNotNull(ctrl.object.driverValues)
                    || !Tools.isDefinedNotNull(ctrl.detail.selectedDriverValue)
                    || !Tools.isDefinedNotNull(ctrl.object.driverValues[ctrl.detail.selectedDriverValue])
                    || !Tools.isDefinedNotNull(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].id)
                    || !Tools.isDefinedNotNull(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.id)
                    || !Tools.isDefinedNotNull(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewaySensorId)
                    || ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewaySensorId === ''
                    || !Tools.isDefinedNotNull(ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewayId)) {
                    return;
                }
                var driverValue = ctrl.object.driverValues[ctrl.detail.selectedDriverValue];
                var gatewaySensorId = driverValue.driver.gatewaySensorId;
                var gatewayId = driverValue.driver.gatewayId;
                var sensorToCheck = {
                    sensorId: ctrl.object.id,
                    driverValueId: driverValue.id,
                    driverId: driverValue.driver.id,
                    gatewaySensorId: gatewaySensorId,
                    gatewayId: gatewayId
                };
                if (typeof gatewaySensorId !== 'number') {
                    RoomService.getIdsOfGatewaySensors(sensorToCheck).then(function (response) {
                        if (setValidatedSensors(response)) {
                            gatewaySensorId = response.data.gatewayDbId;
                            var sensorId = response.data.sensorId;
                            validateSensorsWithValidIds(gatewaySensorId, sensorId);
                        }
                    }, function (error) {
                        throwErrorForInvalidSensor(sensorToCheck.sensorId, sensorToCheck.driverValueId, error.data.message);
                    });
                } else {
                    validateSensorsWithValidIds(gatewaySensorId, sensorToCheck.sensorId);
                }
            };

            function validateSensorsWithValidIds(gatewaySensorId, sensorId) {
                var sensorsInRoom = ctrl.room.sensors.filter(function (sensor) {
                    return sensor.id !== sensorId;
                });
                if (sensorsInRoom.length > 0) {
                    var sameDriverInSensors = sensorsInRoom.filter(function (sensor) {
                        return sensor.driverValues.filter(function (driverValue) {
                            if (Tools.isDefinedNotNull(driverValue.driver)) {
                                return driverValue.driver.gatewaySensorId === gatewaySensorId;
                            } else {
                                return false;
                            }
                        }).length > 0;
                    });
                    if (sameDriverInSensors.length > 0) {
                        driverAlreadySetDialog();
                    } else {
                        RoomService.isDriverAlreadySet(gatewaySensorId).then(function (response) {
                            if (response.data) {
                                driverAlreadySetDialog();
                            }
                        }, function (error) {
                            Notify.defaultError();
                        });
                    }
                }
                RoomService.isDriverAlreadySet(gatewaySensorId).then(function (response) {
                    if (response.data) {
                        driverAlreadySetDialog();
                    }
                }, function (error) {
                    Notify.defaultError();
                });
            }

            function driverAlreadySetDialog() {
                $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.wirelessSensorDuplicate', 'global.btn.yes', 'global.btn.no']).then(function (trans) {
                    GenDialogService.showDialog(false, {
                        headText: trans['global.dialog.head.warning'],
                        headIcon: 'glyphicon glyphicon-warning-sign',
                        messageText: trans['room.edit.dialog.messages.wirelessSensorDuplicate'],
                        showClose: true,
                        textButton0: trans['global.btn.yes'],
                        textButton1: trans['global.btn.no'],
                        iconButton0: 'glyphicon glyphicon-ok',
                        iconButton1: 'glyphicon glyphicon-remove',
                        classButton0: 'btn-default',
                        classButton1: 'btn-default',
                        callbackButton0: function () {
                            GenDialogService.hideDialog();
                        },
                        callbackButton1: function () {
                            ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.gatewaySensorId = null;
                            if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].parameter.physType === 5) ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.fieldPosition = null;
                            GenDialogService.hideDialog();
                        }
                    });
                });
            }

            ctrl.checkDPLimit = function () {
                if (ctrl.isFreeVersion) {
                    if (Tools.isDefinedNotNull(ctrl.object.driverValues)) {
                        if (ctrl.object.driverValues.length >= 5) {
                            ctrl.limitReached = true;
                            setTimeout(function () {
                                var button = document.getElementById("addDVbutton");
                                var tooltip = $translate.instant('global.btn.freeVersion');
                                if (Tools.isDefinedNotNull(button)) {
                                    button.setAttribute("title", tooltip);
                                }

                            }, 100);
                        } else if (ctrl.object.driverValues.length <= 5) {
                            ctrl.limitReached = false;
                            setTimeout(function () {
                                var button = document.getElementById("addDVbutton");
                                if (Tools.isDefinedNotNull(button)) {
                                    button.removeAttribute("title");
                                }

                            }, 100);
                        }
                    } else {
                        ctrl.limitReached = false;
                        setTimeout(function () {
                            var button = document.getElementById("addDVbutton");
                            if (Tools.isDefinedNotNull(button)) {
                                button.removeAttribute("title");
                            }

                        }, 100);
                    }
                }
            };

            /**
             * @description function to add new driver value/data point, button in detail view
             */
            ctrl.addDriverValue = function () {
                var p = ctrl.detail.unusedParams.filter(function (elem) {
                    return elem.id === ctrl.detail.selectedUnusedParam;
                })[0];
                var param = angular.merge({}, p);
                var ndv = new DriverValue(Entity.getNewIdFromArray(ctrl.object.driverValues), Entity.getNewLocaleUniqueId(), new Driver(), param, null, null, param.physType);
                // fix for GLT-562, GLT-489 and GLT-559
                for (var i = 0; i < ctrl.object.driverValues.length; i++) {
                    if (ctrl.object.driverValues[i] === undefined) {
                        ctrl.object.driverValues.splice(i, i + 1);
                    }
                    if (ctrl.object.driverValues[i].driver.outMax === null) {
                        ctrl.object.driverValues.splice(i, i + 1);
                    }
                }
                ndv.driverParameterId = param.id;
                var temp = ctrl.detail.selectedUnusedParam;
                ctrl.object.driverValues.push(ndv);
                setupParams();
                ctrl.detail.selectedUsedParam = temp;
                ctrl.handleUsedParamChange();
                ctrl.checkDPLimit();
                ctrl.checkForDefaultLimitCreation();
            };

            /**
             * @description function to get a value to a properly configured driver configuration via oid
             */
            ctrl.getOIDValue = function (getonly) {
                ctrl.isGetClicked = true;
                var getonly = getonly;
                var drivervalue = ctrl.object.driverValues[ctrl.detail.selectedDriverValue];
                var driver = drivervalue.driver;

                if (driver.driverType === 12 || driver.driverType === 13) {
                    if (driver.oid === undefined || driver.oid === null || driver.oid === "" ||
                        driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "" ||
                        driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0 ||
                        driver.community === undefined || driver.community === null || driver.community === "") {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.getValueMessage.validationError", 4000);
                        ctrl.isGetClicked = false;
                    } else {
                        //call get service
                        _callGetService(getonly, driver, drivervalue);
                    }

                } else if (driver.driverType === 14) {
                    if (driver.authenticationPassphrase.length < 8 || driver.privacyPassphrase.length < 8) {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.errorInputLength", 5000);
                        ctrl.isGetClicked = false;
                    } else {
                        if (driver.oid === undefined || driver.oid === null || driver.oid === "" ||
                            driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "" ||
                            driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0 ||
                            driver.userName === undefined || driver.userName === null || driver.userName === "" ||
                            driver.authenticationPassphrase === undefined || driver.authenticationPassphrase === null || driver.authenticationPassphrase === "" ||
                            driver.privacyPassphrase === undefined || driver.privacyPassphrase === null || driver.privacyPassphrase === "") {
                            Notify.error("global.notification.error.title", "room.driver.getAndSet.getValueMessage.validationError", 4000);
                            ctrl.isGetClicked = false;
                        } else {
                            //call get service
                            _callGetService(getonly, driver, drivervalue);
                        }
                    }
                }
            }

            var _callGetService = function (getonly, driver, drivervalue) {
                OIDValueService.getValueForSnmp(drivervalue).then(function (response) {
                    var returnValue = response;
                    if (Tools.isDefinedNotNull(returnValue) && returnValue !== undefined && returnValue !== "") {
                        if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].getAndSet === 'getAndSet') {
                            ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSet = returnValue;
                            //multiply if only out max is set
                            if (driver.inMin == 0 && driver.inMax == 0 && driver.outMin == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSetReal = ((returnValue * driver.outMax) / 1000).toFixed(3);
                            }
                            //divide when only out min is set
                            else if (driver.inMin == 0 && driver.inMax == 0 && driver.outMax == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSetReal = ((returnValue / driver.outMin) / 1000).toFixed(3);
                            }
                            // ignore pill when nothing is set
                            else if (driver.inMin == 0 && driver.inMax == 0 && driver.outMin == 0 && driver.outMax == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSetReal = (returnValue / 1000).toFixed(3);
                            } else {
                                //linear interpolation of values
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSetReal = ((driver.outMin + (((driver.outMax - driver.outMin) / (driver.inMax - driver.inMin)) * (returnValue - driver.inMin))) / 1000).toFixed(3);
                            }
                            if (getonly) {
                                Notify.success("global.notification.success.title", "room.driver.getAndSet.getValueMessage.success", 2000);
                                ctrl.isGetClicked = false;
                            } else {
                                ctrl.isGetClicked = false;
                            }
                        } else {
                            ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.getOnly = returnValue;
                            //multiply if only out max is set
                            if (driver.inMin == 0 && driver.inMax == 0 && driver.outMin == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.getOnlyReal = ((returnValue * driver.outMax) / 1000).toFixed(3);
                            }
                            //divide when only out min is set
                            else if (driver.inMin == 0 && driver.inMax == 0 && driver.outMax == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.getOnlyReal = ((returnValue / driver.outMin) / 1000).toFixed(3);
                            }
                            // ignore pill when nothing is set
                            else if (driver.inMin == 0 && driver.inMax == 0 && driver.outMin == 0 && driver.outMax == 0) {
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.getOnlyReal = (returnValue / 1000).toFixed(3);
                            } else {
                                //linear interpolation of values
                                ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.getOnlyReal = ((driver.outMin + (((driver.outMax - driver.outMin) / (driver.inMax - driver.inMin)) * (returnValue - driver.inMin))) / 1000).toFixed(3);
                            }
                            Notify.success("global.notification.success.title", "room.driver.getAndSet.getValueMessage.success", 2000);
                            ctrl.isGetClicked = false;
                        }
                    } else {
                        ctrl.isGetClicked = false;
                    }
                }, function (error) {
                    $log.error("Error getting OID value\n" + error);
                    ctrl.isGetClicked = false;
                });
            };

            /**
             * @description function to set a value to a properly configured driver configuration via oid
             */
            ctrl.setOIDValue = function () {
                ctrl.isSetClicked = true;
                var driverValue = ctrl.object.driverValues[ctrl.detail.selectedDriverValue];
                var driver = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver;
                var value = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.oidGetSet;

                if (driver.driverType == 12 || driver.driverType == 13) {
                    if (driver.oid === undefined || driver.oid === null || driver.oid === "" ||
                        driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "" ||
                        driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0 ||
                        driver.community === undefined || driver.community === null || driver.community === "") {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.validationError", 4000);
                        ctrl.isSetClicked = false;
                    } else {
                        //call set service
                        _callSetService(driverValue, driver, value);
                    }

                } else if (driver.driverType === 14) {
                    if (driver.authenticationPassphrase.length < 8 || driver.privacyPassphrase.length < 8) {
                        Notify.error("global.notification.error.title", "room.driver.getAndSet.errorInputLength", 5000);
                        ctrl.isSetClicked = false;
                    } else {
                        if (driver.oid === undefined || driver.oid === null || driver.oid === "" ||
                            driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "" ||
                            driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0 ||
                            driver.userName === undefined || driver.userName === null || driver.userName === "" || driver.authenticationPassphrase === "" || driver.privacyPassphrase === "") {
                            Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.validationError", 4000);
                            ctrl.isSetClicked = false;
                        } else {
                            //call set service
                            _callSetService(driverValue, driver, value);
                        }
                    }
                }
            };

            var _callSetService = function (driverValue, driver, value) {
                if (_SetValueValidation(driverValue, driver, value)) {
                    OIDValueService.setValueForSnmp(driverValue, value).then(function (response) {
                        if (response !== undefined) {
                            if (ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 14) {
                                ctrl.getOIDValue(false);
                                Notify.success("global.notification.success.title", "room.driver.getAndSet.setValueMessage.success", 2000);
                                ctrl.isSetClicked = false;
                            } else {
                                if (driverValue.driver.community.contains("private") && response.data === true ||
                                    ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 12 ||
                                    ctrl.object.driverValues[ctrl.detail.selectedDriverValue].driver.driverType === 13) {
                                    ctrl.getOIDValue(false);
                                    Notify.success("global.notification.success.title", "room.driver.getAndSet.setValueMessage.success", 2000);
                                } else if (driverValue.driver.community === "public") {
                                    Notify.warning("global.notification.error.title", "room.driver.getAndSet.setValueMessage.warning", 2000);
                                } else {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 2000);
                                }
                                ctrl.isSetClicked = false;
                            }
                        } else {
                            ctrl.isSetClicked = false;
                        }
                    });
                }
            };


            /**
             * @description Calculates if the entered value can be set, based on driver inMax configuration and given value and if parameter has oid write permission
             */
            var _SetValueValidation = function (driverValue, driver, value) {
                if (Tools.isDefinedNotNull(driverValue.parameter.oidDataPoint)) {
                    if (((value * driver.inMax) / 1000).toFixed(3) < driverValue.parameter.oidDataPoint.inMin.toFixed(3) || ((value * driver.inMax) / 1000).toFixed(3) > driverValue.parameter.oidDataPoint.inMax.toFixed(3)) {
                        var errorMessage = $translate.instant('room.driver.getAndSet.minMaxRange', {
                            min: driverValue.parameter.oidDataPoint.inMin,
                            max: driverValue.parameter.oidDataPoint.inMax
                        });
                        Notify.error("global.notification.error.title", errorMessage, 3000);
                        ctrl.isSetClicked = false;
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }

            /**
             * @description function to remove driver value/data point
             */
            ctrl.deleteDriverValue = function () {
                if (Tools.isDefinedNotNull(ctrl.room.json)) {
                    var driverValues = ctrl.object.driverValues;
                    var driverValueIds = getDriverValueIds(driverValues);
                    var driverValueModbusUids = getDriverValueModbusUids(driverValues);
                    var selectedParam = ctrl.detail.selectedUsedParam;
                    if (driverValueIds !== undefined && driverValueIds.length) {
                        var selectedDvId;
                        tempDeleteDriverValueIds = tempDeleteDriverValueIds.concat(driverValueIds);
                        for (var i = 0; i < driverValues.length; i++) {
                            if(driverValues[i].driverParameterId === selectedParam) {
                                selectedDvId = driverValues[i].id;
                            }
                        }
                        checkIsUsedInErrorConfig(selectedDvId);
                    }
                    if (driverValueModbusUids !== undefined && driverValueModbusUids.length > 0) {
                        var selectedDvUid;
                        tempDeleteDriverValueModbusUids = tempDeleteDriverValueModbusUids.concat(driverValueModbusUids);
                        for (var j = 0; j < driverValues.length; j++) {
                            if (driverValues[j].uniqueId > 0 && driverValues[j].driver.driverType == ctrl.driverType.MODBUS_TCP.id) {
                                selectedDvUid = driverValues[i].uniqueId;
                            }
                        }
                        checkIsUsedInErrorConfig(selectedDvUid);
                    }
                    if (!driverValueIds.length && !driverValueModbusUids.length) {
                        deleteSelectedDriverValue();
                    }
                }
                ctrl.checkDPLimit();
            };

            /**
             * @description helper method to check if the selected driver value is configured in an error configuration
             */
            var checkIsUsedInErrorConfig = function (selectedDvId) {
                var errorConfigs = ctrl.config.errorConfigs;
                var isUsedInCondition;
                if (errorConfigs.length) {
                    var impactedErrorConfigList = "";
                    var increment = 1;
                    for (var i = 0; i < errorConfigs.length; i++) {
                        var conditions = errorConfigs[i].errorConditions;
                        isUsedInCondition = conditions.find((condition) => condition.driverValueId === selectedDvId);
                        if (isUsedInCondition) {
                            impactedErrorConfigList += increment + ". " + errorConfigs[i].name + '<br/>';
                            increment++;
                        }
                    }
                    if(impactedErrorConfigList !== "") {
                        deleteDvConfirmationModal(impactedErrorConfigList);
                    } else {
                        deleteSelectedDriverValue();
                    }
                } else {
                    deleteSelectedDriverValue();
                }
            }

            /**
             * @description function to show modal dialog for driverValue deletion confirmation
             */
            var deleteDvConfirmationModal = function (list) {
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: $translate.instant('glyphicon glyphicon-warning-sign'),
                    messageText: $translate.instant('room.edit.dialog.messages.deleteDriverValueInUse') + '<br/><br/>' + $translate.instant('room.edit.dialog.messages.impactedAlarms') + '<br/><br/>' + list,
                    showClose: false,
                    textButton1: $translate.instant('global.btn.delete'),
                    textButton0: $translate.instant('global.btn.cancel'),
                    iconButton1: 'glyphicon glyphicon-remove-circle',
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton1: 'btn-delete',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        deleteSelectedDriverValue();
                        GenDialogService.hideDialog();
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            };


            /**
             * @description deletes currently selected driverValue
             * **/
            function deleteSelectedDriverValue() {
                ctrl.object.driverValues.splice(ctrl.detail.selectedDriverValue, 1);
                setupParams(ctrl.detail.availableParams);
            }


            /**
             * @description function to add limit to currently selected driver value/data point
             */
            ctrl.addLimit = function () {
                var limitArray = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].limits;
                var limit = new Limit(Entity.getNewIdFromArray(limitArray), Entity.getNewLocaleUniqueId());
                limit.driverValueId = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].id;
                limitArray.push(limit);
                ctrl.detail.selectedLimit = limit.id;
                ctrl.detail.selectedLimitNum = limitArray.length - 1;
            };


            /**
             * @description While adding driver value check if setpoint/threshold and usage for limits is defined
             */

            ctrl.checkForDefaultLimitCreation = function () {
                if (ctrl.room.roomConfigurations.useForLimits) {
                    if (ctrl.detail.selectedUsedParam === 1) {
                        ctrl.addDefaultHighLowLimits(ctrl.detail.selectedUsedParam, ctrl.room.roomConfigurations.temperatureSetpoint, ctrl.room.roomConfigurations.temperatureThreshold);
                    }
                    if (ctrl.detail.selectedUsedParam === 7) {
                        ctrl.addDefaultHighLowLimits(ctrl.detail.selectedUsedParam, ctrl.room.roomConfigurations.pressureSetpoint, ctrl.room.roomConfigurations.pressureThreshold);
                    }
                }
            }


            /**
             * @description function to add default limits for high and low temperature and pressure
             */

            ctrl.addDefaultHighLowLimits = function (selectedUsedParam, setpoint, threshold) {

                if (Tools.isDefinedNotNull(setpoint) && Tools.isDefinedNotNull(threshold)) {
                    var limitArray = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].limits;
                    var lowLimit = new Limit(Entity.getNewIdFromArray(limitArray), Entity.getNewLocaleUniqueId());
                    limitArray.push(lowLimit);
                    var highLimit = new Limit(Entity.getNewIdFromArray(limitArray), Entity.getNewLocaleUniqueId());
                    limitArray.push(highLimit);
                    //set general values, identical for every created limit
                    lowLimit.driverValueId = highLimit.driverValueId = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].id;
                    lowLimit.delay = highLimit.delay = 0;
                    lowLimit.priority = highLimit.priority = 149;

                    //set lower equal / greater equal for low and high limit
                    lowLimit.type = 4;
                    highLimit.type = 3;

                    //setting low and high limit values, based on room setpoint and threshold
                    lowLimit.value = setpoint - threshold;
                    highLimit.value = setpoint + threshold;

                    //set values based on selected param type (1 = temperature / 7 = pressure)
                    if (selectedUsedParam === 1) {
                        lowLimit.name = ctrl.object.name + "_temp_low";
                        highLimit.name = ctrl.object.name + "_temp_high";

                    }
                    if (selectedUsedParam === 7) {
                        lowLimit.name = ctrl.object.name + "_pressure_low";
                        highLimit.name = ctrl.object.name + "_pressure_high";
                    }
                    //push both Limits to limit array

                }
            }

            /**
             * @description function to remove limit from currently selected driver value/data point
             */
            ctrl.deleteLimit = function () {
                var limitArray = ctrl.object.driverValues[ctrl.detail.selectedDriverValue].limits;
                limitArray.splice(ctrl.detail.selectedLimitNum, 1);
                if (limitArray.length > 0) {
                    ctrl.detail.selectedLimit = limitArray[0].id;
                    ctrl.detail.selectedLimitNum = 0;
                }
            };
            // region handler entity

            /**
             * @description change handler for floor type modification (select ground/basement/normal)
             */
            ctrl.handleFloorPositionInput = function () {
                if (ctrl.object.floorPosition.indexOf(ctrl.object.stdFloorPos) === -1) {
                    if (ctrl.object.floorPanelCustomPos !== "" && ctrl.object.floorPanelCustomPos !== null) {
                        ctrl.object.floorPosition = ctrl.object.floorPanelCustomPos.replace("|fp|", ctrl.object.stdFloorPos);
                    } else {
                        ctrl.object.floorPanelCustomPos = "|fp|";
                        ctrl.object.floorPosition = ctrl.object.floorPanelCustomPos.replace("|fp|", ctrl.object.stdFloorPos);
                    }
                }
                ctrl.object.floorPanelCustomPos = ctrl.object.floorPosition.replace(ctrl.object.stdFloorPos, "|fp|");
            };

            /**
             * @description change handler for entity size changes (incl. collision checks, redraw etc)
             */
            ctrl.handleSizeChange = function () {
                var oldSize = ctrl.object instanceof FloorTile || ctrl.object instanceof Containment ? ctrl.object.size : obj3d.selectedObj.scale.clone();
                if (ctrl.object instanceof FloorTile) {
                    oldSize = obj3d.selectedObj.userData.size.clone();
                }
                if (ctrl.object instanceof Containment) {
                    oldSize = obj3d.selectedObj.userData.obb.e.clone();
                    oldSize.x *= 2;
                    oldSize.y *= 2;
                    oldSize.z *= 2;
                }
                var oldPos = obj3d.selectedObj.position.clone();
                var pos;
                if (ctrl.object.size.x === undefined || ctrl.object.size.y === undefined || ctrl.object.size.z === undefined) {
                    ctrl.object.size.x = oldSize.x;
                    ctrl.object.size.y = oldSize.y;
                    ctrl.object.size.z = oldSize.z;
                    return;
                }

                var applyNewSizeStdRoomObject = function () {
                    obj3d.selectedObj.scale.set(ctrl.object.size.x, ctrl.object.size.y, ctrl.object.size.z);
                    obj3d.selectedObj.position.y = pos.y;
                    obj3d.selectedObj.userData.obb.e.set(ctrl.object.size.x / 2, ctrl.object.size.y / 2, ctrl.object.size.z / 2);
                    obj3d.selectedObj.userData.obb.c.copy(obj3d.selectedObj.position);
                    ctrl.object.pos.y = pos.y;
                    GLMeasureService.measureObject(ctrl.object, obj3d.selectedObj, obj3d.room, undefined, ctrl.room);
                };

                var applyNewSizeFloorTile = function () {
                    var newObj = Object3DFactory.buildFloorTile(ctrl.object, getDisplayMode(), obj3d.room, ctrl.room);
                    obj3d.selectedObj.parent.add(newObj);
                    obj3d.selectedObj.parent.remove(obj3d.selectedObj);
                    obj3d.selectedObj = newObj;
                    WebGLService.markObject(obj3d.selectedObj, undefined, true);
                };

                var applyNewSizeContainment = function () {
                    adjustHeatmap();
                    var newObj = Object3DFactory.buildRoomObject(ctrl.object, getDisplayMode(), obj3d.room);
                    obj3d.room.remove(obj3d.selectedObj);
                    obj3d.selectedObj = newObj;
                    obj3d.selectedObj.position.y = pos.y;
                    obj3d.selectedObj.userData.obb.e.set(ctrl.object.size.x / 2, ctrl.object.size.y / 2, ctrl.object.size.z / 2);
                    obj3d.selectedObj.userData.obb.c.copy(obj3d.selectedObj.position);
                    ctrl.object.pos.y = pos.y;
                    obj3d.room.add(obj3d.selectedObj);
                    WebGLService.markObject(obj3d.selectedObj, undefined, true);
                };

                if (ctrl.object instanceof Asset || ctrl.object instanceof Cooling || ctrl.object instanceof Rack || ctrl.object instanceof Ups) {
                    pos = new THREE.Vector3(ctrl.object.pos.x, ctrl.object.pos.y, ctrl.object.pos.z);
                    pos.y = ctrl.object.size.y / 2;
                    if (ctrl.room.hasRaisedFloor) pos.y += ctrl.room.getRaisedFloor().size.y;
                    if (ctrl.object instanceof Rack && (ctrl.object.size.y / ctrl.object.heightUnits < 0.046)) {
                        ctrl.object.size.x = oldSize.x;
                        ctrl.object.size.y = oldSize.y;
                        ctrl.object.size.z = oldSize.z;
                    } else {
                        if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, pos, false, ctrl.room)) {
                            RoomEditorService.showCollideDialog(function () {
                                applyNewSizeStdRoomObject();
                                ctrl.handleRackHUNumber(ctrl.object.size.y);
                            }, function () {
                                ctrl.object.size.x = oldSize.x;
                                ctrl.object.size.y = oldSize.y;
                                ctrl.object.size.z = oldSize.z;
                            });
                        } else {
                            applyNewSizeStdRoomObject();
                            ctrl.handleRackHUNumber(ctrl.object.size.y);
                        }
                    }
                }
                if (ctrl.object instanceof FloorTile) {
                    pos = new THREE.Vector3(ctrl.object.pos.x, ctrl.object.pos.y, ctrl.object.pos.z);
                    pos.y = ctrl.room.getRaisedFloor().size.y;
                    if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, pos, false, ctrl.room)) {
                        RoomEditorService.showCollideDialog(function () {
                            applyNewSizeFloorTile();
                        }, function () {
                            ctrl.object.size.x = oldSize.x;
                            ctrl.object.size.y = oldSize.y;
                            ctrl.object.size.z = oldSize.z;
                        });
                    } else {
                        applyNewSizeFloorTile();
                    }
                }
                if (ctrl.object instanceof Containment) {
                    pos = new THREE.Vector3(ctrl.object.pos.x, ctrl.object.pos.y, ctrl.object.pos.z);
                    pos.y = ctrl.object.size.y / 2;
                    if (ctrl.room.hasRaisedFloor) pos.y += ctrl.room.getRaisedFloor().size.y;
                    if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, pos, false, ctrl.room)) {
                        RoomEditorService.showCollideDialog(function () {
                            applyNewSizeContainment();
                        }, function () {
                            ctrl.object.size.x = oldSize.x;
                            ctrl.object.size.y = oldSize.y;
                            ctrl.object.size.z = oldSize.z;
                        });
                    } else {
                        applyNewSizeContainment();
                    }
                }
            };

            ctrl.handleRackHUNumber = function (sizeY) {
                ctrl.maxHu = Math.round(sizeY / 0.046);
                var huInputField = document.getElementById("HUInput");
                if (Tools.isDefinedNotNull(huInputField)) huInputField.setAttribute("max", ctrl.maxHu);
                ctrl.InvalidRackHuNumber = ctrl.checkHURoomRacks();
            };

            ctrl.checkHURoomRacks = function () {
                if (ctrl.room.racks.length > 0) {
                    for (var i = 0; i < ctrl.room.racks.length; i++) {
                        var maxHu = Math.round(ctrl.room.racks[i].size.y / 0.046);
                        if (!validateNumber(ctrl.room.racks[i].heightUnits) || ctrl.room.racks[i].heightUnits < 1 || ctrl.room.racks[i].heightUnits > 100 || ctrl.room.racks[i].heightUnits > maxHu) {
                            return true;
                        }
                    }
                    return false;
                } else {
                    return false;
                }
            }

            /**
             * @description change handler for rotation input
             */
            ctrl.handleRotationChange = function () {
                var applyNewRotStdRoomObject = function () {
                    obj3d.selectedObj.rotation.y = MathService.degToRad(ctrl.object.rot.y) * -1;
                    obj3d.selectedObj.userData.obb.rotateY(obj3d.selectedObj.rotation.y);
                    GLMeasureService.measureObject(ctrl.object, obj3d.selectedObj, obj3d.room, undefined, ctrl.room);
                };
                var oldRotation = MathService.radToDeg(obj3d.selectedObj.rotation.y) * -1;
                if (ctrl.object.rot.y === undefined) {
                    ctrl.object.rot.y = oldRotation;
                    return;
                }
                if (ctrl.object instanceof Asset || ctrl.object instanceof Cooling || ctrl.object instanceof Rack || ctrl.object instanceof Ups || ctrl.object instanceof Containment) {
                    if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, undefined, false, ctrl.room)) {
                        RoomEditorService.showCollideDialog(function () {
                            applyNewRotStdRoomObject();
                        }, function () {
                            ctrl.object.rot.y = oldRotation;
                        });
                    } else {
                        applyNewRotStdRoomObject();
                    }
                }
            };

            /**
             * @description change handler for position inputs (redraw etc)
             */
            ctrl.handlePositionChange = function () {
                adjustHeatmap();
                var applyNewRelPosToObject = function (pos) {
                    obj3d.selectedObj.position.set(pos.x, pos.y, pos.z);
                    obj3d.selectedObj.userData.obb.c.copy(obj3d.selectedObj.position);
                    RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "pos", pos);
                    WebGLService.markObject(obj3d.selectedObj);
                    GLMeasureService.measureObject(ctrl.object, obj3d.selectedObj, obj3d.room, undefined, ctrl.room);
                };
                if (ctrl.object.relX === undefined || ctrl.object.relY === undefined || ctrl.object.relZ === undefined) {
                    ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                    return;
                }
                if (ctrl.object instanceof Asset || ctrl.object instanceof Cooling || ctrl.object instanceof Rack || ctrl.object instanceof Ups || ctrl.object.isSensor || ctrl.object instanceof Containment) {
                    var newPos = ctrl.object.getRealPositionFromRelative(ctrl.room.bbox.min, new THREE.Vector3(ctrl.object.relX, ctrl.object.relY, ctrl.object.relZ));
                    if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, newPos, false, ctrl.room)) {
                        RoomEditorService.showCollideDialog(function () {
                            applyNewRelPosToObject(newPos);
                        }, function () {
                            ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                        });
                    } else {
                        applyNewRelPosToObject(newPos);
                    }
                }
                if (ctrl.object instanceof FloorTile) {
                    var newFloortilePos = ctrl.object.getRealPositionFromRelative(ctrl.room.bbox.min, new THREE.Vector3(ctrl.object.relX, ctrl.object.relY, ctrl.object.relZ));
                    if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, newFloortilePos, false, ctrl.room)) {
                        RoomEditorService.showCollideDialog(function () {
                            newFloortilePos.y = 0;
                            applyNewRelPosToObject(newFloortilePos);
                        }, function () {
                            newFloortilePos.y = 0;
                            ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                        });
                    } else {
                        newFloortilePos.y = 0;
                        applyNewRelPosToObject(newFloortilePos);
                    }
                }
            };

            /**
             * @description change handler for slot orientation input (redraw etc)
             */
            ctrl.handleSlotOrientationChange = function () {
                ctrl.object.pos.z *= -1;
                var slot3d = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId, obj3d.rack);
                var rotation = slot3d.parent.rotation.y === 0 ? Math.PI : 0;

                slot3d.parent.rotation.y = rotation;
                slot3d.parent.position.z = ctrl.object.pos.z;
                if (!WebGLService.isCam3D()) {
                    var obj = RoomEditorService.findBackObjectForSlot(ctrl.object);
                    if (obj) {
                        obj.parent.rotation.y = Math.abs(obj.parent.rotation.y) <= 0.001 ? Math.PI : 0;
                    }
                }
            };

            /**
             * @description change handler for slot blade row/col modification
             */
            ctrl.handleSlotBladeChange = function () {
                obj3d.selectedObj.parent.parent.remove(obj3d.selectedObj.parent);
                obj3d.selectedObj = Object3DFactory.buildSlot(ctrl.object, getDisplayMode());
                obj3d.rack.add(obj3d.selectedObj);
                obj3d.selectedObj = WebGLService.findObjectByName("slot", obj3d.selectedObj);
                WebGLService.markObject(obj3d.selectedObj);
            };

            /**
             * @description change handler for slot height input
             */
            ctrl.handleHeightUnitChange = function () {
                if (ctrl.object.posHU === undefined || ctrl.object.posHU === null) return;
                var pos = ctrl.object.computePositionForHU(ctrl.rack);
                var applyNewPos = function () {
                    RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "pos", pos);
                    if (obj3d.selectedObj.name === "slot") {
                        obj3d.selectedObj.parent.position.y = pos.y;
                    } else {
                        obj3d.selectedObj.position.y = pos.y;
                    }
                    if (!WebGLService.isCam3D()) {
                        var obj = RoomEditorService.findBackObjectForSlot(ctrl.object);
                        if (obj) obj.parent.position.y = pos.y;
                    }
                };
                if (ctrl.object.checkCollision(ctrl.rack, pos)) {
                    RoomEditorService.showSlotPositionCollideDialog(function () {
                            applyNewPos();
                        },
                        function () {
                            ctrl.object.computeHUPosition(ctrl.rack);
                        });
                } else {
                    applyNewPos();
                }
            };

            /**
             * @description change handler for slot size inputs
             */
            ctrl.handleSizeChangeSlot = function () {
                var oldSize = ctrl.object instanceof FloorTile || ctrl.object instanceof Containment ? ctrl.object.size : obj3d.selectedObj.scale.clone();
                var oldPos = obj3d.selectedObj.position.clone();
                if (!validateNumber(ctrl.object.size.x) || !validateNumber(ctrl.object.size.y) || !validateNumber(ctrl.object.size.z)) {
                    RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "size", oldSize);
                    return;
                }
                var applyNewSizeSlot = function () {
                    obj3d.selectedObj.parent.parent.remove(obj3d.selectedObj.parent);
                    RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "pos", pos);
                    var obj = Object3DFactory.buildSlot(ctrl.object, getDisplayMode());
                    obj3d.selectedObj = obj.children[0];
                    WebGLService.markObject(obj.children[0], undefined, true);
                    obj3d.rack.add(obj);
                    if (!WebGLService.isCam3D()) {
                        var oldSlot = RoomEditorService.findBackObjectForSlot(ctrl.object);
                        var newSlot = obj.clone();
                        oldSlot.parent.parent.add(newSlot);
                        oldSlot.parent.parent.remove(oldSlot.parent);
                    }

                };
                var pos = ctrl.object.computePositionForHU(ctrl.rack);
                if (ctrl.object.checkCollision(ctrl.rack, pos)) {
                    RoomEditorService.showSlotCollideDialog(function () {
                            applyNewSizeSlot();
                        },
                        function () {
                            RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "size", oldSize);
                        });
                } else {
                    applyNewSizeSlot();
                }
            };

            /**
             * @description function to switch between slot height input types
             * @param type the input type (input in m or in HU)
             */
            ctrl.setSlotHeightType = function (type) {
                ctrl.detail.slotHeightType = type;
                ctrl.detail.slotHeightTypeName = ctrl.detail.slotHeightType === 0 ? $translate.instant("room.edit.slotHeightTypeNormal") : $translate.instant("room.edit.slotHeightTypeHU");
            };

            //endregion

            var lastAddedCpuHelper = 1;

            /**
             * @description function to handle click on 'add cpu' in frontend
             */
            ctrl.addCpu = function () {
                if (ctrl.object.blades.length === 0) {
                    var blade = new Blade(Entity.getNewIdFromArray(ctrl.object.blades), {x: 0, y: 0, z: 0}, {
                        x: 0,
                        y: 0,
                        z: 0
                    }, {
                        x: 0,
                        y: 0,
                        z: 0
                    }, $translate.instant("global.entityTypes.blade"), '', '', '', '', 0, undefined, 1, Entity.getNewLocaleUniqueId(), ctrl.object.id);
                    ctrl.object.blades.push(blade);
                }
                var cpu = new Cpu(Entity.getNewIdFromArray(ctrl.object.blades[0].cpus), {x: 0, y: 0, z: 0}, {
                    x: 0,
                    y: 0,
                    z: 0
                }, {
                    x: 0,
                    y: 0,
                    z: 0
                }, $translate.instant(["partlib.cpu.generic"])['partlib.cpu.generic'], '', '', '', Entity.getNewLocaleUniqueId(), ctrl.object.blades[0].id);
                cpu.type = lastAddedCpuHelper++;

                ctrl.object.blades[0].cpus.push(cpu);
                for (var i = 0; i < ctrl.object.blades[0].cpus.length; i++) {
                    ctrl.detail.selectedCpuId = ctrl.object.blades[0].cpus[i].id;
                }
            };

            /**
             * @description function to show selected cpu details
             */
            ctrl.showCpu = function () {
                var cpu = ctrl.object.blades.length > 0 ? ctrl.object.blades[0].cpus.filter(function (c) {
                    return c.id == ctrl.detail.selectedCpuId;
                }) : [];
                if (cpu.length) {
                    ctrl.object = cpu[0];
                    handleParameterSetup();
                }
            };

            /**
             * @description function to handle click on remove cpu in frontend
             */
            ctrl.removeCpu = function () {
                var cpu = ctrl.object.blades.length > 0 ? ctrl.object.blades[0].cpus.filter(function (c) {
                    return c.id == ctrl.detail.selectedCpuId;
                }) : [];
                if (cpu.length > 0) {
                    RoomEditorService.showDeleteObjectDialog(cpu[0], null, function () {
                        if (cpu[0].id > 0) {
                            RoomService.deleteCpuEntity(cpu[0], ctrl.room.json).then(function (response) {
                                Notify.defaultSuccess();
                                var filtered = ctrl.object.blades[0].cpus.filter(function (filteredCpu) {
                                    return filteredCpu.id != cpu[0].id;
                                });
                                ctrl.object.blades[0].cpus = filtered;
                                GenDialogService.hideDialog();
                            }, function (error) {
                                Notify.defaultError();
                                GenDialogService.hideDialog();
                            });
                        } else {
                            RoomService.deleteUnsavedEntity(cpu[0], ctrl.room.findObjectByUniqueID(ctrl.object.blades[0].uniqueId), obj3d.rack);
                            GenDialogService.hideDialog();
                        }
                    });
                }
            };

            var adjustHeatmap = function () {
                if (ctrl.gl.heatmapActive) {
                    HeatMapGLService.removeHeatmapFromRoom();
                    ctrl.gl.heatmapActive = false;
                }
            };

            /**
             * @description function to add label for HU configuration to content
             */
            var addHULabel = function () {
                var label = document.createElement("div");
                label.className = "hulabel";
                label.id = "hulabel";
                $('#glContainer').append(label);
            };

            /**
             * @description function to remove HU label from content
             */
            var removeHULabel = function () {
                $('#hulabel').remove();
            };

            /**
             * @description function to position HU configuration label
             * @param {MouseEvent} e the mouse event to use for positioning
             */
            var positionHULabel = function (e) {
                var offsetX = e.offsetX + $('#hulabel').width() * 0.75;

                $('#hulabel').css("top", e.clientY + "px");
                $('#hulabel').css("left", offsetX + "px");

            };

            /**
             * @description function to change text HU label
             * @param text
             */
            var setHULabelText = function (text) {
                $('#hulabel').html(text);
            };

            /**
             * @description function to determine if selected object is applicable for detail view (sidebar)
             * @returns {boolean} returns true if details should be displayed for object, otherwise false
             */
            ctrl.isEntityForDetail = function () {
                if (ctrl.viewObject instanceof Rack && ctrl.object instanceof Rack) return false;
                return ctrl.object instanceof Rack || ctrl.object instanceof Sensor || ctrl.object instanceof Asset || ctrl.object instanceof Cooling || ctrl.object instanceof Slot || ctrl.object instanceof Cpu || ctrl.object instanceof FloorTile || ctrl.object instanceof Ups;
            };

            /**
             * @description function to determine if provided object is of instance 'NamesEntity', allow name/comment inputs etc
             * @param {object} obj the object to check
             * @returns {boolean} returns true if object is instance of 'NamedEntity'
             */
            ctrl.isNamedEntity = function (obj) {
                if (obj) return obj instanceof NamedEntity;
                return ctrl.object instanceof NamedEntity;
            };

            /**
             * @description function to determine if provided object is applicable to have input for floor panel position (raised floor position ie 'AA34')
             * @param {object} obj the object to check
             * @returns {boolean} returns true if input should be displayed, otherwise false
             */
            var isFloorPanelObject = function (obj) {
                if (!obj) obj = ctrl.object;
                return (obj instanceof Rack || obj instanceof Cooling || obj instanceof Asset || obj instanceof FloorTile || obj instanceof Sensor || obj instanceof Ups);
            };

            /**
             * @description function to determine if provided object/current object should have mask for data points
             * @param obj
             * @returns {boolean}
             */
            ctrl.isDriverObject = function (obj) {
                if (!obj) obj = ctrl.object;
                return (obj instanceof Cpu || obj instanceof Cooling || obj instanceof Sensor || obj instanceof Asset || obj instanceof Ups);
            };

            /**
             * @description function to handle click on 'open rack' buttons
             * @param {object?} obj the rack object to use, if not defined current selected object will be used
             */
            ctrl.openRack = function (obj) {
                if (obj === undefined) obj = ctrl.object;
                if (!ctrl.config.edit) {
                    $state.go('location.rooms.view.rack', {rackid: obj.id});
                } else {
                    $state.go('location.rooms.edit.rack', {rackid: obj.id});
                    ClipboardService.clearClipboard();
                }
            };
            //endregion

            //region Timer/Intervals
            var queryIntervalLiveData = null;

            /**
             * @description function to cancel all running intervals
             */
            var cancelIntervals = function () {
                httpCanceller.resolve();
                stopLiveDataQuery();
            };

            // needed for testing otherwise useless
            ctrl.startLiveDataQuery = function () {
                startLiveDataQuery();
            };
            // needed for testing otherwise useless
            ctrl.stopLiveDataQuery = function () {
                stopLiveDataQuery();
            };

            /**
             * @description function to start live data query interval
             */
            var startLiveDataQuery = function () {
                if (queryIntervalLiveData === null) queryIntervalLiveData = $interval(queryLiveData, 5000);
            };

            /**
             * @description function to stop live data query
             */
            var stopLiveDataQuery = function () {
                if (queryIntervalLiveData) {
                    $interval.cancel(queryIntervalLiveData);
                    queryIntervalLiveData = null;
                    roomLifeDataQueryBusy = false;
                }
            };

            /**
             * @description function to handle update for driver value values (live data query), set sensor color etc.
             */
            var handleLiveDataUpdateRoom = function () {
                RoomService.colorizeRoomTemp(ctrl.room, obj3d.room, tempHeatMap);
                if (ctrl.gl.heatmapActive) HeatMapGLService.updateValues(ctrl.room, ctrl.gl.selectedHeatmap);
                TrackingDivLabelFactory.updateLabelValues();
            };

            /**
             * @description function to query live data for current room
             */
            var queryLiveData = function () {
                if (ctrl.viewObject instanceof Room && !roomLifeDataQueryBusy) {
                    roomLifeDataQueryBusy = true;
                    LiveDataService.getLiveDataForRoom(ctrl.room, httpCanceller).then(function () {
                        handleLiveDataUpdateRoom();
                        roomLifeDataQueryBusy = false;
                    });
                }
                if (ctrl.viewObject instanceof Rack) {
                    roomLifeDataQueryBusy = true;
                    LiveDataService.getLiveDataForRoom(ctrl.room, httpCanceller).then(function () {
                        roomLifeDataQueryBusy = false;
                    });
                    LiveDataService.getLiveDataForEntity(ctrl.room, ctrl.rack);
                }
            };

            //endregion
            //region update service
            var ignoreUpdate = false;

            /**
             * @description function to handle detected changes for current room
             */
            var handleNewData = function () {
                RoomService.getRoom($stateParams.id, $stateParams.roomid).then(function (response) {
                    if (ignoreUpdate) return;
                    var room = Room.parseFromHtmlObject(response.data);

                    if (!ctrl.config.edit) {
                        if (ctrl.viewObject.isRoom) {
                            ctrl.room = room;
                            WebGLService.remove3DObjectFromContent(obj3d.room);
                            obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                            handleRoomRotation();
                            handleUUIDInPath();
                        }
                        if (ctrl.viewObject.isRack) {
                            ctrl.room = room;
                            ctrl.rack = room.findObjectByUniqueID(ctrl.viewObject.uniqueId);
                            if (isCam3D()) {
                                WebGLService.remove3DObjectFromContent(obj3d.rack);
                                obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                                Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                                WebGLService.add3DObjectToContent(obj3d.rack);
                            } else {
                                WebGLService.cleanContent();
                                obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                                Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                                WebGLService.add3DObjectToContent(obj3d.rack);
                                var addObj = Object3DFactory.buildRack2DView(obj3d.rack);
                                WebGLService.add3DObjectToContent(addObj[0]);
                            }
                        }
                    } else {
                        if (ctrl.viewObject.isRoom && !ctrl.evalRoomChanged()) {
                            if (ctrl.config.editState == 1) {
                                ctrl.room = room;
                                initRoom = angular.copy(room);
                                WebGLService.remove3DObjectFromContent(obj3d.room);
                                obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                                handleRoomRotation();
                                handleUUIDInPath();
                            }
                        }
                        if (ctrl.viewObject.isRack && !ctrl.evalRoomChanged()) {
                            ctrl.room = room;
                            ctrl.rack = room.findObjectByUniqueID(ctrl.viewObject.uniqueId);
                            initRoom = angular.copy(room);
                            if (isCam3D()) {
                                WebGLService.remove3DObjectFromContent(obj3d.rack);
                                obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                                Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                                WebGLService.add3DObjectToContent(obj3d.rack);
                            } else {
                                WebGLService.cleanContent();
                                obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                                Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                                WebGLService.add3DObjectToContent(obj3d.rack);
                                var addObj = Object3DFactory.buildRack2DView(obj3d.rack);
                                WebGLService.add3DObjectToContent(addObj[0]);
                            }
                        }
                    }
                }, function (error) {
                    $state.go("notfound");
                    $log.error("Error retrieving new room data\m" + error);
                });
            };
            //endregion
            //region Partlib
            /**
             * @description function to be called after click on copy button in entity detail
             */
            ctrl.copyEntity = function () {
                clipboardDeepCopy = false;
                ClipboardService.putItem(ctrl.object);
                Notify.success("global.notification.success.title", "room.notifications.objCopy", 2000);
            };

            ctrl.deepcopyEntity = function () {
                clipboardDeepCopy = true;
                ClipboardService.putItem(ctrl.object);
                Notify.success("global.notification.success.title", "room.notifications.objCopy", 2000);
            }

            /**
             * @description function to determine if clipboard is filled
             * @returns {boolean}
             */
            ctrl.clipboardFilled = function () {
                return ClipboardService.hasItem();
            };

            /**
             * @description helper-function to handle changes in partlib manufacturer/model input
             * @param {boolean} custom true for custom partlib objects, otherwise false
             * @param {string} infoObject string describing partlib sub element (rack, asset etc.)
             */
            var helperFuncPartlib = function (custom, infoObject) {
                var infoName = infoObject == "ups" ? infoObject + "Info" : infoObject + "sInfo";
                if (custom) infoName += "Custom";
                var partlibObjectName = custom ? "c" + infoObject : infoObject;
                if (ctrl.config.partlib.items[infoName][ctrl.config.partlib.select[partlibObjectName].manufacturer].length == 1) {
                    ctrl.config.partlib.select[partlibObjectName].model =
                        ctrl.config.partlib.items[infoName][ctrl.config.partlib.select[partlibObjectName].manufacturer][0];
                    var tmp = ctrl.config.partlib.items[infoObject].filter(function (item) {
                        return item.manufacturer == ctrl.config.partlib.select[partlibObjectName].manufacturer &&
                            item.model == ctrl.config.partlib.select[partlibObjectName].model && item.customEntity == custom;
                    });
                    if (tmp.length == 1) {
                        ctrl.config.partlib.select[partlibObjectName].id = tmp[0].id;
                        ctrl.partlibObjectChanged(partlibObjectName, custom);
                    }
                }
            };

            /**
             * @description change handler for changes of manufactureer select in partlib UI
             * @param {string} itemType the item type for which the change occured
             */
            ctrl.handleManuChange = function (itemType) {
                switch (itemType) {
                    case "stdRack":
                        helperFuncPartlib(false, "rack");
                        break;
                    case "cRack":
                        helperFuncPartlib(true, "rack");
                        break;
                    case "stdCooling":
                        helperFuncPartlib(false, "cooling");
                        break;
                    case "cCooling":
                        helperFuncPartlib(true, "cooling");
                        break;
                    case "stdTile":
                        helperFuncPartlib(false, "floortile");
                        break;
                    case "cTile":
                        helperFuncPartlib(true, "floortile");
                        break;
                    case "stdSensor":
                        helperFuncPartlib(false, "sensor");
                        break;
                    case "stdUps":
                        helperFuncPartlib(false, "ups");
                        break;
                    case "cUps":
                        helperFuncPartlib(true, "ups");
                        break;
                    case "stdAsset":
                        helperFuncPartlib(false, "asset");
                        break;
                    case "cAsset":
                        helperFuncPartlib(true, "asset");
                        break;
                    case "stdSlot":
                        helperFuncPartlib(false, "slot");
                        break;
                    case "cSlot":
                        helperFuncPartlib(true, "slot");
                        break;
                }
            };

            /**
             * @description change handler for model select UI
             * @param {string} itemType string determining which sub element of partlib UI was used
             */
            ctrl.handleModelChange = function (itemType) {
                switch (itemType) {
                    case "stdRack":
                        helperFuncPartlib(false, "rack");
                        break;
                    case "cRack":
                        helperFuncPartlib(true, "rack");
                        break;
                    case "stdCooling":
                        helperFuncPartlib(false, "cooling");
                        break;
                    case "cCooling":
                        helperFuncPartlib(true, "cooling");
                        break;
                    case "stdTile":
                        helperFuncPartlib(false, "floortile");
                        break;
                    case "cTile":
                        helperFuncPartlib(true, "floortile");
                        break;
                    case "stdSensor":
                        helperFuncPartlib(false, "sensor");
                        break;
                    case "stdUps":
                        helperFuncPartlib(false, "ups");
                        break;
                    case "cUps":
                        helperFuncPartlib(true, "ups");
                        break;
                    case "stdAsset":
                        helperFuncPartlib(false, "asset");
                        break;
                    case "cAsset":
                        helperFuncPartlib(true, "asset");
                        break;
                    case "stdSlot":
                        helperFuncPartlib(false, "slot");
                        break;
                    case "cSlot":
                        helperFuncPartlib(true, "slot");
                        break;
                }
            };

            /**
             * @description function to filter stulz cooling units depending on inputs in UI
             * @returns {Function} returns filter function
             */
            ctrl.filterStulzUnits = function () {
                return function (item) {
                    if (item.manufacturer != "STULZ") return false;
                    if (item.model != ctrl.config.partlib.select.cooling.model) return false;

                    if (ctrl.config.partlib.select.cooling.size && item.stulzInfo.size != ctrl.config.partlib.select.cooling.size) return false;
                    if (ctrl.config.partlib.select.cooling.csystem && ctrl.config.partlib.select.cooling.csystem != item.stulzInfo.cSystem) return false;
                    if (ctrl.config.partlib.select.cooling.circuits && ctrl.config.partlib.select.cooling.circuits != item.stulzInfo.circuits) return false;
                    if (ctrl.config.partlib.select.cooling.version && ctrl.config.partlib.select.cooling.version != item.stulzInfo.version) return false;
                    if (ctrl.config.partlib.select.cooling.airDirection && ctrl.config.partlib.select.cooling.airDirection != item.stulzInfo.airDirection) return false;
                    if (item.customEntity) return false;
                    return true;
                }
            };

            /**
             * @description function to filter racks depending on inputs in UI
             * @param {boolean} custom true for custom partlibrary items, otherwise false
             * @returns {Function} returns filter function
             */
            ctrl.filterRacks = function (custom) {
                return function (item) {
                    if (item.customEntity != custom) return false;
                    if (custom) {
                        if (ctrl.config.partlib.select.crack.manufacturer && ctrl.config.partlib.select.crack.manufacturer != item.manufacturer) return false;
                        if (ctrl.config.partlib.select.crack.model && ctrl.config.partlib.select.crack.model != item.model) return false;
                    } else {
                        if (ctrl.config.partlib.select.rack.manufacturer && ctrl.config.partlib.select.rack.manufacturer != item.manufacturer) return false;
                        if (ctrl.config.partlib.select.rack.model && ctrl.config.partlib.select.rack.model != item.model) return false;
                    }
                    return true;
                }
            };

            /**
             * @description change handler for part library object select UI
             * @param {string} type the object type (rack, cooling, asset etc.)
             * @param {boolean} custom true for custom part library items, otherwise false
             */
            ctrl.partlibObjectChanged = function (type, custom) {
                switch (type) {
                    case 'rack':
                        ctrl.config.partlib.select.rack.obj = ctrl.config.partlib.items.rack.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.rack.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'cooling':
                        ctrl.config.partlib.select.cooling.obj = ctrl.config.partlib.items.cooling.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.cooling.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'sensor':
                        ctrl.config.partlib.select.sensor.obj = ctrl.config.partlib.items.sensor.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.sensor.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'slot':
                        ctrl.config.partlib.select.slot.obj = ctrl.config.partlib.items.slot.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.slot.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'ups':
                        ctrl.config.partlib.select.ups.obj = ctrl.config.partlib.items.ups.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.ups.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'floortile':
                        ctrl.config.partlib.select.floortile.obj = ctrl.config.partlib.items.floortile.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.floortile.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'asset':
                        ctrl.config.partlib.select.asset.obj = ctrl.config.partlib.items.asset.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.asset.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'casset':
                        ctrl.config.partlib.select.casset.obj = ctrl.config.partlib.items.asset.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.casset.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'cfloortile':
                        ctrl.config.partlib.select.cfloortile.obj = ctrl.config.partlib.items.floortile.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.cfloortile.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'cups':
                        ctrl.config.partlib.select.cups.obj = ctrl.config.partlib.items.ups.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.cups.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'cslot':
                        ctrl.config.partlib.select.cslot.obj = ctrl.config.partlib.items.slot.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.cslot.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'ccooling':
                        ctrl.config.partlib.select.ccooling.obj = ctrl.config.partlib.items.cooling.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.ccooling.id && item.customEntity == custom
                        })[0];
                        break;
                    case 'crack':
                        ctrl.config.partlib.select.crack.obj = ctrl.config.partlib.items.rack.filter(function (item) {
                            return item.id == ctrl.config.partlib.select.crack.id && item.customEntity == custom
                        })[0];
                        break;
                }
            };

            /**
             * @description function to determine if provided items is present in quickbar
             * @param {object} item the item to check
             * @returns {boolean} returns true if provided item is already in quickbar, otherwise false
             */
            var checkItemInQB = function (item) {
                return ctrl.config.partlib.qbItems.filter(function (elem) {
                    return elem.uid == item.uniqueId;
                }).length > 0;
            };

            /**
             * @description function to remove provided item from quickbar
             * @param {object} item the object to remove from quickbar
             */
            var removeItemFromQB = function (item) {
                var idx = -1;
                for (var i = 0; i < ctrl.config.partlib.qbItems.length; i++) {
                    if (item.uniqueId == ctrl.config.partlib.qbItems[i].uid) {
                        idx = i;
                        break;
                    }
                }
                ctrl.config.partlib.qbItems.splice(idx, 1);
            };

            /**
             * @description function to add provided item to quickbar
             * @param {object} item the item to add to the quickbar
             * @param {string} type the item type as string
             * @param {string} icon the icon name as string
             */
            var pushItemIntoQB = function (item, type, icon) {
                ctrl.config.partlib.qbItems.push({type: type, uid: item.uniqueId, id: item.id, icon: icon});
                saveQuickbarState();
            };

            /**
             * @description change handler for part library option to place selected part library item in quickbar
             * @param {string} type the name of part library sub element
             */
            ctrl.toggleItemInQB = function (type) {
                var obj = ctrl.config.partlib.select[type].obj;
                var icon = "";
                switch (type) {
                    case "rack":
                        icon = type;
                        break;
                    case "crack":
                        icon = "rack";
                        break;
                    case "cooling":
                        icon = "cooling";
                        break;
                    case "ccooling":
                        icon = "cooling";
                        break;
                    case "sensor":
                        icon = type;
                        break;
                    case "csensor" :
                        icon = "sensor";
                        break;
                    case "slot" :
                        icon = "slot";
                        break;
                    case "cslot":
                        icon = "slot";
                        break;
                    case "asset" :
                        icon = type;
                        break;
                    case "casset":
                        icon = "asset";
                        break;
                    case "cups":
                        icon = "battery";
                        break;
                    case "ups":
                        icon = "battery";
                        break;
                    case "floortile":
                        icon = "floor-vent";
                        break;
                    case "cfloortile":
                        icon = "floor-vent";
                        break;
                }
                if (type.startsWith("c") && type != "cooling") {
                    type = type.substring(1, type.length);
                }
                ctrl.config.partlib.isDefault = true;
                if (checkItemInQB(obj)) {
                    removeItemFromQB(obj);
                } else {
                    pushItemIntoQB(obj, type, icon);
                }
            };

            ctrl.setSingleBlockRack = function (b) {
                ctrl.config.partlib.select.rack.single = b;
                helperFuncPartlib(false, "rack");
                if (ctrl.config.partlib.select.rack.single) {
                    ctrl.config.partlib.select.rack.racknumber = null;
                    ctrl.config.partlib.select.rack.dragRackBlock = true;
                    setRackDraggable(true, '');
                } else {
                    ctrl.config.partlib.select.rack.racknumber = 2;
                    ctrl.checkBlockRackInsideRoom();
                }
            };

            var setRackDraggable = function (isDraggable, title) {
                if (Tools.isDefinedNotNull(document.getElementById("rackDrag"))) {
                    document.getElementById("rackDrag").setAttribute("draggable", isDraggable);
                    document.getElementById("rackDrag").setAttribute("title", title);
                }
            };

            ctrl.checkBlockRackInsideRoom = function () {
                var RackBlockWidth = (ctrl.config.partlib.select.rack.obj.width * ctrl.config.partlib.select.rack.racknumber) + 0.03 * (ctrl.config.partlib.select.rack.racknumber - 1);
                var RoomWidth = Math.abs(ctrl.room.bbox.min.x - ctrl.room.bbox.max.x);
                if (Math.abs(RoomWidth) - Math.abs(RackBlockWidth) > 0) {
                    ctrl.config.partlib.select.rack.dragRackBlock = true;
                    setRackDraggable(true, '');
                } else {
                    ctrl.config.partlib.select.rack.dragRackBlock = false;
                    setRackDraggable(false, $translate.instant('partlib.rack.limitExcTooltip'));
                }
            };

            /**
             * @description function to empty quickbar items
             */
            ctrl.clearQB = function () { //by DL, if anything goes haywire
                for (var i = 0; i < ctrl.config.partlib.qbItems.length; i++) {
                    var item = ctrl.config.partlib.items[ctrl.config.partlib.qbItems[i].type].filter(function (elem) {
                        return elem.uniqueId === ctrl.config.partlib.qbItems[i].uid;
                    });
                    if (item.length) item[0].inQB = false;
                }
                ctrl.config.partlib.qbItems = [];
                saveQuickbarState();
            };

            var saveQuickbarState = function () {
                if (ctrl.config.partlib.isDefault) {
                    var qbItems;
                    if (Tools.isDefinedNotNull(quickbarSettings)) {
                        qbItems = angular.copy(ctrl.config.partlib.qbItems);
                        quickbarSettings.settings = JSON.stringify(qbItems);
                    } else {
                        qbItems = angular.copy(ctrl.config.partlib.qbItems);
                        quickbarSettings = new QuickbarSettings(null, user.id, JSON.stringify(qbItems));
                    }
                    QuickBarSettingsService.save(quickbarSettings).then(function (response) {
                        quickbarSettings = response;
                    }, function (error) {
                        console.log(error);
                    });
                }
            };

            /**
             * @description function to activate mass inserting mode
             */
            ctrl.activateMassInsert = function () {
                ctrl.config.partlib.insertType = 0;
            };

            /**
             * @description function to activate single insert mode (after successful insert detail view will be displayed)
             */
            ctrl.activateSingleInsert = function () {
                ctrl.config.partlib.insertType = 1;
            };

            /**
             * @description function to setup pre-selected items in part library UI
             */
            var setupPreSelection = function () {
                if (ctrl.config.partlib.items.assetsInfo.manufacturer.length == 1) {
                    ctrl.config.partlib.select.asset.manufacturer = ctrl.config.partlib.items.assetsInfo.manufacturer[0];
                    if (ctrl.config.partlib.items.assetsInfo[ctrl.config.partlib.select.asset.manufacturer].length == 1) ctrl.config.partlib.select.asset.model = ctrl.config.partlib.items.assetsInfo[ctrl.config.partlib.select.asset.manufacturer][0];
                    helperFuncPartlib(false, "asset");
                }
                if (ctrl.config.partlib.items.assetsInfoCustom.manufacturer.length == 1) {
                    ctrl.config.partlib.select.casset.manufacturer = ctrl.config.partlib.items.assetsInfoCustom.manufacturer[0];
                    helperFuncPartlib(true, "asset");
                }
                if (ctrl.config.partlib.items.sensorsInfo.manufacturer.length == 1) {
                    ctrl.config.partlib.select.sensor.manufacturer = ctrl.config.partlib.items.sensorsInfo.manufacturer[0];
                    if (ctrl.config.partlib.items.sensorsInfo[ctrl.config.partlib.select.sensor.manufacturer].length == 1) ctrl.config.partlib.select.sensor.model = ctrl.config.partlib.items.sensorsInfo[ctrl.config.partlib.select.sensor.manufacturer][0];
                    ctrl.config.partlib.select.sensor.id = ctrl.config.partlib.items.sensor.filter(function (item) {
                        return item.manufacturer == ctrl.config.partlib.select.sensor.manufacturer && item.model == ctrl.config.partlib.select.sensor.model;
                    })[0].id;
                    ctrl.partlibObjectChanged("sensor", false);
                }
                if (ctrl.config.partlib.items.floortilesInfo.manufacturer.length == 1) {
                    ctrl.config.partlib.select.floortile.manufacturer = ctrl.config.partlib.items.floortilesInfo.manufacturer[0];
                    helperFuncPartlib(false, "floortile");
                }
                if (ctrl.config.partlib.items.floortilesInfoCustom.manufacturer.length == 1) {
                    ctrl.config.partlib.select.cfloortile.manufacturer = ctrl.config.partlib.items.floortilesInfoCustom.manufacturer[0];
                    helperFuncPartlib(true, "floortile");
                }
                if (ctrl.config.partlib.items.upsInfo.manufacturer.length == 1) {
                    ctrl.config.partlib.select.ups.manufacturer = ctrl.config.partlib.items.upsInfo.manufacturer[0];
                    helperFuncPartlib(false, "ups");
                }
                if (ctrl.config.partlib.items.upsInfoCustom.manufacturer.length == 1) {
                    ctrl.config.partlib.select.cups.manufacturer = ctrl.config.partlib.items.upsInfoCustom.manufacturer[0];
                    helperFuncPartlib(true, "ups");
                }
                if (ctrl.config.partlib.items.racksInfoCustom.manufacturer.length == 1) {
                    ctrl.config.partlib.select.crack.manufacturer = ctrl.config.partlib.items.racksInfoCustom.manufacturer[0];
                    helperFuncPartlib(true, "rack");
                }
                if (ctrl.config.partlib.items.coolingsInfoCustom.manufacturer.length == 1) {
                    ctrl.config.partlib.select.ccooling.manufacturer = ctrl.config.partlib.items.coolingsInfoCustom.manufacturer[0];
                    helperFuncPartlib(true, "cooling");
                }
            };
            // TODO move to init function
            setupPreSelection();

            var cstate = 0;
            var cmover = null;
            var cobj = null;

            /**
             * @description function to handle mouse down action containment creation tool
             * @param {MouseEvent} e the mouse event to use
             */
            var handleMouseDownContainmentSpacing = function (e) {
                if (cstate === 0) {
                    var i = WebGLService.pickObjectsName(e, WebGLService.getDummyObject(), ['markrectmover']);
                    if (i.length > 0) {
                        cstate = 1;
                        cmover = i[0].object;
                    }
                }
            };

            /**
             * @description function to handle mouse move action for containment creation tool
             * @param {MouseEvent} e the mouse event to use
             */
            var handleMouseMoveContainmentSpacing = function (e) {
                if (cstate === 1) {
                    var pi = WebGLService.getPositionPickplane(e);
                    var p = RoomEditorService.projectOnRay(cmover.userData.op, cmover.userData.dir, pi[0].point);
                    if (!Room.checkPointInPolygon(p, ctrl.room.corner)) return;
                    //TODO check constraints
                    cmover.position.setX(p.x).setZ(p.z);
                    var snapInfo = RoomEditorService.checkSnapMarkRectMover(pi[0].point, ctrl.room, obj3d.room, cmover);
                    if (snapInfo[0]) {
                        p = RoomEditorService.projectOnRay(cmover.userData.op, cmover.userData.dir, new THREE.Vector3(snapInfo[1].x, 0, snapInfo[1].z));
                        cmover.position.setX(p.x).setZ(p.z);
                    }
                    RoomEditorService.handleMarkRectMoverChange(cmover, obj3d.rectmark);
                }
            };

            /**
             * @description function to handle mouse up action for containment creation tool
             * @param {MouseEvent} e the mouse event to use
             */
            var handleMouseUpContainmentSpacing = function (e) {
                if (cstate === 0) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, ['asset', 'cooling', 'rack', 'ups']);
                    if (i.length > 0) {
                        if (obj3d.rectmark) WebGLService.remove3DObjectFromDummyObj(obj3d.rectmark);
                        obj3d.rectmark = Object3DFactory.buildContainmentMarker(i[0].object, ctrl.room);
                        WebGLService.add3DObjectToDummyObj(obj3d.rectmark);
                        cobj = i[0].object;
                        ctrl.config.containment.valid = true;
                    }
                }
                if (cstate === 1) {
                    cstate = 0;
                    cmover = null;
                }
            };

            /**
             * @description function to toggle containment creation tool
             */
            ctrl.toggleSelectContainmentSpacing = function () {
                if (!ctrl.config.containment.selectBySpacing) {
                    ctrl.config.containment.selectBySpacing = true;
                    WebGLService.bindMouseHandler(handleMouseDownContainmentSpacing, handleMouseMoveContainmentSpacing, handleMouseUpContainmentSpacing, undefined);
                    ctrl.gl.show2D();
                    WebGLService.disableControlsRotate();
                    ctrl.gl.allowButton3D = false;
                } else {
                    ctrl.config.containment.selectBySpacing = false;
                    WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                    connectDragDropListener();
                    ctrl.gl.allowButton3D = true;
                    ctrl.gl.show3D();
                    WebGLService.enableControlsRotate();
                    WebGLService.remove3DObjectFromDummyObj(obj3d.rectmark);
                }
            };

            /**
             * @description function to find containment to enclose selected objects (deprecated)
             * @returns {boolean}
             */
            var checkSelectedItemsForContainment = function () {
                return RoomEditorService.findContainmentForObjects() !== false;
            };

            /**
             * @description function to add containment to room, click on button in part library sub-element
             */
            ctrl.addContainment = function () {
                var c, cObj;
                if (ctrl.config.containment.selectBySpacing) {
                    if (Tools.isDefinedNotNull(obj3d.rectmark) && Tools.isDefinedNotNull(cobj)) {
                        c = new Containment(Entity.getNewIdFromArray(ctrl.room.roomObjs),
                            {
                                x: RoomEditorService.getPositionMarkedRect(obj3d.rectmark, "x"),
                                y: cobj.position.y,
                                z: RoomEditorService.getPositionMarkedRect(obj3d.rectmark, "z")
                            },
                            {
                                x: RoomEditorService.getSizeForMarkedRect(obj3d.rectmark, "x"),
                                y: cobj.userData.obb.e.y * 2,
                                z: RoomEditorService.getSizeForMarkedRect(obj3d.rectmark, "z")
                            },
                            {
                                x: 0,
                                y: MathService.radToDeg(obj3d.rectmark.children[0].rotation.z),
                                z: 0
                            }, "dummy", "", Containment.TYPERANGE[0], Entity.getNewLocaleUniqueId(), ctrl.room.id);
                        c.name = $translate.instant("room.edit.containment.newContainment");
                        cObj = Object3DFactory.buildRoomObject(c, getDisplayMode());
                        if (!c.checkCollision(cObj, obj3d.room, cObj.position.clone(), false, ctrl.room)) {
                            ctrl.room.roomObjs.push(c);
                            obj3d.room.add(cObj);
                            WebGLService.remove3DObjectFromDummyObj(obj3d.rectmark);
                            obj3d.rectmark = null;
                            var selectedObj = ctrl.room.findObjectByUniqueID(cObj.userData.uid);
                            selectedObj.genRelativePositions(ctrl.room.bbox.min);
                            WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                        } else {
                            RoomEditorService.showSaveErrorDialog("room.errorList.codes.simple.406");
                        }
                    }
                }
            };

            //endregion
            //region RoomEditor
            // options for floor select drop down
            ctrl.floors = [
                {id: 1, name: $translate.instant('room.edit.floors.floor')},
                {id: 0, name: $translate.instant('room.edit.floors.ground')},
                {id: -1, name: $translate.instant('room.edit.floors.basement')}
            ];

            var allIdentOpts = [
                {id: 0, name: $translate.instant('room.edit.tileNameOpts.none')},
                {id: 1, name: $translate.instant('room.edit.tileNameOpts.numbersNormal')},
                {id: 2, name: $translate.instant('room.edit.tileNameOpts.numbersLeadingZero')},
                {id: 3, name: $translate.instant('room.edit.tileNameOpts.lettersNormal')},
                {id: 4, name: $translate.instant('room.edit.tileNameOpts.lettersFilled')},
                {id: 5, name: $translate.instant('room.edit.tileNameOpts.custom')}
            ];
            // options for possible raised floor row description
            ctrl.xIdentOpts = [
                {id: 0, name: $translate.instant('room.edit.tileNameOpts.none')},
                {id: 1, name: $translate.instant('room.edit.tileNameOpts.numbersNormal')},
                {id: 2, name: $translate.instant('room.edit.tileNameOpts.numbersLeadingZero')},
                {id: 3, name: $translate.instant('room.edit.tileNameOpts.lettersNormal')},
                {id: 4, name: $translate.instant('room.edit.tileNameOpts.lettersFilled')},
                {id: 5, name: $translate.instant('room.edit.tileNameOpts.custom')}
            ];
            // options for possible raised floor col description
            ctrl.zIdentOpts = [
                {id: 0, name: $translate.instant('room.edit.tileNameOpts.none')},
                {id: 1, name: $translate.instant('room.edit.tileNameOpts.numbersNormal')},
                {id: 2, name: $translate.instant('room.edit.tileNameOpts.numbersLeadingZero')},
                {id: 3, name: $translate.instant('room.edit.tileNameOpts.lettersNormal')},
                {id: 4, name: $translate.instant('room.edit.tileNameOpts.lettersFilled')},
                {id: 5, name: $translate.instant('room.edit.tileNameOpts.custom')}
            ];
            var innerWallPosi = [
                {id: 1, name: $translate.instant('room.edit.innerwall.all')},
                {id: 2, name: $translate.instant('room.edit.innerwall.floor')},
                {id: 3, name: $translate.instant('room.edit.innerwall.main')},
                // {id:4, name:'room.edit.innerwall.ceil'}
            ];

            // options for possible inner wall position types
            ctrl.innerTypes = [
                {id: 0, name: $translate.instant('room.edit.innerwall.solid')},
                {id: 1, name: $translate.instant('room.edit.innerwall.cage')}
            ];
            ctrl.innerWallPositions = [];

            // settings for editor tools
            ctrl.tools = {
                rect: {
                    w: 0,
                    d: 0
                },
                wall: {
                    l: 0
                },
                innerwall: {
                    l: 0,
                    h: 1.0,
                    d: 0.05,
                    pos: -1,
                    type: 0,
                    maxHeight: 4,
                    posDef: 1
                },
                pillar: {
                    w: 0.5,
                    d: 0.5,
                    rot: 0
                },
                window: {
                    w: 1,
                    h: 1
                },
                door: {
                    w: 1,
                    h: 2
                },
                raisedFloor: {
                    w: 0.6,
                    h: 0.5,
                    d: 0.6,
                    ox: 0,
                    oz: 0,
                    gridXIdentType: 0,
                    gridZIdentType: 0,
                    expX: "",
                    expZ: "",
                    gridXIdentLowerCase: false,
                    gridZIdentLowerCase: false,
                    nameInfo: [[], []]
                },
                loweredCeiling: {
                    w: 0,
                    h: 0,
                    d: 0,
                    ox: 0,
                    oz: 0
                },
                layout: {
                    file: '',
                    img: null,
                    imgSelected: false,
                    scaled: false,
                    placed: false,
                    scale: 0,
                    measureScale: 0
                }
            };

            /**
             * @description function to create empty editor for new room
             */
            var setupEmptyEditor = function () {
                obj3d.grid = Object3DFactory.buildGrid(ctrl.config.tools.gridSize / 2, ctrl.config.tools.cellSize, parseInt(ctrl.config.tools.gridColor.substring(1), 16), false);
                obj3d.editorMarker = Object3DFactory.buildEditorMarker();
                var axes = Object3DFactory.getAxes(2, new THREE.Vector3());
                WebGLService.cleanContent();
                WebGLService.cleanDummy();
                WebGLService.add3DObjectToContent(obj3d.grid);
                WebGLService.add3DObjectToContent(obj3d.editorMarker);
                WebGLService.add3DObjectToDummyObj(axes);//TODO REMOVE ONLY DEBUG
                WebGLService.bindMouseHandler(undefined, stdMouseMoveHandler, undefined);
                //update
                updateInnerWallOptions();
            };

            /**
             * @description change handler for modification of storey input
             */
            ctrl.handleStoreyChange = function () {
                if (ctrl.room.floor === undefined || ctrl.room.floor === null) {
                    ctrl.room.floor = 0;
                }
                if (ctrl.room.floor < 0) ctrl.room.floor = Math.abs(ctrl.room.floor);
            };

            /**
             * @description change handler for storey type changes
             */
            ctrl.handleStoreyTypeChange = function () {
                if (ctrl.room.floorType === 0) ctrl.room.floor = 0;
            };

            /**
             * @description function to modify inner wall position options according to current room state
             */
            var updateInnerWallOptions = function () {
                ctrl.innerWallPositions = [];
                ctrl.innerWallPositions.push(innerWallPosi[0]);
                if (ctrl.room.hasRaisedFloor) {
                    ctrl.innerWallPositions.push(innerWallPosi[2]);
                }
                if (ctrl.innerWallPositions.length === 1) {
                    ctrl.tools.innerwall.posDef = 1;
                } else {
                    ctrl.tools.innerwall.posDef = 1;
                }
            };

            /**
             * @description function to hide grid
             */
            var hideGrid = function () {
                obj3d.grid.visible = false;
            };

            /**
             * @description function to show grid
             */
            var showGrid = function () {
                obj3d.grid.visible = true;
            };

            var newWindowFull = function (wallObj, window) {
                wallObjects = [];
                var dummy = Object3DFactory.buildWall(wallObj.userData.s, wallObj.userData.e, wallObj.userData.h, wallObj.userData.d, 'full', true, "dummy");
                obj3d.dummyObj = dummy[0];
                WebGLService.add3DObjectToDummyObj(dummy[0]);
                WebGLService.add3DObjectToLineObj(dummy[1]);
                doorwindowWall = wallObj;
                var windowPos = new THREE.Vector3(window.pos.x, window.pos.y, window.pos.z);
                var rotation = MathService.radToDeg(obj3d.dummyObj.rotation.y);
                var w = new Window(window.id
                    , windowPos
                    , {x: window.size.x, y: window.size.y, z: 0.01}
                    , {x: 0, y: rotation * -1, z: 0}
                    , ""
                    , ""
                    , 1000
                    , window.uniqueId, ctrl.room.id, true);
                ctrl.room.roomObjs.push(w);
                var newWindow = Object3DFactory.buildRoomObject(w, 'full');
                WebGLService.markObject(newWindow);
                wallObjects.push(newWindow);
                obj3d.room.add(newWindow);
                RoomEditorService.removeDummyCurrentObjs(obj3d.dummys, true, true);
                WebGLService.cleanDummy();
                WebGLService.cleanLineObj();
            };

            var updateWindows = function () {
                if (Tools.isDefinedNotNull(ctrl.room)
                    && Tools.isDefinedNotNull(ctrl.room.outerWalls)
                    && ctrl.room.outerWalls.length > 2) {
                    for (var index = 0; index < ctrl.room.outerWalls.length; index++) {
                        var wall = ctrl.room.outerWalls[index];
                        // Checks all windows for the wall. Doors are working
                        var list = ctrl.room.getWindowsForWall(wall);
                        if (list.length > 0) {
                            for (var i = 0; i < list.length; i++) {
                                var object3DToDelete = WebGLService.findObjectByUniqueId(list[i].uniqueId, obj3d.room);
                                var idx = RoomEditorService.getIndexOfRoomObj(ctrl.room, object3DToDelete);
                                if (Tools.isDefinedNotNull(idx)) {
                                    ctrl.room.roomObjs.splice(idx, 1);
                                    obj3d.room.remove(object3DToDelete);
                                }
                                var wall3d = RoomEditorService.get3DOuterWall(obj3d.room, wall.start, wall.end);
                                newWindowFull(wall3d, list[i]);
                            }
                        } else {
                            // Do nothin'. There are no objects on the wall
                        }
                    }
                    WebGLService.remove3DObjectFromContent(obj3d.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                }
            };

            /**
             * @description function to handle deactivation for room editor tools
             */
            var deactivateEditTool = function () {
                switch (ctrl.config.tools.active) {
                    case 1:
                        break;
                    case 2:
                        checkRoomWallsForCompleteRoomAndBuildIfPossible();
                        RoomEditorService.removeDummyCurrentObjs(obj3d.dummys, true, true);
                        updateWindows();
                        RoomEditorService.rebuildObjectsOnWall(ctrl.room, obj3d.room);
                        if (ctrl.config.subtools.pointMode) ctrl.togglePointMode();
                        if (ctrl.config.subtools.snapActive) ctrl.toggleSnap();
                        break;
                    case 3:
                        WebGLService.cleanDummy();
                        WebGLService.cleanLineObj();
                        WebGLService.cleanHoverObj();
                        if (obj3d.room !== null) WebGLService.remove3DObjectFromContent(obj3d.room);
                        checkRoomWallsForCompleteRoomAndBuildIfPossible();
                        if (ctrl.config.subtools.pointMode) ctrl.togglePointMode();
                        if (ctrl.config.subtools.snapActive) ctrl.toggleSnap();
                        break;
                    case 4:
                    case 5:
                        GLMeasureService.clearMeasure();
                        WebGLService.unmarkObject(WebGLService.getMarkedObject());
                        WebGLService.cleanDummy();
                        WebGLService.cleanLineObj();
                        WebGLService.cleanHoverObj();
                        if (obj3d.room !== null) WebGLService.remove3DObjectFromContent(obj3d.room);
                        obj3d.room = Object3DFactory.buildRoom(ctrl.room, 'full', true);
                        obj3d.room.position.add(new THREE.Vector3(0, -0.001, 0));
                        WebGLService.add3DObjectToContent(obj3d.room, ctrl.room.bbox);
                        ctrl.config.tools.substate = 0;
                        break;
                    case 6:
                        GLMeasureService.clearMeasure();
                        WebGLService.unmarkObject(WebGLService.getMarkedObject());
                        delete ctrl.room.pts2d;
                        ctrl.config.modObject = null;
                        obj3d.selectedObj = null;
                        break;
                    case 7:
                        WebGLService.unmarkObject(WebGLService.findObjectByName("raisedFloor", obj3d.room), true);
                        showGrid();
                        WebGLService.cleanDummy();
                        if (ctrl.room.usesRaisedFloorIdents) {
                            if (ctrl.room.floorPanel === null) {
                                ctrl.room.floorPanel = new FloorPanel(null, null, ctrl.room.id, ctrl.tools.raisedFloor.nameInfo[0], ctrl.tools.raisedFloor.nameInfo[1], ctrl.tools.raisedFloor.gridXIdentType, ctrl.tools.raisedFloor.gridZIdentType, ctrl.tools.raisedFloor.gridXIdentLowerCase, ctrl.tools.raisedFloor.gridZIdentLowerCase);
                            } else {
                                ctrl.room.floorPanel.xNames = ctrl.tools.raisedFloor.nameInfo[0];
                                ctrl.room.floorPanel.yNames = ctrl.tools.raisedFloor.nameInfo[1];
                                ctrl.room.floorPanel.xType = ctrl.tools.raisedFloor.gridXIdentType;
                                ctrl.room.floorPanel.yType = ctrl.tools.raisedFloor.gridZIdentType;
                                ctrl.room.floorPanel.xLower = ctrl.tools.raisedFloor.gridXIdentLowerCase;
                                ctrl.room.floorPanel.yLower = ctrl.tools.raisedFloor.gridZIdentLowerCase;
                            }
                        } else if (ctrl.room.floorPanel !== null) ctrl.room.floorPanel = null;
                        obj3d.editorMarker.visible = true;
                        break;
                    case 9:
                        if (obj3d.layoutObj !== null) obj3d.layoutObj.children[1].visible = false;
                        ctrl.config.tools.mouseDownPos = null;
                        if (!ctrl.tools.layout.imgSelected && obj3d.layoutObj !== null) {
                            obj3d.layoutObj.parent.remove(obj3d.layoutObj);
                            obj3d.layoutObj = null;
                        }
                        break;
                    case 0:
                        break;
                }
                WebGLService.unmarkAllObjects();
                RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                ctrl.gl.allowButton3D = true;
                ctrl.gl.allowButton2D = true;
                ctrl.modObject = null;
            };

            /**
             * @description function to activate room editor tool
             */
            var activateEditTool = function () {
                var i;
                switch (ctrl.config.tools.active) {
                    case 1:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(rectRoomMouseDownHandler, rectRoomMouseMoveHandler, rectRoomMouseUpHandler);
                        break;
                    case 2:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(outerWallMouseDownHandler, outerWallMouseMoveHandler, outerWallMouseUpHandler);
                        if (ctrl.room.outerWalls.length) {
                            WebGLService.cleanDummyLineHover();
                            for (i = 0; i < ctrl.room.outerWalls.length; i++) {
                                var wall = ctrl.room.outerWalls[i];
                                var wallObjs = Object3DFactory.buildWall(wall.start, wall.end, wall.height, wall.thickness, 'flat', true);
                                RoomEditorService.addFlatWallToScene(wallObjs);
                            }
                            if (ctrl.room.checkOuterWallsClosed()) RoomEditorService.buildDummyFloor(ctrl.room);
                            RoomEditorService.buildDummyCurrentObjs(ctrl.room, true, true, obj3d.dummys);
                            if (obj3d.room !== null) WebGLService.remove3DObjectFromContent(obj3d.room);
                        }
                        break;
                    case 3:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(innerWallMouseDownHandler, innerWallMouseMoveHandler, innerWallMouseUpHandler, innerWallMouseDblClickHandler);
                        WebGLService.cleanObject(obj3d.room, "innerwall");
                        WebGLService.cleanObject(obj3d.room, "door");
                        WebGLService.cleanObject(obj3d.room, "window_obj");
                        if (obj3d.dummyObj) obj3d.dummyObj = null;
                        //TODO refactor this
                        ctrl.room.pts2d = ctrl.room.getCornersFromOuterWalls(2);
                        for (i = 0; i < ctrl.room.innerWalls.length; i++) {
                            var iwo = Object3DFactory.buildWall(ctrl.room.innerWalls[i].start, ctrl.room.innerWalls[i].end, ctrl.room.innerWalls[i].height, ctrl.room.innerWalls[i].thickness, "flat", true);
                            if (ctrl.room.innerWalls[i].type) iwo[1].material.color.setHex(Object3DFactory.colors.flatWall.outlineCage);
                            RoomEditorService.addFlatWallToScene(iwo);
                        }
                        break;
                    case 4:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show3D();
                        ctrl.gl.allowButton2D = false;
                        WebGLService.bindMouseHandler(doorMouseDownHandler, doorMouseMoveHandler, doorMouseUpHandler);
                        break;
                    case 5:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show3D();
                        ctrl.gl.allowButton2D = false;
                        WebGLService.bindMouseHandler(windowMouseDownHandler, windowMouseMoveHandler, windowMouseUpHandler);
                        break;
                    case 6:
                        ctrl.config.tools.alignCursor = false;
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(pillarMouseDownHandler, pillarMouseMoveHandler, pillarMouseUpHandler);
                        ctrl.room.pts2d = ctrl.room.getCornerPoints(2);
                        break;
                    case 7:
                        ctrl.config.tools.alignCursor = true;
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(raisedFloorMouseDownHandler, raisedFloorMouseMoveHandler, raisedFloorMouseUpHandler);
                        hideGrid();
                        if (ctrl.room.hasRaisedFloor) {
                            var rf = WebGLService.findObjectByName("raisedFloor", obj3d.room);
                            if (rf) {
                                ctrl.tools.raisedFloor.w = rf.userData.w;
                                ctrl.tools.raisedFloor.h = rf.userData.h;
                                ctrl.tools.raisedFloor.d = rf.userData.d;
                                ctrl.tools.raisedFloor.ox = rf.userData.ox;
                                ctrl.tools.raisedFloor.oz = rf.userData.oz;
                                WebGLService.markObject(rf, undefined, true);

                            }
                            for (i = 0; i < ctrl.room.roomObjs.length; i++) {
                                if (ctrl.room.roomObjs[i].type >= 12000 && ctrl.room.roomObjs[i].type < 13000) {
                                    ctrl.config.modObject = ctrl.room.roomObjs[i];
                                    break;
                                }
                            }
                            if (ctrl.room.floorPanel !== null) {
                                ctrl.tools.raisedFloor.backupFloorPanel = angular.merge({}, ctrl.room.floorPanel);
                                ctrl.tools.raisedFloor.gridXIdentType = ctrl.room.floorPanel.xType;
                                ctrl.tools.raisedFloor.gridZIdentType = ctrl.room.floorPanel.yType;
                                ctrl.tools.raisedFloor.gridXIdentLowerCase = ctrl.room.floorPanel.xLower;
                                ctrl.tools.raisedFloor.gridZIdentLowerCase = ctrl.room.floorPanel.yLower;
                                ctrl.tools.raisedFloor.nameInfo[0] = ctrl.room.floorPanel.xNames;
                                ctrl.tools.raisedFloor.nameInfo[1] = ctrl.room.floorPanel.yNames;
                                ctrl.room.usesRaisedFloorIdents = ctrl.room.floorPanel !== null;
                                ctrl.handleToggleTileIdents();
                            }
                        } else {
                            var nrf = new RaisedFloor(Entity.getNewIdFromArray(ctrl.room.roomObjs), {
                                x: ctrl.tools.raisedFloor.ox,
                                y: 0,
                                z: ctrl.tools.raisedFloor.oz
                            }, {
                                x: ctrl.tools.raisedFloor.w,
                                y: ctrl.tools.raisedFloor.h,
                                z: ctrl.tools.raisedFloor.d
                            }, {x: 0, y: 0, z: 0}, "", "", 12000);
                            handleRaisedFloorAdd(nrf, function () {
                                ctrl.config.modObject = new RaisedFloor(Entity.getNewIdFromArray(ctrl.room.roomObjs), {
                                    x: ctrl.tools.raisedFloor.ox,
                                    y: 0,
                                    z: ctrl.tools.raisedFloor.oz
                                }, {
                                    x: ctrl.tools.raisedFloor.w,
                                    y: ctrl.tools.raisedFloor.h,
                                    z: ctrl.tools.raisedFloor.d
                                }, {
                                    x: 0,
                                    y: 0,
                                    z: 0
                                }, "DummyValidationName", "", 12000, Entity.getNewLocaleUniqueId(), ctrl.room.id);
                                var rf = Object3DFactory.buildRaisedFloor(ctrl.config.modObject, "full", ctrl.room);
                                WebGLService.markObject(rf, undefined, true);
                                obj3d.room.add(rf);
                                ctrl.room.roomObjs.push(ctrl.config.modObject);
                                ctrl.room.hasRaisedFloor = true;
                                WebGLService.removeObjectByNameFromObject("floor", obj3d.room);
                                updateInnerWallOptions();
                                ctrl.updateOuterWalls();
                            });
                        }
                        obj3d.editorMarker.visible = false;
                        break;
                    case 9:
                        ctrl.gl.show2D();
                        ctrl.gl.allowButton3D = false;
                        WebGLService.disableControlsRotate();
                        WebGLService.bindMouseHandler(layoutMouseDownHandler, layoutMouseMoveHandler, layoutMouseUpHandler);
                        if (obj3d.layoutObj === null) {
                            obj3d.layoutObj = Object3DFactory.buildLayoutObject(ctrl.config.tools.gridSize, ctrl.config.tools.gridSize);
                            obj3d.layoutObj.position.setY(-0.1);
                            WebGLService.add3DObjectToScene(obj3d.layoutObj);
                            ctrl.config.substate = 0;
                            obj3d.layoutObj.children[1].visible = false;
                        } else {
                            obj3d.layoutObj.children[1].visible = true;
                        }
                        break;
                    case 0:
                        ctrl.config.tools.alignCursor = false;
                        WebGLService.bindMouseHandler(deleteMouseDownHandler, deleteMouseMoveHandler, deleteMouseUpHandler);
                        ctrl.config.substate = 0;
                        break;
                    case -1:
                        ctrl.gl.show3D();
                        ctrl.gl.allowButton3D = true;
                        ctrl.gl.allowButton2D = true;
                        WebGLService.enableControls();
                        WebGLService.enableControlsRotate();
                        WebGLService.bindMouseHandler(undefined, stdMouseMoveHandler, undefined);
                        break;
                }
                Object3DFactory.modMarkerTex(ctrl.config.tools.active, obj3d.editorMarker);
            };

            /**
             * @description function to handle click on room editor tool buttons
             * @param {nuzmber} toolID the room editor tool id to use
             */
            ctrl.toggleTool = function (toolID) {
                if (ctrl.config.tools.active !== -1) {
                    deactivateEditTool();
                }
                if (ctrl.config.tools.active !== toolID) {
                    deactivateEditTool();
                    ctrl.config.tools.active = toolID;
                    activateEditTool();
                } else {
                    ctrl.config.tools.active = -1;
                    activateEditTool();
                }
            };

            /**
             * @description function to toggle alignment to grid (cursor moveing in 'discrete' steps)
             */
            ctrl.toggleAlignToGrid = function () {
                ctrl.config.tools.alignCursor = !ctrl.config.tools.alignCursor;
            };

            /**
             * @description function to toggle snapping
             */
            ctrl.toggleSnap = function () {
                ctrl.config.subtools.snapActive = !ctrl.config.subtools.snapActive;
            };

            //delete mouse handler
            var deleteObj = null;

            /**
             * @description function to check if provided object name is the name of a 'deletable' object
             * @param {string} name the object name to check
             * @returns {boolean} returns true if room editor object is deletable
             */
            var checkDeleteObjectNames = function (name) {
                var nameArray = ["hoverWall", "window", "door", "wall", "pillar", "raisedFloor", "innerwall", "lattice", "outerwall"];
                return nameArray.indexOf(name) !== -1;
            };

            /**
             * @description function to find index of outerwall defined by provided start and end point
             * @param {THREE.Vector3} s the start point of the wall
             * @param {THREE.Vector3} e the end point of the wall
             * @returns {number} returns index of found wall in outer wall array, if no wall is found -1 will be returned
             */
            var getIndexOfWall = function (s, e) {
                for (var i = 0; i < ctrl.room.outerWalls.length; i++) {
                    if (ctrl.room.outerWalls[i].start.equals(s) && ctrl.room.outerWalls[i].end.equals(e)) return i;
                }
                return -1;
            };

            /**
             * @description function to find index of inner wall defined by provided start and end point
             * @param {THREE.Vector3} s the start point of the wall
             * @param {THREE.Vector3} e the end point of the wall
             * @returns {number} returns index of found wall in inner wall array, if no wall is found -1 will be returned
             */
            var getIndexOfInnerWall = function (s, e) {
                for (var i = 0; i < ctrl.room.innerWalls.length; i++) {
                    if (ctrl.room.innerWalls[i].start.equals(s) && ctrl.room.innerWalls[i].end.equals(e)) return i;
                }
                return -1;
            };

            /**
             * @description handler function for mouse down events of delete tool (set delete object)
             * @param {MouseEvent} e the mouse event to use
             */
            var deleteMouseDownHandler = function (e) {
                if (e.originalEvent.button != 0) return;
                var intersectArray = [WebGLService.getHoverObject()];
                if (obj3d.room) intersectArray.push(obj3d.room);
                var i = WebGLService.pickObjects(e, intersectArray);
                if (i.length > 0) {
                    deleteObj = i[0].object;
                }
            };

            /**
             * @description handler function for mouse move events of delete tool (marking)
             * @param {MouseEvent} e the mouse event to use
             */
            var deleteMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e);
                var intersectArray = [WebGLService.getHoverObject()];
                if (obj3d.room) intersectArray.push(obj3d.room);
                var i = WebGLService.pickObjects(e, intersectArray);
                if (i.length > 0) {
                    if (checkDeleteObjectNames(i[0].object.name)) {
                        if (i[0].object.name === "window") {
                            WebGLService.markObject(i[0].object.parent, "delete");
                        } else {
                            WebGLService.markObject(i[0].object, "delete");
                        }
                        if (deleteObj !== null) {
                            if (deleteObj.uuid !== i[0].object.uuid) {
                                deleteObj = i[0].object;
                            }
                        }
                    } else {
                        WebGLService.unmarkObject();
                        deleteObj = null;
                    }
                } else {
                    WebGLService.unmarkObject();
                    deleteObj = null;
                }
            };

            /**
             * @description handler function for mouse up events of delete tool
             * @param {MouseEvent} e the mouse event to use
             */
            var deleteMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                if (deleteObj !== null) {
                    if (deleteObj.name === "hoverWall") {
                        var idx = getIndexOfWall(deleteObj.userData.parent.userData.s, deleteObj.userData.parent.userData.e);
                        if (idx !== -1) {
                            ctrl.room.outerWalls.splice(idx, 1);
                            checkRoomWallsForCompleteRoomAndBuildIfPossible();
                            deleteObj = null;
                            return;
                        }
                    }
                    if (deleteObj.name === "innerwall") {
                        var idx = getIndexOfInnerWall(deleteObj.userData.s, deleteObj.userData.e);
                        if (idx !== -1) {
                            $translate(['global.dialog.head.warning', 'room.edit.dialog.messages.deleteDoorsWindowsToo', 'global.btn.yes', 'global.btn.no']).then(function (trans) {
                                GenDialogService.showDialog(false, {
                                    headText: trans['global.dialog.head.warning'],
                                    headIcon: 'glyphicon glyphicom-warning-sign',
                                    messageText: trans['room.edit.dialog.messages.deleteDoorsWindowsToo'],
                                    showClose: false,
                                    textButton1: trans['global.btn.yes'],
                                    classButton1: 'btn-add',
                                    iconButton1: 'glyphicon glyphicon-ok',
                                    textButton0: trans['global.btn.no'],
                                    classButton0: 'btn-default',
                                    iconButton0: 'glyphicon glyphicon-remove',
                                    callbackButton1: function () {

                                        RoomEditorService.deleteAllWindowsDoorsInnerWalls(ctrl.room, deleteObj);
                                        ctrl.room.innerWalls.splice(idx, 1);
                                        checkRoomWallsForCompleteRoomAndBuildIfPossible();
                                        RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                                        deleteObj = null;
                                        GenDialogService.hideDialog();

                                    },
                                    callbackButton0: function () {
                                        GenDialogService.hideDialog();
                                    }
                                });
                            });
                        }
                    }
                    if (deleteObj.name === "lattice") {
                        var idx = getIndexOfInnerWall(deleteObj.parent.userData.s, deleteObj.parent.userData.e);
                        if (idx !== -1) {
                            ctrl.room.innerWalls.splice(idx, 1);
                            checkRoomWallsForCompleteRoomAndBuildIfPossible();
                            RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                            deleteObj = null;
                            return;
                        }
                    }
                    if (deleteObj.name === "pillar" || deleteObj.name === "door" || deleteObj.name === "window") {
                        var idx = deleteObj.name === "window" ? RoomEditorService.getIndexOfRoomObj(ctrl.room, deleteObj.parent) : RoomEditorService.getIndexOfRoomObj(ctrl.room, deleteObj);
                        if (idx !== undefined) {
                            obj3d.room.remove(deleteObj.name === "window" ? deleteObj.parent : deleteObj);
                            ctrl.room.roomObjs.splice(idx, 1);
                            WebGLService.remove3DObjectFromContent(obj3d.room);
                            obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                            ctrl.config.tools.validRoom = true;
                            RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                        }
                        deleteObj = null;
                        return;
                    }
                    if (deleteObj.name === "raisedFloor") {
                        var idx = RoomEditorService.getIndexOfRoomObj(ctrl.room, deleteObj);
                        if (idx !== undefined) {
                            handleRaisedFloorRemove(ctrl.room.roomObjs[idx], function () {
                                ctrl.room.hasRaisedFloor = false;
                                obj3d.room.remove(deleteObj);
                                ctrl.room.roomObjs.splice(idx, 1);
                                WebGLService.remove3DObjectFromContent(obj3d.room);
                                obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                                RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                                ctrl.config.tools.validRoom = true;
                            });
                        }
                        deleteObj = null;
                        return;
                    }
                    if (deleteObj.name === "outerwall") {
                        var helperRemoveOuterWall = function () {
                            var objectsAffected = ctrl.room.getWindowsForWall(deleteObj);
                            objectsAffected = objectsAffected.concat(ctrl.room.getDoorsForWall(deleteObj));
                            for (var i = 0; i < objectsAffected.length; i++) {
                                var object3d = WebGLService.findObjectByUniqueId(objectsAffected[i].uniqueId, obj3d.room);
                                var idx = RoomEditorService.getIndexOfRoomObj(ctrl.room, object3d);
                                if (Tools.isDefinedNotNull(idx)) {
                                    ctrl.room.roomObjs.splice(idx, 1);
                                    deleteObj.parent.remove(object3d);
                                }
                            }
                            //remove floor
                            if (ctrl.room.hasRaisedFloor) {
                                var raisedFloor = WebGLService.findObjectByUniqueId(ctrl.room.getRaisedFloor().uniqueId, obj3d.room);
                                raisedFloor.visible = false;
                            } else {
                                var floor = WebGLService.findObjectByName("floor", obj3d.room);
                                floor.visible = false;
                            }
                            //show dummy floor
                            if (ctrl.config.tools.validRoom) {
                                RoomEditorService.buildDummyFloor(ctrl.room);
                            }
                            //remove wall
                            deleteObj.parent.remove(deleteObj);
                            //remove outer wall object
                            var idx = getIndexOfWall(deleteObj.userData.s, deleteObj.userData.e);
                            if (Tools.isDefinedNotNull(idx)) {
                                ctrl.room.outerWalls.splice(idx, 1);
                            }
                            ctrl.config.tools.validRoom = false;
                            RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                        };
                        if (ctrl.config.tools.validRoom) {
                            RoomEditorService.showRemoveWallInvalidRoomDialog(function () {
                                WebGLService.unmarkObject();
                            }, function () {
                                helperRemoveOuterWall();
                            });
                        } else {
                            helperRemoveOuterWall();
                        }
                    }
                }
            };

            /**
             * @description function checks room wall validity and draws room in 3d-context
             */
            var checkRoomWallsForCompleteRoomAndBuildIfPossible = function () {
                var i, checkRet = ctrl.room.checkOuterWallsClosed(true);
                if (checkRet) {
                    WebGLService.cleanDummyLineHover();
                    WebGLService.remove3DObjectFromContent(obj3d.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, 'full', true);
                    ctrl.config.tools.validRoom = true;
                } else {
                    if (ctrl.config.tools.validRoom) {
                        WebGLService.remove3DObjectFromContent(obj3d.room);
                    }
                    WebGLService.cleanDummyLineHover();
                    for (i = 0; i < ctrl.room.outerWalls.length; i++) {
                        var w0 = ctrl.room.outerWalls[i];
                        var wa = Object3DFactory.buildWall(w0.start, w0.end, ctrl.room.size.y, ctrl.room.thickness, "flat", true);
                        RoomEditorService.addFlatWallToScene(wa);
                    }
                    ctrl.config.tools.validRoom = false;
                }
            };
            //region rect room mouse handler

            /**
             * @description function to setup data rectangular room data for provided min point (x and z most negative values)
             * @param {THREE.Vector3} minPoint the most negative point (x,z-components)
             * @param {number} w the width to use (x-axis)
             * @param {number} d the depth to use {z-axis}
             */
            var setupRectRoomData = function (minPoint, w, d) {
                var oldCorners = null;
                if (ctrl.room.corner.length) oldCorners = angular.copy(ctrl.room.corner);
                RoomEditorService.buildOuterWallArrayFromMinPointWidthDepth(minPoint, w, d, ctrl.room);
                if (obj3d.room !== null) WebGLService.remove3DObjectFromContent(obj3d.room);
                obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                ctrl.config.tools.validRoom = true;

                var collideCheckResult = RoomEditorService.checkAllObjectsWithinRoom(ctrl.room, obj3d.room);
                if (!collideCheckResult) console.warn("collide error room change");
            };

            /**
             * @description handler function for mouse down events of rectangular room tool
             * @param {MouseEvent} e the mouse event to use
             */
            var rectRoomMouseDownHandler = function (e) {
                if (e.buttons === 2) return;
                var i = WebGLService.getPositionPickplane(e);
                if (i.length === 1) {
                    ctrl.config.tools.mouseDownPos = obj3d.editorMarker.position.clone();
                }
            };

            /**
             * @description handler function for mouse up events of rectangular room tool
             * @param {MouseEvent} e the mouse event to use
             * @returns {boolean}
             */
            var rectRoomMouseUpHandler = function (e) {
                if (e.button === 2) return;
                if (obj3d.dummyObj !== null) {
                    WebGLService.remove3DObjectFromDummyObj(obj3d.dummyObj);
                }
                var bbox = new THREE.Box3().setFromPoints([ctrl.config.tools.mouseDownPos, obj3d.editorMarker.position]);
                if (bbox.min.equals(bbox.max)) {
                    ctrl.config.tools.mouseDownPos = null;
                    return;
                }
                var sizeX = bbox.max.x - bbox.min.x;
                var sizeZ = bbox.max.z - bbox.min.z;
                ctrl.tools.rect.w = NumberService.roundToPrecision(sizeX, 3);
                ctrl.tools.rect.d = NumberService.roundToPrecision(sizeZ, 3);
                setupRectRoomData(bbox.min.clone().setY(0), ctrl.tools.rect.w, ctrl.tools.rect.d);
                ctrl.config.tools.mouseDownPos = null;
                e.cancelBubble = true;
                $scope.$apply();
                return false;
            };

            /**
             * @description handler function for mouse move events of rectangular room tool
             * @param e
             */
            var rectRoomMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e);
                if (ctrl.config.tools.mouseDownPos === null) return;
                if (obj3d.dummyObj !== null) WebGLService.remove3DObjectFromDummyObj(obj3d.dummyObj);
                var bbox = new THREE.Box3().setFromPoints([ctrl.config.tools.mouseDownPos, obj3d.editorMarker.position]);

                var sizeX = bbox.max.x - bbox.min.x;
                var sizeZ = bbox.max.z - bbox.min.z;

                obj3d.dummyObj = Object3DFactory.buildMarkingPlane(sizeX, sizeZ, 0x00ff00);
                obj3d.dummyObj.rotation.x = Math.PI / -2;
                obj3d.dummyObj.position.copy(bbox.getCenter());
                WebGLService.add3DObjectToDummyObj(obj3d.dummyObj);
            };

            /**
             * @description change handler for width/depth changes of rectangular room tool (inputs in UI)
             */
            ctrl.updateRectRoomSize = function () {
                if (ctrl.tools.rect.d > 0 && ctrl.tools.rect.w > 0) {
                    var initPos = new THREE.Vector3(ctrl.tools.rect.w / -2, 0, ctrl.tools.rect.d / -2);
                    setupRectRoomData(initPos, ctrl.tools.rect.w, ctrl.tools.rect.d);
                }
            };
            //endregion
            //region outer wall mouse handler

            /**
             * @description handler function to handle mosue down events for outer wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var outerWallMouseDownHandler = function (e) {
                if (e.buttons === 2) return;
                var i = WebGLService.getPositionPickplane(e);
                if (i.length === 1) {
                    ctrl.config.tools.mouseDownPos = NumberService.roundToPrecisionV3(obj3d.editorMarker.position.clone(), 3);
                }
            };

            /**
             * @description handler function to handle mouse up events for outer wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var outerWallMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                if (!obj3d.dummyObj) {
                    ctrl.config.tools.mouseDownPos = null;
                    return;
                }
                var start = NumberService.roundToPrecisionV3(obj3d.dummyObj.userData.s.clone(), 3);
                var end = NumberService.roundToPrecisionV3(obj3d.dummyObj.userData.e.clone(), 3);
                var newWall = new OuterWall(Entity.getNewIdFromArray(ctrl.room.outerWalls), start.x, 0, start.z, end.x, 0, end.z, ctrl.room.thickness, ctrl.room.size.y);
                if (ctrl.config.tools.validRoom) {
                    WebGLService.remove3DObjectFromContent(newWall);
                } else {
                    RoomEditorService.handleChangeConnectingWallLoop(newWall, ctrl.room.outerWalls);
                    ctrl.room.outerWalls.push(newWall);
                    if (!ctrl.room.checkOuterWallIntersect() && ctrl.room.checkOuterWallsClosed(true)) {
                        RoomEditorService.redrawWalls(ctrl.room);
                        RoomEditorService.buildDummyFloor(ctrl.room);
                        ctrl.config.tools.validRoom = true;
                    } else {
                        RoomEditorService.redrawWalls(ctrl.room);
                        ctrl.config.tools.validRoom = false;
                    }
                    obj3d.dummyObj = null;
                    ctrl.config.tools.mouseDownPos = null;
                }
            };

            /**
             * @description handler function to handle mouse move events for outer wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var outerWallMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e, true);
                if (ctrl.config.tools.mouseDownPos === null) {
                    return;
                }
                if (obj3d.dummyObj !== null) {
                    WebGLService.remove3DObjectFromDummyObj(obj3d.dummyObj);
                    WebGLService.remove3DObjectFromLineObj(obj3d.dummyObj.userData.outline);
                    WebGLService.remove3DObjectFromHoverObj(obj3d.dummyObj.userData.hoverObj);
                }
                if (ctrl.config.tools.mouseDownPos.clone().sub(obj3d.editorMarker.position).length() < 0.001) {
                    obj3d.dummyObj = null;
                    return;
                }

                var objs = Object3DFactory.buildWall(ctrl.config.tools.mouseDownPos, obj3d.editorMarker.position, ctrl.room.size.y, ctrl.room.thickness, "flat", true);
                obj3d.dummyObj = objs[0];
                RoomEditorService.addFlatWallToScene(objs);
            };

            /**
             * @description change handler for outer wall tool height input
             */
            ctrl.updateOuterWalls = function () {
                if (ctrl.room.size.y === null || ctrl.room.thickness === null) {
                    ctrl.roomSizeValueUndefined = true;
                } else {
                    ctrl.roomSizeValueUndefined = false;
                }
                ctrl.roomHeightExceeded = !(validateNumber(ctrl.room.size.y) && ctrl.room.size.y >= 2.00 || ctrl.room.size.y <= 20);
                ctrl.wallThicknessExceeded = !(validateNumber(ctrl.room.thickness) && ctrl.room.thickness >= 0.1 || ctrl.room.size.y <= 2);

                if (validateNumber(ctrl.room.size.y) && ctrl.room.size.y > 0) updateInnerWallMaxHeight();
                if (ctrl.room.size.y !== undefined
                    && ctrl.room.thickness !== undefined
                    && ctrl.room.size.y > 0
                    && ctrl.room.thickness > 0
                    && ctrl.room.checkOuterWallsClosed()) {
                    if (RoomEditorService.getHeightChangeInfoLength() === 0) RoomEditorService.generateHeightChangeInfo(initRoom);
                    RoomEditorService.updateOuterWallThickness(ctrl.room);
                    RoomEditorService.shadowCleanDoorsWindows(ctrl.room);
                    WebGLService.remove3DObjectFromContent(obj3d.room);
                    RoomEditorService.updateOuterWallHeight(ctrl.room);
                    RoomEditorService.updatePillarHeight(ctrl.room);
                    RoomEditorService.updateInnerWallHeight(ctrl.room);
                    RoomEditorService.generateHeightChangeInfo(ctrl.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                    RoomEditorService.rebuildObjectsOnWall(ctrl.room, obj3d.room);
                    updateInnerWallMaxHeight();
                    RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                }
            };
            //endregion
            //region point mode
            var currentPointObject = null;
            var modifierPickPosDiff = null;
            var modifier = null;
            var connectedWalls = [];
            var wallEndMarkers = null;
            var modifierObject = null;
            var connectedObject = null;

            var connectedObjectsOnWalls = {}; // windows/doors in selected wall

            ctrl.updateRoomConfig = function () {
                if (ctrl.room.roomConfigurations.temperatureSetpoint === null || ctrl.room.roomConfigurations.temperatureThreshold === null ||
                    ctrl.room.roomConfigurations.pressureSetpoint === null || ctrl.room.roomConfigurations.pressureThreshold === null) {
                    ctrl.roomConfigurationValueUndefined = true;
                } else {
                    ctrl.roomConfigurationValueUndefined = false;
                }
                ctrl.temperatureSetpointExceeded = !(validateNumber(ctrl.room.roomConfigurations.temperatureSetpoint) && ctrl.room.roomConfigurations.temperatureSetpoint >= 0
                    || ctrl.room.roomConfigurations.temperatureSetpoint <= 100);
                ctrl.temperatureThresholdExceeded = !(validateNumber(ctrl.room.roomConfigurations.temperatureThreshold) && ctrl.room.roomConfigurations.temperatureThreshold >= 0
                    || ctrl.room.roomConfigurations.temperatureThreshold <= 100);
                ctrl.pressureSetpointExceeded = !(validateNumber(ctrl.room.roomConfigurations.pressureSetpoint) && ctrl.room.roomConfigurations.pressureSetpoint >= 0
                    || ctrl.room.roomConfigurations.pressureSetpoint <= 100);
                ctrl.pressureThresholdExceeded = !(validateNumber(ctrl.room.roomConfigurations.pressureThreshold) && ctrl.room.roomConfigurations.pressureThreshold >= 0
                    || ctrl.room.roomConfigurations.pressureThreshold <= 100);
            }

            /**
             * @description function to toggle point mode on/off
             */
            ctrl.togglePointMode = function () {
                if (ctrl.room.outerWalls.length < 1) return;
                ctrl.config.subtools.pointMode = !ctrl.config.subtools.pointMode;
                if (ctrl.config.subtools.pointMode) {
                    obj3d.pointLayer = Object3DFactory.buildPointLayer(ctrl.room, ctrl.config.tools.active === 2);
                    WebGLService.add3DObjectToScene(obj3d.pointLayer);
                    if (ctrl.config.tools.active === 2) WebGLService.bindMouseHandler(pointModeOuterMouseDownHandler, pointModeOuterMouseMoveHandler, pointModeOuterMouseUpHandler);
                    if (ctrl.config.tools.active === 3) WebGLService.bindMouseHandler(pointModeInnerMouseDownHandler, pointModeInnerMouseMoveHandler, pointModeInnerMouseUpHandler);
                } else {
                    obj3d.pointLayer.remove(obj3d.pointControl);
                    WebGLService.remove3DObjectFromScene(obj3d.pointLayer);
                    if (ctrl.config.tools.active === 2) WebGLService.bindMouseHandler(outerWallMouseDownHandler, outerWallMouseMoveHandler, outerWallMouseUpHandler);
                    if (ctrl.config.tools.active === 3) WebGLService.bindMouseHandler(innerWallMouseDownHandler, innerWallMouseMoveHandler, innerWallMouseUpHandler);
                    GLMeasureService.clearMeasure();
                }
            };

            /**
             * @description function to remove corner point / merge wall only useable with selected corner point
             */
            ctrl.mergeWall = function () {
                var innerWalls = ctrl.config.tools.active === 3;
                setConnectedWalls(currentPointObject, innerWalls);
                setConnectedObjectsOnWalls(currentPointObject, innerWalls);

                var w;
                for (w in connectedObjectsOnWalls) {
                    for (var i = 0; i < connectedObjectsOnWalls[w].length; i++) {
                        var idx = ctrl.room.roomObjs.indexOf(connectedObjectsOnWalls[w][i]);
                        if (idx !== -1) ctrl.room.roomObjs.splice(idx, 1);
                    }
                }

                if (!innerWalls) {
                    RoomEditorService.mergeWall(ctrl.room, connectedWalls, false);
                    RoomEditorService.redrawWalls(ctrl.room);
                    var tParent = obj3d.pointLayer.parent;
                    obj3d.pointLayer.parent.remove(obj3d.pointLayer);
                    obj3d.pointLayer = Object3DFactory.buildPointLayer(ctrl.room, true);
                    tParent.add(obj3d.pointLayer);
                    currentPointObject = null;
                    ctrl.config.subtools.allowRemove = false;
                    GLMeasureService.clearMeasure();
                    if (ctrl.room.checkOuterWallsClosed(true)) {
                        RoomEditorService.buildDummyFloor(ctrl.room);
                    }
                } else {
                    RoomEditorService.mergeWall(ctrl.room, connectedWalls, true);
                    RoomEditorService.redrawWalls(ctrl.room, true);
                    obj3d.pointLayer.parent.remove(obj3d.pointLayer);
                    obj3d.pointLayer = Object3DFactory.buildPointLayer(ctrl.room, false);
                    WebGLService.add3DObjectToScene(obj3d.pointLayer);
                    currentPointObject = null;
                    ctrl.config.subtools.allowRemove = false;
                    GLMeasureService.clearMeasure();
                }

            };

            /**
             * @description function to split selected wall in point mode
             */
            ctrl.splitWall = function () {
                var wall;
                var innerWalls = ctrl.config.tools.active === 3;
                setConnectedObjectsOnWalls(currentPointObject, innerWalls);

                for (var i = 0; i < connectedObjectsOnWalls['m'].length; i++) {
                    var idx = ctrl.room.roomObjs.indexOf(connectedObjectsOnWalls['m'][i]);
                    if (idx !== -1) ctrl.room.roomObjs.splice(idx, 1);
                }

                if (!innerWalls) {
                    wall = RoomEditorService.findWallByPoints(ctrl.room.outerWalls, currentPointObject.userData.parent.userData.s, currentPointObject.userData.parent.userData.e);
                    RoomEditorService.splitWall(ctrl.room, wall[0]);
                    RoomEditorService.redrawWalls(ctrl.room);
                    obj3d.pointLayer.parent.remove(obj3d.pointLayer);
                    obj3d.pointLayer = Object3DFactory.buildPointLayer(ctrl.room, ctrl.config.tools.active === 2);
                    WebGLService.add3DObjectToScene(obj3d.pointLayer);
                    currentPointObject = null;
                    ctrl.config.subtools.allowSplit = false;
                    GLMeasureService.clearMeasure();
                    if (ctrl.room.checkOuterWallsClosed(true)) {
                        RoomEditorService.buildDummyFloor(ctrl.room);
                    }
                } else {
                    wall = RoomEditorService.findWallByPoints(ctrl.room.innerWalls, currentPointObject.userData.parent.userData.s, currentPointObject.userData.parent.userData.e);
                    RoomEditorService.splitInnerWall(ctrl.room, wall[0]);
                    RoomEditorService.redrawWalls(ctrl.room, true);
                    obj3d.pointLayer.parent.remove(obj3d.pointLayer);
                    obj3d.pointLayer = Object3DFactory.buildPointLayer(ctrl.room, false);
                    WebGLService.add3DObjectToScene(obj3d.pointLayer);
                    currentPointObject = null;
                    ctrl.config.subtools.allowSplit = false;
                    GLMeasureService.clearMeasure();
                }

            };

            /**
             * @description function to handle selection of corner point object
             * @param {THREE.Object3D} obj the selected object to use
             */
            var handleSelectPointObject = function (obj) {
                if ((currentPointObject !== undefined && currentPointObject !== null) || !obj) {
                    if (currentPointObject && currentPointObject.name === "wallEndPoint") {
                        currentPointObject.visible = true;
                        if (currentPointObject.userData.connectedTo) currentPointObject.userData.connectedTo.visible = true;
                    }
                    if (currentPointObject && currentPointObject.name === "hoverWall") {
                        modEndMarker(currentPointObject, true);
                        wallEndMarkers = [];
                    }
                    obj3d.pointLayer.remove(obj3d.pointControl);
                    ctrl.config.subtools.allowSplit = ctrl.config.subtools.allowRemove = false;
                    GLMeasureService.clearMeasure();
                    if (!obj) return;
                }
                if (obj && obj.name === "wallEndPoint") {
                    currentPointObject = obj;
                    currentPointObject.visible = false;
                    ctrl.config.subtools.allowRemove = true;
                    ctrl.config.subtools.allowSplit = false;
                    if (currentPointObject.userData.connectedTo) currentPointObject.userData.connectedTo.visible = false;

                }
                if (obj && obj.name === "hoverWall") {
                    currentPointObject = obj;
                    ctrl.config.subtools.allowSplit = true;
                    ctrl.config.subtools.allowRemove = false;
                    wallEndMarkers = modEndMarker(obj, false);
                }
                $scope.$apply();
            };

            /**
             * @description function to modify wall end markers
             * @param {THREE.Object3D} obj the 3d-object defining the active corner object
             * @param {boolean} show true if endpoints should be displayed, otherwise false
             * @returns array of wall end points for provided object
             */
            var modEndMarker = function (obj, show) {
                var marker = [null, null];
                obj3d.pointLayer.traverse(function (o) {
                    if (o.name === "wallEndPoint") {
                        if (o.position.clone().setY(0).equals(obj.userData.parent.userData.s)) {
                            marker[0] = o;
                            o.visible = show;
                            if (o.userData.connectedTo) o.userData.connectedTo.visible = show;
                        }
                        if (o.position.clone().setY(0).equals(obj.userData.parent.userData.e)) {
                            marker[1] = o;
                            o.visible = show;
                            if (o.userData.connectedTo) o.userData.connectedTo.visible = show;
                        }
                    }
                });
                return marker;
            };

            /**
             * @description function to setup connected walls for provided corner point object
             * @param {THREE.Object3D} obj the corner point object
             * @param {boolean} innerWalls true if inner walls are modified, false for outer walls
             */
            var setConnectedWalls = function (obj, innerWalls) {
                var wallArrayName = innerWalls ? 'innerWalls' : 'outerWalls';
                var i;
                if (obj.name === "wallEndPoint") {
                    connectedWalls = [null, null];
                    for (i = 0; i < ctrl.room[wallArrayName].length; i++) {
                        if (ctrl.room[wallArrayName][i].end.x === obj.position.x && ctrl.room[wallArrayName][i].end.z === obj.position.z) {
                            if (connectedWalls[0] === null) {
                                connectedWalls[0] = i;
                            } else {
                                connectedWalls[1] = i;
                            }
                            continue;
                        }
                        if (ctrl.room[wallArrayName][i].start.x === obj.position.x && ctrl.room[wallArrayName][i].start.z === obj.position.z) {
                            if (connectedWalls[1] === null) {
                                connectedWalls[1] = i;
                            } else {
                                connectedWalls[0] = i;
                            }
                        }
                    }
                }
                if (obj.name === "hoverWall") {
                    var s = obj.userData.parent.userData.s, e = obj.userData.parent.userData.e;
                    connectedWalls = [null, null];
                    for (i = 0; i < ctrl.room[wallArrayName].length; i++) {
                        if (ctrl.room[wallArrayName][i].start.equals(s) && ctrl.room[wallArrayName][i].end.equals(e)) continue;
                        if (ctrl.room[wallArrayName][i].start.equals(s)) {
                            connectedWalls[0] = i;
                            continue;
                        }
                        if (ctrl.room[wallArrayName][i].start.equals(e)) {
                            connectedWalls[0] = i;
                            continue;
                        }
                        if (ctrl.room[wallArrayName][i].end.equals(s)) {
                            connectedWalls[1] = i;
                            continue;
                        }
                        if (ctrl.room[wallArrayName][i].end.equals(e)) {
                            connectedWalls[1] = i;
                        }
                    }
                }
            };

            var oldWallObjects = {};
            var oldMod = null;
            var oldModPos = null;
            var oldWallIdx = null;

            /**
             * @description function to setup objects(doors, windows) for connected walls
             * @param {THREE.Object3D} obj the corner point object to use
             * @param {boolean} useInnerWall set to true for inner wall point mode, false for outer wall point mode
             */
            var setConnectedObjectsOnWalls = function (obj, useInnerWall) {
                var helper = function (idx) {
                    connectedObjectsOnWalls[connectedWalls[idx]] = ctrl.room.getWindowsForWall(useInnerWall ? ctrl.room.innerWalls[connectedWalls[idx]] : ctrl.room.outerWalls[connectedWalls[idx]]);
                    connectedObjectsOnWalls[connectedWalls[idx]] = connectedObjectsOnWalls[connectedWalls[idx]].concat(ctrl.room.getDoorsForWall(useInnerWall ? ctrl.room.innerWalls[connectedWalls[idx]] : ctrl.room.outerWalls[connectedWalls[idx]]));
                    RoomEditorService.setWallDistances(useInnerWall ? ctrl.room.innerWalls[connectedWalls[idx]] : ctrl.room.outerWalls[connectedWalls[idx]], connectedObjectsOnWalls[connectedWalls[idx]]);
                };

                if (obj.name === "wallEndPoint" || obj.name === "hoverWall") {
                    if (connectedWalls[0] !== undefined && connectedWalls[0] !== null) helper(0);
                    if (connectedWalls[1] !== undefined && connectedWalls[1] !== null) helper(1);
                }
                if (obj.name === "hoverWall") {
                    var wall = {
                        start: obj.userData.parent.userData.s.clone(),
                        end: obj.userData.parent.userData.e.clone()
                    };
                    connectedObjectsOnWalls['m'] = ctrl.room.getWindowsForWall(wall);
                    connectedObjectsOnWalls['m'] = connectedObjectsOnWalls['m'].concat(ctrl.room.getDoorsForWall(wall));
                    RoomEditorService.setWallDistances(wall, connectedObjectsOnWalls['m']);
                }
                oldWallObjects = angular.copy(connectedObjectsOnWalls);
            };

            /**
             * @description function to update objects on connected walls (position)
             * @param {THREE.Object3D} obj the corner point object
             * @param {boolean} useInnerWalls set to true for inner wall point mode, false for outer wall point mode
             */
            var updateConnectedObjectsOnWall = function (obj, useInnerWalls) {
                var wall, stdDir = new THREE.Vector3(1, 0, 0);

                var objsToDelete = [];

                var helper = function (wall, idx) {
                    var wallStart = new THREE.Vector3(wall.start.x, wall.start.y, wall.start.z);
                    var wallDir = new THREE.Vector3(wall.end.x, wall.end.y, wall.end.z).sub(wallStart);
                    var wallLen = wallDir.length();
                    wallDir.normalize();
                    var newAngle = wallDir.angleTo(stdDir);
                    if (wallDir.z > 0) newAngle *= -1;
                    for (var i = 0; i < connectedObjectsOnWalls[connectedWalls[idx]].length; i++) {
                        var wo = connectedObjectsOnWalls[connectedWalls[idx]][i];
                        // distance based
                        if (wo.wallDistance > wallLen - wo.size.x / 2) {
                            wo.alignedToWall = false;
                            objsToDelete.push(wo);
                            continue;
                        }
                        var newPos = wallStart.clone().add(wallDir.clone().multiplyScalar(wo.wallDistance));
                        wo.pos.x = newPos.x;
                        wo.pos.z = newPos.z;
                        wo.rot.y = MathService.radToDeg(newAngle * -1);
                    }
                };
                if (obj.name === "wallEndPoint" || obj.name === "hoverWall") {
                    if (connectedWalls[0] !== undefined && connectedWalls[0] !== null) {
                        wall = useInnerWalls ? ctrl.room.innerWalls[connectedWalls[0]] : ctrl.room.outerWalls[connectedWalls[0]];
                        helper(wall, 0);
                    }
                    if (connectedWalls[1] !== undefined && connectedWalls[1] !== null) {
                        wall = useInnerWalls ? ctrl.room.innerWalls[connectedWalls[1]] : ctrl.room.outerWalls[connectedWalls[1]];
                        helper(wall, 1);
                    }
                }

                var helper2 = function () {
                    for (var k in connectedObjectsOnWalls) {
                        for (var i = 0; i < connectedObjectsOnWalls[k].length; i++) {
                            var to = ctrl.room.roomObjs.filter(function (el) {
                                return el.id === connectedObjectsOnWalls[k][i].id;
                            });
                            if (to.length) {
                                to[0].pos.x = connectedObjectsOnWalls[k][i].pos.x;
                                to[0].pos.y = connectedObjectsOnWalls[k][i].pos.y;
                                to[0].pos.z = connectedObjectsOnWalls[k][i].pos.z;
                                to[0].rot.y = connectedObjectsOnWalls[k][i].rot.y;

                                var to3d = WebGLService.findObjectByUniqueId(to[0].uniqueId);
                                if (to3d) {
                                    to3d.position.set(to[0].pos.x, to[0].pos.y, to[0].pos.z);
                                    to3d.rotation.y = -MathService.degToRad(to[0].rot.y);
                                }
                            }
                        }
                    }
                };

                if (obj.name === "hoverWall") {
                    wall = {s: obj.userData.parent.userData.s.clone(), e: obj.userData.parent.userData.e.clone()};
                    var wallDir = new THREE.Vector3(wall.e.x, wall.e.y, wall.e.z).sub(new THREE.Vector3(wall.s.x, wall.s.y, wall.s.z));
                    var wallLen = wallDir.length();
                    wallDir.normalize();
                    var newAngle = wallDir.angleTo(stdDir);
                    if (wallDir.z > 0) newAngle *= -1;
                    for (var i = 0; i < connectedObjectsOnWalls['m'].length; i++) {
                        var wo = connectedObjectsOnWalls['m'][i];
                        var newPos = new THREE.Vector3(wall.s.x, wall.s.y, wall.s.z).add(wallDir.clone().multiplyScalar(wo.wallDistance));
                        if (wo.wallDistance > wallLen - wo.size.x / 2) {
                            objsToDelete.push(wo);
                            continue;
                        }
                        wo.pos.x = newPos.x;
                        wo.pos.z = newPos.z;
                        wo.rot.y = MathService.radToDeg(newAngle * -1);
                    }
                }

                if (objsToDelete.length > 0) {
                    RoomEditorService.showDoorWindowRemoveDialog(function () {
                        if (oldMod >= 0) {
                            modifier = oldMod;
                            connectedObjectsOnWalls = oldWallObjects;
                            updateConnectedWalls(useInnerWalls);
                            helper2();
                        }
                        if (oldMod < 0) {
                            if (oldMod === -2) {
                                var wall = null;
                                var sName = useInnerWalls ? "start" : "start";
                                var eName = useInnerWalls ? "end" : "end";
                                if (useInnerWalls) {
                                    wall = oldWallIdx == 0 ? ctrl.room.innerWalls[connectedWalls[0]] : ctrl.room.innerWalls[connectedWalls[1]];
                                } else {
                                    wall = oldWallIdx == 0 ? ctrl.room.outerWalls[connectedWalls[0]] : ctrl.room.outerWalls[connectedWalls[1]];
                                }

                                var s = currentPointObject.userData.parent.userData.s.clone();
                                var e = currentPointObject.userData.parent.userData.e.clone();
                                var vec = oldModPos.multiplyScalar(-1);
                                var modPoint = null;
                                if (s.equals(wall[sName]) || s.equals(wall[eName])) {
                                    modPoint = s.clone();
                                    s.add(vec);
                                }
                                if (e.equals(wall[sName]) || e.equals(wall[eName])) {
                                    modPoint = e.clone();
                                    e.add(vec);
                                }

                                updateWallEndMarkerLength(modPoint, vec);
                                updateConnectedWalls(useInnerWalls);
                                updateConnectedObjectsOnWall(currentPointObject, useInnerWalls);
                                RoomEditorService.redrawWalls(ctrl.room, useInnerWalls);
                                WebGLService.getHoverObject().traverse(function (o) {
                                    if (o.name == "hoverWall") {
                                        if (o.userData.parent.userData.s.equals(s) && o.userData.parent.userData.e.equals(e)) {
                                            currentPointObject = o;
                                        }
                                    }
                                });
                                if (!useInnerWalls) {
                                    if (ctrl.room.checkOuterWallsClosed(true)) {
                                        RoomEditorService.buildDummyFloor(ctrl.room);
                                    }
                                }

                                GLMeasureService.measureObject(undefined, getWall3DObjects(useInnerWalls), undefined, useInnerWalls ? handleInnerWallLengthInput : handleWallLengthInput);
                                obj3d.pointLayer.remove(obj3d.pointControl);
                                obj3d.pointControl = Object3DFactory.buildPointControls("wall", ctrl.room.size.y, currentPointObject);
                                obj3d.pointControl.position.copy(currentPointObject.position);
                                obj3d.pointLayer.add(obj3d.pointControl);
                            }
                            if (oldMod === -3) {

                                var wall = null;
                                var sName = useInnerWalls ? "start" : "start";
                                var eName = useInnerWalls ? "end" : "end";
                                var s = currentPointObject.userData.parent.userData.s.clone();
                                var e = currentPointObject.userData.parent.userData.e.clone();
                                if (useInnerWalls) {
                                    for (var i = 0; i < ctrl.room.innerWalls.length && wall === null; i++) {
                                        if (s.equals(ctrl.room.innerWalls[i][sName]) && e.equals(ctrl.room.innerWalls[i][eName])) {
                                            wall = ctrl.room.innerWalls[i];
                                            break;
                                        }
                                    }
                                }
                                if (!useInnerWalls) {

                                    for (var i = 0; i < ctrl.room.outerWalls.length && wall === null; i++) {
                                        if (s.equals(ctrl.room.outerWalls[i][sName]) && e.equals(ctrl.room.outerWalls[i][eName])) {
                                            wall = ctrl.room.outerWalls[i];
                                            break;
                                        }
                                    }
                                }

                                var pos = modifierObject.position.clone().setY(0).applyMatrix4(modifierObject.parent.matrixWorld).setY(0);
                                var vec0 = pos.clone().sub(oldModPos.clone().setY(0)).setY(0).multiplyScalar(-1);
                                var vec = null;
                                var modPoint = null;
                                if (MathService.compareVector3(s, pos, 0.0001)) {
                                    var dir = wall[eName].clone().sub(wall[sName]).normalize();
                                    vec = dir.multiplyScalar(vec0.length());
                                    modPoint = s.clone();
                                    s.add(vec0);
                                }
                                if (MathService.compareVector3(e, pos, 0.0001)) {
                                    var dir = wall[sName].clone().sub(wall[eName]).normalize();
                                    vec = dir.multiplyScalar(vec0.length());
                                    modPoint = e.clone();
                                    e.add(vec0);
                                }

                                connectedObjectsOnWalls = oldWallObjects;
                                var pos = modifierObject.position.clone().setY(0).applyMatrix4(modifierObject.parent.matrixWorld).setY(0);
                                updateWallEndMarkerLength(modPoint, vec0);
                                updateConnectedWalls(useInnerWalls);
                                updateConnectedObjectsOnWall(currentPointObject, useInnerWalls);
                                RoomEditorService.redrawWalls(ctrl.room, useInnerWalls);
                                if (!useInnerWalls) {
                                    if (ctrl.room.checkOuterWallsClosed(true)) {
                                        RoomEditorService.buildDummyFloor(ctrl.room);
                                    }
                                }
                                GLMeasureService.measureObject(undefined, getWall3DObjects(useInnerWalls), undefined, useInnerWalls ? handleInnerWallLengthInput : handleWallLengthInput);
                                obj3d.pointLayer.remove(obj3d.pointControl);
                                obj3d.pointControl = Object3DFactory.buildPointControls("wall", ctrl.room.size.y, currentPointObject);
                                obj3d.pointControl.position.copy(currentPointObject.position);
                                obj3d.pointLayer.add(obj3d.pointControl);
                            }
                            helper2();
                        }
                        modifier = oldMod = oldModPos = null;
                        connectedObjectsOnWalls = oldWallObjects = {};
                    }, function () {
                        for (var i = ctrl.room.roomObjs.length - 1; i >= 0; i--) {
                            if (objsToDelete.filter(function (el) {
                                return el.id === ctrl.room.roomObjs[i].id
                            }).length > 0) {

                                ctrl.room.roomObjs.splice(i, 1);
                            }
                        }
                    });
                }
            };

            /**
             * @description function to update connected wall objects
             * @param {boolean} innerWalls set to true if point mode for inner walls, false for outer walls point mode
             */
            var updateConnectedWalls = function (innerWalls) {
                var wallArrayName = innerWalls ? "innerWalls" : "outerWalls";
                if (currentPointObject.name === "wallEndPoint") {
                    if (connectedWalls[0] !== null) {
                        ctrl.room[wallArrayName][connectedWalls[0]].end.x = obj3d.pointControl.position.x;
                        ctrl.room[wallArrayName][connectedWalls[0]].end.z = obj3d.pointControl.position.z;
                    }
                    if (connectedWalls[1] !== null) {
                        ctrl.room[wallArrayName][connectedWalls[1]].start.x = obj3d.pointControl.position.x;
                        ctrl.room[wallArrayName][connectedWalls[1]].start.z = obj3d.pointControl.position.z;
                    }
                }
                if (currentPointObject.name === "hoverWall") {
                    var w0 = connectedWalls[0] !== null ? ctrl.room[wallArrayName][connectedWalls[0]] : null;
                    var w1 = connectedWalls[1] !== null ? ctrl.room[wallArrayName][connectedWalls[1]] : null;
                    var ws = RoomEditorService.findWallByPoints(ctrl.room[wallArrayName], currentPointObject.userData.parent.userData.s, currentPointObject.userData.parent.userData.e)[0];
                    if (ws === null) return;
                    var os = ws.start.clone(), oe = ws.end.clone();
                    for (var i in wallEndMarkers) {
                        if (wallEndMarkers[i].userData.op.clone().setY(0).equals(ws.start)) {
                            ws.start.setX(wallEndMarkers[i].position.x).setZ(wallEndMarkers[i].position.z);
                            currentPointObject.userData.parent.userData.s = ws.start.clone();
                        }
                        if (wallEndMarkers[i].userData.op.clone().setY(0).equals(ws.end)) {
                            ws.end.setX(wallEndMarkers[i].position.x).setZ(wallEndMarkers[i].position.z);
                            currentPointObject.userData.parent.userData.e = ws.end.clone();
                        }
                    }
                    var helper = function (wall) {
                        if (os.equals(wall.start)) {
                            wall.start.copy(ws.start);
                        }
                        if (os.equals(wall.end)) {
                            wall.end.copy(ws.start);
                        }
                        if (oe.equals(wall.start)) {
                            wall.start.copy(ws.end);
                        }
                        if (oe.equals(wall.end)) {
                            wall.end.copy(ws.end);
                        }
                    };
                    if (w0 !== null) {
                        helper(w0);
                    }
                    if (w1 !== null) {
                        helper(w1);
                    }
                }
            };

            /**
             * @description handler function for mouse down events of point mode tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pointModeOuterMouseDownHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                helperPointModeMouseDownHandler(e, false);
            };

            /**
             * @description handler function for mouse move events of point mode tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pointModeOuterMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e);
                if (ctrl.config.subtools.modifierSelected) {
                    var i = WebGLService.getPositionPickplane(e);
                    if (i.length > 0) {
                        var p = NumberService.roundToPrecisionV3(i[0].point, 3);
                        updateWallsMouseMove(p, false, currentPointObject.matrixWorld, false);
                    }
                } else {
                    var i = WebGLService.pickObjects(e, [obj3d.pointLayer, WebGLService.getHoverObject()]);
                    if (i.length > 0) {
                        WebGLService.markObject(i[0].object);
                    } else {
                        WebGLService.unmarkObject();
                    }
                }
            };

            /**
             * @description handler function for mouse up events of point mode tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pointModeOuterMouseUpHandler = function (e) {
                if (e.originalEvent.button != 0) return;
                helperPointModeMouseUpHandler(e, false);
            };

            /**
             * @description function to 3d-objects for connected walls
             * @param {boolean} innerWalls true for inner walls, false for outer walls
             * @returns an array
             */
            var getWall3DObjects = function (innerWalls) {
                var wallArrayName = innerWalls ? "innerWalls" : "outerWalls";
                var objs = [null, null];
                WebGLService.getDummyObject().traverse(function (o) {
                    if (o instanceof THREE.Mesh && o.name === "outerwallSingle") {
                        if (connectedWalls[0] !== undefined && connectedWalls[0] !== null) {
                            if (o.userData.s.equals(ctrl.room[wallArrayName][connectedWalls[0]].start) && o.userData.e.equals(ctrl.room[wallArrayName][connectedWalls[0]].end)) objs[0] = o;
                        }
                        if (connectedWalls[1] !== undefined && connectedWalls[1] !== null) {
                            if (o.userData.s.equals(ctrl.room[wallArrayName][connectedWalls[1]].start) && o.userData.e.equals(ctrl.room[wallArrayName][connectedWalls[1]].end)) objs[1] = o;
                        }
                    }
                });
                return objs;
            };

            /**
             * @description function to update wall end markers in point mode after changes to wall length
             * @param {THREE.Vector3} pos the position to find the wall end marker
             * @param {THREE.Vector3} vec the vector to add to original position of end marker
             */
            var updateWallEndMarkerLength = function (pos, vec) {
                for (var wem = 0; wem < wallEndMarkers.length; wem++) {
                    wallEndMarkers[wem].userData.op = wallEndMarkers[wem].position.clone();
                    if (wallEndMarkers[wem].position.clone().setY(0).equals(pos)) {
                        wallEndMarkers[wem].position.add(vec);
                        if (wallEndMarkers[wem].userData.connectedTo) wallEndMarkers[wem].userData.connectedTo.position.copy(wallEndMarkers[wem].position);
                    }
                }
            };

            /**
             * @description callback function to handle input events for outer wall lengths (overlay inputs)
             * @param {Event} e the event to use (to get input element value)
             */
            var handleWallLengthInput = function (e) {
                var inputValue = parseFloat(e.currentTarget.value.replace(",", "."));
                var diff = inputValue - $(e.currentTarget).attr("ov");
                var tv = $(e.currentTarget).attr("trackedvalue");
                setConnectedObjectsOnWalls(currentPointObject, false);
                currentPointObject.userData.op = currentPointObject.position.clone();
                oldModPos = currentPointObject.position.clone();
                oldMod = 0;
                oldWallIdx = tv;
                if (currentPointObject.name === "wallEndPoint") {
                    var wall = tv == 0 ? ctrl.room.outerWalls[connectedWalls[0]] : ctrl.room.outerWalls[connectedWalls[1]];
                    var dir = null;
                    if (currentPointObject.position.clone().setY(0).equals(wall.start)) dir = wall.start.clone().sub(wall.end);
                    if (currentPointObject.position.clone().setY(0).equals(wall.end)) dir = wall.end.clone().sub(wall.start);
                    dir.normalize();
                    var vec = dir.multiplyScalar(diff);
                    obj3d.pointControl.position.add(vec);
                    currentPointObject.position.add(vec);
                    if (currentPointObject.userData.connectedTo) currentPointObject.userData.connectedTo.position.add(vec);
                    updateConnectedWalls();
                    updateConnectedObjectsOnWall(currentPointObject, false);
                    RoomEditorService.redrawWalls(ctrl.room);
                    GLMeasureService.measureObject(undefined, getWall3DObjects(), undefined, handleWallLengthInput);
                    if (ctrl.room.checkOuterWallsClosed(true)) {
                        RoomEditorService.buildDummyFloor(ctrl.room);
                    }
                }
                if (currentPointObject.name == "hoverWall") {
                    var wall = tv == 0 ? ctrl.room.outerWalls[connectedWalls[0]] : ctrl.room.outerWalls[connectedWalls[1]];
                    var dir = null;
                    var s = currentPointObject.userData.parent.userData.s.clone();
                    var e = currentPointObject.userData.parent.userData.e.clone();
                    var modPoint = null;
                    if (s.equals(wall.start) || s.equals(wall.end)) {
                        dir = wall.end.clone().sub(wall.start);
                        dir.normalize();
                        var vec = dir.multiplyScalar(diff);
                        modPoint = s.clone();
                        s.add(vec);
                    }
                    if (e.equals(wall.start) || e.equals(wall.end)) {
                        dir = wall.start.clone().sub(wall.end);
                        dir.normalize();
                        var vec = dir.multiplyScalar(diff);
                        modPoint = e.clone();
                        e.add(vec);
                    }
                    updateWallEndMarkerLength(modPoint, vec);
                    updateConnectedWalls();
                    updateConnectedObjectsOnWall(currentPointObject);
                    RoomEditorService.redrawWalls(ctrl.room);
                    WebGLService.getHoverObject().traverse(function (o) {
                        if (o.name == "hoverWall") {
                            if (o.userData.parent.userData.s.equals(s) && o.userData.parent.userData.e.equals(e)) {
                                currentPointObject = o;
                            }
                        }
                    });
                    GLMeasureService.measureObject(undefined, getWall3DObjects(), undefined, handleWallLengthInput);
                    if (ctrl.room.checkOuterWallsClosed(true)) {
                        RoomEditorService.buildDummyFloor(ctrl.room);
                    }
                    obj3d.pointLayer.remove(obj3d.pointControl);
                    obj3d.pointControl = Object3DFactory.buildPointControls("wall", ctrl.room.size.y, currentPointObject);
                    obj3d.pointControl.position.copy(currentPointObject.position);
                    currentPointObject.userData.op = modPoint.clone().sub(vec);
                    oldMod = -2;
                    oldModPos = vec.clone();
                    obj3d.pointLayer.add(obj3d.pointControl);
                }
            };

            /**
             * @description callback function to handle input events for inner wall lengths (overlay inputs)
             * @param {Event} e the event to use (to get input element value)
             */
            var handleInnerWallLengthInput = function (e) {
                var inputValue = parseFloat(e.currentTarget.value.replace(",", "."));
                var diff = inputValue - $(e.currentTarget).attr("ov");
                var tv = $(e.currentTarget).attr("trackedvalue");
                setConnectedObjectsOnWalls(currentPointObject, true);
                currentPointObject.userData.op = currentPointObject.position.clone();
                oldModPos = currentPointObject.position.clone();
                oldMod = 0;
                oldWallIdx = tv;
                if (currentPointObject.name === "wallEndPoint") {
                    var wall = tv == 0 ? ctrl.room.innerWalls[connectedWalls[0]] : ctrl.room.innerWalls[connectedWalls[1]];
                    var dir = null;
                    if (currentPointObject.position.clone().setY(0).equals(wall.start)) dir = wall.start.clone().sub(wall.end);
                    if (currentPointObject.position.clone().setY(0).equals(wall.end)) dir = wall.end.clone().sub(wall.start);
                    dir.normalize();
                    var vec = dir.multiplyScalar(diff);
                    obj3d.pointControl.position.add(vec);
                    currentPointObject.position.add(vec);
                    if (currentPointObject.userData.connectedTo) currentPointObject.userData.connectedTo.position.add(vec);
                    updateConnectedWalls(true);
                    updateConnectedObjectsOnWall(currentPointObject, true);
                    RoomEditorService.redrawWalls(ctrl.room, true);
                    GLMeasureService.measureObject(undefined, getWall3DObjects(true), undefined, handleInnerWallLengthInput);
                }
                if (currentPointObject.name == "hoverWall") {
                    var wall = tv == 0 ? ctrl.room.innerWalls[connectedWalls[0]] : ctrl.room.innerWalls[connectedWalls[1]];
                    var dir = null;
                    var s = currentPointObject.userData.parent.userData.s.clone();
                    var e = currentPointObject.userData.parent.userData.e.clone();
                    var modPoint = null;
                    if (s.equals(wall.start) || s.equals(wall.end)) {
                        dir = wall.end.clone().sub(wall.start);
                        dir.normalize();
                        var vec = dir.multiplyScalar(diff);
                        modPoint = s.clone();
                        s.add(vec);
                    }
                    if (e.equals(wall.start) || e.equals(wall.end)) {
                        dir = wall.start.clone().sub(wall.end);
                        dir.normalize();
                        var vec = dir.multiplyScalar(diff);
                        modPoint = e.clone();
                        e.add(vec);
                    }
                    updateWallEndMarkerLength(modPoint, vec);
                    updateConnectedWalls(true);
                    updateConnectedObjectsOnWall(currentPointObject, true);
                    RoomEditorService.redrawWalls(ctrl.room, true);
                    WebGLService.getHoverObject().traverse(function (o) {
                        if (o.name == "hoverWall") {
                            if (o.userData.parent.userData.s.equals(s) && o.userData.parent.userData.e.equals(e)) {
                                currentPointObject = o;
                            }
                        }
                    });
                    GLMeasureService.measureObject(undefined, getWall3DObjects(true), undefined, handleInnerWallLengthInput);
                    obj3d.pointLayer.remove(obj3d.pointControl);
                    obj3d.pointControl = Object3DFactory.buildPointControls("wall", ctrl.room.size.y, currentPointObject);
                    obj3d.pointControl.position.copy(currentPointObject.position);
                    currentPointObject.userData.op = modPoint.clone().sub(vec);
                    oldMod = -2;
                    oldModPos = vec.clone();
                    obj3d.pointLayer.add(obj3d.pointControl);
                }
            };

            /**
             * @description helper function for mouse down events in point mode tool
             * @param {MouseEvent} ev the mouse event to use
             * @param {boolean} innerWalls true if point mode for inner walls, false for point mode of outer walls
             */
            var helperPointModeMouseDownHandler = function (ev, innerWalls) {
                var i = WebGLService.pickObjects(ev, obj3d.pointLayer);
                if (i.length > 0) {
                    var info = i[0].object.name.split("_");
                    if (info[0] == "move") {
                        if (info[1] === "len") {
                            i[0].object.updateMatrixWorld();
                            var p = i[0].object.position;
                            p = p.clone().setY(0).applyMatrix4(i[0].object.parent.matrixWorld);
                            modifierPickPosDiff = p.setY(0).sub(i[0].point.setY(0));
                            modifierObject = i[0].object;
                        } else {
                            modifierPickPosDiff = obj3d.pointControl.position.clone().setY(0).sub(i[0].point.setY(0));
                        }
                        ctrl.config.subtools.modifierSelected = true;
                        if (info[1][0] == "c") modifier = 0;
                        if (info[1][0] == "x") modifier = 1;
                        if (info[1][0] == "z") modifier = 2;
                        if (info[1][0] == "l") modifier = 3;
                        setConnectedWalls(currentPointObject, innerWalls);
                        setConnectedObjectsOnWalls(currentPointObject, innerWalls);
                        currentPointObject.userData.op = currentPointObject.position.clone();
                        oldWallObjects = angular.copy(connectedObjectsOnWalls);
                        if (modifier == 3) {
                            var pmod = modifierObject.position.clone().setY(0).applyMatrix4(modifierObject.parent.matrixWorld);
                            pmod.setY(0);
                            oldModPos = pmod.clone();
                            oldMod = -3;
                            if (MathService.compareVector3(pmod, wallEndMarkers[0].position.clone().setY(0))) connectedObject = wallEndMarkers[0];
                            if (MathService.compareVector3(pmod, wallEndMarkers[1].position.clone().setY(0))) connectedObject = wallEndMarkers[1];
                        }
                    }
                } else {
                    ctrl.config.subtools.modifierSelected = false;
                }
            };

            /**
             * @description handler function for mouse down events of point mode tool for inner walls
             * @param {MouseEvent} e the mouse event to use
             */
            var pointModeInnerMouseDownHandler = function (e) {
                if (e.originalEvent.button != 0) return;
                helperPointModeMouseDownHandler(e, true);
            };

            /**
             * @description function to update walls when mouse is moved in point mode
             * @param {THREE.Vector3} point the point to move walls endpoints for
             * @param {boolean} reset true if not trigger by user, false if triggered by user
             * @param {THREE.Matrix4} matWorld the world transformation matrix
             * @param {boolean} innerWalls true for inner wall point mode, false for outer wall point mode
             */
            var updateWallsMouseMove = function (point, reset, matWorld, innerWalls) {
                if (innerWalls && !MathService.checkPointInPolygon(point, ctrl.room.pts2d)) return;
                var oldPointObjectPosition = currentPointObject.position.clone();
                var wallEndObject = currentPointObject.name === "wallEndPoint";
                if (modifier === 0) {
                    obj3d.pointControl.position.setX(point.x).setZ(point.z);
                    if (!reset) obj3d.pointControl.position.add(modifierPickPosDiff);
                    if (wallEndObject) currentPointObject.position.setX(obj3d.pointControl.position.x).setZ(obj3d.pointControl.position.z);
                } else if (modifier === 1) {
                    obj3d.pointControl.position.setX(point.x);
                    if (!reset) obj3d.pointControl.position.x += modifierPickPosDiff.x;
                    if (wallEndObject) currentPointObject.position.setX(obj3d.pointControl.position.x);
                } else if (modifier === 2) {
                    obj3d.pointControl.position.setZ(point.z);
                    if (!reset) obj3d.pointControl.position.z += modifierPickPosDiff.z;
                    if (wallEndObject) currentPointObject.position.setZ(obj3d.pointControl.position.z);
                }
                if (wallEndObject && currentPointObject.userData.connectedTo) currentPointObject.userData.connectedTo.position.copy(currentPointObject.position);
                if (currentPointObject.name === "hoverWall") {
                    if (modifier < 3) {
                        var diff = currentPointObject.position.clone().setY(0).sub(obj3d.pointControl.position.clone().setY(0));
                        currentPointObject.position.setX(obj3d.pointControl.position.x).setZ(obj3d.pointControl.position.z);
                        var resetPositions = false;
                        for (var i in wallEndMarkers) {
                            wallEndMarkers[i].userData.op = wallEndMarkers[i].position.clone();
                            wallEndMarkers[i].position.sub(diff);
                            if (innerWalls && !MathService.checkPointInPolygon(wallEndMarkers[i].position.clone(), ctrl.room.pts2d)) {
                                resetPositions = true;
                            }
                            if (wallEndMarkers[i].userData.connectedTo) wallEndMarkers[i].userData.connectedTo.position.sub(diff);
                        }

                        if (resetPositions) {
                            for (var i in wallEndMarkers) {
                                wallEndMarkers[i].position.copy(wallEndMarkers[i].userData.op);
                                if (wallEndMarkers[i].userData.connectedTo) wallEndMarkers[i].userData.connectedTo.position.copy(wallEndMarkers[i].position);
                            }
                            currentPointObject.position.setX(oldPointObjectPosition.x).setZ(oldPointObjectPosition.z);
                            obj3d.pointControl.position.setX(oldPointObjectPosition.x).setZ(oldPointObjectPosition.z);
                        }

                    } else {
                        for (var wem in wallEndMarkers) {
                            wallEndMarkers[wem].userData.op = wallEndMarkers[wem].position.clone();
                        }
                        var wallLine = new THREE.Line3(wallEndMarkers[0].position.clone().setY(0), wallEndMarkers[1].position.clone().setY(0));
                        var midPos = wallLine.getCenter();
                        var p = wallLine.closestPointToPoint(point.clone().setY(0));
                        var p0 = p.clone();
                        var inv = matWorld.clone().getInverse(modifierObject.parent.matrixWorld);
                        p0.applyMatrix4(inv);
                        midPos.applyMatrix4(inv);
                        modifierObject.position.setX(p0.x).setZ(p0.z);
                        connectedObject.position.setX(p.x).setZ(p.z);
                        modifierObject.parent.parent.traverse(function (o) {
                            if (o.name === "posControls") {
                                o.position.setX(midPos.x).setZ(midPos.z);
                            }
                        });
                        if (connectedObject.userData.connectedTo) connectedObject.userData.connectedTo.position.setX(p.x).setZ(p.z);
                    }
                }
                updateConnectedWalls(innerWalls);
                RoomEditorService.redrawWalls(ctrl.room, innerWalls);
                var wallObjects = getWall3DObjects(innerWalls);
                GLMeasureService.measureObject(undefined, wallObjects, undefined, innerWalls ? handleInnerWallLengthInput : handleWallLengthInput);
                TrackingDivLabelFactory.setPointerEventsAllowedForAll(false);
                if (!innerWalls && ctrl.room.checkOuterWallsClosed()) {
                    RoomEditorService.buildDummyFloor(ctrl.room);
                }
            };

            /**
             * @description handler function for mouse move events of inner wall point mode tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pointModeInnerMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e);
                if (ctrl.config.subtools.modifierSelected) {
                    var i = WebGLService.getPositionPickplane(e);
                    if (i.length > 0) {
                        updateWallsMouseMove(i[0].point.clone(), false, i[0].object.matrixWorld, true);
                    }
                } else {
                    var i = WebGLService.pickObjects(e, [obj3d.pointLayer, WebGLService.getHoverObject()]);
                    if (i.length > 0) {
                        WebGLService.markObject(i[0].object);
                    } else {
                        WebGLService.unmarkObject();
                    }
                }
            };

            /**
             * @description helper function for mouse up events of point mode tool
             * @param {MouseEvent} ev the mouse event to use
             * @param {boolean} innerWalls true for inner walls, false for outer walls
             */
            var helperPointModeMouseUpHandler = function (ev, innerWalls) {
                var wallObjects = null;
                if (ctrl.config.subtools.modifierSelected) {
                    updateConnectedObjectsOnWall(currentPointObject, innerWalls);
                    ctrl.config.subtools.modifierSelected = false;
                    oldMod = modifier === 3 ? -3 : modifier;
                    modifier = null;
                    return;
                }
                var i = WebGLService.pickObjects(ev, [obj3d.pointLayer, WebGLService.getHoverObject()]);
                if (i.length > 0) {
                    if (i[0].object.name === "wallEndPoint") {
                        handleSelectPointObject(i[0].object);
                        connectedWalls = [];
                        setConnectedWalls(currentPointObject, innerWalls);
                        if (!obj3d.pointControl || obj3d.pointControl.name !== "pointControls") obj3d.pointControl = Object3DFactory.buildPointControls("point", ctrl.room.size.y);
                        obj3d.pointControl.position.copy(currentPointObject.position);
                        if (!obj3d.pointControl.parent) obj3d.pointLayer.add(obj3d.pointControl);
                        wallObjects = getWall3DObjects(innerWalls);
                        GLMeasureService.measureObject(undefined, wallObjects, undefined, innerWalls ? handleInnerWallLengthInput : handleWallLengthInput);
                    }
                    if (i[0].object.name === "hoverWall") {
                        if (currentPointObject != null) {
                            if (currentPointObject.name === "wallEndPoint" && i[0].point.clone().setY(0).distanceTo(currentPointObject.position.clone().setY(0)) < 0.3) return;
                            handleSelectPointObject(i[0].object);
                        } else {
                            handleSelectPointObject(i[0].object);
                        }
                        connectedWalls = [];
                        setConnectedWalls(currentPointObject, innerWalls);
                        if (obj3d.pointControl) obj3d.pointLayer.remove(obj3d.pointControl);
                        obj3d.pointControl = Object3DFactory.buildPointControls("wall", ctrl.room.size.y, currentPointObject);
                        obj3d.pointControl.position.copy(currentPointObject.position);
                        if (!obj3d.pointControl.parent) obj3d.pointLayer.add(obj3d.pointControl);
                        wallObjects = getWall3DObjects(innerWalls);
                        GLMeasureService.measureObject(undefined, wallObjects, undefined, innerWalls ? handleInnerWallLengthInput : handleWallLengthInput);
                    }
                } else {
                    handleSelectPointObject();
                    //TODO check if condition might be removed
                    if (!innerWalls) {
                        obj3d.pointLayer.remove(obj3d.pointControl);
                        obj3d.pointControl = null;
                    }
                }
            };

            /**
             * @description handler function for mouse up events of point mode for inner walls
             * @param e
             */
            var pointModeInnerMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                helperPointModeMouseUpHandler(e, true);
            };
            //endregion
            //region inner wall mouse handler
            /**
             * @description change handler for inner wall height input
             */
            ctrl.handleInnerWallHeightChange = function () {
                if (ctrl.tools.innerwall.h === undefined) {
                    ctrl.tools.innerwall.h = 1;
                }
            };

            /**
             * @description change handler for inner wall thickness input
             */
            ctrl.handleInnerWallThicknessChange = function () {
                if (ctrl.tools.innerwall.d === undefined) {
                    ctrl.tools.innerwall.d = 1;
                }
            };

            /**
             * @description change handler for inner wall position type changes
             */
            ctrl.handleInnerWallPositionChange = function () {
                updateInnerWallMaxHeight();
            };

            /**
             * @description change handler for inner wall position type changes of selected inner wall
             */
            ctrl.handleInnerWallPositionModify = function () {
                if (ctrl.modObject.checkCollide(ctrl.room)) {
                    modifyWallHeightForCurrentConfig();
                }
            };

            /**
             * @description change handler for inner wall height changes of selected inner wall
             */
            ctrl.handleInnerWallHeightModify = function () {
                if (ctrl.modObject.height === undefined) {
                    ctrl.modObject.height = 0.01;
                    return;
                }
                if (ctrl.modObject.checkCollide(ctrl.room)) {
                    modifyWallHeightForCurrentConfig();
                } else {
                    RoomEditorService.cleanWindowsDoorsInnerWalls(ctrl.room, ctrl.modObject);
                }
            };

            /**
             * @description function to show modal dialog for collision of inner walls
             */
            var modifyWallHeightForCurrentConfig = function () {

                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: $translate.instant('glyphicon glyphicon-warning-sign'),
                    messageText: $translate.instant('room.edit.dialog.messages.innerWallCollideMessage'),
                    showClose: false,
                    textButton1: $translate.instant('global.btn.yes'),
                    textButton0: $translate.instant('global.btn.no'),
                    iconButton1: 'glyphicon glyphicon-ok',
                    iconButton0: 'glyphicon glyphicon-remove',
                    classButton1: 'btn-add',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        if (!ctrl.room.hasRaisedFloor) ctrl.modObject.height = ctrl.room.size.y;
                        if (ctrl.modObject.posType === 1) ctrl.modObject.height = ctrl.room.size.y;
                        if (ctrl.modObject.posType === 3) ctrl.modObject.height = ctrl.room.size.y - ctrl.room.getRaisedFloor().size.y;
                        GenDialogService.hideDialog();
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            };

            /**
             * @description function to update inner wall max height value
             */
            var updateInnerWallMaxHeight = function () {
                if (ctrl.tools.innerwall.posDef === 1 || ctrl.tools.innerwall.posDef === 2) ctrl.tools.innerwall.maxHeight = ctrl.room.size.y;
                if (ctrl.tools.innerwall.posDef === 3) {
                    ctrl.tools.innerwall.maxHeight = ctrl.room.size.y - ctrl.room.getRaisedFloor().size.y;
                }
            };

            /**
             * @description handler function for mouse down events of inner wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var innerWallMouseDownHandler = function (e) {
                if (e.buttons === 2 || ctrl.modObject !== null) return;
                if (Tools.isDefinedNotNull(ctrl.config.tools.mouseDownPos)) ctrl.config.tools.mouseDownPos = null;
                var i = WebGLService.getPositionPickplane(e);
                if (i.length === 1) {
                    if (MathService.checkPointInPolygon(i[0].point, ctrl.room.pts2d)) {
                        ctrl.config.tools.mouseDownPos = NumberService.roundToPrecisionV3(obj3d.editorMarker.position.clone(), 3);
                    }
                }
            };

            /**
             * @description handler function for mouse up events of inner wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var innerWallMouseUpHandler = function (e) {
                if (ctrl.room.innerWalls === undefined) ctrl.room.innerWalls = [];
                if (obj3d.dummyObj === null || obj3d.dummyObj === undefined) {
                    ctrl.config.tools.mouseDownPos = null;
                    return;
                }

                NumberService.roundToPrecisionV3(obj3d.dummyObj.userData.s, 3);
                NumberService.roundToPrecisionV3(obj3d.dummyObj.userData.e, 3);

                if (MathService.compareVector3(obj3d.dummyObj.userData.s, obj3d.dummyObj.userData.e, 0.002)) return;

                var iw = new InnerWall(Entity.getNewIdFromArray(ctrl.room.innerWalls), '', '', '', '',
                    ctrl.tools.innerwall.type,
                    ctrl.tools.innerwall.posDef,
                    ctrl.tools.innerwall.d,
                    ctrl.tools.innerwall.h,
                    obj3d.dummyObj.userData.s.x,
                    obj3d.dummyObj.userData.s.y,
                    obj3d.dummyObj.userData.s.z,
                    obj3d.dummyObj.userData.e.x,
                    obj3d.dummyObj.userData.e.y,
                    obj3d.dummyObj.userData.e.z,
                    -1
                );
                ctrl.room.innerWalls.push(iw);
                obj3d.dummyObj = null;
                ctrl.config.tools.mouseDownPos = null;
            };

            /**
             * @description handler function for mouse move events of inner wall tool
             * @param {MouseEvent} e the mouse event to use
             */
            var innerWallMouseMoveHandler = function (e) {
                stdMouseMoveHandler(e);

                if (!MathService.checkPointInPolygon(obj3d.editorMarker.position, ctrl.room.pts2d)) {
                    return;
                }

                if (ctrl.config.tools.mouseDownPos === null) {
                    var i = WebGLService.pickObjects(e, WebGLService.getHoverObject());
                    if (i.length > 0) {
                        WebGLService.markObject(i[0].object, undefined, false);
                        return;
                    } else if ((WebGLService.getMarkedObject() !== null && WebGLService.getPersistentMarkedObject() === null) || (WebGLService.getMarkedObject() && WebGLService.getMarkedObject().uuid !== WebGLService.getPersistentMarkedObject().uuid)) {
                        WebGLService.unmarkObject(WebGLService.getMarkedObject(), false);
                    }
                    return;
                }

                if (obj3d.dummyObj !== null) {
                    WebGLService.remove3DObjectFromDummyObj(obj3d.dummyObj);
                    WebGLService.remove3DObjectFromLineObj(obj3d.dummyObj.userData.outline);
                    WebGLService.remove3DObjectFromHoverObj(obj3d.dummyObj.userData.hoverObj);
                }
                var objs = Object3DFactory.buildWall(NumberService.roundToPrecisionV3(ctrl.config.tools.mouseDownPos, 3),
                    NumberService.roundToPrecisionV3(obj3d.editorMarker.position, 3), ctrl.tools.innerwall.h,
                    ctrl.tools.innerwall.d, "flat", true, undefined, ctrl.tools.innerwall.type);
                obj3d.dummyObj = objs[0];
                if (ctrl.tools.innerwall.type) objs[1].material.color.setHex(Object3DFactory.colors.flatWall.outlineCage);
                WebGLService.add3DObjectToDummyObj(objs[0]);
                WebGLService.add3DObjectToLineObj(objs[1]);
                WebGLService.add3DObjectToHoverObj(objs[2]);
            };

            /**
             * @description handler function for mouse double click of inner wall tool (select inner wall)
             * @param {MouseEvent} e the mouse event to use
             */
            var innerWallMouseDblClickHandler = function (e) {
                var markedObj = WebGLService.getMarkedObject();
                var persistentMarkedObject = WebGLService.getPersistentMarkedObject();
                var i = WebGLService.pickObjects(e, WebGLService.getHoverObject());
                if ((markedObj || persistentMarkedObject) && i.length > 0 && ((markedObj && i[0].object.uuid === markedObj.uuid) || (persistentMarkedObject && i[0].object.uuid === persistentMarkedObject.uuid))) {

                    var two = i[0].object.userData.parent;
                    for (var iw = 0; iw < ctrl.room.innerWalls.length; iw++) {
                        var tw = ctrl.room.innerWalls[iw];
                        if (tw.start.x === two.userData.s.x && tw.start.y === two.userData.s.y && tw.start.z === two.userData.s.z &&
                            tw.end.x === two.userData.e.x && tw.start.y === two.userData.e.y && tw.end.z === two.userData.e.z) {
                            ctrl.modObject = tw;
                            WebGLService.unmarkObject(undefined, true);
                            WebGLService.unmarkObject(undefined, false);
                            WebGLService.markObject(markedObj, undefined, true);
                            $scope.$apply();
                            break;
                        }
                    }
                } else {
                    WebGLService.unmarkObject(undefined, true);
                    WebGLService.unmarkObject(undefined, false);
                    ctrl.modObject = null;
                }
            };

            /**
             * @description change handler for inner wall position type changes
             */
            ctrl.handleInnerWallPosTypeChange = function () {
                var val = 0;
                for (var i in ctrl.tools.innerwall.posDef) {
                    val = val | ctrl.tools.innerwall.posDef[i];
                }
                ctrl.tools.innerwall.pos = val;
            };
            //endregion
            //door/window
            var doorwindowWall = null;

            /**
             * @description function to go to normal room view
             */
            ctrl.jumpToRoomView = function () {
                WebGLService.cleanDummy();
                WebGLService.cleanLineObj();
                obj3d.room.parent.remove(obj3d.room);
                obj3d.room = RoomEditorService.genRoom(ctrl.room, "full");
                ctrl.config.tools.substate = 0;
                WebGLService.unmarkObject();
            };

            /**
             * @description helper function to handle selection of doors/windows
             * @param {MouseEvent} e the mouse event to use
             */
            var helperDoorWindowWallSelect = function (e) {
                var i = WebGLService.pickObjectsName(e, obj3d.room, ['wall', 'lattice', 'door', 'window']);
                if (i.length > 0) {
                    if (i[0].object.name.indexOf('wall') !== -1 || i[0].object.name.indexOf('lattice') !== -1) {
                        WebGLService.markObject(i[0].object);
                    } else {
                        var tp = i[0].object.name.indexOf('door') !== -1 ? i[0].object.userData.obb.c.clone().setY(0) : i[0].object.parent.userData.obb.c.clone().setY(0);
                        var objToMark = null;
                        obj3d.room.traverse(function (o) {
                            if (o instanceof THREE.Mesh && o.name.indexOf('wall') !== -1) {
                                var line = new THREE.Line3(o.userData.s.clone().setY(0), o.userData.e.clone().setY(0));
                                var tpp = line.closestPointToPoint(tp, true);
                                if (tpp.sub(tp).length() < 0.0005) objToMark = o;
                            }
                        });
                        if (objToMark !== null) WebGLService.markObject(objToMark);
                    }
                } else {
                    WebGLService.unmarkObject(WebGLService.getMarkedObject());
                }
            };

            /**
             * @description helper function to set position for currently active object from currently active 3d-object
             */
            var helperDoorWindowPositionCopy = function () {
                if (obj3d.selectedObj !== null) {
                    var obj = ctrl.room.roomObjs.filter(function (elem) {
                        return elem.id == obj3d.selectedObj.userData.id;
                    })[0];
                    if (obj !== undefined) {
                        obj.pos.x = obj3d.selectedObj.position.x;
                        obj.pos.y = obj3d.selectedObj.position.y;
                        obj.pos.z = obj3d.selectedObj.position.z;
                    }
                    obj3d.selectedObjGrabPos = null;
                }
            };

            /**
             * @description helper function for wall selection (1st step in window/door tool)
             * @param {THREE.Object3D} wallObj the selected wall 3d-object
             */
            var helperDoorWindowWallSelection = function (wallObj) {
                wallObjects = [];
                obj3d.room.traverse(function (obj) {
                    if (obj.name.indexOf('wall') !== -1) obj.visible = false;
                    if (obj.name === "door" || obj.name === "window_obj") {
                        if (!Room.checkPointOnWall(wallObj.userData.s, wallObj.userData.e, obj.userData.obb.c.clone().setY(0))) {
                            obj.visible = false;
                        } else {
                            wallObjects.push(obj);
                        }
                    }
                });
                // setup dummy obj
                var dummy = Object3DFactory.buildWall(wallObj.userData.s, wallObj.userData.e, wallObj.userData.h, wallObj.userData.d, 'full', true, "dummy");
                if (wallObj.userData.type == 1 && ctrl.room.hasRaisedFloor) {
                    dummy[0].position.y += ctrl.room.getRaisedFloor().size.y;
                    dummy[1].position.y += ctrl.room.getRaisedFloor().size.y;
                }
                obj3d.dummyObj = dummy[0];
                WebGLService.add3DObjectToDummyObj(dummy[0]);
                WebGLService.add3DObjectToLineObj(dummy[1]);
                ctrl.config.tools.substate = 1;
                // setup cam
                doorwindowWall = wallObj;
                WebGLService.setupCameraForObject(wallObj);
                WebGLService.disableControlsRotate();
            };

            //region door mouse handler
            /**
             * @description handler function for mouse down events of door tool
             * @param {MouseEvent} e the mouse event to use
             */
            var doorMouseDownHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                ctrl.config.mouseDown = true;
                if (ctrl.config.tools.substate === 0) return;
                if (ctrl.config.tools.substate === 1) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, "door");
                    if (i.length > 0) {
                        var marked = WebGLService.getMarkedObject();
                        if (marked !== null) {
                            var selectedDoor = ctrl.room.roomObjs.filter(function (elem) {
                                return i[0].object.userData.id == elem.id;
                            })[0];
                            if (i[0].object.uuid != marked.uuid) {
                                WebGLService.markObject(i[0].object);
                                ctrl.tools.door.w = selectedDoor.size.x;
                                ctrl.tools.door.h = selectedDoor.size.y;
                                $scope.$apply();
                            }
                            obj3d.selectedObj = i[0].object;
                            obj3d.selectedObjData = selectedDoor;
                            GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                        }
                    } else {
                        if (ctrl.tools.door.w === undefined || ctrl.tools.door.h === undefined) {
                            $translate(['global.dialog.head.warning', 'global.messages.validate.doorHighWidth.message', 'global.btn.close']).then(function (trans) {
                                GenDialogService.showDialog(false, {
                                    headText: trans['global.dialog.head.warning'],
                                    headIcon: 'glyphicon glyphicon-warning-sign',
                                    messageText: trans['global.messages.validate.doorHighWidth.message'],
                                    showClose: false,
                                    textButton0: trans['global.btn.close'],
                                    iconButton1: 'glyphicon glyphicon-remove-circle',
                                    iconButton0: 'glyphicon glyphicon-remove',
                                    classButton1: 'btn-danger',
                                    classButton0: 'btn-default',
                                    callbackButton0: function () {
                                        GenDialogService.hideDialog();
                                    }
                                });
                            });
                        } else {
                            i = WebGLService.pickObjectsName(e, WebGLService.getDummyObject(), "dummy");
                            if (i.length > 0) {
                                var testOBB = new OBB(undefined, new THREE.Vector3(ctrl.tools.door.w / 2, ctrl.tools.door.h / 2, obj3d.dummyObj.userData.d), new THREE.Vector3(0, obj3d.dummyObj.rotation.y, 0));
                                var p = i[0].point;
                                var ds = p.clone().setY(0).sub(obj3d.dummyObj.userData.s).length();
                                var de = p.clone().setY(0).sub(obj3d.dummyObj.userData.e).length();
                                var wallDir = obj3d.dummyObj.userData.e.clone().sub(obj3d.dummyObj.userData.s).normalize();
                                if (ds + de < ctrl.tools.door.w || ctrl.tools.door.h > obj3d.dummyObj.userData.h) {
                                    RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                                    ctrl.config.mouseDown = false;
                                    return;
                                }
                                var dummyInsertPoint = p.setY(ctrl.tools.door.h / 2);
                                if (doorwindowWall.userData.type && doorwindowWall.userData.type == 1 && ctrl.room.hasRaisedFloor) dummyInsertPoint.y += ctrl.room.getRaisedFloor().size.y;
                                if (ds < ctrl.tools.door.w / 2) {
                                    dummyInsertPoint = obj3d.dummyObj.userData.s.clone().add(wallDir.clone().multiplyScalar(ctrl.tools.door.w / 2));
                                    dummyInsertPoint.setY(ctrl.tools.door.h / 2);
                                    if (doorwindowWall.userData.type && doorwindowWall.userData.type == 1 && ctrl.room.hasRaisedFloor) dummyInsertPoint.y += ctrl.room.getRaisedFloor().size.y;
                                }
                                if (de < ctrl.tools.door.w / 2) {
                                    dummyInsertPoint = obj3d.dummyObj.userData.e.clone().add(wallDir.clone().multiplyScalar(ctrl.tools.door.w / -2));
                                    dummyInsertPoint.setY(ctrl.tools.door.h / 2);
                                    if (doorwindowWall.userData.type && doorwindowWall.userData.type == 1 && ctrl.room.hasRaisedFloor) dummyInsertPoint.y += ctrl.room.getRaisedFloor().size.y;
                                }
                                testOBB.c = dummyInsertPoint;
                                if (WebGLService.checkMultiObjectIntersectionOBB(testOBB, wallObjects)) {
                                    RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                                    ctrl.config.mouseDown = false;

                                } else {
                                    var label = TrackingDivLabelFactory.hasAnyLabelFocus();
                                    if (label !== undefined) {
                                        $('#i' + label.e.id).trigger("change");
                                        return;
                                    }
                                    var d = new Door(Entity.getNewIdFromArray(ctrl.room.roomObjs), dummyInsertPoint, {
                                        x: ctrl.tools.door.w,
                                        y: ctrl.tools.door.h,
                                        z: 0.1
                                    }, {
                                        x: 0,
                                        y: MathService.radToDeg(obj3d.dummyObj.rotation.y) * -1,
                                        z: 0
                                    }, "", "", 0, undefined, ctrl.room.id);
                                    ctrl.room.roomObjs.push(d);
                                    var door = Object3DFactory.buildRoomObject(d, 'full');
                                    var newPos;
                                    var tempCopy3dObj = angular.copy(obj3d);
                                    if (ctrl.room.hasRaisedFloor) {
                                        for (var index = 0; index < tempCopy3dObj.room.children.length; index++) {
                                            var child = tempCopy3dObj.room.children[index];
                                            if (child.name === "raisedFloor") {
                                                newPos = child.userData.h;
                                                d.pos.setY(d.pos.y + newPos);
                                                door = Object3DFactory.buildRoomObject(d, 'full');
                                                break;
                                            }
                                        }
                                    }
                                    WebGLService.markObject(door);
                                    wallObjects.push(door);
                                    obj3d.room.add(door);
                                    obj3d.selectedObj = door;
                                    obj3d.selectedObjData = d;
                                    GLMeasureService.measureObject(d, door, doorwindowWall, wallObjects);
                                }
                            } else {
                                var label = TrackingDivLabelFactory.hasAnyLabelFocus();
                                if (label !== undefined) {
                                    $('#i' + label.e.id).trigger("change");

                                }
                            }
                        }
                    }
                }
            };

            /**
             * @description handler function for mouse up events for door tool
             * @param {MouseEvent} e the mouse event to use
             */
            var doorMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                ctrl.config.mouseDown = false;
                var o = WebGLService.getMarkedObject();
                if (ctrl.config.tools.substate === 0 && o !== null) {
                    if (o.name.indexOf('wall') !== -1) {
                        helperDoorWindowWallSelection(o);
                    }
                }
                if (ctrl.config.tools.substate === 1) {
                    helperDoorWindowPositionCopy();
                }
            };

            /**
             * @description handler function for mouse move events of door tool
             * @param {MouseEvent} e the mouse event to use
             */
            var doorMouseMoveHandler = function (e) {
                // stdMouseMoveHandler(e);
                //handle wall select
                if (ctrl.config.tools.substate === 0) {
                    helperDoorWindowWallSelect(e);
                }
                if (ctrl.config.tools.substate == 1 && ctrl.config.mouseDown) {
                    if (obj3d.selectedObj !== null && obj3d.selectedObj.name === "door") {
                        var i = WebGLService.pickObjectsName(e, obj3d.dummyObj, "dummy");
                        if (i.length > 0) {
                            var testOBB = obj3d.selectedObj.userData.obb.clone();
                            testOBB.c = i[0].point.setY(obj3d.selectedObj.position.y);
                            for (var i = 0; i < wallObjects.length; i++) {
                                if (wallObjects[i].userData.id == obj3d.selectedObj.userData.id) continue;
                                if (testOBB.isIntersectionBox(wallObjects[i].userData.obb)) return;
                            }
                            if (!obj3d.selectedObjData.checkWallCollide(obj3d.dummyObj.userData.s, obj3d.dummyObj.userData.e, obj3d.dummyObj.userData.h, testOBB.c)[0]) {
                                obj3d.selectedObj.position.copy(testOBB.c.clone());
                                obj3d.selectedObj.userData.obb.c.copy(testOBB.c.clone());
                                GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                            }
                        }
                    }
                }
            };

            /**
             * @description function to update current selected door 3d-object with size properties from inputs in sidebar
             * @param {object} v the object defining the properties to use
             */
            var handleDoorPropertyUpdate = function (v) {
                if (obj3d.selectedObjData instanceof Door) {
                    var tempCopy3dObj = angular.copy(obj3d);
                    var oldOffsetBottom = tempCopy3dObj.selectedObj.userData.obb.c.y - tempCopy3dObj.selectedObjData.size.y / 2;
                    tempCopy3dObj.selectedObjData.size.x = v.w;
                    tempCopy3dObj.selectedObjData.size.y = v.h;
                    tempCopy3dObj.selectedObj.scale.setX(v.w).setY(v.h);
                    tempCopy3dObj.selectedObj.userData.obb.e.setX(v.w / 2);
                    var newPos = null;
                    if (oldOffsetBottom < 0.0001 && v.h <= ctrl.room.size.y) {
                        newPos = obj3d.selectedObj.position.clone().setY(v.h / 2);
                    } else if (oldOffsetBottom > 0.0001 && (oldOffsetBottom + v.h) <= ctrl.room.size.y) {
                        newPos = obj3d.selectedObj.position.clone().setY(oldOffsetBottom + v.h / 2);
                    }
                    if (newPos !== null) {
                        tempCopy3dObj.selectedObj.position.copy(newPos);
                        tempCopy3dObj.selectedObj.userData.obb.c.copy(newPos);
                        tempCopy3dObj.selectedObj.userData.obb.e.setY(v.h / 2);
                        RoomEditorService.setEntityPropertyXYZFromVec(tempCopy3dObj.selectedObjData, "pos", newPos);
                    }
                    var wallCollideCheck = tempCopy3dObj.selectedObjData.checkWallCollide(tempCopy3dObj.dummyObj.userData.s, tempCopy3dObj.dummyObj.userData.e, tempCopy3dObj.dummyObj.userData.h, tempCopy3dObj.selectedObj.userData.obb.c);
                    var objectCollideCheck = WebGLService.checkMultiObjectIntersectionOBB(tempCopy3dObj.selectedObj.userData.obb.clone(), wallObjects.filter(function (o) {
                        return o.uuid != tempCopy3dObj.selectedObj.uuid;
                    }));
                    if (v.w > doorwindowWall.userData.w || (oldOffsetBottom + v.h) > doorwindowWall.userData.h || wallCollideCheck[0] || objectCollideCheck) {
                        document.querySelector("[ng-model='ctrl.tools.door.w']").blur();
                        document.querySelector("[ng-model='ctrl.tools.door.h']").blur();
                        RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                        ctrl.tools.door.w = obj3d.selectedObjData.size.x;
                        ctrl.tools.door.h = obj3d.selectedObjData.size.y;
                    } else {
                        obj3d.selectedObjData.size.x = v.w;
                        obj3d.selectedObjData.size.y = v.h;
                        obj3d.selectedObjData.pos.y = v.h / 2;
                        obj3d.selectedObj.scale.setX(v.w).setY(v.h);
                        obj3d.selectedObj.userData.obb.e.setX(v.w / 2);
                        if (newPos !== null) {
                            obj3d.selectedObj.position.copy(newPos);
                            obj3d.selectedObj.userData.obb.c.copy(newPos);
                            obj3d.selectedObj.userData.obb.e.setY(v.h / 2);
                            RoomEditorService.setEntityPropertyXYZFromVec(tempCopy3dObj.selectedObjData, "pos", newPos);
                        }
                    }
                    GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                }
            };

            /**
             * @description handler function for mouse down event of window tool
             * @param {MouseEvent} e the mouse event to use
             */
            var windowMouseDownHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                ctrl.config.mouseDown = true;
                if (ctrl.config.tools.substate === 0) return;
                if (ctrl.config.tools.substate === 1) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, "window");
                    if (i.length > 0) {
                        var marked = WebGLService.getMarkedObject();
                        if (marked !== null) {
                            var selectedWindow = ctrl.room.roomObjs.filter(function (elem) {
                                return i[0].object.parent.userData.id == elem.id;
                            })[0];
                            if (i[0].object.parent.uuid !== marked.uuid) {
                                WebGLService.markObject(i[0].object.parent);
                                ctrl.tools.window.w = selectedWindow.size.x;
                                ctrl.tools.window.h = selectedWindow.size.y;
                                $scope.$apply();
                            }
                            obj3d.selectedObj = i[0].object.parent;
                            obj3d.selectedObjData = selectedWindow;
                            GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                        }
                    } else {
                        if (ctrl.tools.window.w === undefined || ctrl.tools.window.h === undefined) {
                            $translate(['global.dialog.head.warning', 'global.messages.validate.windowHighWidth.message', 'global.btn.close']).then(function (trans) {
                                GenDialogService.showDialog(false, {
                                    headText: trans['global.dialog.head.warning'],
                                    headIcon: 'glyphicon glyphicon-warning-sign',
                                    messageText: trans['global.messages.validate.windowHighWidth.message'],
                                    showClose: false,
                                    textButton0: trans['global.btn.close'],
                                    iconButton1: 'glyphicon glyphicon-remove-circle',
                                    iconButton0: 'glyphicon glyphicon-remove',
                                    classButton1: 'btn-danger',
                                    classButton0: 'btn-default',
                                    callbackButton0: function () {
                                        GenDialogService.hideDialog();
                                    }
                                });
                            });
                        } else {
                            i = WebGLService.pickObjectsName(e, WebGLService.getDummyObject(), "dummy");
                            if (i.length > 0) {
                                var testOBB = new OBB(undefined, new THREE.Vector3(ctrl.tools.window.w / 2, ctrl.tools.window.h / 2, obj3d.dummyObj.userData.d), new THREE.Vector3(0, obj3d.dummyObj.rotation.y, 0));
                                var p = i[0].point;
                                var ds = p.clone().setY(0).sub(obj3d.dummyObj.userData.s).length();
                                var de = p.clone().setY(0).sub(obj3d.dummyObj.userData.e).length();
                                var wallDir = obj3d.dummyObj.userData.e.clone().sub(obj3d.dummyObj.userData.s).normalize();
                                if (ds + de < ctrl.tools.window.w) {
                                    RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                                    ctrl.config.mouseDown = false;
                                    return;
                                }
                                var dummyInsertPoint = p;
                                if (ds < ctrl.tools.window.w / 2) {
                                    dummyInsertPoint = obj3d.dummyObj.userData.s.clone().add(wallDir.clone().multiplyScalar(ctrl.tools.window.w / 2));
                                }
                                if (de < ctrl.tools.window.w / 2) {
                                    dummyInsertPoint = obj3d.dummyObj.userData.e.clone().add(wallDir.clone().multiplyScalar(ctrl.tools.window.w / -2));
                                }
                                if (p.y + ctrl.tools.window.h / 2 > obj3d.dummyObj.userData.h) {
                                    dummyInsertPoint.setY(obj3d.dummyObj.userData.h - ctrl.tools.window.h / 2);
                                }
                                if (p.y - ctrl.tools.window.h / 2 < 0) {
                                    dummyInsertPoint.setY(ctrl.tools.window.h / 2);
                                }
                                testOBB.c = dummyInsertPoint;
                                if (WebGLService.checkMultiObjectIntersectionOBB(testOBB, wallObjects)) {
                                    RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                                    ctrl.config.mouseDown = false;

                                } else {
                                    var w = new Window(Entity.getNewIdFromArray(ctrl.room.roomObjs), testOBB.c, {
                                        x: ctrl.tools.window.w,
                                        y: ctrl.tools.window.h,
                                        z: 0.01
                                    }, {
                                        x: 0,
                                        y: MathService.radToDeg(obj3d.dummyObj.rotation.y) * -1,
                                        z: 0
                                    }, "", "", 1000, Entity.getNewLocaleUniqueId(), ctrl.room.id, true);
                                    ctrl.room.roomObjs.push(w);
                                    var window = Object3DFactory.buildRoomObject(w, 'full');
                                    WebGLService.markObject(window);
                                    wallObjects.push(window);
                                    obj3d.room.add(window);
                                    obj3d.selectedObj = window;
                                    obj3d.selectedObjData = w;
                                    GLMeasureService.measureObject(w, window, doorwindowWall, wallObjects);
                                }
                            }
                        }
                    }
                }
            };

            /**
             * @description handler function for mouse up events of window tool
             * @param {MouseEvent} e the mouse event to use
             */
            var windowMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                ctrl.config.mouseDown = false;
                var o = WebGLService.getMarkedObject();
                if (ctrl.config.tools.substate === 0 && o !== null) {
                    if (o.name.indexOf('wall') !== -1) {
                        helperDoorWindowWallSelection(o);
                    }
                }
                if (ctrl.config.tools.substate === 1) {
                    helperDoorWindowPositionCopy();
                }
            };

            /**
             * @description handler function for mouse move events of window tool
             * @param {MouseEvent} e the mouse event to use
             * @returns {boolean}
             */
            var windowMouseMoveHandler = function (e) {
                if (ctrl.config.tools.substate === 0) {
                    helperDoorWindowWallSelect(e);
                }
                if (ctrl.config.tools.substate == 1 && ctrl.config.mouseDown) {
                    if (obj3d.selectedObj !== null && obj3d.selectedObj.name == "window_obj") {
                        var i = WebGLService.pickObjectsName(e, obj3d.dummyObj, "dummy");
                        if (i.length > 0) {
                            var testOBB = obj3d.selectedObj.userData.obb.clone();
                            testOBB.c = i[0].point;
                            for (var i = 0; i < wallObjects.length; i++) {
                                if (wallObjects[i].userData.id == obj3d.selectedObj.userData.id) continue;
                                if (testOBB.isIntersectionBox(wallObjects[i].userData.obb)) {
                                    return false;
                                }
                            }
                            var collideCheck = obj3d.selectedObjData.checkWallCollide(obj3d.dummyObj.userData.s, obj3d.dummyObj.userData.e, obj3d.dummyObj.userData.h, testOBB.c);
                            if (!collideCheck[0]) {
                                obj3d.selectedObj.position.copy(testOBB.c.clone());
                                obj3d.selectedObj.userData.obb.c.copy(testOBB.c.clone());
                                GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                            }
                        }
                    }
                }
            };

            /**
             * @description function to update current selected window 3d-object with size properties from inputs in sidebar
             * @param {object} v the object defining the properties to use
             */
            var handleWindowPropertyUpdate = function (v) {
                if (obj3d.selectedObjData instanceof Window) {
                    var tempCopy3dObj = angular.copy(obj3d);
                    tempCopy3dObj.selectedObjData.size.x = v.w;
                    tempCopy3dObj.selectedObjData.size.y = v.h;
                    tempCopy3dObj.selectedObj.userData.obb.e.setX(v.w / 2).setY(v.h / 2);
                    var wallCollideCheck = tempCopy3dObj.selectedObjData.checkWallCollide(tempCopy3dObj.dummyObj.userData.s, tempCopy3dObj.dummyObj.userData.e, tempCopy3dObj.dummyObj.userData.h, tempCopy3dObj.selectedObj.userData.obb.c);
                    var objectCollideCheck = WebGLService.checkMultiObjectIntersectionOBB(tempCopy3dObj.selectedObj.userData.obb.clone(), wallObjects.filter(function (o) {
                        return o.uuid != tempCopy3dObj.selectedObj.uuid;
                    }));
                    if (v.w > doorwindowWall.userData.w || v.h > doorwindowWall.userData.h || wallCollideCheck[0] || objectCollideCheck) {
                        document.querySelector("[ng-model='ctrl.tools.window.w']").blur();
                        document.querySelector("[ng-model='ctrl.tools.window.h']").blur();
                        RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                        ctrl.tools.window.w = obj3d.selectedObjData.size.x;
                        ctrl.tools.window.h = obj3d.selectedObjData.size.y;
                        GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);

                    } else {
                        obj3d.selectedObjData.size.x = v.w;
                        obj3d.selectedObjData.size.y = v.h;
                        obj3d.selectedObj.userData.obb.e.setX(v.w / 2).setY(v.h / 2);
                        wallObjects.splice(wallObjects.indexOf(obj3d.selectedObj), 1);
                        var parent = obj3d.selectedObj.parent;
                        if (parent !== null) {
                            obj3d.selectedObj.parent.remove(obj3d.selectedObj);
                            obj3d.selectedObj = Object3DFactory.buildRoomObject(obj3d.selectedObjData, 'full');
                            parent.add(obj3d.selectedObj);
                        }
                        wallObjects.push(obj3d.selectedObj);
                        GLMeasureService.measureObject(obj3d.selectedObjData, obj3d.selectedObj, doorwindowWall, wallObjects);
                    }
                }
            };

            // region pillar
            /**
             * @description handler function for mouse down events of pillar tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pillarMouseDownHandler = function (e) {
                if (e.originalEvent.button != 0) return;
                var i = WebGLService.pickObjectsName(e, obj3d.room, "pillar");
                if (i.length > 0) {//pick present pillar
                    var marked = WebGLService.getMarkedObject();
                    if (marked !== null) {
                        var selectedPillar = ctrl.room.roomObjs.filter(function (elem) {
                            return i[0].object.userData.id == elem.id;
                        })[0];
                        if (i[0].object.uuid != marked.uuid) {
                            WebGLService.markObject(i[0].object);
                            ctrl.tools.pillar.w = selectedPillar.size.x;
                            ctrl.tools.pillar.d = selectedPillar.size.z;
                            ctrl.tools.pillar.rot = selectedPillar.rot.y;
                            $scope.$apply();
                        }
                        ctrl.config.mouseDown = true;
                        obj3d.selectedObj = i[0].object;
                        ctrl.config.modObject = selectedPillar;
                    }
                } else {
                    i = WebGLService.getPositionPickplane(e);
                    if (i.length > 0) {
                        var testOBB = new OBB(i[0].point.setY(ctrl.room.size.y / 2), new THREE.Vector3(ctrl.tools.pillar.w / 2, ctrl.room.size.y / 2, ctrl.tools.pillar.d / 2), new THREE.Vector3(0, ctrl.tools.pillar.rot, 0));
                        var collide = false;
                        if (ctrl.room.pts2d.length === 0) {
                            ctrl.room.pts2d = ctrl.room.getCornersFromOuterWalls(2);
                        }
                        if (!MathService.checkPointInPolygon(testOBB.c, ctrl.room.pts2d)) return;
                        obj3d.room.traverse(function (o) {
                            if (o instanceof THREE.Mesh && (o.name.indexOf("wall") !== -1 || o.name === "pillar")) {
                                if (o.userData.obb.isIntersectionBox(testOBB)) collide = true;
                            }
                        });
                        if (collide) {
                            RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                        } else {
                            var p = new Pillar(Entity.getNewIdFromArray(ctrl.room.roomObjs)
                                , testOBB.c.clone()
                                , testOBB.e.clone().multiplyScalar(2)
                                , new THREE.Vector3(0, ctrl.tools.pillar.rot, 0)
                                , ""
                                , ""
                                , 2000
                                , undefined
                                , ctrl.room.id);
                            ctrl.room.roomObjs.push(p);
                            var pillar = Object3DFactory.buildRoomObject(p, "full");
                            WebGLService.markObject(pillar);
                            obj3d.room.add(pillar);
                            obj3d.selectedObj = pillar;
                            ctrl.config.modObject = p;
                            GLMeasureService.clearMeasure();
                            GLMeasureService.measureObject(p, pillar, obj3d.room, ctrl.room, ctrl.room);
                        }
                    }
                }
            };

            /**
             * @description handler function for mouse up events of pillar tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pillarMouseUpHandler = function (e) {
                if (e.originalEvent.button !== 0) return;
                ctrl.config.mouseDown = false;
            };

            /**
             * @description handler function for mouse move events of pillar tool
             * @param {MouseEvent} e the mouse event to use
             */
            var pillarMouseMoveHandler = function (e) {
                // stdMouseMoveHandler(e);
                if (obj3d.selectedObj === null || !ctrl.config.mouseDown) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, "pillar");
                    if (i.length > 0) {
                        WebGLService.markObject(i[0].object);
                    }
                } else {
                    var i = WebGLService.getPositionPickplane(e);
                    if (i.length > 0) {
                        var testOBB = obj3d.selectedObj.userData.obb.clone();
                        testOBB.c.setX(i[0].point.x).setZ(i[0].point.z);
                        var collide = false;
                        obj3d.room.traverse(function (o) {
                            if (o instanceof THREE.Mesh && (o.name.indexOf("wall") !== -1 || (o.name === "pillar" && o.uuid !== obj3d.selectedObj.uuid))) {
                                if (o.userData.obb.isIntersectionBox(testOBB)) collide = true;
                            }
                        });
                        if (collide || !MathService.checkPointInPolygon(testOBB.c, ctrl.room.pts2d)) {

                        } else {
                            obj3d.selectedObj.position.copy(testOBB.c.clone());
                            obj3d.selectedObj.userData.obb.c.copy(testOBB.c.clone());
                            ctrl.config.modObject.pos = obj3d.selectedObj.position.clone();
                            GLMeasureService.measureObject(ctrl.config.modObject, obj3d.selectedObj, obj3d.room, ctrl.room, ctrl.room);
                        }
                    }
                }
            };

            /**
             * @description function to handle property updates for current pillar object
             * @param {object} v the object defining the new object properties
             */
            var handlePillarPropertyUpdate = function (v) {
                if (ctrl.config.modObject instanceof Pillar) {
                    var tempCopy3dObj = angular.copy(obj3d);
                    var tempCopyModObject = angular.copy(ctrl.config.modObject);

                    tempCopyModObject.size.x = v.w;
                    tempCopyModObject.size.z = v.d;
                    tempCopyModObject.rot.y = v.rot;
                    tempCopy3dObj.selectedObj.scale.setX(v.w).setZ(v.d);
                    tempCopy3dObj.selectedObj.rotation.y = -MathService.degToRad(v.rot);
                    tempCopy3dObj.selectedObj.userData.obb.e.setX(v.w / 2).setZ(v.d / 2);
                    tempCopy3dObj.selectedObj.userData.obb.rotateY(obj3d.selectedObj.rotation.y);

                    var objectCollideCheck = WebGLService.checkMultiObjectIntersectionOBB(tempCopy3dObj.selectedObj.userData.obb.clone()
                        , tempCopy3dObj.room.children.filter(function (obj) {
                            return obj.uuid !== tempCopy3dObj.selectedObj.uuid && !angular.equals(obj.userData, {});
                        }));
                    if (objectCollideCheck) {
                        document.querySelector("[ng-model='ctrl.tools.pillar.w']").blur();
                        document.querySelector("[ng-model='ctrl.tools.pillar.d']").blur();
                        document.querySelector("[ng-model='ctrl.tools.pillar.rot']").blur();
                        RoomEditorService.showDoorWindowNotEnoughSpaceDialog();
                        ctrl.tools.pillar.w = ctrl.config.modObject.size.x;
                        ctrl.tools.pillar.d = ctrl.config.modObject.size.z;
                        ctrl.tools.pillar.rot = ctrl.config.modObject.rot.y;
                        GLMeasureService.measureObject(ctrl.config.modObject, obj3d.selectedObj, obj3d.room, obj3d.room.children, ctrl.room);
                        return;
                    } else {
                        ctrl.config.modObject.size.x = v.w;
                        ctrl.config.modObject.size.z = v.d;
                        ctrl.config.modObject.rot.y = v.rot;
                        obj3d.selectedObj.scale.setX(v.w).setZ(v.d);
                        obj3d.selectedObj.rotation.y = -MathService.degToRad(v.rot);
                        obj3d.selectedObj.userData.obb.e.setX(v.w / 2).setZ(v.d / 2);
                        obj3d.selectedObj.userData.obb.rotateY(obj3d.selectedObj.rotation.y);
                    }
                    GLMeasureService.measureObject(ctrl.config.modObject, obj3d.selectedObj, obj3d.room, obj3d.room.children, ctrl.room);
                }
            };
            //endregion
            //region layout tool
            /**
             * @description handler function for mouse down events of layout tool
             * @param {MouseEvent} e the mouse event to use
             */
            var layoutMouseDownHandler = function (e) {
                if (e.buttons === 2) return;
                if (ctrl.tools.layout.imgSelected && !ctrl.tools.layout.scaled) {
                    var i = WebGLService.getPositionPickplane(e);
                    if (i.length > 0) {
                        ctrl.config.tools.mouseDownPos = i[0].point;
                        ctrl.config.substate = 6;
                    }
                }
                if (ctrl.tools.layout.scaled) {
                    var i = WebGLService.pickObjects(e, obj3d.layoutObj);
                    if (i.length > 0) {

                        if (i[0].object.name === "layoutplane") {
                            ctrl.config.substate = 1;
                            ctrl.config.tools.mouseDownPos = i[0].point;
                            ctrl.config.tools.orgPos = obj3d.layoutObj.position.clone();
                        }
                        if (i[0].object.parent.name.indexOf("move") !== -1) {
                            ctrl.config.substate = 2;
                            ctrl.config.tools.mouseDownPos = i[0].point;
                            // ctrl.config.tools.orgPos = i[0].object.parent.position.clone();
                            ctrl.config.tools.orgPos = obj3d.layoutObj.position.clone();
                        }
                        if (i[0].object.parent.name.indexOf("rotate") !== -1) {
                            ctrl.config.substate = 3;
                            ctrl.config.tools.mouseDownPos = i[0].point;
                            ctrl.config.tools.orgPos = obj3d.layoutObj.position.clone();
                        }

                    } else {
                        ctrl.config.substate = 0;
                    }
                }

            };

            /**
             * @description handler function for mouse up events of layout tool
             * @param {MouseEvent} e the mouse event to use
             */
            var layoutMouseUpHandler = function (e) {
                ctrl.config.substate = 0;
            };

            /**
             * @description handler function for mouse move events of layout tool
             * @param {MouseEvent} e the mouse event to use
             */
            var layoutMouseMoveHandler = function (e) {
                var i = WebGLService.getPositionPickplane(e);
                if (i.length > 0) {
                    if (ctrl.config.substate === 0) {
                        var ii = WebGLService.pickObjects(e, obj3d.layoutObj);
                        if (ii.length > 0) {
                            if (ii[0].object.name.indexOf("hover") !== -1) WebGLService.markObject(ii[0].object.parent);
                            if (ii[0].object.name === "layoutplane") WebGLService.unmarkObject();
                        } else {
                            WebGLService.unmarkObject();
                        }
                    }
                    // move layout free
                    if (ctrl.config.substate === 1) {
                        var diff = i[0].point.clone().sub(ctrl.config.tools.mouseDownPos);
                        obj3d.layoutObj.position.copy(ctrl.config.tools.orgPos.clone().add(diff.setY(ctrl.config.tools.orgPos.y)));
                    }
                    // move layout along axis
                    if (ctrl.config.substate === 2) {
                        var diff = i[0].point.clone().sub(ctrl.config.tools.mouseDownPos);
                        var obj = WebGLService.getMarkedObject();
                        if (obj.name.indexOf('x') !== -1) {
                            diff.setZ(0);
                            var dx = diff.x;
                            if (obj.name.indexOf("neg") !== -1) dx *= -1;
                            var pos = ctrl.config.tools.orgPos.clone().add(diff.setY(ctrl.config.tools.orgPos.y));
                            obj3d.layoutObj.position.setX(pos.x);
                            obj3d.layoutObj.position.setZ(pos.z);

                        }
                        if (obj.name.indexOf('z') !== -1) {
                            diff.setX(0);
                            var dz = diff.z;
                            if (obj.name.indexOf("neg") !== -1) dz *= -1;
                            var pos = ctrl.config.tools.orgPos.clone().add(diff.setY(ctrl.config.tools.orgPos.y));
                            obj3d.layoutObj.position.setX(pos.x);
                            obj3d.layoutObj.position.setZ(pos.z);
                        }
                    }
                    // rotate layout
                    if (ctrl.config.substate === 3) {
                        var curDir = i[0].point.clone().sub(obj3d.layoutObj.position).normalize();
                        var curAngle = new THREE.Vector3(1, 0, 0).angleTo(curDir);
                        if (curDir.z > 0) curAngle *= -1;
                        obj3d.layoutObj.traverse(function (o) {
                            if (o.name === "rotationControls") o.rotation.y = curAngle + Math.PI / 4;
                            if (o.name === "layoutplane") o.rotation.z = curAngle + Math.PI / 4;
                        });
                    }
                    if (ctrl.config.substate === 6) {
                        var pos = i[0].point;
                        var len = pos.clone().sub(ctrl.config.tools.mouseDownPos).length();
                        ctrl.tools.layout.measureScale = len;
                        if (obj3d.scaleMeasure !== null) obj3d.layoutObj.remove(obj3d.scaleMeasure);
                        obj3d.scaleMeasure = Object3DFactory.buildScaleMeasure(ctrl.config.tools.mouseDownPos, pos);
                        obj3d.layoutObj.add(obj3d.scaleMeasure);
                    }
                }
            };

            /**
             * @description change handler for layout scale input UI
             */
            ctrl.handleScaleInput = function () {
                if (ctrl.tools.layout.measureScale !== 0 && ctrl.tools.layout.scale && ctrl.tools.layout.scale !== 0) {
                    var scale = ctrl.tools.layout.scale / ctrl.tools.layout.measureScale;
                    obj3d.layoutObj.children[0].scale.set(scale, scale, 1);
                    ctrl.tools.layout.scaled = true;
                    WebGLService.removeObjectByNameFromObject("scaleMeasure", obj3d.layoutObj);
                    WebGLService.removeObjectByNameFromObject("layoutControls", obj3d.layoutObj);
                    obj3d.layoutObj.add(Object3DFactory.buildLayoutControls(obj3d.layoutObj.children[0].geometry.parameters.width * scale, obj3d.layoutObj.children[0].geometry.parameters.height * scale));
                } else {
                    Notify.error("global.notification.error.title", "room.notifications.invalidInputImgScale", 2000);
                }
            };

            /**
             * @description function to reset layout tool, removes image, scaling, position
             */
            ctrl.layoutResetImage = function () {
                ctrl.tools.layout.scaled = false;
                ctrl.tools.layout.imgSelected = false;
                ctrl.tools.layout.file = '';
                $('#layoutImage').val(null);
            };

            /**
             * @description function to reset scaling state for layout tool
             */
            ctrl.layoutResetScale = function () {
                ctrl.tools.layout.scaled = false;
                obj3d.layoutObj.children[0].scale.set(1, 1, 1);
            };

            /**
             * @description function to remove image for layout tool
             */
            ctrl.layoutRemoveImage = function () {
                obj3d.layoutObj.parent.remove(obj3d.layoutObj);
                obj3d.layoutObj = Object3DFactory.buildLayoutObject(ctrl.config.tools.gridSize, ctrl.config.tools.gridSize);
                obj3d.layoutObj.position.setY(-0.1);
                WebGLService.add3DObjectToScene(obj3d.layoutObj);
                ctrl.config.substate = 0;
                obj3d.layoutObj.children[1].visible = false;
                ctrl.tools.layout.file = '';
                $('#layoutImage').val(null);
                ctrl.tools.layout.scaled = ctrl.tools.layout.imgSelected = false;
            };

            /**
             * @description watcher for changes of layout file input, load image display it on plane
             */
            $scope.$watch("ctrl.tools.layout.file", function (n, o) {
                if (n !== o) {
                    FileReaderService.readImage(n, undefined, function (e) {
                            $('#previewImage').attr("src", e.target.result);
                            var img = new Image();
                            img.src = e.target.result;
                            img.onload = function () {
                                var canvas = document.createElement("canvas");
                                canvas.width = 2048;
                                canvas.height = 2048;
                                var scaleFac = Math.min((2048 / this.width), (2048 / this.height));
                                var offX = (canvas.width - this.width * scaleFac) / 2;
                                var offY = (canvas.height - this.height * scaleFac) / 2;
                                var x0 = this.width * scaleFac, y0 = this.height * scaleFac;
                                var cc = canvas.getContext("2d");
                                cc.translate(canvas.width / 2, canvas.height / 2);
                                cc.drawImage(this, 0, 0, this.width, this.height, offX - canvas.width / 2, offY - canvas.height / 2, x0, y0);
                                var tex = new THREE.Texture(canvas);
                                tex.needsUpdate = true;
                                obj3d.layoutObj.children[0].material.map = tex;
                                obj3d.layoutObj.children[0].material.needsUpdate = true;
                                //mod preview
                                var aspect = this.height / this.width;
                                $('#previewImage').attr("height", 100 * aspect);
                                ctrl.tools.layout.imgSelected = true;
                            };
                        },
                        function (e) {
                            RoomEditorService.showErrorLoadImageDialog();
                        });
                }
            });
            //endregion
            //region raised floor
            var tileInputLabel = null;

            /**
             * @description function to handle changes for raised floor properties
             */
            var handleRaisedFloorPropertyUpdate = function () {
                var rf = WebGLService.removeObjectByNameFromObject("raisedFloor", obj3d.room);
                if (rf) {
                    var heightChanged = ctrl.config.modObject.size.y !== ctrl.tools.raisedFloor.h;
                    var offsetChanged = ctrl.config.modObject.pos.x !== ctrl.tools.raisedFloor.ox || ctrl.config.modObject.pos.z !== ctrl.tools.raisedFloor.oz;

                    if (heightChanged) {
                        var objectsInFloor = RoomEditorService.findObjectsOnRemovedRaisedFloor(ctrl.room, ctrl.config.modObject);
                        for (var i in objectsInFloor) {
                            objectsInFloor[i].pos.y = objectsInFloor[i].size.y / 2 + ctrl.tools.raisedFloor.h;
                            obj3d.dummys.traverse(function (o) {
                                if (o instanceof THREE.Mesh && o.userData.hasOwnProperty("uid") && o.userData.uid === objectsInFloor[i].uniqueId) {
                                    o.position.y = objectsInFloor[i].pos.y;
                                }
                            });
                        }
                        if (Tools.isDefinedNotNull(obj3d.dummys)) {
                            obj3d.dummys.traverse(function (o) {
                                if (o instanceof THREE.Mesh && o.name === "dummyTile") {
                                    o.position.y = ctrl.tools.raisedFloor.h - o.geometry.parameters.height / 2 + 0.01;
                                }
                            });
                        }
                    }
                    if (offsetChanged) {
                        var moveVec = new THREE.Vector3(ctrl.tools.raisedFloor.ox - ctrl.config.modObject.pos.x, 0, ctrl.tools.raisedFloor.oz - ctrl.config.modObject.pos.z);
                        for (var i = 0; i < ctrl.room.floorTiles.length; i++) {
                            if (ctrl.room.floorTiles[i].aligned) {
                                if (!ctrl.room.floorTiles[i].checkCollision(undefined, obj3d.room, new THREE.Vector3(ctrl.room.floorTiles[i].pos.x + moveVec.x, 0, ctrl.room.floorTiles[i].pos.z + moveVec.z), true, ctrl.room)) {
                                    if (ctrl.room.floorTiles[i].ignoreNextMove) {
                                        ctrl.room.floorTiles[i].ignoreNextMove = false;
                                        continue;
                                    }
                                    ctrl.room.floorTiles[i].pos.x += moveVec.x;
                                    ctrl.room.floorTiles[i].pos.z += moveVec.z;
                                    ctrl.room.floorTiles[i].ignoreNextMove = false;
                                    obj3d.dummys.traverse(function (o) {
                                        if (o instanceof THREE.Mesh && o.name === "dummyTile" && o.userData.uid === ctrl.room.floorTiles[i].uniqueId) {
                                            o.position.add(moveVec);
                                        }
                                    });
                                } else {
                                    ctrl.room.floorTiles[i].ignoreNextMove = true;
                                }
                            }
                        }
                    }

                    ctrl.config.modObject.size.x = ctrl.tools.raisedFloor.w;
                    ctrl.config.modObject.size.y = ctrl.tools.raisedFloor.h;
                    ctrl.config.modObject.size.z = ctrl.tools.raisedFloor.d;
                    ctrl.config.modObject.pos.x = ctrl.tools.raisedFloor.ox;
                    ctrl.config.modObject.pos.z = ctrl.tools.raisedFloor.oz;
                    rf = Object3DFactory.buildRaisedFloor(ctrl.config.modObject, "full", ctrl.room);
                    obj3d.room.add(rf);
                    WebGLService.markObject(rf, undefined, true);
                    if (ctrl.room.usesRaisedFloorIdents) {
                        WebGLService.cleanDummy();
                        ctrl.handleToggleTileIdents();
                    }
                }
            };

            /**
             * @description function to toggle tile identity input
             */
            ctrl.handleToggleTileIdents = function () {
                if (ctrl.config.modObject instanceof RaisedFloor) {
                    if (ctrl.room.usesRaisedFloorIdents) {
                        obj3d.dummyObj = Object3DFactory.buildTileNameDummy(ctrl.config.modObject, ctrl.room);
                        WebGLService.cleanDummy();
                        WebGLService.add3DObjectToDummyObj(obj3d.dummyObj);
                        ctrl.tools.raisedFloor.nameInfo = Object3DFactory.buildTileNameObjects("x", ctrl.tools.raisedFloor.gridXIdentType, ctrl.tools.raisedFloor.gridZIdentType, ctrl.tools.raisedFloor.gridXIdentLowerCase, ctrl.tools.raisedFloor.gridZIdentLowerCase, ctrl.config.modObject, ctrl.room, obj3d.dummyObj, ctrl.tools.raisedFloor.nameInfo[0], ctrl.tools.raisedFloor.nameInfo[1]);
                        ctrl.tools.raisedFloor.nameInfo = Object3DFactory.buildTileNameObjects("z", ctrl.tools.raisedFloor.gridXIdentType, ctrl.tools.raisedFloor.gridZIdentType, ctrl.tools.raisedFloor.gridXIdentLowerCase, ctrl.tools.raisedFloor.gridZIdentLowerCase, ctrl.config.modObject, ctrl.room, obj3d.dummyObj, ctrl.tools.raisedFloor.nameInfo[0], ctrl.tools.raisedFloor.nameInfo[1]);
                    } else {
                        WebGLService.cleanDummy();
                    }
                }
            };

            /**
             * @description change handler for row tile identity types
             */
            ctrl.handleXIdentChange = function () {
                WebGLService.cleanObject(obj3d.dummyObj, "tileObjX");
                TrackingDivLabelFactory.clearAll();
                ctrl.tools.raisedFloor.nameInfo = Object3DFactory.buildTileNameObjects("x", ctrl.tools.raisedFloor.gridXIdentType, ctrl.tools.raisedFloor.gridZIdentType, ctrl.tools.raisedFloor.gridXIdentLowerCase, ctrl.tools.raisedFloor.gridZIdentLowerCase, ctrl.config.modObject, ctrl.room, obj3d.dummyObj);
            };

            /**
             * @description change handler for column tile identity types
             */
            ctrl.handleZIdentChange = function () {
                WebGLService.cleanObject(obj3d.dummyObj, 'tileObjZ');
                TrackingDivLabelFactory.clearAll();
                ctrl.tools.raisedFloor.nameInfo = Object3DFactory.buildTileNameObjects("z", ctrl.tools.raisedFloor.gridXIdentType, ctrl.tools.raisedFloor.gridZIdentType, ctrl.tools.raisedFloor.gridXIdentLowerCase, ctrl.tools.raisedFloor.gridZIdentLowerCase, ctrl.config.modObject, ctrl.room, obj3d.dummyObj);
            };
            var raisedFloorMouseDownHandler = function (e) {

            };

            /**
             * @description handler function for mouse move events of raised floor tool
             * @param {MouseEvent} e the mouse event to use
             */
            var raisedFloorMouseMoveHandler = function (e) {
                // stdMouseMoveHandler(e);
                var i = WebGLService.pickObjectsName(e, WebGLService.getDummyObject(), ["tile"]);
                if (i.length > 0) {
                    if (i[0].object.userData.custom) WebGLService.markObject(i[0].object);
                } else {
                    WebGLService.unmarkObject();
                }
            };

            /**
             * @description handler function for mouse up events of raised floor tool
             * @param MouseEvent} e the mouse event to use
             */
            var raisedFloorMouseUpHandler = function (e) {
                var i = WebGLService.pickObjectsName(e, WebGLService.getDummyObject(), ["tile"]);
                if (i.length > 0) {
                    if (tileInputLabel === null || tileInputLabel.getObj().uuid !== i[0].object.uuid) {
                        if (tileInputLabel !== null) TrackingDivLabelFactory.removeLabel(tileInputLabel.id);
                        tileInputLabel = TrackingDivLabelFactory.buildLabel(i[0].object, "input", "glContainer");
                        tileInputLabel.setValue(i[0].object.userData.tileId);
                        tileInputLabel.setOnChangeCallback(handleTileNameChange);
                        tileInputLabel.setOnInputCallback(handleTileNameInput);
                        tileInputLabel.setOnKeyDownCallback(handleTileNameKeyDown);
                        TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
                    }
                } else {
                    if (tileInputLabel !== null) {
                        TrackingDivLabelFactory.removeLabel(tileInputLabel.id);
                        tileInputLabel = null;
                    }
                }
            };

            /**
             * @description function to reverse the provided string
             * @param {string} str the string to reverse
             * @returns {*} returns reversed string
             */
            var reverseString = function (str) {
                return str.split("").reverse().join("");
            };

            /**
             * @description callback function for changes to tile row/col name label inputs
             */
            var handleTileNameChange = function () {
                var value = tileInputLabel.getValue();
                if (value !== "") {
                    var tile = tileInputLabel.getObj();
                    tile.userData.tileId = value;
                    if (tile.userData.tileNum[0] === "x") ctrl.tools.raisedFloor.nameInfo[0][tile.userData.tileNum.split("_")[1]] = value;
                    if (tile.userData.tileNum[0] === "z") ctrl.tools.raisedFloor.nameInfo[1][tile.userData.tileNum.split("_")[1]] = value;
                    tile.material.uniforms.letters.value = Object3DFactory.getLetterArray(tile.userData.tileNum[0] === "x" ? reverseString(value) : value);
                    tile.material.uniforms.letterCount.value = Object3DFactory.getLetterCount(value);
                    tile.material.needsUpdate = true;
                    tile.needsUpdate = true;
                }
                TrackingDivLabelFactory.removeLabel(tileInputLabel.id);
                tileInputLabel = null;
            };

            /**
             * @description function to handle input of tile row/col names
             */
            var handleTileNameInput = function () {
                var value = tileInputLabel.getValue();
                if (!RaisedFloor.checkLetter(value[value.length - 1]) || value.length > 5) {
                    value = value.substring(0, value.length - 1);
                    tileInputLabel.setValue(value);
                }
            };

            /**
             * @description handler function for key down events of tile name inputs
             * @param {Event} e the key down event to use
             */
            var handleTileNameKeyDown = function (e) {
                if (e.keyCode === 13) handleTileNameChange();
            };

            /**
             * @description function to handle removal of raised floor (reset y-position for assets etc)
             * @param {RaisedFloor} raisedFloor the raised floor object to remove
             * @param {function} callback callback function to remove raised floor object
             */
            var handleRaisedFloorRemove = function (raisedFloor, callback) {
                if (ctrl.room.floorTiles.length === 0 && ctrl.room.racks.length === 0 && ctrl.room.assets.length === 0 && ctrl.room.coolings.length === 0 && ctrl.room.ups.length === 0 && ctrl.room.innerWalls.length === 0 && ctrl.room.roomObjs.length === 0) {
                    callback();
                } else {
                    RoomEditorService.showRemoveRaisedFloorDialog(function () {
                        ctrl.room.floorTiles = [];
                        var objectsOnFloor = RoomEditorService.findObjectsOnRemovedRaisedFloor(ctrl.room, raisedFloor);
                        for (var i in objectsOnFloor) objectsOnFloor[i].pos.y = objectsOnFloor[i].size.y / 2;
                        for (var i = 0; i < ctrl.room.innerWalls.length; i++) {
                            if (ctrl.room.innerWalls[i].posType === 3) ctrl.room.innerWalls[i].posType = 1;
                        }
                        callback();
                    });
                }
            };

            /**
             * @description function to handle addition of raised floor to room (move racks etc up)
             * @param {RaisedFloor} raisedFloor the raised floor object to add to room
             * @param {function} callback callback function to run
             */
            var handleRaisedFloorAdd = function (raisedFloor, callback) {
                if (ctrl.room.assets.length === 0 && ctrl.room.coolings.length === 0 && ctrl.room.racks.length === 0 && ctrl.room.ups.length === 0 && ctrl.room.innerWalls.length === 0 && ctrl.room.roomObjs.length === 0) {
                    callback();
                } else {
                    RoomEditorService.showAddRaisedFloorDialog(function () {
                        var objectsInFloor = RoomEditorService.findObjectsInNewRaisedFloor(ctrl.room, raisedFloor);
                        for (var i in objectsInFloor) objectsInFloor[i].pos.y = objectsInFloor[i].size.y / 2 + raisedFloor.size.y;
                        callback();
                    });
                }
            };
            //TODO check for uselessness
            var handleRaisedFloorChange = function (raisedFloor, heightChanged, offsetChanged, callback) {
                if (ctrl.room.assets.length === 0 && ctrl.room.coolings.length === 0 && ctrl.room.racks.length === 0 && ctrl.room.ups.length === 0) {
                    callback();
                } else {
                    if (heightChanged) {
                        RoomEditorService.showChangeRaisedFloorHeightDialog(function () {
                            var objectsInFloor = RoomEditorService.findObjectsInNewRaisedFloor(ctrl.room, raisedFloor);
                            for (var i in objectsInFloor) objectsInFloor[i].pos.y = objectsInFloor[i].size.y / 2 + raisedFloor.size.y;
                            callback();
                        });
                    }
                    if (offsetChanged) {
                        RoomEditorService.showChangeRaisedFloorOffsetDialog(function () {
                            var objectsInFloor = RoomEditorService.findObjectsInNewRaisedFloor(ctrl.room, raisedFloor);
                            for (var i in objectsInFloor) objectsInFloor[i].pos.y = objectsInFloor[i].size.y / 2 + raisedFloor.size.y;
                            callback();
                        });
                    }
                }
            };

            /**
             * @description function to setup alignment type for current object (only use for floor tiles)
             */
            ctrl.handleAlignToGridChange = function () {
                ctrl.object.aligned = ctrl.config.alignToGrid;
            };

            //endregion

            /**
             * @description basic handler function for all mouse down events
             * @param {MouseEvent} e the mouse event to use
             * @param {boolean} snapOnlyToWallEnds true if snapping is active, otherwise false
             */
            var stdMouseMoveHandler = function (e, snapOnlyToWallEnds) {
                if (ctrl.saveInProcess) {
                    return;
                }
                var i = WebGLService.getPositionPickplane(e);
                if (i.length === 1) {
                    var p = new THREE.Vector3(i[0].point.x, obj3d.editorMarker.position.y, i[0].point.z);
                    //let cursor jump in discrete steps
                    if (ctrl.config.tools.alignCursor) {
                        var fac = 1 / ctrl.config.tools.cellSize;
                        p.x = Math.round(p.x * fac) / fac;
                        p.z = Math.round(p.z * fac) / fac;
                    }
                    if (ctrl.config.subtools.snapActive) {
                        snapToObject(p, snapOnlyToWallEnds);
                    }
                    //let cursor stay in grid bounds
                    if (p.x < ctrl.config.tools.gridSize / -2) p.x = ctrl.config.tools.gridSize / -2;
                    if (p.x > ctrl.config.tools.gridSize / 2) p.x = ctrl.config.tools.gridSize / 2;
                    if (p.z < ctrl.config.tools.gridSize / -2) p.z = ctrl.config.tools.gridSize / -2;
                    if (p.z > ctrl.config.tools.gridSize / 2) p.z = ctrl.config.tools.gridSize / 2;

                    obj3d.editorMarker.position.copy(p);
                    WebGLService.rotateObjectToCamera(obj3d.editorMarker);
                }
            };

            /**
             * @description function to handle object snapping
             * @param {THREE.Vector3} position the current position to use
             * @param {boolean} onlyWallEnds if set to true snapping will only work for wall ends, otherwise false
             */
            var snapToObject = function (position, onlyWallEnds) {
                var snapDistance = ctrl.config.tools.snapDistance || ctrl.config.tools.cellSize / 2;
                var line, cp, l, i;
                //TODO WHAT TO SNAP TO ?
                // BASIC WALL SNAP
                // outer wall
                var minDistance = Infinity;
                for (i = 0; ctrl.room.outerWalls && i < ctrl.room.outerWalls.length; i++) {
                    if (!onlyWallEnds) {
                        line = new THREE.Line3(ctrl.room.outerWalls[i].start, ctrl.room.outerWalls[i].end);
                        cp = line.closestPointToPoint(position, true);
                        l = cp.clone().sub(position).length();
                        if (l <= snapDistance) {
                            position.x = cp.x;
                            position.z = cp.z;
                        }
                        if (minDistance < l) {
                            minDistance = l;
                        }
                    } else {
                        var d = position.clone().sub(ctrl.room.outerWalls[i].start).length();
                        if (d <= snapDistance) {
                            position.x = ctrl.room.outerWalls[i].start.x;
                            position.z = ctrl.room.outerWalls[i].start.z;
                        }
                        d = position.clone().sub(ctrl.room.outerWalls[i].end).length();
                        if (d <= snapDistance) {
                            position.x = ctrl.room.outerWalls[i].end.x;
                            position.z = ctrl.room.outerWalls[i].end.z;
                        }
                    }
                }
                //inner wall
                if (ctrl.room.innerWalls === undefined) return;
                for (i = 0; i < ctrl.room.innerWalls.length; i++) {
                    line = new THREE.Line3(ctrl.room.innerWalls[i].start, ctrl.room.innerWalls[i].end);
                    cp = line.closestPointToPoint(position, true);
                    l = cp.clone().sub(position).length();
                    if (l <= snapDistance) {
                        position.x = cp.x;
                        position.z = cp.z;
                    }
                    if (minDistance < l) {
                        minDistance = l;
                    }
                }
            };
            //endregion
            //TODO move to init func
            MessageService.subscribe("showCollideMessage", function (msg, opts) {
                RoomEditorService.showCollideDialog(opts.yes_callback, opts.no_callback);
            });
            //endregion
            //init

            /**
             * @description function to handle UUID in url parameters, used to select an object directly after init
             */
            var handleUUIDInPath = function () {
                if ($stateParams.ruuid && $stateParams.ruuid > 0) {
                    var object = ctrl.room.findObjectByUniqueID($stateParams.ruuid);
                    ctrl.object = object;
                    obj3d.selectedObj = WebGLService.findObjectByUniqueId($stateParams.ruuid, obj3d.room);
                    obj3d.selectedObjData = object;
                    WebGLService.markObject(obj3d.selectedObj, undefined, true);
                    handleParameterSetup();
                    ctrl.config.allowDetails = true;
                } else {
                    RoomEditorService.updateURLForObject(null);
                    ctrl.config.allowDetails = !ctrl.config.edit;
                }
            };
            var initRoom = null;

            /**
             * @description function to initialize the 3D view
             */
            var initialize = function () {
                WebGLService.destroy();
                WebGLService.init('glContainer', {
                    widthPercent: 100,
                    heightPercent: 'adjust',
                    addPickplane: true,
                    antialias: ctrl.account.graphics.aa === "1",
                    pureService: false
                });
                if (ctrl.object === null) { // new room
                    ctrl.room = new Room();
                    ctrl.room.name = $translate.instant("room.overview.addRoom");
                    ctrl.room.locationId = $stateParams.id;
                    ctrl.room.buildingId = parseInt($stateParams.bid);
                    ctrl.room.size.y = 4.0;
                    initRoom = angular.copy(ctrl.room);
                    setupEmptyEditor();
                    WebGLService.setActiveCameraProps(new THREE.Vector3(ctrl.config.tools.gridSize * -1, 10, ctrl.config.tools.gridSize), new THREE.Vector3(0, 0, 0));
                    WebGLService.addControlListener("trackLabels", function () {
                        TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
                    });
                    ctrl.gl.visibilityOpts = RoomEditorService.getVisibilityOpts("roomedit");
                    ctrl.viewObject = ctrl.room;
                    ctrl.config.create = true;
                    ctrl.toggleSideBarState();
                    return;
                }
                if (ctrl.object instanceof Room && ctrl.config.edit) { // room in edit mode
                    ctrl.room = ctrl.object;
                    RoomEditorService.setRoomBuildingId(buildings, ctrl.room);
                    ctrl.room.raisedFloor = ctrl.room.hasRaisedFloor ? ctrl.room.getRaisedFloor() : null;
                    if (initRoom === null) initRoom = angular.copy(ctrl.room);
                    ctrl.config.editState = 1;
                    ctrl.config.sidebarActive = true;
                    ctrl.config.tools.validRoom = true;
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false); // TODO add User info
                    WebGLService.setActiveCameraProps(new THREE.Vector3((ctrl.room.bbox.max.x - ctrl.room.bbox.min.x) * -0.75, ctrl.room.size.y + 10, (ctrl.room.bbox.max.z - ctrl.room.bbox.min.z) * 0.75).add(ctrl.room.bbox.getCenter().setY(0)), ctrl.room.bbox.getCenter());
                    WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                    connectDragDropListener();
                    queryLiveData();
                    startLiveDataQuery();
                    tempHeatMap = heatmaps.filter(function (map) {
                        return map.physicalTypeId === 1;
                    })[0];
                    ctrl.gl.allowHeatMapButton = true;
                    ctrl.gl.visibilityOpts = RoomEditorService.getVisibilityOpts("roomview");
                    WebGLService.addControlListener("hidewalls", handleRoomRotation);
                    handleRoomRotation();
                    handleUUIDInPath();
                    ctrl.toggleSideBarState();
                    return;
                }
                if (ctrl.object instanceof Room && !ctrl.config.edit) { // room in view mode
                    ctrl.room = ctrl.object;
                    RoomEditorService.setRoomBuildingId(buildings, ctrl.room);
                    if (initRoom === null) initRoom = angular.copy(ctrl.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                    WebGLService.setActiveCameraProps(new THREE.Vector3((ctrl.room.bbox.max.x - ctrl.room.bbox.min.x) * -0.75, ctrl.room.size.y + 10, (ctrl.room.bbox.max.z - ctrl.room.bbox.min.z) * 0.75).add(ctrl.room.bbox.getCenter().setY(0)), ctrl.room.bbox.getCenter());
                    WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                    queryLiveData();
                    startLiveDataQuery();
                    tempHeatMap = heatmaps.filter(function (map) {
                        return map.physicalTypeId === 1;
                    })[0];
                    ctrl.gl.allowHeatMapButton = true;
                    ctrl.gl.visibilityOpts = RoomEditorService.getVisibilityOpts("roomview");
                    WebGLService.addControlListener("hidewalls", handleRoomRotation);
                    handleRoomRotation();
                    handleUUIDInPath();
                    ctrl.config.sidebarActive = ctrl.isEntityForDetail();
                    ctrl.toggleSideBarState();
                    return;
                }
                if (ctrl.object instanceof Rack && ctrl.config.edit) { // rack in edit mode
                    ctrl.room = room;
                    RoomEditorService.setRoomBuildingId(buildings, ctrl.room);
                    if (initRoom === null) initRoom = angular.copy(ctrl.room);
                    ctrl.rack = ctrl.room.findObjectByTypeAndID("rack", ctrl.object.id);
                    for (var i = 0; i < ctrl.rack.slots.length; i++) ctrl.rack.slots[i].computeHUPosition(ctrl.rack);
                    ctrl.object = ctrl.rack;
                    ctrl.config.editState = 1;
                    ctrl.config.sidebarActive = true;
                    obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                    Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                    WebGLService.add3DObjectToContent(obj3d.rack);
                    WebGLService.setActiveCameraProps(new THREE.Vector3(0, 0, 4), new THREE.Vector3(0, 0, 0));
                    WebGLService.bindMouseHandler(handleMouseDownStd, handleMouseMoveStd, handleMouseUpStd, handleDblClickStd);
                    connectDragDropListener();
                    queryLiveData();
                    startLiveDataQuery();
                    if ($stateParams.rauuid) {
                        var obj = ctrl.rack.findObjectByUniqueId(parseInt($stateParams.rauuid));
                        if (obj !== null) {
                            ctrl.object = obj;

                            var markSlotUID = null;
                            if (obj instanceof Slot) {
                                if (obj.type === 5) handleParameterSetup();
                                ctrl.config.allowDetails = true;
                                markSlotUID = obj.uniqueId;
                                obj.computeHUPosition(ctrl.rack);
                                obj3d.selectedObj = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId, obj3d.rack);
                            }
                            if (obj instanceof Blade) {
                                var o = ctrl.rack.findObjectByTypeAndId("slot", obj.slotId);
                                if (o !== null) markSlotUID = o.uniqueId;
                            }
                            if (obj instanceof Cpu) {
                                handleParameterSetup();
                                ctrl.config.allowDetails = true;
                                var bo = ctrl.rack.findObjectByTypeAndId("blade", obj.bladeId);
                                if (bo !== null) {
                                    var so = ctrl.rack.findObjectByTypeAndId("slot", bo.slotId);
                                    markSlotUID = so.uniqueId;
                                }
                            }
                            obj3d.rack.traverse(function (o) {
                                if (o.name === "slot" && o.userData.uid === markSlotUID) WebGLService.markObject(o, undefined, true);
                            });
                        }
                    }
                    ctrl.gl.allowHeatMapButton = false;
                    ctrl.toggleSideBarState();
                    return;
                }
                if (ctrl.object instanceof Rack && !ctrl.config.edit) { // rack in view mode
                    ctrl.rack = ctrl.object;
                    ctrl.room = room;
                    for (var i = 0; i < ctrl.rack.slots.length; i++) ctrl.rack.slots[i].computeHUPosition(ctrl.rack);
                    RoomEditorService.setRoomBuildingId(buildings, ctrl.room);
                    if (initRoom === null) initRoom = angular.copy(ctrl.room);
                    obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                    Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                    WebGLService.add3DObjectToContent(obj3d.rack);
                    WebGLService.setActiveCameraProps(new THREE.Vector3(0, 0, 4), new THREE.Vector3(0, 0, 0));
                    WebGLService.bindMouseHandler(undefined, handleMouseMoveStd, undefined, handleDblClickStd);
                    queryLiveData();
                    startLiveDataQuery();
                    if ($stateParams.rauuid) {
                        var obj = ctrl.rack.findObjectByUniqueId(parseInt($stateParams.rauuid));
                        if (obj !== null) {
                            ctrl.object = obj;

                            var markSlotUID = null;
                            if (obj instanceof Slot) {
                                if (obj.type === 5) handleParameterSetup();
                                markSlotUID = obj.uniqueId;
                                obj.computeHUPosition(ctrl.rack);
                                ctrl.config.allowDetails = true;
                            }
                            if (obj instanceof Blade) {
                                var o = ctrl.rack.findObjectByTypeAndId("slot", obj.slotId);
                                if (o !== null) markSlotUID = o.uniqueId;
                            }
                            if (obj instanceof Cpu) {
                                handleParameterSetup();
                                ctrl.config.allowDetails = true;
                                var bo = ctrl.rack.findObjectByTypeAndId("blade", obj.bladeId);
                                if (bo !== null) {
                                    var so = ctrl.rack.findObjectByTypeAndId("slot", bo.slotId);
                                    markSlotUID = so.uniqueId;
                                }
                            }
                            obj3d.rack.traverse(function (o) {
                                if (o.name === "slot" && o.userData.uid === markSlotUID) WebGLService.markObject(o);
                            });
                        }
                    }
                    ctrl.gl.allowHeatMapButton = false;
                    ctrl.config.sidebarActive = ctrl.isEntityForDetail();
                    ctrl.config.allowDetails = true;
                    ctrl.toggleSideBarState();

                }
            };

            function setAttributes(el, attrs) {
                for (var key in attrs) {
                    el.setAttribute(key, attrs[key]);
                }
            }

            var disableDragDrop = function () {
                var rackDrag = document.getElementsByClassName("rackDrag");
                var assetDrag = document.getElementsByClassName("assetDrag");
                var coolDrag = document.getElementsByClassName("coolDrag");
                var sensDrag = document.getElementsByClassName("sensDrag");
                var tileDrag = document.getElementsByClassName("tileDrag");
                var upsDrag = document.getElementsByClassName("upsDrag");
                var clipboardDrag = document.getElementsByClassName("clipboardDrag");

                if (ctrl.isFreeVersion) {
                    ctrl.hideDragImg = true;
                    var tooltip = $translate.instant('global.btn.freeVersion');
                    setTimeout(function () {
                        setAttributes(rackDrag, {"title": tooltip, "draggable": false});
                        setAttributes(assetDrag, {"title": tooltip, "draggable": false});
                        setAttributes(coolDrag, {"title": tooltip, "draggable": false});
                        setAttributes(sensDrag, {"title": tooltip, "draggable": false});
                        setAttributes(tileDrag, {"title": tooltip, "draggable": false});
                        setAttributes(upsDrag, {"title": tooltip, "draggable": false});
                        if (Tools.isDefinedNotNull(clipboardDrag)) {
                            setAttributes(clipboardDrag, {"title": tooltip, "draggable": false});
                        }
                    }, 100);
                }
            };

            var enableDragDrop = function () {
                ctrl.hideDragImg = false;
                var tooltip = "";
                setTimeout(function () {
                    setAttributes(rackDrag, {"title": tooltip, "draggable": true});
                    setAttributes(assetDrag, {"title": tooltip, "draggable": true});
                    setAttributes(coolDrag, {"title": tooltip, "draggable": true});
                    setAttributes(sensDrag, {"title": tooltip, "draggable": true});
                    setAttributes(tileDrag, {"title": tooltip, "draggable": true});
                    setAttributes(upsDrag, {"title": tooltip, "draggable": true});
                    if (Tools.isDefinedNotNull(clipboardDrag)) {
                        setAttributes(clipboardDrag, {"title": tooltip, "draggable": true});
                    }
                }, 100);
            };

            /**
             * @description function to setup drag/drop functionality
             */
            var connectDragDropListener = function () {
                // setup drag enter listener
                WebGLService.addDragEnterListener(function (e, pickPos) {
                    var setupObjects = function (o3d, obj) {
                        if (obj instanceof Slot) {
                            obj3d.selectedObj = o3d;
                            obj3d.selectedObjData = obj;
                            o3d.visible = false;
                            obj3d.rack.add(o3d);
                            if (!WebGLService.isCam3D()) {
                                var backObj = WebGLService.findObjectByName("backview", WebGLService.getSceneObject());
                                obj3d.selectedSlotBack = obj3d.selectedObj.clone();
                                backObj.children[0].add(obj3d.selectedSlotBack);
                            }
                        } else {
                            obj3d.selectedObj = o3d;
                            obj3d.selectedObjData = obj;
                            obj3d.room.add(o3d);
                        }
                    };
                    var setupRackGroup = function (o3d, obj) {
                        obj3d.selectedObj = o3d;
                        obj3d.selectedObjData = obj;
                        obj3d.room.add(o3d);
                    }
                    var obj, o3d;
                    var dragObj = DragDropService.getDragObject();
                    if (!Tools.isDefinedNotNull(dragObj)) {
                        e.preventDefault();
                        e.cancelBubble = true;
                        return false;
                    }
                    var raisedFloorHeight = ctrl.room && ctrl.room.hasRaisedFloor ? ctrl.room.getRaisedFloor().size.y : 0;
                    var partlibItem = null;
                    if (dragObj.type !== 'clipboard') {
                        partlibItem = ctrl.config.partlib.items[dragObj.type].filter(function (e) {
                            return e.id === dragObj.id
                        })[0];
                        partlibItem.name = $translate.instant(partlibItem.name);
                        partlibItem.comment = partlibItem.comment !== null ? $translate.instant(partlibItem.comment) : "";
                    } else if (dragObj.type === null) {
                        e.preventDefault();
                        return false;
                    } else {
                        partlibItem = ClipboardService.getItem();
                    }
                    switch (dragObj.type) {
                        case 'rack':
                            if (ctrl.config.partlib.select.rack.single) {
                                obj = RoomEditorService.buildRackFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                                o3d = Object3DFactory.buildRack(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                                break;
                            } else {
                                var rackblock = new THREE.Group();
                                var racknumber = ctrl.config.partlib.select.rack.racknumber;
                                var xGap = partlibItem.width + avoid_collision_gap;
                                var newPos = angular.copy(pickPos[0].point);
                                for (var i = 0; i < racknumber; i++) {
                                    obj = RoomEditorService.buildRackFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                                    obj.genRelativePositions(ctrl.room.bbox.min);
                                    o3d = Object3DFactory.buildRack(obj, getDisplayMode());
                                    ctrl.config.partlib.select.grack.racks.push(obj);
                                    rackblock.add(o3d);
                                    newPos.x = newPos.x + xGap;
                                }
                                rackblock.userData.id = -1;
                                rackblock.userData.uid = -1;
                                rackblock.userData.obb = rackblock.children[0].userData.obb.clone();
                                rackblock.userData.obb.e.x = (rackblock.userData.obb.e.x * racknumber) + (avoid_collision_gap * (racknumber - 1));
                                rackblock.position.set(rackblock.userData.obb.c.x, rackblock.userData.obb.c.y, rackblock.userData.obb.c.z);
                                ctrl.config.partlib.select.grack.pos = angular.copy(rackblock.userData.obb.c);
                                ctrl.config.partlib.select.grack.size = angular.copy(ctrl.config.partlib.select.grack.racks[0].size);
                                ctrl.config.partlib.select.grack.size.x = (ctrl.config.partlib.select.grack.size.x * racknumber) + (avoid_collision_gap * (racknumber - 1));
                                ctrl.config.partlib.select.grack.rot = angular.copy(ctrl.config.partlib.select.grack.racks[0].rot);
                                setupRackGroup(rackblock, ctrl.config.partlib.select.grack);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                                break;
                            }

                        case 'sensor':
                            obj = RoomEditorService.buildSensorFromInfoObj(partlibItem, pickPos[0].point, ctrl.room.size.y, ctrl.room.id);
                            o3d = Object3DFactory.buildSensor(obj, getDisplayMode());
                            setupObjects(o3d, obj);
                            if (ctrl.isFreeVersion) {
                                if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                    disableDragDrop();
                                }
                            }
                            break;
                        case 'cooling':
                            obj = RoomEditorService.buildCoolingFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                            o3d = Object3DFactory.buildCooling(obj, getDisplayMode());
                            setupObjects(o3d, obj);
                            if (ctrl.isFreeVersion) {
                                if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                    disableDragDrop();
                                }
                            }
                            break;
                        case 'asset':
                            obj = RoomEditorService.buildAssetFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                            o3d = Object3DFactory.buildAsset(obj, getDisplayMode());
                            setupObjects(o3d, obj);
                            if (ctrl.isFreeVersion) {
                                if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                    disableDragDrop();
                                }
                            }
                            break;
                        case 'floortile':
                            obj = RoomEditorService.buildFloorTileFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                            o3d = Object3DFactory.buildFloorTile(obj, getDisplayMode(), obj3d.room, ctrl.room);
                            setupObjects(o3d, obj);
                            if (ctrl.isFreeVersion) {
                                if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                    disableDragDrop();
                                }
                            }
                            break;
                        case 'ups':
                            obj = RoomEditorService.buildUpsFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id);
                            o3d = Object3DFactory.buildUps(obj, getDisplayMode());
                            setupObjects(o3d, obj);
                            if (ctrl.isFreeVersion) {
                                if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                    disableDragDrop();
                                }
                            }
                            break;
                        case 'slot':
                            Object3DFactory.setupEmptySlotHovers(ctrl.rack, WebGLService.getHoverObject(), {
                                x: partlibItem.width,
                                y: partlibItem.height,
                                z: partlibItem.depth
                            }, WebGLService.getActiveCamera() instanceof THREE.PerspectiveCamera);
                            obj = RoomEditorService.buildSlotFromInfoObj(partlibItem, ctrl.rack.id);
                            o3d = Object3DFactory.buildSlot(obj, getDisplayMode());
                            setupObjects(o3d, obj);
                            addHULabel();
                            positionHULabel(e);
                            break;
                        case 'clipboard':
                            if (partlibItem instanceof Asset) {
                                obj = RoomEditorService.buildAssetFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildAsset(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            if (partlibItem instanceof Cooling) {
                                obj = RoomEditorService.buildCoolingFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildCooling(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            if (partlibItem instanceof Rack) {
                                obj = RoomEditorService.buildRackFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildRack(obj, getDisplayMode(), false);
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            if (partlibItem instanceof Ups) {
                                obj = RoomEditorService.buildUpsFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildUps(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            if (partlibItem instanceof FloorTile) {
                                obj = RoomEditorService.buildFloorTileFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildFloorTile(obj, getDisplayMode(), obj3d.room, ctrl.room);
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            if (partlibItem instanceof Slot) {
                                Object3DFactory.setupEmptySlotHovers(ctrl.rack, WebGLService.getHoverObject(), {
                                    x: partlibItem.width,
                                    y: partlibItem.height,
                                    z: partlibItem.depth
                                }, WebGLService.getActiveCamera() instanceof THREE.PerspectiveCamera);
                                obj = RoomEditorService.buildSlotFromInfoObj(partlibItem, ctrl.rack.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildSlot(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                addHULabel();
                                positionHULabel(e);
                            }
                            if (partlibItem instanceof Sensor) {
                                obj = RoomEditorService.buildSensorFromInfoObj(partlibItem, pickPos[0].point, raisedFloorHeight, ctrl.room.id, clipboardDeepCopy);
                                o3d = Object3DFactory.buildSensor(obj, getDisplayMode());
                                setupObjects(o3d, obj);
                                if (ctrl.isFreeVersion) {
                                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 2) {
                                        disableDragDrop();
                                    }
                                }
                            }
                            break;
                    }
                });
                // setup drag over listener
                WebGLService.addDragOverListener(function (e, pickPos) {
                    if (Tools.isDefinedNotNull(e.originalEvent.dataTransfer)) {
                        var dragObj = DragDropService.getDragObject();
                        if (!Tools.isDefinedNotNull(dragObj)) {
                            ctrl.object = ctrl.viewObject;
                            WebGLService.unmarkObject(undefined, true);
                            RoomEditorService.updateURLForObject(null);
                            GLMeasureService.clearMeasure();
                            obj3d.selectedObj = null;
                            obj3d.selectedObjData = null;
                            if (!ctrl.config.edit) {
                                ctrl.config.allowDetails = true;
                            } else {
                                ctrl.config.allowDetails = false;
                            }
                            ctrl.config.alignToGrid = false;
                            $scope.$apply();
                        }
                    }
                    if (ctrl.viewObject instanceof Room && obj3d.selectedObj instanceof THREE.Object3D && pickPos.length > 0) {
                        //Check if the seleted object is a single entity
                        if (obj3d.selectedObjData instanceof Rack || obj3d.selectedObjData instanceof Cooling ||
                            obj3d.selectedObjData instanceof Sensor || obj3d.selectedObjData instanceof Asset ||
                            obj3d.selectedObjData instanceof FloorTile || obj3d.selectedObjData instanceof Ups) {
                            obj3d.selectedObj.userData.obb.c.copy(obj3d.selectedObj.position);
                            if (obj3d.selectedObjData instanceof FloorTile) {
                                obj3d.selectedObj.userData.obb.c.setY(ctrl.room.getRaisedFloor().size.y);
                            }
                            obj3d.selectedObj.position.setX(pickPos[0].point.x);
                            obj3d.selectedObj.position.setZ(pickPos[0].point.z);
                            if (obj3d.selectedObjData instanceof FloorTile && ctrl.config.alignToGrid) {
                                var rp = NamedEntity.getRelativePosition(ctrl.room.bbox.min, obj3d.selectedObj.position.clone());
                                var nrp = ctrl.room.computeAlignedFloorPosition(rp);
                                var np = ctrl.object.getRealPositionFromRelative(ctrl.room.bbox.min, nrp);
                                obj3d.selectedObjData.aligned = true;
                                obj3d.selectedObj.position.setX(np.x).setZ(np.z);
                            }
                            obj3d.selectedObjData.pos.x = obj3d.selectedObj.position.x;
                            obj3d.selectedObjData.pos.z = obj3d.selectedObj.position.z;
                            obj3d.selectedObjData.genRelativePositions(ctrl.room.bbox.min);
                        } else {
                            //The selected object is a group of racks
                            obj3d.selectedObj.position.setX(0);
                            obj3d.selectedObj.position.setZ(0);
                            obj3d.selectedObj.userData.obb.c.copy(obj3d.selectedObj.position);
                            for (var i = 0; i < obj3d.selectedObj.children.length; i++) {
                                obj3d.selectedObj.children[i].position.setX(pickPos[0].point.x);
                                if (i > 0) obj3d.selectedObj.children[i].position.setX(pickPos[0].point.x + i * (ctrl.config.partlib.select.rack.obj.width + avoid_collision_gap));
                                obj3d.selectedObj.children[i].position.setY(0);
                                obj3d.selectedObj.children[i].position.setZ(pickPos[0].point.z);
                                obj3d.selectedObj.children[i].userData.obb.c.copy(obj3d.selectedObj.children[i].position);
                                obj3d.selectedObjData.racks[i].pos.x = obj3d.selectedObj.children[i].position.x;
                                obj3d.selectedObjData.racks[i].pos.z = obj3d.selectedObj.children[i].position.z;
                                obj3d.selectedObjData.racks[i].genRelativePositions(ctrl.room.bbox.min);
                            }
                        }

                    }
                    if (ctrl.viewObject instanceof Rack) {
                        var i = WebGLService.pickObjectsName(e, WebGLService.getHoverObject(), "hoverSlot");
                        if (i.length > 0) {
                            WebGLService.markObject(i[0].object);
                            obj3d.selectedObj.visible = true;
                            obj3d.selectedObj.position.copy(i[0].object.position);
                            obj3d.selectedObj.position.z = ctrl.viewObject.size.z / 2 - 0.05 - obj3d.selectedObjData.size.z / 2 - 0.01;
                            if (obj3d.selectedObjData.size.y > 0.045) {
                                obj3d.selectedObj.position.y = (i[0].object.position.y + 0.045 / 2) - obj3d.selectedObjData.size.y / 2;
                            }
                            if (i[0].object.position.x !== 0) {
                                obj3d.selectedObj.position.x = 0;
                            }
                            if (!WebGLService.isCam3D()) {
                                obj3d.selectedSlotBack.visible = true;
                                obj3d.selectedSlotBack.position.setY(obj3d.selectedObj.position.y).setZ(obj3d.selectedObj.position.z * -1);
                            }

                            positionHULabel(e);
                            setHULabelText("HU " + i[0].object.userData.slot);
                        } else {
                            WebGLService.unmarkObject();
                            positionHULabel(e);
                            setHULabelText("noposition");
                            obj3d.selectedObj.visible = false;
                            if (!WebGLService.isCam3D()) obj3d.selectedSlotBack.visible = false;
                        }
                    }
                });
                // setup drop listener
                WebGLService.addDropListener(function (e, pickPos) {
                    var dragObj = DragDropService.getDragObject();
                    if (Tools.isDefinedNotNull(e.originalEvent.dataTransfer)) {
                        if (!Tools.isDefinedNotNull(dragObj)) {
                            ctrl.object = ctrl.viewObject;
                            WebGLService.unmarkObject(undefined, true);
                            RoomEditorService.updateURLForObject(null);
                            GLMeasureService.clearMeasure();
                            obj3d.selectedObj = null;
                            obj3d.selectedObjData = null;
                            if (!ctrl.config.edit) {
                                ctrl.config.allowDetails = true;
                            } else {
                                ctrl.config.allowDetails = false;
                            }
                            ctrl.config.alignToGrid = false;
                            $scope.$apply();
                        }
                    }
                    var dropHelper = function (array, objData, obj) {
                        objData.id = Entity.getNewIdFromArray(array);
                        objData.uniqueId = Entity.getNewLocaleUniqueId();
                        obj.userData.id = objData.id;
                        obj.userData.uid = objData.uniqueId;
                        objData.pos = obj.position.clone();
                        if (!(objData instanceof FloorTile)) objData.size = obj.scale.clone();
                        array.push(objData);
                        if (ctrl.config.partlib.insertType === 1) {
                            ctrl.object = objData;
                            ctrl.config.allowDetails = true;
                            $scope.$apply();
                        } else {
                            obj3d.selectedObj = null;
                        }
                        DragDropService.setDragObject(null);
                    };


                    // Function for create the real block of racks
                    var dropHelperBlockRack = function (array, objData, obj, racknumber) {
                        var raisedFloorHeight = ctrl.room && ctrl.room.hasRaisedFloor ? ctrl.room.getRaisedFloor().size.y : 0;
                        var partlibItem = ctrl.config.partlib.items[dragObj.type].filter(function (e) {
                            return e.id === dragObj.id
                        })[0];
                        partlibItem.name = $translate.instant(partlibItem.name);
                        partlibItem.comment = partlibItem.comment !== null ? $translate.instant(partlibItem.comment) : "";
                        var xGap = partlibItem.width + avoid_collision_gap;
                        var newPos = angular.copy(pickPos[0].point);
                        for (var i = 0; i < racknumber; i++) {
                            var objDataCopy = RoomEditorService.buildRackFromInfoObj(partlibItem, newPos, raisedFloorHeight, ctrl.room.id);
                            objDataCopy.genRelativePositions(ctrl.room.bbox.min);
                            var objCopy = Object3DFactory.buildRack(objDataCopy, getDisplayMode());
                            objDataCopy.id = Entity.getNewIdFromArray(array);
                            objDataCopy.uniqueId = Entity.getNewLocaleUniqueId();
                            objCopy.userData.id = objDataCopy.id;
                            objCopy.userData.uid = objDataCopy.uniqueId;
                            objDataCopy.pos = objCopy.position.clone();
                            if (!(objDataCopy instanceof FloorTile)) objDataCopy.size = objCopy.scale.clone();
                            obj3d.room.add(objCopy);
                            array.push(objDataCopy);
                            newPos.x += xGap;
                        }
                        if (ctrl.config.partlib.insertType === 1) {
                            ctrl.object = objData;
                            ctrl.config.allowDetails = true;
                            $scope.$apply();
                        } else {
                            obj3d.selectedObj = null;
                        }
                        DragDropService.setDragObject(null);
                        // Remove from the room children objects the previous THREE.Group() created for the block of racks
                        var idx = null;
                        for (var i = 0; i < obj3d.room.children.length; i++) {
                            if (obj3d.room.children[i].type == "Group") {
                                idx = i;
                                break;
                            }
                        }
                        if (idx !== null) {
                            obj3d.room.children.splice(idx, 1);
                        }
                    };


                    DragDropService.setDragObject(null);
                    if (!Tools.isDefinedNotNull(obj3d.selectedObj)) {
                        e.preventDefault();
                        e.cancelBubble = true;
                        return false;
                    }

                    if (ctrl.viewObject instanceof Room) {
                        if (ctrl.room.checkOBBInRoom(obj3d.selectedObj.userData.obb)) {
                            if (obj3d.selectedObjData instanceof Rack) dropHelper(ctrl.room.racks, obj3d.selectedObjData, obj3d.selectedObj);
                            if (obj3d.selectedObjData instanceof Cooling) dropHelper(ctrl.room.coolings, obj3d.selectedObjData, obj3d.selectedObj);
                            if (obj3d.selectedObjData instanceof Sensor) dropHelper(ctrl.room.sensors, obj3d.selectedObjData, obj3d.selectedObj);
                            if (obj3d.selectedObjData instanceof Asset) dropHelper(ctrl.room.assets, obj3d.selectedObjData, obj3d.selectedObj);
                            if (obj3d.selectedObjData instanceof FloorTile) dropHelper(ctrl.room.floorTiles, obj3d.selectedObjData, obj3d.selectedObj);
                            if (obj3d.selectedObjData instanceof Ups) dropHelper(ctrl.room.ups, obj3d.selectedObjData, obj3d.selectedObj);
                            if (!(obj3d.selectedObjData instanceof Rack) && !(obj3d.selectedObjData instanceof Cooling) &&
                                !(obj3d.selectedObjData instanceof Sensor) && !(obj3d.selectedObjData instanceof Asset) &&
                                !(obj3d.selectedObjData instanceof FloorTile) && !(obj3d.selectedObjData instanceof Ups) && !ctrl.config.partlib.select.rack.single) {
                                dropHelperBlockRack(ctrl.room.racks, obj3d.selectedObjData.racks[0], obj3d.selectedObj.children[0], ctrl.config.partlib.select.rack.racknumber);
                            }
                        } else {
                            obj3d.room.remove(obj3d.selectedObj);
                            obj3d.selectedObj = null;
                            obj3d.selectedObjData = null;
                        }
                    }
                    if (ctrl.viewObject instanceof Rack) {
                        var markedObject = WebGLService.getMarkedObject();
                        if (markedObject) {
                            if (obj3d.selectedObjData.checkCollision(ctrl.rack, obj3d.selectedObj.position.clone())) {
                                Notify.warning("global.notification.warning.warn", "room.edit.dialog.messages.invalidPosition", 3000);
                                obj3d.rack.remove(obj3d.selectedObj);
                                obj3d.selectedObj = null;
                                obj3d.selectedObjData = null;
                            } else {
                                obj3d.selectedObjData.id = Entity.getNewIdFromArray(ctrl.rack.slots);
                                obj3d.selectedObjData.uniqueId = Entity.getNewLocaleUniqueId();
                                obj3d.selectedObj.children[0].userData.id = obj3d.selectedObjData.id;
                                obj3d.selectedObj.children[0].userData.uid = obj3d.selectedObjData.uniqueId;
                                obj3d.selectedObj.position.z += 0.01;
                                obj3d.selectedObjData.pos = obj3d.selectedObj.position.clone();
                                obj3d.selectedObjData.posHU = markedObject.userData.slot;
                                if (!WebGLService.isCam3D()) {
                                    obj3d.selectedSlotBack.position.setZ(obj3d.selectedObj.position.z * -1);
                                    obj3d.selectedSlotBack.children[0].userData.id = obj3d.selectedObjData.id;
                                    obj3d.selectedSlotBack.children[0].userData.uid = obj3d.selectedObjData.uniqueId;
                                }
                                ctrl.rack.slots.push(obj3d.selectedObjData);
                                if (ctrl.config.partlib.insertType === 1) {
                                    ctrl.object = obj3d.selectedObjData;
                                    ctrl.config.allowDetails = true;
                                    WebGLService.markObject(obj3d.selectedObj.children[0], undefined, true);
                                    $scope.$apply();
                                }
                            }
                        }
                        WebGLService.cleanHoverObj("hoverSlot");
                        removeHULabel();
                    }
                });
                // setup drag leave listener
                WebGLService.addDragLeaveListener(function (e, pickPos) {
                    if (Tools.isDefinedNotNull(e.originalEvent.dataTransfer)) {
                        var dragObj = DragDropService.getDragObject();
                        if (!Tools.isDefinedNotNull(dragObj)) {
                            ctrl.object = ctrl.viewObject;
                            WebGLService.unmarkObject(undefined, true);
                            RoomEditorService.updateURLForObject(null);
                            GLMeasureService.clearMeasure();
                            obj3d.selectedObj = null;
                            obj3d.selectedObjData = null;
                            if (!ctrl.config.edit) {
                                ctrl.config.allowDetails = true;
                            } else {
                                ctrl.config.allowDetails = false;
                            }
                            ctrl.config.alignToGrid = false;
                            $scope.$apply();
                        }
                    }
                    if (ctrl.viewObject instanceof Rack) {
                        WebGLService.cleanHoverObj("hoverSlot");
                        removeHULabel();
                    }
                    if (ctrl.viewObject instanceof Room) {
                        obj3d.room.remove(obj3d.selectedObj);
                        obj3d.selectedObj = null;
                        obj3d.selectedObjData = null;
                    }
                });
            };
            var infoLabel = null;

            /**
             * @description function to open detail view after click on overlay info label
             * @param {Event} e the click event to use
             */
            var handleInfoButtonClick = function (e) {
                var obj = null;
                if (e.target.parentNode.parentNode.labelObj) {
                    obj = e.target.parentNode.parentNode.labelObj.obj;
                } else if (e.target.parentNode.parentNode.parentNode.labelObj) obj = e.target.parentNode.parentNode.parentNode.labelObj.obj;
                if (obj === null) return;
                if ((obj instanceof Asset || obj instanceof Cooling || obj instanceof Ups) && obj.id >= 0) ctrl.showEntityDetail(obj);
                if (obj instanceof Rack) ctrl.openRack(obj);
            };

            /**
             * @description basic handler function for mouse down events
             * @param {MouseEvent} e the mouse event to use
             */
            var handleMouseDownStd = function (e) {
                if (ctrl.saveInProcess) {
                    return;
                }
                if (!isCam3D()) WebGLService.disableControlsRotate();

                if (window.TouchEvent && e['originalEvent'] instanceof TouchEvent) {
                    handleMouseMoveStd(e);
                    handleMouseUpStd(e);
                }

                if (e['originalEvent'] instanceof MouseEvent) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, ['rack', 'cooling', 'sensor', 'asset', 'floortile', 'ups', 'containment']);

                    if (i.length > 0) {
                        if (!ctrl.gl.multiSelection) {
                            if (ctrl.config.edit && ctrl.object && obj3d.selectedObj && obj3d.selectedObj.name === 'containment') {
                                if (ctrl.object.id === obj3d.selectedObj.userData.id) {
                                    WebGLService.setPickplanePosition(new THREE.Vector3(0, i[0].point.y, 0));
                                    var inv = new THREE.Matrix4().getInverse(i[0].object.matrixWorld);
                                    var p = i[0].point.clone().applyMatrix4(inv);
                                    p.x *= i[0].object.scale.x;
                                    p.y *= i[0].object.scale.y;
                                    p.z *= i[0].object.scale.z;
                                    p.setY(0);
                                    ctrl.detail.dragObject = true;
                                    ctrl.detail.posDiff = p;
                                    WebGLService.disableControls();
                                }
                            } else if (ctrl.config.edit && ctrl.object && obj3d.selectedObj && i[0].object.userData.id === obj3d.selectedObj.userData.id && i[0].object.userData.id === ctrl.object.id) {
                                WebGLService.setPickplanePosition(new THREE.Vector3(0, i[0].point.y, 0));
                                var inv = new THREE.Matrix4().getInverse(i[0].object.matrixWorld);
                                var p = i[0].point.clone().applyMatrix4(inv);
                                p.x *= i[0].object.scale.x;
                                p.y *= i[0].object.scale.y;
                                p.z *= i[0].object.scale.z;
                                p.setY(0);
                                ctrl.detail.dragObject = true;
                                ctrl.detail.posDiff = p;
                                WebGLService.disableControls();
                            }
                        } else {
                            if (RoomEditorService.checkItemAlreadySelected(i[0].object)) {
                                RoomEditorService.removeObjectFromSelection(i[0].object);
                                setBackForwardAllows();
                                var lengthOfSelectedItems = RoomEditorService.getSelectedObjectsLength();
                                ctrl.gl.showAlignPanel = lengthOfSelectedItems > 1;
                                ctrl.gl.showAlignLeftAndRight = lengthOfSelectedItems < 3 && lengthOfSelectedItems > 1;
                                $scope.$apply();
                            } else {
                                RoomEditorService.addObjectToSelection(i[0].object);
                                setBackForwardAllows();
                                var lengthOfSelectedItems = RoomEditorService.getSelectedObjectsLength();
                                ctrl.gl.showAlignPanel = lengthOfSelectedItems > 1;
                                ctrl.gl.showAlignLeftAndRight = lengthOfSelectedItems < 3 && lengthOfSelectedItems > 1;
                                if (RoomEditorService.getSelectedObjectsLength > 0) ctrl.config.containment.valid = checkSelectedItemsForContainment() !== false;
                                $scope.$apply();
                            }
                        }
                    }
                }
            };

            /**
             * @description basic handler function for mouse up events
             * @param {MouseEvent} e the mouse event to use
             */
            var handleMouseUpStd = function (e) {
                if (ctrl.saveInProcess) {
                    return;
                }
                if (!isCam3D()) WebGLService.enableControlsRotate();
                if (ctrl.detail.dragObject) {
                    //TODO handle everything else
                    ctrl.detail.dragObject = false;
                    WebGLService.resetPickplanePosition();
                    WebGLService.enableControls();
                } else if (ctrl.gl.focusActive) {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, ['asset', 'cooling', 'rack', 'ups', 'sensor', 'containment']);
                    if (i.length > 0) {
                        WebGLService.focusObjectPosition(i[0].object.position);
                    }
                } else {
                    var i = WebGLService.pickObjectsName(e, obj3d.room, ['asset', 'cooling', 'rack', 'ups', 'sensor', 'containment']);
                    if (i.length && ctrl.config.allowInfoLabels) {
                        var obj = null;
                        obj = ctrl.room.findObjectByUniqueID(i[0].object.userData.uid);
                        if (obj && (infoLabel === null || infoLabel.obj.uniqueId !== obj.uniqueId)) {
                            if (infoLabel !== null) TrackingDivLabelFactory.removeLabel("objInfoLabel");
                            var weightType = 0;
                            if (ctrl.user.settings.length > 0) {
                                var ws = ctrl.user.settings.filter(function (el) {
                                    return el.key === "weight";
                                });
                                if (ws.length) weightType = parseInt(ws[0].value);
                            }
                            infoLabel = TrackingDivLabelFactory.buildObjectInfoLabel(obj,
                                "objInfoLabel",
                                "room-panel",
                                i[0].object.position,
                                ctrl.tempSettings(),
                                handleInfoButtonClick,
                                currLang,
                                $translate.instant,
                                weightType);
                            if (window.TouchEvent && e['originalEvent'] instanceof TouchEvent) {
                                infoLabel.setPositionCSS(e.touches[0].clientY + 10, e.touches[0].clientX - 250);
                            } else {
                                infoLabel.setPositionCSS(e.clientY + 10, e.offsetX);
                            }
                        }
                    } else {
                        if (infoLabel !== null) {
                            TrackingDivLabelFactory.removeLabel("objInfoLabel");
                            infoLabel = null;
                        }
                    }
                }
            };

            /**
             * @description basic handler function for mouse move events
             * @param {MouseEvent} e the mouse event to use
             * @returns {boolean}
             */
            var handleMouseMoveStd = function (e) {
                if (ctrl.saveInProcess) {
                    return;
                }
                var i;
                if (!ctrl.detail.dragObject) {
                    var container = ctrl.viewObject instanceof Room ? obj3d.room : WebGLService.getSceneObject();
                    var nameArray = ctrl.viewObject instanceof Room ? ['rack', 'sensor', 'cooling', 'asset', 'floortile', 'ups', 'containment'] : ['slot', 'blade'];
                    i = WebGLService.pickObjectsName(e, container, nameArray);
                    if (i.length > 0) {
                        if (!WebGLService.isCam3D() && ctrl.viewObject instanceof Rack) {
                            if (i[0].object.name === "slot" && i[0].object.parent.parent.parent.name === "backview") {
                                i[0].object.parent.parent.parent.parent.children[0].traverse(function (o) {
                                    if (o.name === "slot" && o.userData.id === i[0].object.userData.id) WebGLService.markObject(o);
                                });
                            } else {
                                WebGLService.markObject(i[0].object, false);
                            }
                        } else {
                            WebGLService.markObject(i[0].object, false);
                        }
                    } else {
                        if (WebGLService.getMarkedObject() !== null) WebGLService.unmarkObject(undefined, false);
                    }
                    i = null;
                    container = null;
                    nameArray = null;
                } else {
                    i = WebGLService.getPositionPickplane(e);
                    var p;
                    if (i.length > 0) {
                        if (ctrl.object.isContainment) {
                            adjustHeatmap();
                        }
                        //TODO check collide
                        if (!ctrl.object.isFloorTile) {
                            p = i[0].point.clone().setY(0);
                            p.sub(ctrl.detail.posDiff);
                            if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, new THREE.Vector3(p.x, obj3d.selectedObj.position.y, p.z), false, ctrl.room)) {
                                return false;
                            }
                            obj3d.selectedObj.position.setX(p.x).setZ(p.z);
                            obj3d.selectedObj.userData.obb.c.setX(p.x).setZ(p.z);
                            RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "pos", obj3d.selectedObj.position);
                            ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                            if (ctrl.room.usesRaisedFloorIdents && isFloorPanelObject(ctrl.object)) {
                                ctrl.room.computeStdFloorPanelPosition(ctrl.object);
                                ctrl.object.genFloorPosition();
                            }
                            WebGLService.updateMarkerPosition(obj3d.selectedObj);
                            GLMeasureService.measureObject(ctrl.object, obj3d.selectedObj, obj3d.room, undefined, ctrl.room);
                            p = null;
                        } else {
                            p = i[0].point.clone().setY(0);
                            p.sub(ctrl.detail.posDiff);
                            if (ctrl.object.checkCollision(obj3d.selectedObj, obj3d.room, new THREE.Vector3(p.x, obj3d.selectedObj.position.y, p.z), false, ctrl.room)) {
                                return false;
                            }
                            obj3d.selectedObj.position.setX(p.x).setZ(p.z);
                            obj3d.selectedObj.userData.obb.c.setX(p.x).setZ(p.z);
                            obj3d.selectedObj.userData.obb.c.add(new THREE.Vector3(obj3d.selectedObj.geometry.cube.position.x, 0, obj3d.selectedObj.geometry.cube.position.z));
                            if (ctrl.object.isFloorTile && ctrl.config.alignToGrid) {
                                var rp = NamedEntity.getRelativePosition(ctrl.room.bbox.min, obj3d.selectedObj.position.clone());
                                var nrp = ctrl.room.computeAlignedFloorPosition(rp);
                                var np = ctrl.object.getRealPositionFromRelative(ctrl.room.bbox.min, nrp);
                                obj3d.selectedObj.position.setX(np.x).setZ(np.z);
                                obj3d.selectedObj.userData.obb.c.setX(np.x).setZ(np.z);
                                obj3d.selectedObj.userData.obb.c.add(new THREE.Vector3(obj3d.selectedObj.geometry.cube.position.x, 0, obj3d.selectedObj.geometry.cube.position.z));
                            }
                            RoomEditorService.setEntityPropertyXYZFromVec(ctrl.object, "pos", obj3d.selectedObj.userData.obb.c);
                            ctrl.object.genRelativePositions(ctrl.room.bbox.min);
                            if (ctrl.room.usesRaisedFloorIdents && isFloorPanelObject(ctrl.object)) {
                                ctrl.room.computeStdFloorPanelPosition(ctrl.object);
                                ctrl.object.genFloorPosition();
                            }
                            WebGLService.updateMarkerPosition(obj3d.selectedObj);
                            GLMeasureService.measureObject(ctrl.object, obj3d.selectedObj, obj3d.room, undefined, ctrl.room);
                            p = null;
                        }
                    }
                    i = null;
                }
            };

            /**
             * @description basic handler function for mouse double click events
             * @param {MouseEvent} e the mouse event
             */
            var handleDblClickStd = function (e) {
                function setAttributes(el, attrs) {
                    for (var key in attrs) {
                        el.setAttribute(key, attrs[key]);
                    }
                }

                if (ctrl.saveInProcess) {
                    return;
                }
                if (ctrl.gl.multiSelection) return;
                var i = null;
                if (ctrl.viewObject instanceof Room) {
                    i = WebGLService.pickObjectsName(e, obj3d.room, ['rack', 'cooling', 'sensor', 'asset', 'floortile', 'ups', 'containment']);
                }
                if (ctrl.viewObject instanceof Rack) {
                    i = WebGLService.pickObjectsName(e, WebGLService.getSceneObject(), ['slot', 'blade']);
                }
                if (i.length > 0) {
                    if (i[0].object.name === "slot") {
                        ctrl.object = ctrl.room.findObjectByUniqueID(i[0].object.userData.uid);

                        WebGLService.unmarkObject(undefined, true);
                        if (!WebGLService.isCam3D() && i[0].object.parent.parent.parent.name === "backview") {
                            i[0].object.parent.parent.parent.parent.children[0].traverse(function (o) {
                                if (o.name === "slot" && o.userData.id === i[0].object.userData.id) {
                                    obj3d.selectedObj = o;
                                    WebGLService.markObject(o, undefined, true);
                                }
                            });
                        } else {
                            obj3d.selectedObj = i[0].object;
                            WebGLService.markObject(i[0].object, undefined, true);

                        }
                        RoomEditorService.updateURLForObject(ctrl.object);
                        ctrl.config.allowDetails = true;
                        handleParameterSetup();
                        $scope.$apply();
                    }
                    if (i[0].object.name === "blade") {
                        ctrl.object = ctrl.room.findObjectByUniqueID(i[0].object.parent.children[0].userData.uid);
                        obj3d.selectedObj = i[0].object.parent.children[0];
                        WebGLService.markObject(i[0].object.parent.children[0], undefined, true);
                        RoomEditorService.updateURLForObject(ctrl.object);
                        ctrl.config.allowDetails = true;
                        $scope.$apply();
                    }
                    if (i[0].object.name === "asset" || i[0].object.name === "cooling" || i[0].object.name === "rack" || i[0].object.name === "ups" || i[0].object.name === "sensor" || i[0].object.name === "floortile") {
                        ctrl.object = ctrl.room.findObjectByUniqueID(i[0].object.userData.uid);
                        obj3d.selectedObj = i[0].object;
                        if (WebGLService.getPersistentMarkedObject()) WebGLService.unmarkObject(undefined, true);
                        if (WebGLService.getMarkedObject()) WebGLService.unmarkObject(undefined, false);
                        WebGLService.markObject(i[0].object, undefined, true);
                        handleParameterSetup();
                        setupAirFlowDirections();
                        RoomEditorService.updateURLForObject(ctrl.object);
                        GLMeasureService.clearMeasure();
                        if (infoLabel !== null) {
                            TrackingDivLabelFactory.removeLabel('objInfoLabel');
                            infoLabel = null;
                        }
                        ctrl.config.allowDetails = true;
                        if (ctrl.object instanceof FloorTile) ctrl.config.alignToGrid = ctrl.object.aligned !== undefined && ctrl.object.aligned !== null ? ctrl.object.aligned : false;
                        if (ctrl.object instanceof Rack) {
                            oldHuNumber = ctrl.object.heightUnits;
                            ctrl.handleRackHUNumber(ctrl.object.size.y);
                            ctrl.rackContainsSlots = false;
                            if (ctrl.object.slots.length) {
                                ctrl.rackContainsSlots = true;
                                var dimensionTooltip = $translate.instant('global.btn.sizeChange');
                                var HUTooltip = $translate.instant('global.btn.HUChange');
                                if (ctrl.config.edit) {
                                    setTimeout(function () {
                                        var xInput = document.getElementById("xInput");
                                        var yInput = document.getElementById("yInput");
                                        var zInput = document.getElementById("zInput");
                                        var HUInput = document.getElementById("HUInput");
                                        setAttributes(xInput, {"title": dimensionTooltip});
                                        setAttributes(yInput, {"title": dimensionTooltip});
                                        setAttributes(zInput, {"title": dimensionTooltip});
                                        setAttributes(HUInput, {"title": HUTooltip});
                                    }, 100);
                                }
                            } else {
                                ctrl.rackContainsSlots = false;
                            }
                        }
                        $scope.$apply();
                    }
                    if (i[0].object.name === "containment_plane") {
                        ctrl.object = ctrl.room.findObjectByUniqueID(i[0].object.parent.userData.uid);
                        obj3d.selectedObj = i[0].object.parent;
                        if (WebGLService.getPersistentMarkedObject()) WebGLService.unmarkObject(undefined, true);
                        if (WebGLService.getMarkedObject()) WebGLService.unmarkObject(undefined, false);
                        WebGLService.markObject(i[0].object, undefined, true);
                        handleParameterSetup();
                        RoomEditorService.updateURLForObject(ctrl.object);
                        GLMeasureService.clearMeasure();
                        if (infoLabel !== null) {
                            TrackingDivLabelFactory.removeLabel('objInfoLabel');
                            infoLabel = null;
                        }
                        ctrl.config.allowDetails = true;
                        if (ctrl.object instanceof FloorTile) ctrl.config.alignToGrid = ctrl.object.aligned !== undefined && ctrl.object.aligned !== null ? ctrl.object.aligned : false;
                        $scope.$apply();
                    }
                    if (i[0].object.name === "containment_door") {
                        ctrl.object = ctrl.room.findObjectByUniqueID(i[0].object.parent.userData.uid);
                        obj3d.selectedObj = i[0].object.parent;
                        if (WebGLService.getPersistentMarkedObject()) WebGLService.unmarkObject(undefined, true);
                        if (WebGLService.getMarkedObject()) WebGLService.unmarkObject(undefined, false);
                        WebGLService.markObject(i[0].object, undefined, true);
                        ctrl.config.allowDetails = true;
                        $scope.$apply();
                    }
                } else {
                    ctrl.object = ctrl.viewObject;
                    WebGLService.unmarkObject(undefined, true);
                    RoomEditorService.updateURLForObject(null);
                    GLMeasureService.clearMeasure();
                    obj3d.selectedObj = null;
                    obj3d.selectedObjData = null;
                    if (!ctrl.config.edit) {
                        ctrl.config.allowDetails = true;
                    } else {
                        ctrl.config.allowDetails = false;
                    }
                    ctrl.config.alignToGrid = false;
                    $scope.$apply();
                }
                if (ctrl.object) {
                    ctrl.config.sidebarActive = true;
                    $scope.$apply();
                }
                if (!ctrl.config.edit) {
                    if ((ctrl.viewObject instanceof Rack && ctrl.object instanceof Rack) ||
                        (ctrl.viewObject instanceof Room && ctrl.object instanceof Room)) {
                        ctrl.config.sidebarActive = false;
                        $scope.$apply();
                    }
                }
            };

            /**
             * @description function to handle camera rotation (modify opacity of walls etc)
             */
            var handleRoomRotation = function () {
                var handleWindowsDoorsOfWall = function (ws, ds, opacity) {
                    obj3d.room.traverse(function (oo) {
                        if ((oo.name === "door" && ds.indexOf(oo.userData.id) !== -1) || (oo.name === "window" && ws.indexOf(oo.userData.id) !== -1)) {
                            oo.material.transparent = opacity < 1;
                            oo.material.opacity = opacity;
                        }
                    });
                };
                var getIdArray = function (orgArray) {
                    for (var i in orgArray) orgArray[i] = orgArray[i].id;
                };
                if (WebGLService.getActiveCamera() instanceof THREE.PerspectiveCamera && obj3d.room !== null) {
                    obj3d.room.traverse(function (o) {
                        if (o.name === "outerwall") {
                            var windows = angular.copy(ctrl.room.getWindowsForWall(o));
                            getIdArray(windows);
                            var doors = angular.copy(ctrl.room.getDoorsForWall(o));
                            getIdArray(doors);
                            var camPos = WebGLService.getActiveCamera().position.clone();
                            var a = ctrl.room.bbox.getCenter().setY(0).sub(camPos.setY(0)).angleTo(o.userData.normal);
                            if (a < Math.PI / 2) {
                                o.material.transparent = true;
                                o.material.opacity = getFrontWallOpacity();
                                o.visible = getFrontWallVisibility();
                                o.renderOrder = 100;
                                handleWindowsDoorsOfWall(windows, doors, o.material.opacity);

                            } else {
                                o.visible = getBackWallVisibility();
                                o.material.opacity = getBackWallOpacity();
                                o.material.transparent = o.material.opacity < 1;
                                o.renderOrder = 0;
                                handleWindowsDoorsOfWall(windows, doors, o.material.opacity);
                            }
                            windows = null;
                            doors = null;
                            camPos = null;
                            a = null;
                        }
                    });
                }
                TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
            };

            /**
             * @description function to get opacity setting for walls facing away from camera
             * @returns {*} returns opacity for walls facing away from camera
             */
            var getFrontWallOpacity = function () {
                return ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === 10;
                })[0].opacity;
            };

            /**
             * @description function to get opacity setting for wall facing towards the camera
             * @returns {*} returns opacity for wall facing towards the camera
             */
            var getBackWallOpacity = function () {
                return ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === 9;
                })[0].opacity;
            };

            /**
             * @description function to get visibility setting for walls facing away from camera
             * @returns {*} returns the visibility setting for walls facing away from camera
             */
            var getFrontWallVisibility = function () {
                return ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === 10;
                })[0].visible;
            };

            /**
             * @description function to get visibility setting for wall facing towards the camera
             * @returns {*} returns the visibility setting for walls facing the camera
             */
            var getBackWallVisibility = function () {
                return ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === 9;
                })[0].visible;
            };

            //TODO move to init func
            if (AssetService.isReady()) {
                initialize();
                WebGLService.hideWaitingContainer();
                setTimeout(function () {
                    if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 3) {
                        disableDragDrop();
                    }
                }, 500);

            } else {
                WebGLService.showWaitingContainer(); //glContainer
                AssetService.loadData(function () {

                    initialize();
                    WebGLService.hideWaitingContainer();
                    setTimeout(function () {
                        if (RoomEditorService.checkEntityQuantity(ctrl.room) >= 3) {
                            disableDragDrop();
                        }
                    }, 500);
                });
            }

            /**
             * @description change handler function for grid size changes
             */
            ctrl.handleGridSizeChange = function () {
                if (ctrl.config.tools.gridSize === undefined || ctrl.config.tools.gridSize === null ||
                    ctrl.config.tools.cellSize === undefined || ctrl.config.tools.cellSize === null) {
                    return;
                }
                if (obj3d.grid !== null && obj3d.grid.parent) {
                    WebGLService.remove3DObjectFromContent(obj3d.grid);
                }
                obj3d.grid = Object3DFactory.buildGrid(ctrl.config.tools.gridSize / 2, ctrl.config.tools.cellSize, parseInt(ctrl.config.tools.gridColor.substring(1), 16), false);
                WebGLService.add3DObjectToContent(obj3d.grid);
            };

            /**
             * @description change handler function for grid cell size changes
             */
            ctrl.handleCellSizeChange = function () {
                if (ctrl.config.tools.cellSize === undefined || ctrl.config.tools.cellSize === null ||
                    ctrl.config.tools.gridSize === undefined || ctrl.config.tools.gridSize === null) {
                    return;
                }
                if (obj3d.grid !== null && obj3d.grid.parent) {
                    WebGLService.remove3DObjectFromContent(obj3d.grid);
                }
                obj3d.grid = Object3DFactory.buildGrid(ctrl.config.tools.gridSize / 2, ctrl.config.tools.cellSize, parseInt(ctrl.config.tools.gridColor.substring(1), 16), false);
                WebGLService.add3DObjectToContent(obj3d.grid);
            };

            /**
             * @description change handler function for grid color input
             */
            ctrl.handleGridColorChange = function () {
                if (ctrl.config.tools.gridColor !== "" && !isNaN(parseInt(ctrl.config.tools.gridColor.substring(1), 16)) && parseInt(ctrl.config.tools.gridColor.substring(1), 16) >= 0 && ctrl.config.edit && ctrl.config.editState === 0) {
                    if (obj3d.grid !== null && obj3d.grid.parent) {
                        WebGLService.remove3DObjectFromContent(obj3d.grid);
                    }
                    obj3d.grid = Object3DFactory.buildGrid(ctrl.config.tools.gridSize / 2, ctrl.config.tools.cellSize, parseInt(ctrl.config.tools.gridColor.substring(1), 16), false);
                    WebGLService.add3DObjectToContent(obj3d.grid);
                }
            };

            /**
             * @description function to determine if provided object is valid number
             * @param {object} number the number to validate
             * @returns {boolean} returns true if provided number is valid, otherwise false
             */
            var validateNumber = function (number) {
                if (number === undefined || number === null || number === "" || isNaN(number)) return false;
                return true;
            };

            /**
             * @description change handler function for raised floor property inputs
             */
            ctrl.handleRaisedFloorPropertyChange = function () {
                if (validateNumber(ctrl.tools.raisedFloor.w) && validateNumber(ctrl.tools.raisedFloor.d) && validateNumber(ctrl.tools.raisedFloor.h) &&
                    validateNumber(ctrl.tools.raisedFloor.ox) && validateNumber(ctrl.tools.raisedFloor.oz)) {
                    handleRaisedFloorPropertyUpdate();
                }
            };

            /**
             * @description change handler function for pillar property inputs
             */
            ctrl.handlePillarPropertyUpdate = function () {
                if (validateNumber(ctrl.tools.pillar.w) && validateNumber(ctrl.tools.pillar.d) && validateNumber(ctrl.tools.pillar.rot)) handlePillarPropertyUpdate(ctrl.tools.pillar);
            };

            /**
             * @description change handler function for door property inputs
             */
            ctrl.handleDoorPropertyUpdate = function () {
                if (validateNumber(ctrl.tools.door.w) && validateNumber(ctrl.tools.door.h)) handleDoorPropertyUpdate(ctrl.tools.door);
            };

            /**
             * @description change handler function for window property inputs
             */
            ctrl.handleWindowPropertyUpdate = function () {
                if (validateNumber(ctrl.tools.window.w) && validateNumber(ctrl.tools.window.h)) handleWindowPropertyUpdate(ctrl.tools.window);
            };

            //region Watcher
            $scope.$watch("ctrl.room.hasRaisedFloor", function (n, o) {
                if (n && n !== o) {
                    updateInnerWallOptions();
                }
            });

            //endregion
            //TODO move to init func
            $translate(['room.autodetect.offline', 'room.autodetect.running', 'room.autodetect.nodeviceavailable', 'room.autodetect.incomplete', 'room.autodetect.finishedSuccess', 'room.autodetect.finishedError']).then(function (trans) {
                translations.autodetect.offline = trans['room.autodetect.offline'];
                translations.autodetect.running = trans['room.autodetect.running'];
                translations.autodetect.nodeviceavailable = trans['room.autodetect.nodeviceavailable'];
                translations.autodetect.incomplete = trans['room.autodetect.incomplete'];
                translations.autodetect.finishedSuccess = trans['room.autodetect.finishedSuccess'];
                translations.autodetect.finishedError = trans['room.autodetect.finishedError'];
                translations.autodetect.stop = trans['room.autodetect.stop'];
            });

            //region GLControls

            //TODO move to init func
            if (heatmaps === undefined || heatmaps === null || (heatmaps instanceof Array && heatmaps.length === 0)) {
                heatmaps = HeatmapService.generateFallbackHeatmaps();
                if (user.roles.indexOf("ROLE_ADMIN") !== -1) {
                    RoomEditorService.showNoHeatmapsFoundDialogAdmin();
                } else {
                    RoomEditorService.showNoHeatmapsFoundDialogNoAdmin();
                }
            }
            //TODO move to init func
            if (heatmaps instanceof Array && heatmaps.filter(function (map) {
                return map.physicalTypeId === 1;
            }).length === 0) {
                heatmaps.push(HeatmapService.generateFallbackTempHeatmap());
                if (user.roles.indexOf("ROLE_ADMIN") !== -1) {
                    RoomEditorService.showNoTempHeatmapsFoundDialogAdmin();
                } else {
                    RoomEditorService.showNoTempHeatmapsFoundDialogNoAdmin();
                }
            }

            // basic gl control settings (top right corner in editor)
            ctrl.gl = {
                heatmapActive: false,
                heatmaps: heatmaps.filter(function (map) {
                    return (map.physicalTypeId === 1 || map.physicalTypeId === 2 || map.physicalTypeId === 5);
                }),
                selectedHeatmapId: 0,
                selectedHeatmap: null,
                heatmapTransparency: 0.5,
                heatmapPos: 0.5,
                visibilityOpts: [],
                focusActive: false,
                showSensorOverlayOpts: false,
                showSensorOverlay: false,
                sensorOverlayPhysType: 1,
                showGhosts: false,
                is3d: true
            };
            ctrl.gl.selectedHeatmapId = ctrl.gl.heatmaps[0].id;
            ctrl.gl.selectedHeatmap = ctrl.gl.heatmaps[0];
            ctrl.gl.account = user;

            /**
             * @description function to determine if active caemra is 3d or 2d
             * @returns {boolean} returns true if active camera is 3d projecting, otherwise false
             */
            var isCam3D = function () {
                return WebGLService.getActiveCamera() instanceof THREE.PerspectiveCamera;
            };

            ctrl.gl.showSettingsPanel = false;
            ctrl.gl.showHeatmapPanel = false;
            ctrl.gl.showVisibilityPanel = false;
            ctrl.gl.allowHeatMapButton = false;
            ctrl.gl.allowRackScaleButton = false;
            ctrl.gl.allowBack = false;
            ctrl.gl.allowForward = false;

            ctrl.gl.allowSelection = true;
            ctrl.gl.multiSelection = false;
            ctrl.gl.showAlignPanel = false;
            ctrl.gl.showAlignLeftAndRight = false;

            ctrl.gl.allowButton3D = true;
            ctrl.gl.allowButton2D = true;

            /**
             * @description function to show/hide gfx setting panel
             */
            ctrl.gl.toggleGFXSettings = function () {
                ctrl.gl.showSettingsPanel = !ctrl.gl.showSettingsPanel;
            };

            /**
             * @description function to show/hide heatmap settings panel
             */
            ctrl.gl.toggleHeatmapPanel = function () {
                ctrl.gl.showHeatmapPanel = !ctrl.gl.showHeatmapPanel;
            };

            /**
             * @description function to show/hide visibility option panel
             */
            ctrl.gl.toggleVisibilityPanel = function () {
                ctrl.gl.showVisibilityPanel = !ctrl.gl.showVisibilityPanel;
            };

            /**
             * @description function to move camera left
             */
            ctrl.gl.camLeft = function () {
                WebGLService.camLeft();
            };

            /**
             * @description fucntion to move camera right
             */
            ctrl.gl.camRight = function () {
                WebGLService.camRight();
            };

            /**
             * @description function to move camera forward
             */
            ctrl.gl.camForward = function () {
                WebGLService.camForward();
            };

            /**
             * @description function to move camera backward
             */
            ctrl.gl.camBackward = function () {
                WebGLService.camBackward();
            };

            /**
             * @description function to center camera on original target position
             */
            ctrl.gl.camCenter = function () {
                WebGLService.camCenter();
            };

            /**
             * @description function to increase zoom for active camera
             */
            ctrl.gl.camZoomIn = function () {
                WebGLService.camZoomIn();
            };

            /**
             * @description function to decrease zoom for active camera
             */
            ctrl.gl.camZoomOut = function () {
                WebGLService.camZoomOut();
            };

            /**
             * @description function to move camera 'up'
             */
            ctrl.gl.handleMoveUpClick = function () {
                if (!isCam3D()) {
                    WebGLService.getControls().rotateLeft(Math.PI / 2);
                } else {
                    WebGLService.camMoveUp();
                }

            };

            /**
             * @description function to move camera 'down'
             */
            ctrl.gl.handleMoveDownClick = function () {
                if (!isCam3D()) {
                    WebGLService.getControls().rotateLeft(Math.PI / 2);
                } else {
                    WebGLService.camMoveDown();
                }
            };

            /**
             * @description function to show 3D-view
             */
            ctrl.gl.show3D = function () {
                if (!ctrl.gl.allowButton3D) return;
                if (!ctrl.gl.is3d) {
                    WebGLService.switch3D();
                    WebGLService.enableControlsRotate();
                    ctrl.gl.is3d = true;
                }
                TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
            };

            /**
             * @description functino to show 2D-view
             */
            ctrl.gl.show2D = function () {
                if (!ctrl.gl.allowButton2D) return;
                if (ctrl.gl.is3d) {
                    WebGLService.switch2D();
                    ctrl.gl.is3d = false;
                    if (ctrl.config.editState == 0) WebGLService.disableControlsRotate();
                }
                TrackingDivLabelFactory.trackAll(WebGLService.getActiveCamera());
            };

            /**
             * @description function to handle antialias setting changes
             */
            ctrl.gl.handleAAChange = function () {
                WebGLService.setAntialias(ctrl.gl.account.graphics.aa);
            };

            /**
             * @description function to handle zoom speed changes
             */
            ctrl.gl.handleZoomChange = function () {
                WebGLService.setZoomSpeed(ctrl.gl.account.graphics.zoom);
            };

            /**
             * @description function to handle display mode changes
             */
            ctrl.gl.handleDisplayModeChange = function () {
                MessageService.publish("user:changeDisplayMode", ctrl.gl.account.graphics.displayMode);
            };

            /**
             * @description function to toggle heatmap on/off
             */
            ctrl.gl.toggleHeatmap = function () {
                if (ctrl.gl.heatmapActive) {
                    HeatMapGLService.addHeatmapToRoom(ctrl.room, obj3d.room, ctrl.gl.selectedHeatmap, ctrl.gl.heatmapPos, ctrl.gl.heatmapTransparency);
                    RoomEditorService.modifyRenderOrder(obj3d.room);
                } else {
                    HeatMapGLService.removeHeatmapFromRoom();
                }
            };

            /**
             * @description function to handle changes to displayed heatmap type
             */
            ctrl.gl.handleHeatmapChange = function () {
                ctrl.gl.selectedHeatmap = ctrl.gl.heatmaps.filter(function (map) {
                    return map.id === ctrl.gl.selectedHeatmapId;
                })[0];
                if (ctrl.gl.selectedHeatmap.physicalTypeId === 1) {
                    tempHeatMap = ctrl.gl.selectedHeatmap;
                    RoomService.colorizeRoomTemp(ctrl.room, obj3d.room, tempHeatMap);
                }
                if (ctrl.gl.heatmapActive) {
                    tempHeatMap = ctrl.gl.selectedHeatmap;
                    RoomService.colorizeRoomTemp(ctrl.room, obj3d.room, tempHeatMap);
                    HeatMapGLService.removeHeatmapFromRoom();
                    HeatMapGLService.addHeatmapToRoom(ctrl.room, obj3d.room, ctrl.gl.selectedHeatmap, ctrl.gl.heatmapPos, ctrl.gl.heatmapTransparency);
                    RoomEditorService.modifyRenderOrder(obj3d.room);
                }
            };

            /**
             * @description function to handle heatmap position changes (slider in panel)
             */
            ctrl.gl.handleHeatmapPosChange = function () {
                if (ctrl.gl.heatmapActive) {
                    HeatMapGLService.updateHeatmapPos(ctrl.gl.heatmapPos);
                    RoomEditorService.modifyRenderOrder(obj3d.room);
                }
            };

            /**
             * @description function to handle changes to heatmap transparency
             */
            ctrl.gl.handleHeatmapTransparencyChange = function () {
                if (ctrl.gl.heatmapActive) HeatMapGLService.updateHeatmapTransparency(ctrl.gl.heatmapTransparency);
            };

            /**
             * @description function to activate single selection mode
             */
            ctrl.gl.setSingleSelection = function () {
                ctrl.gl.multiSelection = false;
                RoomEditorService.clearSelectedObjects();
                RoomEditorService.clearSteps();
                setBackForwardAllows();
                ctrl.gl.showAlignPanel = false;
                ctrl.config.allowInfoLabels = true;
                WebGLService.unmarkObjectMulti();
            };

            /**
             * @description function to activate multi selection mode
             */
            ctrl.gl.setMultiSelection = function () {
                ctrl.gl.multiSelection = true;
                ctrl.config.allowInfoLabels = false;
                if (obj3d.selectedObj !== null) {
                    ctrl.object = ctrl.viewObject;
                    WebGLService.unmarkObject(undefined, true);
                    RoomEditorService.updateURLForObject(null);
                    GLMeasureService.clearMeasure();
                    obj3d.selectedObj = null;
                    obj3d.selectedObjData = null;
                    ctrl.config.allowDetails = false;
                    ctrl.config.alignToGrid = false;
                    RoomEditorService.clearSelectedObjects();
                    RoomEditorService.clearSteps();
                    setBackForwardAllows();
                    ctrl.gl.showAlignPanel = false;
                    // commented out for ticket #1413
                    // $scope.$apply();
                }
            };

            /**
             * @description function to set forward/backward step available/not available
             */
            var setBackForwardAllows = function () {
                var cs = RoomEditorService.getCurrentStep();
                var ls = RoomEditorService.getStepLength();

                if (cs < 0 || ls === 0) {
                    ctrl.gl.allowBack = false;
                } else {
                    ctrl.gl.allowBack = true;
                }

                if (cs === ls - 1) {
                    ctrl.gl.allowForward = false;
                } else {
                    ctrl.gl.allowForward = true;
                }
            };

            /**
             * @description function to handle click on align objects to front
             */
            ctrl.gl.alignObjectsFront = function () {
                RoomEditorService.alignObjects(false, "z", ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to handle click on align objects to back
             */
            ctrl.gl.alignObjectsBack = function () {
                RoomEditorService.alignObjects(true, "z", ctrl.room, obj3d.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to handle click on align objects to right
             */
            ctrl.gl.alignObjectsRight = function () {
                RoomEditorService.alignObjects(false, "x", ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**c
             * @description function to handle click on align objects to left
             */
            ctrl.gl.alignObjectsLeft = function () {
                RoomEditorService.alignObjects(true, "x", ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to handle click on close gap x-axis button
             */
            ctrl.gl.closeGapsX = function () {
                RoomEditorService.closeGaps("x", ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to handle click on close gap z-axis button
             */
            ctrl.gl.closeGapsZ = function () {
                RoomEditorService.closeGaps("z", ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to undo last step for align action
             */
            ctrl.gl.alignBack = function () {
                RoomEditorService.alignBack(ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description function to redo last step for align actions
             */
            ctrl.gl.alignForward = function () {
                RoomEditorService.alignForward(ctrl.room);
                RoomEditorService.markingHelper();
                setBackForwardAllows();
            };

            /**
             * @description change handler for visibility checkboxes in visibility panel
             * @param {number} id type id to change visibility for
             */
            ctrl.gl.handleVisibleChange = function (id) {
                var obj = ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === id;
                })[0];
                RoomEditorService.updateVisibility(obj3d.room, obj.objname, obj.visible, ctrl.room);
            };

            /**
             * @description change handler for opacity sliders in visiblity panel
             * @param {number} id type id to change opacity for
             */
            ctrl.gl.handleOpacityChange = function (id) {
                var obj = ctrl.gl.visibilityOpts.filter(function (el) {
                    return el.id === id;
                })[0];
                if (obj.id !== 9 && obj.id !== 10) {
                    RoomEditorService.updateOpacity(obj3d.room, obj.objname, obj.opacity, ctrl.room);
                } else {
                    handleRoomRotation();
                }
            };

            /**
             * @description function to toggle focus functionality
             */
            ctrl.gl.toggleFocus = function () {
                ctrl.gl.focusActive = !ctrl.gl.focusActive;
            };

            /**
             * @description function to handle camera rotation and set new positions for cluster labels
             */
            var handleMultiSensorLabels = function () {
                var tempSettings = ctrl.user.settings.filter(function (settings) {
                    return settings.key === "temp";
                });
                RoomEditorService.addSensorOverlay(ctrl.room,
                    ctrl.gl.sensorOverlayPhysType,
                    "glContainer",
                    Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0");
            };

            /**
             * @description function to toggle sensor overlay
             */
            ctrl.gl.toggleSensorOverlay = function () {
                ctrl.gl.showSensorOverlay = !ctrl.gl.showSensorOverlay;
                if (ctrl.gl.showSensorOverlay) {
                    var tempSettings = ctrl.user.settings.filter(function (settings) {
                        return settings.key === "temp";
                    });
                    RoomEditorService.addSensorOverlay(ctrl.room,
                        ctrl.gl.sensorOverlayPhysType,
                        "glContainer",
                        Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0");
                    WebGLService.addControlListener("handleMultiSensorLabels", handleMultiSensorLabels);
                } else {
                    RoomEditorService.removeSensorOverlay();
                    WebGLService.removeControlListener("handleMultiSensorLabels", handleMultiSensorLabels);
                }
            };

            /**
             * @description function to show/hide sensor overlay detail panel
             */
            ctrl.gl.toggleSensorOverlayOpts = function () {
                ctrl.gl.showSensorOverlayOpts = !ctrl.gl.showSensorOverlayOpts;
            };

            /**
             * @description function to set sensor overlay physical type
             * @param {number} type the physical type to set
             */
            ctrl.gl.setSensorOverlayType = function (type) {
                ctrl.gl.sensorOverlayPhysType = type;
                if (ctrl.gl.showSensorOverlay) {
                    var tempSettings = ctrl.user.settings.filter(function (settings) {
                        return settings.key === "temp";
                    });
                    RoomEditorService.removeSensorOverlay();
                    RoomEditorService.addSensorOverlay(ctrl.room,
                        ctrl.gl.sensorOverlayPhysType,
                        "glContainer",
                        Tools.isDefinedNotNull(tempSettings) && tempSettings.length === 1 ? tempSettings[0].value : "0");
                }
            };

            /**
             * @description function to object by unique id in current room object
             * @param uid
             * @returns {*}
             */
            ctrl.gl.getEntityByUniqueID = function (uid) {
                return ctrl.room.findObjectByUniqueID(uid);
            };

            /**
             * @description function to show/hide ghosts objects in room editor
             */
            ctrl.gl.toggleGhosts = function () {
                ctrl.gl.showGhosts = !ctrl.gl.showGhosts;
                if (!ctrl.gl.showGhosts && obj3d.dummys) {
                    obj3d.dummys.visible = false;
                } else {
                    obj3d.dummys.visible = true;
                }
            };
            //endregion

            /**
             * @description function to handle click on back button at bottom of editor panel
             */
            ctrl.goBack = function () {
                if (ctrl.config.edit) {
                    if (ctrl.viewObject.isRoom) {
                        if (ctrl.viewObject.id === null || ctrl.viewObject.id < 0) {
                            $state.go('location.rooms', undefined, {reload: true});
                        } else {
                            $state.go('location.rooms.view', {roomid: $stateParams.roomid}, {reload: true});
                        }
                    }
                    if (ctrl.viewObject.isRack) {
                        $state.go('location.rooms.edit', {roomid: $stateParams.roomid}, {reload: true});
                    }
                }
                if (!ctrl.config.edit) {
                    if (ctrl.viewObject.isRoom) {
                        if ($rootScope.previousStateName === "location.buildings.detail" || $rootScope.previousStateName === "location.rooms.floorview") {
                            $state.go($rootScope.previousStateName, $rootScope.previousStateParams, {reload: true});
                        } else {
                            $state.go('location.rooms', undefined, {reload: true});
                        }
                    }
                    if (ctrl.viewObject.isRack) {
                        $state.go('location.rooms.view', {roomid: $stateParams.roomid}, {reload: true});
                    }
                }
            };

            /**
             * @description function to handle click on edit button at bottom of editor panel
             */
            ctrl.editObject = function () {
                if (ctrl.viewObject instanceof Room) {
                    $state.go("location.rooms.edit", {
                        roomMode: "edit",
                        roomid: $stateParams.roomid
                    }, {reload: true});
                }
                if (ctrl.viewObject instanceof Rack) {
                    $state.go("location.rooms.edit.rack", {
                        roomid: $stateParams.roomid,
                        rackid: ctrl.viewObject.id,
                        rackMode: 'edit'
                    }, {reload: true});
                }
            };

            /**
             * @description function to determine if the current room has been changed with respect to last saved state
             * @returns {boolean} returns true if room object has been changed, otherwise false
             */
            ctrl.evalRoomChanged = function () {
                if (ctrl.room === undefined) return true;
                var currentEqualInitRoom = ctrl.room.equals(initRoom);
                return !currentEqualInitRoom;
            };

            function throwErrorForInvalidSensor(sensorId, dvId, errorString) {
                var sensor = ctrl.room.sensors.filter(function (sensor) {
                    return sensor.id === sensorId
                })[0];
                sensor.driverValues.filter(function (dv) {
                    if (dv.id === dvId) {
                        dv.driver.gatewaySensorId = null;
                        return true;
                    }
                });
                WebGLService.hideWaitingContainer();
                RoomEditorService.showSaveErrorDialog(errorString);
            }

            function setWirelessSensorsByType(driverValue) {
                var gateways = ctrl.wirelessGateways.filter(function (elem) {
                    return elem.id === driverValue.driver.gatewayId;
                });
                var gateway = null;
                if (gateways.length > 1) {
                    Notify.defaultError();
                    return;
                } else {
                    gateway = gateways[0];
                }
                var driverValParameter = driverValue.parameter;
                if (driverValParameter.physType === 1) {
                    ctrl.wirelessSensors = gateway.tempSensors.concat(gateway.tempHumiSensors);
                }
                if (driverValParameter.physType === 2) {
                    ctrl.wirelessSensors = gateway.tempHumiSensors;
                }
                if (driverValParameter.physType === 5) {
                    ctrl.wirelessSensors = gateway.pressureSensors;
                }
            }

            function _checkforInvalidLimits() {
                var invalidObjectNameList = [];
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.assets));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.coolings));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.floorTiles));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.racks));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.roomObjs));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.sensors));
                _combineLists(invalidObjectNameList, _getObjectsWithInvalidLimits(ctrl.viewObject.ups));
                return invalidObjectNameList;
            }

            function _combineLists(list, otherList) {
                if (!Array.isArray(list) && !Array.isArray(otherList)) {
                    return [];
                } else if (Array.isArray(list) && Array.isArray(otherList)) {
                    for (var otherListIndex in otherList) {
                        list.push(otherList[otherListIndex]);
                    }
                    return list;
                } else if (Array.isArray(list)) {
                    return list;
                } else if (Array.isArray(otherList)) {
                    return otherList;
                } else {
                    return [];
                }
            }

            function _isFilledArray(object) {
                return Tools.isDefinedNotNull(object) && Array.isArray(object) && object.length > 0;
            }

            function _getObjectsWithInvalidLimits(objects) {
                if (!_isFilledArray(objects)) return [];
                var objectWithInvalidLimits = [];
                for (var objectsIndex in objects) {
                    var currentObj = objects[objectsIndex];
                    if (_hasInvalidLimits(currentObj)) {
                        objectWithInvalidLimits.push(currentObj.name);
                    }

                }
                return objectWithInvalidLimits;
            }

            /**
             * search the current object for available driver values with limits. if there are any check if they are valid
             * @param object
             * @returns {*}
             * @private
             */
            function _hasInvalidLimits(object) {
                if (_isFilledArray(object.driverValues)) {
                    for (var dvsIndex in object.driverValues) {
                        var currentDriverValues = object.driverValues[dvsIndex];
                        if (_isFilledArray(currentDriverValues.limits)) {
                            for (var limitIndex in currentDriverValues.limits) {
                                var currentLimit = currentDriverValues.limits[limitIndex];
                                return !Tools.isDefinedNotNull(currentLimit.name) ||
                                    currentLimit.name === "" ||
                                    !Tools.isDefinedNotNull(currentLimit.value);
                            }
                        }
                    }
                }
                return false;
            }

            /**
             * @description function to handle click on save button at bottom of editor panel
             */
            ctrl.saveObject = function () {
                adjustHeatmap();
                ctrl.saveInProcess = true;
                ctrl.overlayMessage = "room.glCtrl.savingData";
                if (Tools.isDefinedNotNull(ctrl.room.outerWalls) && ctrl.room.outerWalls.length < 3) {
                    Notify.error("global.notification.error.title", "room.errorList.codes.simple.401", 4000);
                    ctrl.saveInProcess = false;

                } else {
                    RoomEditorService.setRoomBuildingId(buildings, ctrl.room)
                    if (ctrl.viewObject instanceof Room) {
                        if (ctrl.room.roomObjs.filter(function (el) {
                            return el.shadowDeleted !== undefined && el.shadowDeleted === true;
                        }).length > 0) {
                            showHeightChangeWindowDoorDeleteDialog();
                            return;
                        }
                        ignoreUpdate = true;
                        saveRoom();
                    } else if (ctrl.viewObject instanceof Rack) {
                        ignoreUpdate = true;
                        saveRoom();
                    } else {
                        WebGLService.hideWaitingContainer();
                        RoomEditorService.showSaveErrorDialog();
                        ignoreUpdate = true;
                        ctrl.saveInProcess = false;
                    }
                }
            };

            /**
             * Shows a dialog when a wall has been changed and windows/doors no longer have space on it
             */
            var showHeightChangeWindowDoorDeleteDialog = function () {
                RoomEditorService.showHeightChangeWindowDoorDeleteDialog(function () {
                    for (var i = ctrl.room.roomObjs.length - 1; i >= 0; i--) {
                        if (ctrl.room.roomObjs[i].shadowDeleted !== undefined && ctrl.room.roomObjs[i].shadowDeleted) {
                            ctrl.room.roomObjs.splice(i, 1);
                        }
                    }
                    ctrl.saveObject();
                }, function () {
                    var infoObj = RoomEditorService.findLastHeightChangeInfoObjectWithoutShadowDeletedObjects();
                    for (var i = 0; i < ctrl.room.roomObjs.length; i++) {
                        if (ctrl.room.roomObjs[i].shadowDeleted !== undefined) {
                            delete ctrl.room.roomObjs[i].shadowDeleted;
                        }
                    }
                    ctrl.room.size.y = infoObj.roomHeight;
                    for (var i = 0; i < ctrl.room.innerWalls.length; i++) {
                        var innerWallObj = infoObj.innerWallsInfo.filter(function (el) {
                            return el.id === ctrl.room.innerWalls[i].id;
                        });
                        if (innerWallObj.length === 1) {
                            ctrl.room.innerWalls[i].height = innerWallObj[0].height;
                        }
                    }
                    RoomEditorService.updateOuterWallHeight(ctrl.room);
                    RoomEditorService.updatePillarHeight(ctrl.room);
                    WebGLService.remove3DObjectFromContent(obj3d.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                    updateInnerWallMaxHeight();
                });
            };

            function checkCollision() {
                if (ctrl.config.tools.active >= 0) ctrl.toggleTool(ctrl.config.tools.active);
                var children = [];
                if (ctrl.viewObject instanceof Room) {
                    children = angular.copy(obj3d.room.children)
                } else if (ctrl.viewObject instanceof Rack) {
                    var roomObj = Object3DFactory.buildRoom(ctrl.room, "full", false);
                    children = angular.copy(roomObj.children)
                }
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    if (Tools.isDefinedNotNull(child)
                        && child.name !== "outerwall"
                        && child.name !== "raisedFloor"
                        && child.name !== "containment"
                        && child.name !== "sensor"
                        && child.name !== "dummyTile"
                        && !angular.equals(child.userData, {})) {
                        var filteredChildes = children.filter(function (objToCheck) {
                            if (!angular.equals(objToCheck.userData, {})
                                && objToCheck.uuid !== child.uuid
                                && objToCheck instanceof THREE.Mesh) {
                                if (objToCheck.name === "innerwall" && child.name === "innerwall") {
                                    return false;
                                }
                                if ((objToCheck.name === "innerwall" && child.name === "door") || (objToCheck.name === "innerwall" && child.name === "window_obj")) {
                                    return false;
                                }
                                if ((objToCheck.name === "door" && child.name === "innerwall") || (objToCheck.name === "window_obj" && child.name === "innerwall")) {
                                    return false;
                                }
                                if (objToCheck.name === "outerwall" && child.name === "innerwall") {
                                    return false;
                                }
                                return objToCheck.name !== "outerwall"
                                    && objToCheck.name !== "raisedFloor"
                                    && objToCheck.name !== "containment"
                                    && objToCheck.name !== "dummyTile"
                                    && objToCheck.name !== "sensor";
                            }
                        });
                        var objectCollideCheck = WebGLService.checkMultiObjectIntersectionOBB(child.userData.obb.clone(), filteredChildes);
                        if (objectCollideCheck) break;
                    }
                }
                if (!objectCollideCheck) {
                    if (ctrl.viewObject instanceof Room) {
                        saveInstanceRoom();
                    } else if (ctrl.viewObject instanceof Rack) {
                        updateOpenRack();
                        saveInstanceRack();
                    }
                } else {
                    WebGLService.hideWaitingContainer();
                    RoomEditorService.showSaveErrorDialog("room.edit.dialog.messages.objectsCollide");
                    ignoreUpdate = true;
                    ctrl.saveInProcess = false;
                }
            }

            function updateOpenRack() {
                for (var i = 0; i < ctrl.room.racks.length; i++) {
                    if (ctrl.room.racks[i].uniqueId === ctrl.viewObject.uniqueId) {
                        for (var j = 0; j < ctrl.room.racks[i].slots.length; j++) {
                            if (ctrl.room.racks[i].slots[j].uniqueId === ctrl.object.uniqueId) {
                                ctrl.room.racks[i].slots[j] = ctrl.object;
                                break;
                            } else {
                                for (var k = 0; k < ctrl.room.racks[i].slots[j].blades.length; k++) {
                                    for (var l = 0; l < ctrl.room.racks[i].slots[j].blades[k].cpus.length; l++) {
                                        if (ctrl.room.racks[i].slots[j].blades[k].cpus[l].uniqueId === ctrl.object.uniqueId) {
                                            ctrl.room.racks[i].slots[j].blades[k].cpus[l] = ctrl.object;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            function saveRoom() {
                if (!Tools.isDefinedNotNull(ctrl.room.name) || ctrl.room.name === "") {
                    Notify.error("global.notification.error.title", "room.notifications.roomNameEmpty", 5000);
                    ctrl.saveInProcess = false;
                } else {
                    ignoreUpdate = true;
                    WebGLService.unmarkObject(undefined, true);
                    RoomEditorService.updateURLForObject(null);
                    GLMeasureService.clearMeasure();
                    obj3d.selectedObj = null;
                    obj3d.selectedObjData = null;
                    if (!ctrl.config.edit) {
                        ctrl.config.allowDetails = true;
                    } else {
                        ctrl.config.allowDetails = false;
                    }
                    ctrl.config.alignToGrid = false;
                    Room.buildCornerArrayFromOuterWalls(ctrl.room, true);
                    if (checkWallsValidity()) {
                        checkCollision();
                    } else {
                        ctrl.saveInProcess = false;
                    }
                }
            }

            function checkWallsValidity() {
                function _showErrorDialog(text) {
                    WebGLService.hideWaitingContainer();
                    RoomEditorService.showSaveErrorDialog(text);
                    ignoreUpdate = true;
                }

                var room3d = angular.copy(obj3d.room);
                if (!Tools.isDefinedNotNull(room3d)) return true;
                var children = room3d.children;
                var objectInvalid = true;
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    if (Tools.isDefinedNotNull(child)) {
                        if (child.name === "window_obj") {
                            var obj = new Window(null, child.position, child.scale, child.rotation, null, null, null, null);
                            objectInvalid = !obj.validate(ctrl.room, obj3d.room, RoomEditorService);
                            if (objectInvalid) {
                                _showErrorDialog("room.errorList.codes.simple.404");
                                return false;
                            }
                        } else if (child.name === "door") {
                            var obj = new Door(null, child.position, child.scale, child.rotation, null, null, null, null);
                            objectInvalid = !obj.validate(ctrl.room);
                            if (objectInvalid) {
                                _showErrorDialog("room.errorList.codes.simple.405");
                                return false;
                            }
                        } else {
                            if (Tools.isDefinedNotNull(child.userData) && Tools.isDefinedNotNull(child.userData.obb)) {
                                objectInvalid = !RoomEditorService.checkAllObjectsWithinRoom(ctrl.room, obj3d.room);
                                if (objectInvalid) {
                                    _showErrorDialog("room.errorList.codes.simple.406");
                                    return false;
                                }
                            }
                        }
                    }
                }
                return true;
            }


            function removeParameterFromRoom(parameterId) {
                var entityFound = false;

                var _searchArray = function (object) {
                    for (var i = 0; i < object.length; i++) {
                        if (object[i].driverValues.length > 0) {
                            for (var j = 0; j < object[i].driverValues.length; j++) {
                                if (object[i].driverValues[j].parameter.id === parameterId) {
                                    object[i].driverValues.splice(j, 1);
                                    entityFound = true;
                                }
                            }
                        }
                    }
                }

                if (ctrl.room.ups.length > 0) {
                    _searchArray(ctrl.room.ups);
                }
                for (var i = 0; i < ctrl.room.racks.length; i++) {
                    if (ctrl.room.racks[i].slots.length > 0) {
                        _searchArray(ctrl.room.racks[i].slots);

                        if (!entityFound) {
                            for (var j = 0; j < ctrl.room.racks[i].slots.length; j++) {
                                for (var k = 0; k < ctrl.room.racks[i].slots[j].blades.length; k++) {
                                    if (ctrl.room.racks[i].slots[j].blades[k].cpus.length > 0) {
                                        _searchArray(ctrl.room.racks[i].slots[j].blades[k].cpus);
                                    }
                                }
                            }
                        }
                    }
                }

                if (!entityFound) {
                    if (ctrl.room.assets.length > 0) {
                        _searchArray(ctrl.room.assets);
                    }
                }
                if (!entityFound) {
                    if (ctrl.room.floorTiles.length > 0) {
                        _searchArray(ctrl.room.floorTiles);
                    }
                }

                if (!entityFound) {
                    if (ctrl.room.sensors.length > 0) {
                        _searchArray(ctrl.room.sensors);
                    }
                }
                if (!entityFound) {
                    if (ctrl.room.coolings.length > 0) {
                        _searchArray(ctrl.room.coolings);
                    }
                }
            }

            function makeItUnique(array) {
                var result = [];
                for (var i = 0; i < array.length; i++) {
                    var exists = false;
                    for (var j = 0; j < result.length; j++) {
                        if (array[i] === result[j]) {
                            exists = true;
                            break;
                        }
                    }
                    if (!exists) {
                        result.push(array[i]);
                    }
                }
                return result;
            }

            function saveInstanceRoom() {
                if (checkValidationError()) {
                    RoomEditorService.clearHeightChanges();
                    checkAndDeleteAllReferences();

                    RoomService.saveRoom(ctrl.room).then(function (response) {
                        if (!(response.data instanceof Room) && response.data.error === "-1") {
                            var msg = $translate.instant("room.edit.dialog.messages.noParameterFound", {
                                name: response.data.parameterName
                            });
                            RoomEditorService.showSaveErrorDialog(msg);
                            ctrl.saveInProcess = false;
                            handleParameterSetup();
                            removeParameterFromRoom(parseInt(response.data.parameterId));
                        } else if (!(response.data instanceof Room) && response.data.error === "-2") {
                            var _consumer = "";
                            consumer.filter(function (el) {
                                if (el.id === parseInt(response.data.consumerTypeId))
                                    _consumer = el.name;
                            });
                            var msg = $translate.instant("room.edit.dialog.messages.noConsumerFound", {
                                name: _consumer
                            });
                            RoomEditorService.showSaveErrorDialog(msg);
                            ctrl.saveInProcess = false;
                            handleParameterSetup();
                            removeParameterFromRoom(parseInt(response.data.consumerTypeId));
                        } else {
                            if (ctrl.room.id === null || ctrl.room.id < 0) {
                                $state.go("location.rooms.edit", {
                                    roomMode: "edit",
                                    roomid: response.data.id
                                });
                                ctrl.room = Room.parseFromHtmlObject(response.data);
                            } else {
                                ctrl.room = Room.parseFromHtmlObject(response.data);
                                updateRoom(response.data);
                            }
                            ctrl.room = Room.parseFromHtmlObject(response.data);
                            Notify.defaultSuccess();
                            WebGLService.hideWaitingContainer();
                            ignoreUpdate = true;
                            ctrl.saveInProcess = false;
                            ctrl.gl.setSingleSelection();
                        }
                        RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                    }, function () {
                        WebGLService.hideWaitingContainer();
                        RoomEditorService.showSaveErrorDialog();
                        ctrl.saveInProcess = false;
                        ignoreUpdate = true;
                    });
                } else {
                    ctrl.saveInProcess = false;
                }
            }

            var checkDevice = function () {
                var deviceMacLength = 0;
                for (var i = 0; i < ctrl.virtualDeviceMac.length; i++) {
                    for (var j = 0; j < ctrl.room.driverValueGroups.length; j++) {
                        if (!Tools.isDefinedNotNull(ctrl.room.driverValueGroups[j].deviceMac) ||
                            ctrl.virtualDeviceMac[i] === ctrl.room.driverValueGroups[j].deviceMac) {
                            deviceMacLength++;
                        }
                    }
                }
                if (ctrl.room.driverValueGroups.length === deviceMacLength) {
                    return true;
                }
                return false;
            };

            function checkValidationError() {
                var fields = [];
                var driverErrorList = [];
                var inputFieldErrorList = [];

                var addToDriverList = function (code, name, field, id) {
                    driverErrorList.push({
                        code: code,
                        entityName: name,
                        fieldName: field,
                        uniqueId: id
                    });
                };

                var addToInputFieldList = function (code, name, field) {
                    inputFieldErrorList.push({
                        code: code,
                        entityName: name,
                        fieldName: field
                    });
                };

                var _searchArray = function (arr) {
                    for (var i = 0; i < arr.length; i++) {

                        if (arr[i].isNameInvalid) {
                            addToInputFieldList(103, arr[i].name, "room.errorList.name");
                        }
                        if (arr[i].isCommentInvalid) {
                            addToInputFieldList(103, arr[i].name, "room.errorList.comment");
                        }
                        if (arr[i].driverValues && arr[i].driverValues.length > 0) {
                            for (var dv = 0; dv < arr[i].driverValues.length; dv++) {
                                if (Tools.isDefinedNotNull(arr[i].driverValues[dv].driver.id)) {
                                    var entity = arr[i];
                                    var driverVal = entity.driverValues[dv];
                                    var driverLimits = driverVal.limits;
                                    var driver = driverVal.driver;

                                    if (driver.isIdentifierInvalid) {
                                        addToDriverList(103, entity.name, "room.errorList.identifier", driverVal.uniqueId);
                                    }
                                    driverErrorList = driverErrorList.concat(Driver.validateDriverObject(entity, driverVal, driver));
                                    if (driverLimits !== undefined && driverLimits.length > 0) {
                                        for (var dL = 0; dL < driverLimits.length; dL++) {
                                            var driverLimit = driverLimits[dL];

                                            if (driverLimit.name === undefined || driverLimit.name === "") {
                                                addToDriverList(104, entity.name, "room.errorList.limit_name", driverVal.uniqueId);
                                            }
                                            if (driverLimit.value === undefined || driverLimit.name === "") {
                                                addToDriverList(105, entity.name, "room.errorList.limit_val", driverVal.uniqueId);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                };

                var _searchArrayRacks = function (racks) {
                    for (var indexOfRack = 0; indexOfRack < racks.length; indexOfRack++) {
                        // racks[indexOfRack].driverValues = [];
                        if (racks[indexOfRack].slots && racks[indexOfRack].slots.length > 0) {
                            for (var indexOfSlot = 0; indexOfSlot < racks[indexOfRack].slots.length; indexOfSlot++) {
                                if (ctrl.object.id === racks[indexOfRack].slots[indexOfSlot].id &&
                                    ctrl.object.uniqueId === racks[indexOfRack].slots[indexOfSlot].uniqueId) {
                                    racks[indexOfRack].slots[indexOfSlot].driverValues = ctrl.object.driverValues;
                                }
                                if (racks[indexOfRack].slots[indexOfSlot].blades && racks[indexOfRack].slots[indexOfSlot].blades.length > 0) {
                                    racks[indexOfRack].slots[indexOfSlot].driverValues = [];
                                    for (var indexOfBlade = 0; indexOfBlade < racks[indexOfRack].slots[indexOfSlot].blades.length; indexOfBlade++) {
                                        if (racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus && racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus.length > 0) {
                                            for (var indexOfCpu = 0; indexOfCpu < racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus.length; indexOfCpu++) {
                                                if (ctrl.object.id === racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus[indexOfCpu].id &&
                                                    ctrl.object.uniqueId === racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus[indexOfCpu].uniqueId) {
                                                    racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus[indexOfCpu].driverValues = ctrl.object.driverValues;
                                                }
                                            }
                                            _searchArray(racks[indexOfRack].slots[indexOfSlot].blades[indexOfBlade].cpus);
                                        }
                                    }
                                }
                            }
                            _searchArray(racks[indexOfRack].slots);
                        }
                    }
                    return null;
                };

                if (ctrl.room.racks.length !== 0) {
                    _searchArrayRacks(ctrl.room.racks);
                }
                if (ctrl.room.assets.length !== 0) {
                    _searchArray(ctrl.room.assets);
                }
                if (ctrl.room.sensors.length !== 0) {
                    _searchArray(ctrl.room.sensors);
                }
                if (ctrl.room.coolings.length !== 0) {
                    _searchArray(ctrl.room.coolings);
                }
                if (ctrl.room.roomObjs.length !== 0) {
                    _searchArray(ctrl.room.roomObjs);
                }
                if (ctrl.room.floorTiles.length !== 0) {
                    _searchArray(ctrl.room.floorTiles);
                }
                if (ctrl.room.ups.length !== 0) {
                    _searchArray(ctrl.room.ups);
                }

                var listOfAllErrors = inputFieldErrorList.concat(driverErrorList);

                if (listOfAllErrors.length === 0) {
                    return true;
                } else {
                    handelBackendErrorList(listOfAllErrors);
                    return false;
                }
                return false;
            }

            var updateRack = function (roomData) {
                setRoomObject(roomData);
                ctrl.viewObject = ctrl.viewObject.id < 0 ? ctrl.room.findObjectByTypeAndPos('rack', ctrl.viewObject.pos) : ctrl.room.findObjectByTypeAndID('rack', ctrl.viewObject.id);
                ctrl.rack = ctrl.viewObject;
                WebGLService.cleanContent();
                obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                WebGLService.add3DObjectToContent(obj3d.rack);
                if (!isCam3D()) {
                    var to = Object3DFactory.buildRack2DView(obj3d.rack);
                    WebGLService.add3DObjectToContent(to[0]);
                }
                if (!(ctrl.object instanceof Room)) {
                    if (ctrl.object instanceof Cpu) {
                        if (ctrl.object.id !== null && ctrl.object.id >= 0) {
                            ctrl.object = ctrl.rack.findCpuByAttribute("id", ctrl.object.id);
                        }
                    } else if (ctrl.object instanceof Slot) {
                        if (ctrl.object.id !== null && ctrl.object.id > 0) {
                            ctrl.object = ctrl.rack.findObjectByTypeAndId("slot", ctrl.object.id);
                        } else {
                            ctrl.object = ctrl.rack.findSlotByPosition(ctrl.object.pos);
                        }

                        if (ctrl.object !== undefined && ctrl.object !== null && ctrl.object instanceof Slot) {
                            obj3d.rack.traverse(function (o) {
                                if (o.name === "slot" && o.userData.uid === ctrl.object.uniqueId) WebGLService.markObject(o, undefined, true);
                            });
                        }
                    }
                    if (ctrl.object !== null) {
                        setupParams();
                    }
                }
            };

            var setRoomObject = function (roomData) {
                ctrl.room = Room.parseFromHtmlObject(roomData);
                ctrl.roomJson = ctrl.room.json;
                initRoom = angular.copy(ctrl.room);
            };

            var updateRoom = function (roomData) {
                setRoomObject(roomData);
                ctrl.room.setupWalls();
                ctrl.viewObject = ctrl.room;
                WebGLService.remove3DObjectFromContent(obj3d.room);
                if (ctrl.config.editState === 0) {
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), true);
                } else {
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                }
                if (ctrl.config.editState !== 0) RoomEditorService.updateAllVisibilityAndOpacity(ctrl.gl.visibilityOpts, ctrl.room, obj3d.room);
                handleRoomRotation();
                if (ctrl.object !== null && !(ctrl.object instanceof Room) && !(ctrl.object instanceof Containment)) {
                    ctrl.object = ctrl.room.findObjectByTypeAndPos(ctrl.object.constructor.name.toLowerCase(), ctrl.object.pos);
                    obj3d.selectedObj = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId, obj3d.room);
                    WebGLService.markObject(obj3d.selectedObj, undefined, true);
                    setupParams();
                }
            };

            function checkAndDeleteAllReferences() {
                if (tempDeleteDriverValueIds.length > 0) {
                    RoomService.deleteAllReferenceForDeletedDriverValueIds(tempDeleteDriverValueIds).then(function (response) {
                        if (response) tempDeleteDriverValueIds = [];
                    });
                }

                if (tempDeleteDriverValueModbusUids.length > 0) {
                    // This because sometimes duplicates values can be passed to the backend (CGLT-906)
                    var reducedArray = makeItUnique(tempDeleteDriverValueModbusUids);

                    RoomService.deleteAllReferenceForDeletedDriverValueModbusUids(reducedArray).then(function (response) {
                        if (response) tempDeleteDriverValueModbusUids = [];
                    });
                }
            }

            function saveInstanceRack() {
                if (checkValidationError()) {
                    checkAndDeleteAllReferences();
                    RoomService.saveRoom(ctrl.room).then(function (response) {
                        if (!(response.data instanceof Room) && response.data.error === "-1") {
                            var msg = $translate.instant("room.edit.dialog.messages.noParameterFound", {
                                name: response.data.parameterName
                            });
                            RoomEditorService.showSaveErrorDialog(msg);
                            ctrl.saveInProcess = false;
                            handleParameterSetup();
                            removeParameterFromRoom(parseInt(response.data.parameterId));
                        } else {
                            Notify.defaultSuccess();
                            updateRack(response.data);
                            ctrl.saveInProcess = false;
                            ignoreUpdate = true;
                            WebGLService.hideWaitingContainer();
                        }
                    }, function () {
                        WebGLService.hideWaitingContainer();
                        RoomEditorService.showSaveErrorDialog("room.edit.dialog.messages.saveError");
                        ignoreUpdate = true;
                    });
                } else {
                    ctrl.saveInProcess = false;
                }
            }

            function handelBackendErrorList(data) {
                WebGLService.hideWaitingContainer();
                ignoreUpdate = true;
                ctrl.saveInProcess = false;
                showErrorListModal(data);
            }

            /**
             * @description function to handle successful deletion of object
             */
            var handleDeleteObjectSuccess = function () {
                if (!(ctrl.object instanceof Cpu)) {
                    if (Tools.isDefinedNotNull(obj3d.selectedObj)) {
                        WebGLService.unmarkObject(obj3d.selectedObj, true);
                    }
                    if (ctrl.object instanceof Slot) {
                        var oo = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId);
                        oo.parent.remove(oo);
                        if (!ctrl.gl.is3d) {
                            var oo = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId);
                            oo.parent.remove(oo);
                        }
                    } else {
                        var oo = WebGLService.findObjectByUniqueId(ctrl.object.uniqueId);
                        oo.parent.remove(oo);
                    }
                } else {
                    WebGLService.unmarkObject(undefined, true);
                }
                ctrl.room.deleteObject(ctrl.object);
                GLMeasureService.clearMeasure();
                ctrl.object = ctrl.viewObject instanceof Room ? ctrl.room : ctrl.rack;
                RoomEditorService.updateURLForObject(null);
                ctrl.config.allowDetails = false;
                Notify.success("global.notification.success.title", "room.notifications.deleteSuccess", 2000);
                if (ctrl.isFreeVersion && RoomEditorService.checkEntityQuantity(ctrl.room) < 3) {
                    enableDragDrop();
                }
                ctrl.InvalidRackHuNumber = ctrl.checkHURoomRacks();
            };

            /**
             * @description function to handle successful deletion of array of objects (multi selection mode)
             */
            var handleDeleteMultiObjectSuccess = function (selectedObjects) {
                for (var i = 0; i < selectedObjects.length; i++) {
                    WebGLService.unmarkObject(selectedObjects[i], true);
                    var oo = WebGLService.findObjectByUniqueId(selectedObjects[i].userData.uid);
                    var ou = ctrl.room.findObjectByUniqueID(selectedObjects[i].userData.uid);
                    oo.parent.remove(oo);
                    ctrl.room.deleteObject(ou);
                    initRoom.deleteObject(ou);
                }
                GLMeasureService.clearMeasure();
                ctrl.object = ctrl.viewObject;
                RoomEditorService.updateURLForObject(null);
                ctrl.config.allowDetails = false;
                Notify.success("global.notification.success.title", "room.notifications.deleteSuccess", 2000);
                if (ctrl.isFreeVersion && RoomEditorService.checkEntityQuantity(ctrl.room) < 3) {
                    enableDragDrop();
                }
                ctrl.InvalidRackHuNumber = ctrl.checkHURoomRacks();
            };

            /**
             * @description function to handle errors when deleting a object
             */
            var handleDeleteObjectError = function () {
                Notify.error("global.notification.error.title", "room.notifications.deleteError", 2000);
            };

            /**
             * @description function to handle click on delete button in object detail view
             */
            ctrl.deleteObject = function () {
                RoomEditorService.showDeleteObjectDialog(ctrl.object, ctrl.config.errorConfigs, function () {
                    if (ctrl.object.id > 0) {
                        if (Tools.isDefinedNotNull(ctrl.room.json)) {
                            if (ctrl.object instanceof Slot) {
                                RoomService.deleteUnsavedEntity(ctrl.object, ctrl.rack, obj3d.rack);
                                GenDialogService.hideDialog();
                                handleDeleteObjectSuccess();
                            } else {
                                var driverValueIds = deleteEntityFromRoomObject(ctrl.object);
                                var driverValueModbusUids = [];
                                if (ctrl.object instanceof Rack) {
                                    for (var s = 0; s < ctrl.object.slots.length; s++) {
                                        if (ctrl.object.slots[s].driverValues.length > 0) {
                                            driverValueModbusUids = driverValueModbusUids.concat(getDriverValueModbusUids(ctrl.object.slots[s].driverValues));
                                        } else {
                                            for (var b = 0; b < ctrl.object.slots[s].blades.length; b++) {
                                                for (var c = 0; c < ctrl.object.slots[s].blades[b].cpus.length; c++) {
                                                    if (ctrl.object.slots[s].blades[b].cpus[c].driverValues.length > 0) {
                                                        driverValueModbusUids = driverValueModbusUids.concat(getDriverValueModbusUids(ctrl.object.slots[s].blades[b].cpus[c].driverValues));
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    driverValueModbusUids = getDriverValueModbusUids(ctrl.object.driverValues);
                                }
                                if (driverValueIds !== undefined && driverValueIds.length > 0) {
                                    tempDeleteDriverValueIds = tempDeleteDriverValueIds.concat(driverValueIds);
                                }
                                if (driverValueModbusUids !== undefined && driverValueModbusUids.length > 0) {
                                    tempDeleteDriverValueModbusUids = tempDeleteDriverValueModbusUids.concat(driverValueModbusUids);
                                }
                                GenDialogService.hideDialog();
                                handleDeleteObjectSuccess();
                            }
                        } else {
                            RoomService.deleteUnsavedEntity(ctrl.object, ctrl.room, obj3d.room);
                            GenDialogService.hideDialog();
                            handleDeleteObjectSuccess();
                        }
                    } else {
                        if (ctrl.object instanceof Slot) {
                            RoomService.deleteUnsavedEntity(ctrl.object, ctrl.rack, obj3d.rack);
                        } else if (ctrl.object instanceof Cpu) {
                            RoomService.deleteUnsavedEntity(ctrl.object, ctrl.rack.findObjectByTypeAndId("blade", ctrl.object.bladeId), obj3d.rack);
                        } else {
                            RoomService.deleteUnsavedEntity(ctrl.object, ctrl.room, obj3d.room);
                        }
                        GenDialogService.hideDialog();
                        handleDeleteObjectSuccess();
                    }
                });
            };

            var deleteObjectFromArray = function (obj, array) {
                var idx = null;
                for (var i = 0; i < array.length; i++) {
                    if (array[i].id == obj.id) {
                        idx = i;
                        break;
                    }
                }
                if (idx !== null) {
                    array.splice(idx, 1);
                    return array;
                }
            };

            var getDriverValueIds = function (driverValues) {
                var driverValueIds = [];
                for (var i = 0; i < driverValues.length; i++) {
                    if (driverValues[i].id > 0) {
                        driverValueIds = driverValueIds.concat(driverValues[i].id);
                    }
                }

                return driverValueIds;
            };

            var getDriverValueModbusUids = function (driverValues) {
                var driverValueUids = [];
                for (var i = 0; i < driverValues.length; i++) {
                    if (driverValues[i].uniqueId > 0 && driverValues[i].driver.driverType == ctrl.driverType.MODBUS_TCP.id) {
                        driverValueUids = driverValueUids.concat(driverValues[i].uniqueId);
                    }
                }

                return driverValueUids;
            };


            var deleteEntityFromRoomObject = function (obj) {
                if (obj instanceof Asset) {
                    ctrl.room.assets = deleteObjectFromArray(obj, ctrl.room.assets);
                    return getDriverValueIds(obj.driverValues);
                }
                if (obj instanceof Cooling) {
                    ctrl.room.coolings = deleteObjectFromArray(obj, ctrl.room.coolings);
                    return getDriverValueIds(obj.driverValues);
                }
                if (obj instanceof Rack) {
                    if (ctrl.room.racks.indexOf(obj) != -1) {
                        ctrl.room.racks = deleteObjectFromArray(obj, ctrl.room.racks);
                    }
                    var driverValueIds = [];
                    for (var s = 0; s < obj.slots.length; s++) {
                        if (obj.slots[s].driverValues.length > 0) {
                            driverValueIds = driverValueIds.concat(getDriverValueIds(obj.slots[s].driverValues));
                        } else {
                            for (var b = 0; b < obj.slots[s].blades.length; b++) {
                                for (var c = 0; c < obj.slots[s].blades[b].cpus.length; c++) {
                                    if (obj.slots[s].blades[b].cpus[c].driverValues.length > 0) {
                                        driverValueIds = driverValueIds.concat(getDriverValueIds(obj.slots[s].blades[b].cpus[c].driverValues));
                                    }
                                }
                            }
                        }
                    }
                    return driverValueIds;
                }
                if (obj instanceof Sensor) {
                    ctrl.room.sensors = deleteObjectFromArray(obj, ctrl.room.sensors);
                    return getDriverValueIds(obj.driverValues);
                }
                if (obj instanceof FloorTile) {
                    ctrl.room.floorTiles = deleteObjectFromArray(obj, ctrl.room.floorTiles);
                    return getDriverValueIds(obj.driverValues);
                }
                if (obj instanceof Ups) {
                    ctrl.room.ups = deleteObjectFromArray(obj, ctrl.room.ups);
                    return getDriverValueIds(obj.driverValues);
                }
            };

            var tempDeleteDriverValueIds = [];
            var tempDeleteDriverValueModbusUids = [];

            ctrl.deleteSelectedObjects = function () {
                RoomEditorService.showDeleteSelectedObjects(ctrl.room, function () {
                    var SelectedItems = RoomEditorService.getSelectedObjects();
                    if (SelectedItems && SelectedItems.length > 0) {
                        for (var i = 0; i < SelectedItems.length; i++) {
                            var currentObject = ctrl.room.findObjectByUniqueID(SelectedItems[i].userData.uid);
                            // start check if there are MdobusTCP drivervalues
                            var driverValueModbusUids = [];

                            if (currentObject instanceof Rack) {
                                for (var s = 0; s < currentObject.slots.length; s++) {
                                    if (currentObject.slots[s].driverValues.length > 0) {
                                        driverValueModbusUids = driverValueModbusUids.concat(getDriverValueModbusUids(currentObject.slots[s].driverValues));
                                    } else {
                                        for (var b = 0; b < currentObject.slots[s].blades.length; b++) {
                                            for (var c = 0; c < currentObject.slots[s].blades[b].cpus.length; c++) {
                                                if (currentObject.slots[s].blades[b].cpus[c].driverValues.length > 0) {
                                                    driverValueModbusUids = driverValueModbusUids.concat(getDriverValueModbusUids(currentObject.slots[s].blades[b].cpus[c].driverValues));
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                driverValueModbusUids = getDriverValueModbusUids(currentObject.driverValues);
                            }
                            var driverValueIds = deleteEntityFromRoomObject(currentObject);
                            // end check if there are MdobusTCP drivervalues
                            if (driverValueIds !== undefined && driverValueIds.length > 0) {
                                tempDeleteDriverValueIds = tempDeleteDriverValueIds.concat(driverValueIds);
                            }
                            if (driverValueModbusUids !== undefined && driverValueModbusUids.length > 0) {
                                tempDeleteDriverValueModbusUids = tempDeleteDriverValueModbusUids.concat(driverValueModbusUids);
                            }
                        }
                        if (tempDeleteDriverValueIds.length > 0 || tempDeleteDriverValueModbusUids.length > 0) {
                            RoomEditorService.clearSelectedObjects();
                            handleDeleteMultiObjectSuccess(SelectedItems);
                            GenDialogService.hideDialog();
                            ctrl.gl.setSingleSelection();
                            ctrl.gl.allowBack = false;
                        } else {
                            handleDeleteMultiObjectSuccess(SelectedItems);
                            GenDialogService.hideDialog();
                            ctrl.gl.setSingleSelection()
                            ctrl.gl.allowBack = false;
                        }
                    }
                });
            };

//endregion
// DL start
            ctrl.scrolldown = function (ID) {
                document.getElementById(ID).scrollTop += 100;
            };
            ctrl.scrollup = function (ID) {
                document.getElementById(ID).scrollTop -= 100;
            };
// DL end

//prevent data loss
            $window.onbeforeunload = function (e) {
                if (ctrl.evalRoomChanged()) {
                    e.preventDefault();
                    return $translate.instant("room.unsavedChangesLost");
                }
            };
            localStorageService.remove("roomData");
            var dbRoom = localStorageService.get("dbRoom");
            if (dbRoom) {
                initRoom = Room.parseFromSimpleObject(dbRoom);
                initRoom.floorType = dbRoom.floorType;
                localStorageService.remove("dbRoom");
            }

            var ignoreNextStateChange = false;
            $scope.$on("$stateChangeStart", function (event, toState, toParams) {
                if (ctrl.evalRoomChanged() && !ignoreNextStateChange && toState.name !== "location.rooms.edit.rack" && toState.name !== "location.rooms.edit" && toState.name != "login") {
                    event.preventDefault();
                    $translate(['global.dialog.head.warning', 'room.unsavedChangesLost', 'room.reallyLeaveQ', 'global.modal.empty.yes', 'global.modal.empty.no']).then(function (trans) {
                        GenDialogService.showDialog(false, {
                            headText: trans['global.dialog.head.warning'],
                            headIcon: 'glyphicon glyphicon-warning-sign',
                            messageText: trans['room.unsavedChangesLost'] + "\n" + trans["room.reallyLeaveQ"],
                            showClose: false,
                            textButton1: trans['global.modal.empty.yes'],
                            textButton0: trans['global.modal.empty.no'],
                            iconButton1: 'glyphicon glyphicon-trash',
                            iconButton0: 'glyphicon glyphicon-chevron-left',
                            classButton1: 'btn-danger',
                            classButton0: 'btn-default',
                            callbackButton1: function () {
                                GenDialogService.hideDialog();
                                ignoreNextStateChange = true;
                                if (toState.name === "location.rooms.edit.entity") {
                                    $state.go(toState.name, {
                                        id: toParams.id,
                                        mode: toParams.mode,
                                        entityType: toParams.entityType,
                                        entityId: toParams.entityId
                                    });
                                } else {
                                    $state.go(toState.name, toParams);
                                }
                            },
                            callbackButton0: function () {
                                GenDialogService.hideDialog();
                            }
                        });
                    });
                }
                if (toState.name === "login") {
                    cancelIntervals();
                    destroyContext();
                }
                if ((toState.name === "location.rooms.edit.rack" || toState.name === "location.rooms.view.rack") && ctrl.viewObject instanceof Room) {
                    localStorageService.add("roomData", ctrl.room);
                }
                if ((toState.name === "location.rooms.edit" || toState.name === "location.rooms.view") && ctrl.viewObject instanceof Rack) {
                    localStorageService.add("roomData", ctrl.room);
                }
            });

            /**
             * @description basic init function
             */
            ctrl.$onInit = function () {
                Device.getVirtualDevices().then(function (response) {
                    ctrl.virtualDeviceMac = response.data;
                });
                if (Tools.isDefinedNotNull($stateParams.id)) {
                    Device.getAvailableNodes($stateParams.id).then(function (response) {
                        ctrl.availableDevices = response.data;
                    }, function (error) {
                        console.error("Could not retrieve avaialbe devices!");
                    });
                }
                if (quickbarSettings !== undefined && quickbarSettings !== null) {
                    ctrl.config.partlib.isDefault = true;
                    ctrl.config.partlib.qbItems = quickbarSettings.settings;
                    for (var i = 0; i < ctrl.config.partlib.qbItems.length; i++) {
                        var item = ctrl.config.partlib.items[ctrl.config.partlib.qbItems[i].type].filter(function (elem) {
                            return elem.uniqueId === ctrl.config.partlib.qbItems[i].uid;
                        });
                        if (item.length) item[0].inQB = true;
                    }
                }
                // ChangeService.getServerTime().then(function (response) {
                //     if (response && response instanceof Number) lastChangeTime = response;
                // });
                RoomEditorService.getTranslations().then(function (trans) {
                    ctrl.trans = trans;
                }, function (error) {
                    ctrl.trans = [];
                });

                MessageService.subscribe("mouseleave", handleMouseLeave);

                ctrl.LicenseExpired = false;

                License.get().then(function (response) {
                    ctrl.LicenseExpired = response.data.expired;
                });
                License.getIsFreeVersion().then(function (response) {
                    if (response.data === "freeVersion") {
                        ctrl.isFreeVersion = true;
                    } else {
                        ctrl.isFreeVersion = false;
                    }
                });

                RoomEditorService.clearSelectedObjects();
                RoomEditorService.clearSteps();
                // Clear Clipboard if there is a slot object inside it and it's not an open rack
                if (ClipboardService.hasItem()) {
                    if (ClipboardService.getItem() instanceof Slot) {
                        ClipboardService.clearClipboard();
                    }
                }
                if (Tools.isDefinedNotNull(ctrl.object)) {
                    ctrl.roomJson = ctrl.object.json;
                }
            };

            /**
             * @description function to handle mouse leave for editor mouse
             * @param {string} msg the message
             * @param {object} opts options object
             */
            var handleMouseLeave = function (msg, opts) {
                var event = new MouseEvent("mouseup", {
                    button: 0,
                    buttons: 1,
                    offsetX: opts.offsetX,
                    offsetY: opts.offsetY
                });
                WebGLService.getRendererDomElement().dispatchEvent(event);
            };

            /**
             * @description function to destroy rendering context
             */
            var destroyContext = function () {
                cancelIntervals();
                WebGLService.removeControlListener("hidewalls", handleRoomRotation);
                WebGLService.removeControlListener("trackLabels", handleRoomRotation);
                WebGLService.removeControlListener("handleMultiSensorLabels", handleRoomRotation);
                TrackingDivLabelFactory.clearAll();
                WebGLService.destroy();
                ctrl.object = null;
                ctrl.room = null;
                ctrl.rack = null;
                ctrl.viewObject = null;
                obj3d.room = null;
                obj3d.rack = null;
                $window.onbeforeunload = undefined;
                $(window).off("resize");
            };

            $scope.$on('$destroy', function () {
                roomLifeDataQueryBusy = false;
                destroyContext();
            });

// function for e2e tests
            ctrl.redrawContent = function () {
                if (ctrl.viewObject instanceof Room) {
                    WebGLService.remove3DObjectFromContent(obj3d.room);
                    obj3d.room = RoomEditorService.genRoom(ctrl.room, getDisplayMode(), false);
                    WebGLService.add3DObjectToContent(obj3d.room);
                }
                if (ctrl.viewObject instanceof Rack) {
                    WebGLService.remove3DObjectFromContent(obj3d.rack);
                    obj3d.rack = Object3DFactory.buildRack(ctrl.rack, getDisplayMode(), true);
                    Object3DFactory.buildSlotsForRack(ctrl.rack, obj3d.rack, getDisplayMode());
                    WebGLService.add3DObjectToContent(obj3d.rack);
                }
            };

            var showErrorListModal = function (errorObjects) {
                closeErrorListModal();
                errorListModal = $uibModal.open({
                    animation: false,
                    component: 'errorListComponent',
                    windowClass: 'animated fadeInDown',
                    size: 'lg',
                    close: closeErrorListModal(),
                    resolve: {
                        errors: function () {
                            return errorObjects;
                        },
                        room: function () {
                            return ctrl.room;
                        }
                    }
                });
            };

            var closeErrorListModal = function () {
                if (errorListModal !== null) errorListModal.close();
            };
            // endregion
        });
})
();
