/**
 * Created by MAS on 12.04.21.
 */
(function () {

    'use strict';

    function WidgetGaugeController($scope, $q, $uibModal, $log, $translate, $timeout, $interval, $http, $window, $state, Notify, RoomService, WidgetData, Location,
                                   PhysicalTypeService, Tools, digiChart, LiveDataService, TreeSelectionService) {

        var ctrl = this;
        var currentModal;
        var initPhysType = null;
        var initLocation = null;
        var initDPs = [];
        var liveDataQueryInterval = null;
        var isIntervalRequestIsInProgress = false;

        ctrl.isDpFilterSearchTerm = true;
        ctrl.loadDatapoint = null;
        ctrl.physicalTypes = [];
        ctrl.locations = null;
        ctrl.rooms = null;
        ctrl.isLocationSelected = true;
        ctrl.spinnerDisplay = false;

        ctrl.selectedPhysType = null;
        ctrl.customUnitList = [];

        ctrl.selectedLocationId = null;
        ctrl.previousSelectedLocationId = null;
        ctrl.selectedDriverValue = null;
        ctrl.chart = null;
        ctrl.isReset = false;
        var liveData = [];
        var latestLiveData = null;
        ctrl.displayData = 0;

        ctrl.tree = {
            model: [], options: {
                multiSelection: true, nodeChildren: 'children', dirSelectable: false, injectClasses: {
                    iExpanded: 'fa fa-caret-down', iCollapsed: 'fa fa-caret-right'
                }
            }, filter: "", expandedNodes: [], selectedNodes: []
        };

        ctrl.dpArray = [];
        ctrl.datapoints = [];
        ctrl.filteredTreeNodeIds = []

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl._configure);
            getPhysTypes();
            getLocations();
            _loadSettings();
            initPhysType = angular.copy(ctrl.selectedPhysType);
            initLocation = angular.copy(ctrl.selectedLocationId);
            startLiveDataQuery();
        };

        ctrl._configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/gauge/widget.gauge.modal.html',
                animation: true,
                showClose: false,
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
            getPhysTypes();
            getLocations();
            _loadSettings();
            if (ctrl.selectedLocationId !== null) {
                ctrl.setupSelectedEntity();
            }

            if(ctrl.datapoints && ctrl.datapoints.length){
                ctrl.validateDatapoints = true;
            }
        };


        ctrl.closeModal = function () {
            if (currentModal) {
                if (ctrl.selectedPhysType !== initPhysType) {
                    ctrl.selectedPhysType = initPhysType;
                }
                if (ctrl.selectedLocationId !== initLocation) {
                    ctrl.selectedLocationId = initLocation;
                }
                if (ctrl.datapoints !== initDPs) {
                    ctrl.datapoints = initDPs;
                }
                if (ctrl.selectedLocationId === null && ctrl.datapoints.length === 0) {
                    ctrl.tree.model = [];
                    ctrl.previousSelectedLocationId = ctrl.selectedLocationId;
                }
                ctrl.validateDatapoints = false;
                currentModal.close();
            }
        };

        ctrl.cancelModal = function () {
            if (ctrl.selectedPhysType !== initPhysType) {
                ctrl.selectedPhysType = initPhysType;
            }
            if (ctrl.selectedLocationId !== initLocation) {
                ctrl.selectedLocationId = initLocation;
            }
            if (ctrl.datapoints !== initDPs) {
                ctrl.datapoints = initDPs;
            }
            if (ctrl.selectedLocationId === null && ctrl.datapoints.length === 0) {
                ctrl.tree.model = [];
                ctrl.previousSelectedLocationId = ctrl.selectedLocationId;
            }

            stopLiveDataQuery();
            startLiveDataQuery();
            currentModal.close();
        };


        ctrl.setupSelectedEntity = function () {

            if (ctrl.selectedLocationId === null) {
                ctrl.tree.model = [];
                return;
            }
            if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                ctrl.rooms = [];
                $http.get('api/rooms/' + ctrl.selectedLocationId).then(function (rooms) {
                    if (rooms.data.length > 0) {
                        for (var index = 0; index < rooms.data.length; index++) {
                            ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                        }
                    }

                    ctrl.tree.model = TreeSelectionService.createSelectionTree(ctrl.rooms, [driverValueFilter]);
                    ctrl.loadDatapoint = ctrl.tree.model;

                    if (ctrl.datapoints && ctrl.datapoints.length) {
                        ctrl.previousSelectedLocationId = ctrl.selectedLocationId;
                        findNodeInTreeModel();
                    }
                });
            }
        }

        var driverValueFilter = function(reference){
            return reference.physicalType === ctrl.selectedPhysType;
        }

        ctrl.inputChanged = function (searchTerm) {
            if (searchTerm === undefined || searchTerm === null || searchTerm === "" || ctrl.selectedLocationId === undefined || ctrl.selectedLocationId === null) {
                ctrl.isDpFilterSearchTerm = true;
                ctrl.tree.model = ctrl.loadDatapoint;
            } else {
                ctrl.isDpFilterSearchTerm = false;
            }
        }

        ctrl.filteredTreeNode = function (searchTerm) {

            ctrl.filteredTreeNodeIds = [];
            var array = JSON.parse(JSON.stringify(ctrl.loadDatapoint));
            ctrl.tree.model = filter(array, searchTerm.toLowerCase());

            ctrl.tree.expandedNodes = [];
            ctrl.filteredTreeNodeIds.forEach(function(id){
                searchChildToExpand(ctrl.tree.model, id);
            });


        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.driverValueUniqueId && object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    ctrl.filteredTreeNodeIds.push(object.driverValueUniqueId);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }

        ctrl.physTypeSelection = function () {
            if (ctrl.selectedPhysType != null) {
                ctrl.canSave = true;
            }
        }

        function findNodeInTreeModel() {
            if (ctrl.tree.expandedNodes === undefined || ctrl.tree.expandedNodes.length > 0) ctrl.tree.expandedNodes = [];
            if (ctrl.datapoints && ctrl.datapoints.length) {
                ctrl.datapoints.forEach(function(dp){
                    var selectedNoded  = searchTreeByProperty(dp);
                    if (Tools.isDefinedNotNull(selectedNoded)) {
                        ctrl.tree.selectedNodes.push(selectedNoded);
                    }
                });

            }
        }

        var searchTreeByProperty = function (datapoint) {
            var foundNode = null;
            var traverse = function (nodes) {
                var newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    var node = newNodes[index];
                    if (node.driverValueUniqueId && node.driverValueUniqueId === datapoint.unqId) {
                        foundNode = node;
                        break;
                    }
                    if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        traverse(node.children);
                    }
                }
            };
            traverse(ctrl.tree.model);

            if(foundNode){
                searchChildToExpand(ctrl.tree.model, datapoint.unqId);
            }

            return foundNode;
        }


        var searchChildToExpand = function (nodes, childId) {
            let travers = function (nodes, childId) {

                for (const node of nodes) {
                    if (node.driverValueUniqueId === childId) {
                        return node;
                    } else if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        let child = travers(node.children, childId);
                        if(child){
                            ctrl.tree.expandedNodes.push(child);
                            let nodeToExpand = {
                                id: node.id,
                                title: node.title,
                                children:[child]
                            };
                            return nodeToExpand;
                        }
                    }
                }
            };

            var expNodes = travers(nodes, childId);
            ctrl.tree.expandedNodes.push(expNodes);
        };


        ctrl.handleTreeSelect = function (node, selected) {
            if (selected) {
                ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, node.driverValueUniqueId);
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {

                    var dp = {};
                    dp.id = ctrl.selectedDriverValue.id;
                    dp.unqId = ctrl.selectedDriverValue.uniqueId;
                    dp.protocolType = ctrl.selectedDriverValue.driver.driverType;
                    dp.registerValueType = ctrl.selectedDriverValue.driver.registerValueType;
                    dp.unitOfMeasurement = $translate.instant(ctrl.selectedDriverValue.parameter.name);
                    dp.unit = PhysicalTypeService.getPhysTypeUnit(ctrl.selectedDriverValue.physicalType);
                    dp.path = node.path;
                    dp.title = node.title;
                    dp.locId = node.locationId;
                    dp.liveValue = null;
                    if (ctrl.datapoints.length < 7) {
                        for (var i = 0; i < ctrl.datapoints.length; i++) {
                            if (ctrl.datapoints[i].id === dp.id) {
                                return;
                            }
                        }
                        ctrl.datapoints.push(dp);
                    } else {
                        Notify.warning("global.notification.warning.info", "dashboard.widget.gauge.modal.dpLimit", 3000);
                    }

                } else {
                    Notify.warning("global.notification.warning.warn", "dashboard.widget.speedometer.modal.warningAvailable", 4000);
                }

            }else{
                for(var i = 0; i < ctrl.datapoints.length; i++ ){
                    if(ctrl.datapoints[i].unqId === node.driverValueUniqueId){
                        ctrl.datapoints.splice(i,1);
                        break;
                    }
                }
            }

            if (ctrl.datapoints.length) {
                ctrl.dpListEmpty = false;
                loadGauges();
            } else {
                ctrl.dpListEmpty = true;
            }
        };

        ctrl.removeDatapoint = function (arr, value) {
            ctrl.datapoints = arrayRemove(arr, value);
            for(var i = 0; ctrl.tree.selectedNodes.length; i++){
                if(ctrl.tree.selectedNodes[i].driverValueUniqueId === value.unqId){
                    ctrl.tree.selectedNodes.splice(i, 1);
                    break;
                }
            }
        }

        function arrayRemove(arr, value) {
            return arr.filter(function (ele) {
                return ele != value;
            });
        }

        ctrl.saveSettings = function () {
            var newSettings = [];
            if (Tools.isDefinedNotNull(ctrl.selectedLocationId)) {
                newSettings.push({key: 'selectedPhysType', value: ctrl.selectedPhysType});
                newSettings.push({key: 'selectedLocationId', value: ctrl.selectedLocationId});
                newSettings.push({key: 'gaugeDatapoints', value: JSON.stringify(getReducedDatapoints(ctrl.datapoints))});
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
            } else {
                $scope.widget.settings = [];
            }

            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    initDPs = ctrl.datapoints;
                    ctrl.closeModal();
                    $state.go($state.current, {}, {reload: true});
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                });
            }
        };

        var getReducedDatapoints = function(dps){
            var reduced = [];
            dps.forEach(dp => {
                let copy = angular.copy(dp);
                delete copy.liveValue;
                delete copy.latestLiveValue;
                reduced.push(copy);
            });
            return reduced;
        }

        ctrl.prepareLocPath = function (path) {
            var cut = path.split('/')
            var output = cut[0].split(':')[1] + ' / ' + cut[1];
            return output;
        }

        ctrl.removeLoc = function () {
            ctrl.isReset = true;
            ctrl.selectedPhysType = null;
            ctrl.selectedLocationId = null;
            ctrl.datapoints = [];
            ctrl.tree.model = [];
            ctrl.tree.filter = "";
        }

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case 'selectedPhysType':
                            ctrl.selectedPhysType = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedLocationId':
                            ctrl.selectedLocationId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'gaugeDatapoints':
                            ctrl.datapoints = JSON.parse($scope.widget.settings[i].value);
                            break;
                    }
                }
                if (ctrl.selectedPhysType !== null) {
                    initPhysType = ctrl.selectedPhysType;
                }
                if (ctrl.selectedLocationId !== null) {
                    initLocation = ctrl.selectedLocationId;
                }
                if (ctrl.datapoints.length) {
                    initDPs = angular.copy(ctrl.datapoints);
                }

                var driverValueIds = [];
                ctrl.customUnitList = [];
                for (var i = 0; i < ctrl.datapoints.length; i++) {
                    driverValueIds.push(ctrl.datapoints[i].id);
                    WidgetData.getCustomUnitForDV(driverValueIds).then(function (response) {
                        ctrl.customUnitList = response.data;
                    });
                }
                setTimeout(function () {
                        loadGauges();
                }, 500);
            }
            ctrl.isReset = false;
            ctrl.tree.filter = "";
        }

        function getLocations() {
            Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            });
        }

        function getPhysTypes() {
            PhysicalTypeService.getPhysicalTypes().then(function (response) {
                if (response != null && response.length > 0) ctrl.physicalTypes = response;
            });
        }

        var startLiveDataQuery = function () {
            loadGauges();
            liveDataQueryInterval = $interval(function () {
                loadGauges();
            }, 10000);
        };
        var stopLiveDataQuery = function () {
            if (liveDataQueryInterval !== null) $interval.cancel(liveDataQueryInterval);
        };

        var queryLiveData = function (dp) {
            LiveDataService.queryLiveDataForWidget(dp.id).then(function (response) {
                    if (response.status === 200) {
                        liveData = [];

                        if (response.data[dp.id].data.length > 0) {
                            var data = response.data[dp.id].data;

                            if (dp.protocolType === 5 && dp.registerValueType === 1) {
                                for (var i = 0; i < data.length; i++) {
                                    var value = parseFloat(data[i]);
                                    liveData.push(value);
                                }
                            } else {
                                for (var i = 0; i < data.length; i++) {
                                    var value = parseFloat((data[i] / 1000).toFixed(2));
                                    liveData.push(value);
                                }
                            }
                        }

                        latestLiveData = fetchLatestLiveData(response.data, dp);
                        liveData.push(latestLiveData);
                        setLiveDataForChart(dp.id);

                        if(ctrl.validateDatapoints && !Tools.isDefinedNotNull(latestLiveData)){
                            ctrl.validateDatapoints = false;
                            Notify.warning("global.notification.warning.warn", "dashboard.widget.gauge.modal.warning", 8000);
                        }
                    }
                }, function
                    (error) {
                    $log.error("Error retrieving DriverValues!" + error);
                }
            )
        };

        var fetchLatestLiveData = function (value, dp) {
            var data;
            if (value[dp.id].latestData.length > 0) {
                if (dp.protocolType === 5 && dp.registerValueType === 1) {
                    data = parseFloat(value[dp.id].latestData);
                } else {
                    data = parseFloat((value[dp.id].latestData / 1000).toFixed(2));
                }
            }
            return data;
        };

        var setLiveDataForChart = function (dvId) {
            for (var i = 0; i < ctrl.datapoints.length; i++) {
                if (ctrl.datapoints[i].id === dvId) {
                    ctrl.datapoints[i].liveValue = liveData;// > -2147483632 ? ctrl.liveData / 1000 : null;
                    ctrl.datapoints[i].latestLiveValue = latestLiveData;
                    var container = "#container" + ctrl.datapoints[i].id;
                    var myobj = document.getElementById("container" + ctrl.datapoints[i].id);
                    if (myobj !== null) {
                        $(container).contents().remove();
                    }
                    ctrl.sparkLine2(ctrl.datapoints[i]);
                    break;
                }
            }
        };

        ctrl.sparkLine2 = function (dp) {
            var container = "#container" + dp.id;
            var livevalue = dp.liveValue;
            var unit = dp.unit;
            $(container).highcharts({
                credits: false, title: {
                    text: ''
                }, chart: {
                    height: 50, type: 'area', margin: [2, 0, 2, 0], style: {
                        overflow: 'hidden'
                    }, skipClone: true
                }, yAxis: {
                    endOnTick: false, startOnTick: false, labels: {
                        enabled: false
                    }, title: {
                        text: null
                    }, tickPositions: [0]
                }, xAxis: {
                    labels: {
                        enabled: false
                    }, title: {
                        text: null
                    }, startOnTick: false, endOnTick: false, tickPositions: []
                }, legend: {
                    enabled: false
                }, tooltip: {
                    enabled: true, headerFormat: ' ', pointFormat: '{point.y:.2f}' + " " + unit, //'{point.x:%H:%M:%S %d-%m-%Y}<br/>{point.y:.2f}',
                }, exporting: {
                    enabled: false
                }, series: [{
                    name: "Livedata:", data: liveData, pointStart: 1
                }]
            })
        }

        function loadGauges() {
            if (!isIntervalRequestIsInProgress) {
                isIntervalRequestIsInProgress = true;

                if (Tools.isDefinedNotNull(ctrl.datapoints)) {
                    for (var i = 0; i < ctrl.datapoints.length; i++) {
                        var dp = angular.copy(ctrl.datapoints[i]);
                        queryLiveData(dp);
                    }
                    isIntervalRequestIsInProgress = false;
                }
            }
        }

        $scope.$on('$destroy', function () {
            stopLiveDataQuery();
        });
    }

    angular.module('emsv2App')
        .controller('WidgetGaugeController', WidgetGaugeController)
})();
