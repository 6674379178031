(function () {
    "use strict";

    angular.module('emsv2App').service('PhysicalTypeService', function ($q, $http) {

        var _physicalTypes = null;

        /**
         * @description function to get physical types
         * @param {boolean} force if set to true it will always get information from backend, otherwise it will use local information
         * @returns {*}
         */
        function getPhysicalTypes(force) {

            var deferred = $q.defer();

            if (force) _physicalTypes == null;
            if (_physicalTypes !== null) {
                deferred.resolve(_physicalTypes);
                return deferred.promise;
            }
            return $http.get("/api/physType/all").then(function (response) {
                _physicalTypes = response.data;
                return response.data;
            }, function (error) {
                return error;
            });
        }

        function getPhysTypeArray() {
            return $http.get("/api/physType/all");
        }

        function getPhysTypeUnit(physType) {
            if (!physType) {
                return '';
            }

            switch (physType) {
                case 1:
                    return '°C';
                case 2:
                    return '%';
                case 3:
                    return 'RPM';
                case 4:
                    return '%';
                case 5:
                    return 'Pa';
                case 8:
                    return 'V';
                case 9:
                    return 'A';
                case 10:
                    return 'W';
                case 11:
                    return 'Hz';
                case 12:
                    return 'Wh';
                case 14:
                    return 'RPS';
                case 15:
                    return 'm³/s';
                default:
                    return '';
            }
        }

        function getPhysTypeFormat(physType) {

            var PLAIN =  '###';
            var TENTH =  '###,#';
            if (!physType) {
                return PLAIN;
            }

            switch(physType){
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 15: return TENTH;
                default: return PLAIN;
            }
        }

        return {
            getPhysTypeUnit: getPhysTypeUnit,
            getPhysTypeFormat: getPhysTypeFormat,
            getPhysTypeArray: getPhysTypeArray,
            getPhysicalTypes: getPhysicalTypes
        }
    });
})();
