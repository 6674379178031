(function () {
    'use strict';

    let translatePartialLoader = ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        $translatePartialLoader.addPart('user');
        $translatePartialLoader.addPart('rsqltable');
        return $translate.refresh();
    }];

    let roles = ['$log', 'Authority', function ($log, Authority) {
        return Authority.getAll().then(function (response) {
            let roles = response.data;
            Object.freeze(roles);
            return roles;
        }, function (error) {
            $log.error("Couldn't load roles!", error);
            return [];
        });
    }];

    let countries = ['$log', 'Country', function ($log, Country) {
        return Country.getAll().then(function (response) {
            return response;
        }, function (error) {
            $log.error("Couldn't load countries!", error);
            return [];
        });
    }];

    let locations = ['$log', 'Location', function ($log, Location) {
        return Location.queryCompact().then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Couldn't load locations!", error);
            return [];
        });
    }];

    let groups = ['$log', '$stateParams', 'Authority', function ($log, $stateParams, Authority) {
        return Authority.getGroups($stateParams.userId).then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Couldn't load groups!", error);
            return [];
        });
    }];

    let userInfo = ['$log', '$stateParams', 'User', '$state', function ($log, $stateParams, User, $state) {
        return User.get($stateParams.userId).then(function (response) {
            let user = response.data;
            user.password = null;
            return user;
        }, function (error) {
            $state.go("notfound");
            $log.error("Couldn't load user!", error);
        });
    }];

    let groupLocations = ['$log', '$stateParams', 'Authority', '$state', function ($log, $stateParams, Authority, $state) {
        return Authority.getGroupLocations($stateParams.userId).then(function (response) {
            return response.data;
        }, function (error) {
            $state.go("notfound");
            $log.error("Couldn't load group locations for user!", error);
            return [];
        });
    }];

    let userLocations = ['$log', '$stateParams', 'Authority', function ($log, $stateParams, Authority) {
        return Authority.getDirectLocations($stateParams.userId).then(function (response) {
            return response.data;
        }, function (error) {
            $log.error("Couldn't load user locations!", error);
            return [];
        });
    }];

    let currentUser = ['$log', 'Principal', '$state', function ($log, Principal, $state) {
        return Principal.identity().then(function (user) {
            if (user.hasRole('ROLE_ADMIN') || user.hasRole('ROLE_USER_MANAGEMENT')) {
                return user;
            }
            else {
                $state.go("accessdenied");

            }
        }, function (error) {
            $state.go("notfound");
            $log.error('Couldn\'t load user: ', error);
        });
    }];

    angular
        .module('emsv2App')
        .config(function ($stateProvider) {
            $stateProvider
                .state('user', {
                    parent: 'admin',
                    abstract: true
                })
                .state('userOverview', {
                    parent: 'user',
                    url: '/user',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_USER_MANAGEMENT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'user.management.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/user/user.html',
                            controller: 'UserController',
                            controllerAs: 'uctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        currentUser: currentUser
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('newUser', {
                    parent: 'userOverview',
                    url: '/new',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_USER_MANAGEMENT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'user.management.new.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/user/new/new.user.html',
                            controller: 'NewUserController',
                            controllerAs: 'uctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        roles: roles,
                        countries: countries,
                        locations: locations,
                        currentUser: currentUser
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('user.selected', {
                    parent: 'userOverview',
                    url: '/:userId',
                    abstract: false,
                    redirectTo: 'editUser',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_USER_MANAGEMENT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: '{{userInfo.login}}'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/user/edit/edit.user.html',
                            controller: 'EditUserController',
                            controllerAs: 'uctrl'
                        }
                    },
                    resolve: {
                        translatePartialLoader: translatePartialLoader,
                        locations: locations,
                        countries: countries,
                        roles: roles,
                        groups: groups,
                        userInfo: userInfo,
                        groupLocations: groupLocations,
                        userLocationList: userLocations,
                        currentUser: currentUser
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('editUser', {
                    parent: 'user.selected',
                    url: '/edit',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_USER_MANAGEMENT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'user.management.edit.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/user/edit/edit.user.html',
                            controller: 'EditUserController',
                            controllerAs: 'uctrl'
                        }
                    },
                    resolve: {
                        userInfo: userInfo
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                })
                .state('deleteUser', {
                    parent: 'user.selected',
                    url: '/delete',
                    data: {
                        roles: ['ROLE_ADMIN', 'ROLE_USER_MANAGEMENT'],
                        pageTitle: 'global.menu.admin.pageTitle',
                        breadcrumbsTitle: 'user.management.delete.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'scripts/app/admin/user/delete/delete.user.html',
                            controller: 'DeleteUserController',
                            controllerAs: 'uctrl'
                        }
                    },
                    resolve: {
                        userInfo: userInfo
                    },
                    onEnter: function ($timeout, Principal, License, Tools, $state) {
                        'ngInject';
                        $timeout(function () {
                            if (Principal.showChangePasswordSite()) { Principal.showChangePasswordState(); }
                        });
                    }
                });
        });
})();
