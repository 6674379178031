var myApp = angular.module('emsv2App');

myApp.service('WidgetData', function ($http, $log, $rootScope, $q, $translate, Principal, License) {

    function saveWidget(widget) {
        var promise = $q.defer();
        _saveWidget(widget).then(function (response) {
            $log.debug("Saved widget:", widget);
            promise.resolve(response.data);
        }, function (error) {
            $log.error("Widget could not be saved", error);
            promise.reject(error);

        });
        return promise.promise;
    }

    function _saveWidget(widget) {
        return $http.post('api/dashboard/save', widget);
    }

    function getLastWidget() {
        return $http.get('api/dashboard/getLastWidget');
    }

    function deleteWidget(widgetId) {
        return $http.delete('api/dashboard/delete/' + widgetId);
    }

    function isWidgetConfiguredWithLocation(roomId, locationId) {
        return $http.get('api/dashboard/isWidgetConfiguredWithLocation/' + roomId + '/' + locationId);
    }

    function getCustomUnitForDV(driverValueIds) {
        return $http.put('api/dashboard/getCustomUnitForDriverValues/' + driverValueIds);
    }

    function saveWidgetSettings(widget) {
        var promise = $q.defer();
        // $log.info("Provided Widget:", widget);
        if (widget !== null && widget.settings !== null) {
            $http.post('api/dashboard/save', widget).then(function (response) {
                promise.resolve(response.data);
            }, function (error) {
                promise.reject(error);
            });
        } else {
            $log.error("Widget does not contain settings or widget is null:", widget);
            promise.reject("Widget does not contain settings or widget is null:", widget);
        }
        return promise.promise;
    }
    function updateWidgetSettings(widget) {
        var promise = $q.defer();
        // $log.info("Provided Widget:", widget);
        if (widget !== null && widget.settings !== null && widget.wid) {
            $http.post('api/widget/' +  widget.wid + '/settings', widget.settings).then(function (response) {
                promise.resolve(response.data);
            }, function (error) {
                promise.reject(error);
            });
        } else {
            $log.error("Widget does not contain settings or widget is null:", widget);
            promise.reject("Widget does not contain settings or widget is null:", widget);
        }
        return promise.promise;
    }

    function getWidgets() {
        return $http.get('api/dashboard/getUserWidgets').then(function (responseWidgets) {
            getAvailableWidgets().then(function (response) {
                for (var i = 0; i < responseWidgets.data.length; i++) {
                    var widgetInfo = response.filter(function (el) {
                        return el.type === responseWidgets.data[i].type
                    });
                    if (widgetInfo.length) {
                        if (widgetInfo[0].minWidth) responseWidgets.data[i].minWidth = widgetInfo[0].minWidth;
                        if (widgetInfo[0].minHeight) responseWidgets.data[i].minHeight = widgetInfo[0].minHeight;
                        if (widgetInfo[0].maxWidth) responseWidgets.data[i].maxWidth = widgetInfo[0].maxWidth;
                        if (widgetInfo[0].maxHeight) responseWidgets.data[i].maxHeight = widgetInfo[0].maxHeight;
                    }
                }
            });
            return responseWidgets;
        }, function (error) {
            $log.error("Error retrieving widgets\n" + error);
            return error;
        });
    }

    function saveWidgets(widgets) {
        return $http.post('api/dashboard/saveWidgets', widgets);
    }

    function cotrolWidgetSendMail(contactId, dvName, value) {
        return $http.post("api/dashboard/sendMail", {
            contactId: contactId.toString(),
            driverValueName: dvName,
            value: value
        });
    }

    function cotrolWidgetAlarmEvent(data, driverValue) {
        return $http.post("api/dashboard/alarmEvent/" + data.text + "/" + data.value, driverValue);
    }

    function isWidgetLicensed(license, type) {
        if (license === null || license.data === null) return false;
        if (!license.data.hasOwnProperty(type)) return false;
        if (!angular.isDefined(license.data[null] || license.data[type] === null)) return false;
        return license.data[type];
    }

    function hasRole(role) {
        return Principal.isInRole('ROLE_' + role);
    }

    function getAvailableWidgets(license) {
        var promise = $q.defer();
        License.get().then(function (response) {
            if (response.data !== null && response.data.data !== null) {
                var license = response.data;
                // Some widgets are hidden according too the requirements of task CGLT-768 and can be included again, if necessary
                var availableWidgets = [
                    {
                        type: 'widgetdate',
                        width: 3,
                        height: 2,
                        title: 'dashboard.widget.date.title',
                        label: 'dashboard.widget.date.title',
                        report: true,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetfavourite',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.favourites.title',
                        label: 'dashboard.widget.favourites.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        minWidth: 3,
                        minHeight: 3,
                        maxWidth: 5,
                        maxHeight: 4,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetweather',
                        width: 2,
                        height: 2,
                        title: 'dashboard.widget.weather.title',
                        label: 'dashboard.widget.weather.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetracks',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.racks.title',
                        label: 'dashboard.widget.racks.title',
                        report: true,
                        dashboard: true,
                        device: 'sdcim',
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetalerts',
                        width: 6,
                        height: 4,
                        title: 'dashboard.widget.alerts.title',
                        label: 'dashboard.widget.alerts.title',
                        report: false,
                        dashboard: (hasRole('ADMIN') || (hasRole('DASHBOARD') && hasRole('ALARM_MANAGEMENT'))),
                        device: null,
                        isresizable: true,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'alarms'))
                    },
                    {
                        type: 'widgetalerts',
                        width: 12,
                        height: 12,
                        title: 'dashboard.widget.alerts.title',
                        label: 'dashboard.widget.alerts.titleReport',
                        report: (hasRole('ADMIN') || (hasRole('REPORT') && hasRole('ALARM_MANAGEMENT'))),
                        dashboard: false,
                        device: null,
                        isresizable: false,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'report')
                            && isWidgetLicensed(license, 'alarms'))
                    },
                    {
                        type: 'widgetlocationmap',
                        width: 6,
                        height: 4,
                        title: 'dashboard.widget.locationmap.title',
                        label: 'dashboard.widget.locationmap.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetanalysis',
                        width: 6,
                        height: 4,
                        title: 'dashboard.widget.analysis.title',
                        label: 'dashboard.widget.analysis.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'analysis'))
                    },
                    {
                        type: 'widgetanalysis',
                        width: 12,
                        height: 4,
                        title: 'dashboard.widget.analysis.title',
                        label: 'dashboard.widget.analysis.title12x4',
                        report: true,
                        dashboard: false,
                        device: null,
                        isresizable: false,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'analysis'))
                    },
                    {
                        type: 'widgetanalysis',
                        width: 12,
                        height: 12,
                        title: 'dashboard.widget.analysis.title',
                        label: 'dashboard.widget.analysis.title12x12',
                        report: true,
                        dashboard: false,
                        device: null,
                        isresizable: false,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'analysis'))
                    },
                    {
                        type: 'widgetanalysistable',
                        width: 12,
                        height: 12,
                        title: 'dashboard.widget.analysistable.title',
                        label: 'dashboard.widget.analysistable.title',
                        report: true,
                        dashboard: true,
                        device: null,
                        isresizable: false,
                        hasLicense: (isWidgetLicensed(license, 'dashboard') && isWidgetLicensed(license, 'analysis'))
                    },
                    {
                        type: 'widgettext',
                        width: 12,
                        height: 4,
                        title: 'dashboard.widget.text.title',
                        label: 'dashboard.widget.text.title',
                        report: true,
                        dashboard: false,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetimage',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.image.title',
                        label: 'dashboard.widget.image.title',
                        report: true,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetspeedometer',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.speedometer.label',
                        label: 'dashboard.widget.speedometer.label',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 2,
                        minHeight: 2,
                        maxWidth: 4,
                        maxHeight: 4
                    },
                    {
                        type: 'widgetgauge',
                        width: 2,
                        height: 2,
                        title: 'dashboard.widget.gauge.label',
                        label: 'dashboard.widget.gauge.label',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 2,
                        minHeight: 2,
                        maxWidth: 8,
                        maxHeight: 8
                    },
                    {
                        type: 'widgetcontrol',
                        width: 3,
                        height: 2,
                        title: 'dashboard.widget.widgetcontrol.title',
                        label: 'dashboard.widget.widgetcontrol.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 3,
                        minHeight: 2,
                        maxWidth: 8,
                        maxHeight: 8
                    },
                    {
                        type: 'widgetweatherforecast',
                        width: 2,
                        height: 3,
                        title: 'dashboard.widget.weatherforecast.title',
                        label: 'dashboard.widget.weatherforecast.title',
                        report: true,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard')
                    },
                    {
                        type: 'widgetsysteminfo',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.systeminfo.title',
                        label: 'dashboard.widget.systeminfo.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 2,
                        minHeight: 2

                    },
                    {
                        type: 'widgetpue',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.pue.title',
                        label: 'dashboard.widget.pue.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'pue/energy_tab'),
                        minWidth: 2,
                        minHeight: 2

                    },
                    {
                        type: 'widgetpuemonitor',
                        width: 3,
                        height: 4,
                        title: 'dashboard.widget.puemonitor.title',
                        label: 'dashboard.widget.puemonitor.title',
                        report: true,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'pue/energy_tab'),
                        minWidth: 3,
                        minHeight: 4

                    },
                    {
                        type: 'widgetipwebcam',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.ipwebcam.title',
                        label: 'dashboard.widget.ipwebcam.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 2,
                        minHeight: 2

                    },
                    {
                        type: 'widgetstulzcooling',
                        width: 4,
                        height: 4,
                        title: 'dashboard.widget.stulzCooling.title',
                        label: 'dashboard.widget.stulzCooling.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 4,
                        minHeight: 4

                    },
                    {
                        type: 'widgetdrycontact',
                        width: 3,
                        height: 3,
                        title: 'dashboard.widget.drycontact.title',
                        label: 'dashboard.widget.drycontact.title',
                        report: false,
                        dashboard: true,
                        device: null,
                        isresizable: true,
                        hasLicense: isWidgetLicensed(license, 'dashboard'),
                        minWidth: 4,
                        minHeight: 4
                    }
                ];
                promise.resolve(availableWidgets);
            } else {
                promise.reject();
            }
        });
        return promise.promise;
    }

    return {
        saveWidget: saveWidget,
        getWidgets: getWidgets,
        getLastWidget: getLastWidget,
        saveWidgets: saveWidgets,
        deleteWidget: deleteWidget,
        saveWidgetSettings: saveWidgetSettings,
        updateWidgetSettings: updateWidgetSettings,
        getAvailableWidgets: getAvailableWidgets,
        isWidgetConfiguredWithLocation: isWidgetConfiguredWithLocation,
        getCustomUnitForDV: getCustomUnitForDV,
        cotrolWidgetSendMail: cotrolWidgetSendMail,
        cotrolWidgetAlarmEvent: cotrolWidgetAlarmEvent
    };
});


