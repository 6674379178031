/**
 * Created by DI on 08.06.21.
 */
(function () {

    'use strict';

    function WidgetControlController($scope, $uibModal, $log, $translate, $timeout, $interval, $http, $window, $q, $state, Notify, RoomService,
                                     WidgetData, Location, PhysicalTypeService, Tools, Contacts, DriverValue, OIDValueService, TreeSelectionService) {

        var ctrl = this;
        var currentModal;
        ctrl.wid = $scope.widget.wid;
        ctrl.isDpFilterSearchTerm = true;
        ctrl.loadDatapoint = null;
        ctrl.isLocationSelected = true;
        ctrl.spinnerDisplay = false;
        ctrl.locations = null;
        ctrl.rooms = null;
        ctrl.canSave = true;
        ctrl.contacts = [];
        var isRequestInProgress = false;
        var timer = null;
        ctrl.spOpts = {
            showInput: true,
            preferredFormat: 'hex',
            allowEmpty: false,
            clickoutFiresChanges: true,
            cancelText: $translate.instant("global.btn.cancel"),
            chooseText: $translate.instant("global.btn.choose")
        };

        ctrl.tree = {
            model: [], options: {
                multiSelection: false, nodeChildren: 'children', dirSelectable: false, injectClasses: {
                    iExpanded: 'fa fa-caret-down', iCollapsed: 'fa fa-caret-right'
                }
            }, filter: "", expandedNodes: [], selectedNode: null
        };

        ctrl.preSelectedLocationId = null;
        ctrl.selectedRecipient = null;
        ctrl.selectedDriverValue = null;
        ctrl.selectedParameter = [];
        ctrl.buttons = [];
        ctrl.controlSetting = {
            selectedLocationId: null,
            selectedDriverValueId: null,
            selectedDriverValueUniqueId: null,
            headline: null,
            isDelay: false,
            sec: null,
            defaultDelay: null,
            isSendMail: false,
            contactId: null,
            entryInEventManager: false
        };

        ctrl.default = null;
        ctrl.selectedRecipient = null;

        ctrl.handleTreeSelect = function (node, selected) {
            if (selected) {
                ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, node.driverValueUniqueId);
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                    ctrl.controlSetting.headline = ctrl.selectedDriverValue.name;
                    ctrl.controlSetting.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    ctrl.controlSetting.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;
                    ctrl.selectedParameter = ctrl.selectedDriverValue.parameter;
                    ctrl.buttons = [];
                    if (ctrl.selectedParameter.parameterValues.length > 1) {
                        ctrl.buttons.push({
                            name: 'button1',
                            id: ctrl.selectedParameter.parameterValues[0].id,
                            text: ctrl.selectedParameter.parameterValues[0].description,
                            color: '#00ff00',
                            value: ctrl.selectedParameter.parameterValues[0].value
                        });
                        ctrl.buttons.push({
                            name: 'button2',
                            id: ctrl.selectedParameter.parameterValues[1].id,
                            text: ctrl.selectedParameter.parameterValues[1].description,
                            color: '#ff0000',
                            value: ctrl.selectedParameter.parameterValues[1].value,
                        });
                    } else {
                        cleanInputs();
                        Notify.error("global.notification.error.title", "dashboard.widget.widgetcontrol.modal.only1ValueErrorMsg", 3000);
                    }

                }
            } else {
                cleanInputs();
            }
            ctrl.canSave = inputValid();
        };

        var cleanInputs = function () {
            ctrl.controlSetting.headline = '';
            ctrl.tree.selectedNode = null;
            ctrl.controlSetting.selectedDriverValueUniqueId = null;
            ctrl.buttons = [];
            ctrl.controlSetting.isDelay = false;
            ctrl.controlSetting.sec = null;
            ctrl.default = null;
            ctrl.controlSetting.isSendMail = false;
            ctrl.selectedRecipient = null;
            ctrl.controlSetting.entryInEventManager = false;
        };

        ctrl.checksave = function () {
            ctrl.canSave = inputValid();
        }

        var inputValid = function () {
            if (ctrl.buttons.length > 0) {
                for (var x = 0; x < ctrl.buttons.length; x++) {
                    if (ctrl.buttons[x].text === "" || ctrl.buttons[x].value === "") {
                        return false;
                    }
                }
                return true;
            }
        };

        ctrl.inputChanged = function (searchTerm) {
            if (searchTerm === undefined || searchTerm === null || searchTerm === "" || ctrl.controlSetting.selectedLocationId === undefined || ctrl.controlSetting.selectedLocationId === null) {
                ctrl.isDpFilterSearchTerm = true;
                ctrl.tree.model = ctrl.loadDatapoint;
            } else {
                ctrl.isDpFilterSearchTerm = false;
            }
        }

        ctrl.filteredTreeNode = function (searchTerm) {
            var array = JSON.parse(JSON.stringify(ctrl.loadDatapoint));
            ctrl.tree.model = filter(array, searchTerm.toLowerCase());
            searchChildToExpand(ctrl.tree.model, ctrl.filterTreeNodeId);
        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    ctrl.filterTreeNodeId = object.driverValueUniqueId;
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }


        var timeoutId = null;
        var isInProcess = false;

        ctrl.$onInit = function () {
            var promise = [];

            promise.push(Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            }));

            promise.push(Contacts.getAll().then(function (response) {
                ctrl.contacts = response.data;
                $scope.$parent.setConfigMethod(ctrl._configure);
            }).catch(function (error) {

            }));
            $q.all(promise).then(function () {
                $log.debug('Error Alarm:');
                _loadSettings();
                timeoutId = $interval(function () {
                    _currentState();
                }, 5000);
            });
        };

        $scope.$on('$destroy', function () {
            $interval.cancel(timeoutId);
            timeoutId = null;
        });

        function _currentState() {
            var promise = [];
            if (!isInProcess && Tools.isDefinedNotNull(ctrl.controlSetting.selectedDriverValueId)) {
                isInProcess = true;
                promise.push(OIDValueService.getSnmpValueForDriverValueId(ctrl.controlSetting.selectedDriverValueId).then(function (response) {
                    isInProcess = false;
                    ctrl.currentState = response.data;
                    for (var i = 0; i < ctrl.buttons.length; i++) {
                        var currentButton = ctrl.wid + '-' + ctrl.buttons[i].id;
                        if (parseInt(ctrl.buttons[i].value) === ctrl.currentState) {
                            if (document.getElementById(currentButton) !== null) {
                                document.getElementById(currentButton).style.color = "#000000";
                                document.getElementById(currentButton).style.border = "5px solid";
                            }
                        } else {
                            if (document.getElementById(currentButton) !== null) {
                                document.getElementById(currentButton).style.color = "";
                            }
                        }
                    }
                }));
            }
        }

        ctrl._configure = function () {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/control/widget.control.modal.html',
                animation: true,
                showClose: false,
                backdrop: 'static',
                windowClass: 'animated fadeInDown',
                scope: $scope
            });

            if (ctrl.controlSetting.selectedLocationId !== null) {
                ctrl.setupSelectedEntity();
            }
            dpAccessibility();
            ctrl.preSelectedLocationId = ctrl.controlSetting.selectedLocationId;
        };

        function dpAccessibility() {
            if (ctrl.controlSetting.selectedLocationId != null || ctrl.controlSetting.selectedLocationId != undefined) {
                setTimeout(function () {
                    if (!Tools.isDefinedNotNull(ctrl.selectedDriverValue) && $scope.widget.settings.length > 0) {
                        Notify.warning("global.notification.warning.warn", "dashboard.widget.widgetcontrol.modal.warning", 8000);
                    }
                }, 5000);
            }
        }

        ctrl.toggleCheckbox = function () {
            if (!ctrl.controlSetting.isSendMail) {
                ctrl.selectedRecipient = null;
            }

            if (!ctrl.controlSetting.isDelay) {
                ctrl.controlSetting.sec = null;
                ctrl.default = null;
            } else if (!ctrl.controlSetting.sec) {
                ctrl.controlSetting.sec = 5;
            }

            ctrl.toggleOptions();
        };

        ctrl.toggleOptions = function () {
            if (ctrl.controlSetting.isSendMail && ctrl.selectedRecipient === null) {
                ctrl.canSave = false;
            } else ctrl.canSave = !(ctrl.controlSetting.isDelay && (ctrl.controlSetting.sec === null || ctrl.default === null));

            if (ctrl.controlSetting.isDelay && (ctrl.controlSetting.sec === null || ctrl.default === null)) {
                ctrl.canSave = false;
            } else ctrl.canSave = !(ctrl.controlSetting.isSendMail && ctrl.selectedRecipient === null);
        };

        ctrl.closeModal = function () {
            if (currentModal) {
                cleanInputs();
                _loadSettings();
                currentModal.close();
            }
        };

        ctrl.cancelModal = function () {
            cleanInputs();
            _loadSettings();
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch (true) {
                        case $scope.widget.settings[i].key.startsWith('button'):
                            if (ctrl.buttons.length > 0) {
                                for (var j = 0; j < ctrl.buttons.length; j++) {
                                    if (ctrl.buttons[j].name === $scope.widget.settings[i].key) {
                                        return;
                                    }
                                }
                                ctrl.buttons.push(JSON.parse($scope.widget.settings[i].value));
                            } else {
                                ctrl.buttons.push(JSON.parse($scope.widget.settings[i].value));
                            }
                            break;
                        case $scope.widget.settings[i].key === 'controlSetting':
                            ctrl.controlSetting = JSON.parse($scope.widget.settings[i].value);
                            _currentState();
                            ctrl.buttonControlName = ctrl.controlSetting.headline;
                            for (var bt = 0; bt < ctrl.buttons.length; bt++) {
                                if (ctrl.buttons[bt].name === ctrl.controlSetting.defaultDelay) {
                                    ctrl.default = ctrl.buttons[bt];
                                    break;
                                }
                            }
                            for (var c = 0; c < ctrl.contacts.length; c++) {
                                if (ctrl.contacts[c].id === parseInt(ctrl.controlSetting.contactId)) {
                                    ctrl.selectedRecipient = ctrl.contacts[c];
                                }
                            }
                            break;
                    }
                }
            }
        }

        ctrl.saveSettings = function () {
            var newSettings = [];
            if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                for (var i = 0; i < ctrl.buttons.length; i++) {
                    newSettings.push({key: ctrl.buttons[i].name, value: JSON.stringify(ctrl.buttons[i])});
                }
            }

            if (ctrl.controlSetting.isDelay) {
                ctrl.controlSetting.defaultDelay = ctrl.default.name;
            }
            if (ctrl.controlSetting.isSendMail) {
                ctrl.controlSetting.contactId = ctrl.selectedRecipient.id;
            } else {
                ctrl.controlSetting.contactId = null;
            }
            if (Tools.isDefinedNotNull(ctrl.controlSetting)) {
                newSettings.push({key: 'controlSetting', value: JSON.stringify(ctrl.controlSetting)});
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
            } else {
                $scope.widget.settings = newSettings;
                ctrl.controlSetting.selectedLocationId = null;
                ctrl.tree.model = [];
                cleanInputs();
                $log.debug("Saving Settings:", $scope.widget.settings);
            }
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function () {
                    stopTimer();
                    Notify.defaultSuccess();
                    if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                        ctrl.controlSetting.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;
                        ctrl.controlSetting.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    }
                    ctrl.closeModal();
                }, function () {
                    Notify.error("global.notification.error.title", "dashboard.widget.widgetcontrol.modal.saveErrorMsg", 2000);
                });
            }
            setTimeout(function () {
                $state.go($state.current, {}, {reload: true});
            }, 500);
        };



        ctrl.setupSelectedEntity = function () {
            ctrl.rooms = [];
            if(ctrl.controlSetting.selectedLocationId){
                ctrl.isLocationSelected = true
                ctrl.spinnerDisplay = true;
            $http.get('api/rooms/' + ctrl.controlSetting.selectedLocationId).then(function (rooms) {
                ctrl.spinnerDisplay = false;
                if (rooms.data.length > 0) {
                    for (var index = 0; index < rooms.data.length; index++) {
                        ctrl.rooms.push(Room.parseFromHtmlObject(rooms.data[index]));
                    }
                    ctrl.tree.model = TreeSelectionService.createSelectionTree(ctrl.rooms, [driverValueFilter]);
                    ctrl.loadDatapoint = ctrl.tree.model;
                    if (ctrl.controlSetting.selectedDriverValueUniqueId !== null) {
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.controlSetting.selectedDriverValueUniqueId);
                        findNodeInTreeModel();
                    }
                }

                if (ctrl.preSelectedLocationId !== ctrl.controlSetting.selectedLocationId) {
                    cleanInputs();
                }
                ctrl.canSave = inputValid();
            }, function (error) {
                ctrl.spinnerDisplay = false;
            });
            }
        }


        var driverValueFilter = function(reference){
            return reference.physicalType === 6 && reference.driver.snmpDomain;
        }

        function findNodeInTreeModel() {
            if (ctrl.tree.expandedNodes === undefined || ctrl.tree.expandedNodes.length > 0) ctrl.tree.expandedNodes = [];

            if (ctrl.controlSetting.selectedDriverValueUniqueId !== null) {
                let foundNode = searchTreeByProperty(ctrl.controlSetting.selectedDriverValueUniqueId);
                if (Tools.isDefinedNotNull(foundNode)) {
                    ctrl.tree.selectedNode = foundNode;
                }
            }
        }

        let searchTreeByProperty = function (value) {
            let foundNode = null;
            let traverse = function (nodes) {
                let newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    var node = newNodes[index];
                    if (node.driverValueUniqueId === value) {
                        foundNode = node;
                    }
                    if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        traverse(node.children);
                    }
                }
            };
            traverse(ctrl.tree.model);

            if(foundNode){
                searchChildToExpand(ctrl.tree.model, value);
            }
            return foundNode;
        }

        var searchChildToExpand = function (nodes, childId) {
            ctrl.tree.expandedNodes = [];
            let travers = function (nodes, childId) {

                for (const node of nodes) {
                    if (node.driverValueUniqueId === childId) {
                        return node;
                    } else if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        let child = travers(node.children, childId);
                        if(child){
                            ctrl.tree.expandedNodes.push(child);
                            let nodeToExpand = {
                                id: node.id,
                                title: node.title,
                                children:[child]
                            };
                            return nodeToExpand;
                        }
                    }
                }
            };

            var expNodes = travers(nodes, childId);
            ctrl.tree.expandedNodes.push(expNodes);
        };

        var startTimer = function (driverValue) {
            var delay = (ctrl.controlSetting.sec - 1) * 1000;
            timer = $timeout(function () {
                OIDValueService.restart(driverValue, ctrl.controlSetting.sec, ctrl.default.value);
            }, delay);
        };

        var stopTimer = function () {
            if (timer !== null) {
                $timeout.cancel(timer);
                timer = null;
            }
        };

        ctrl.setButton = function (data) {
            if (!isRequestInProgress) {
                isRequestInProgress = true;
                DriverValue.getById(ctrl.controlSetting.selectedDriverValueId).then(function (response) {
                    if (response.data !== "") {
                        var driverValue = response.data;
                        var driver = driverValue.driver;

                        if (data !== ctrl.default && ctrl.controlSetting.isDelay) {
                            stopTimer();
                            OIDValueService.setAndReset(driverValue, driver.snmpDomain, driver.oid, data.value).then(function (response) {
                                if ((driver.driverType === 14 || driver.community.contains("private")) && response === true) {
                                    var promises = [];

                                    if (ctrl.controlSetting.isSendMail) {
                                        promises.push(WidgetData.cotrolWidgetSendMail(ctrl.controlSetting.contactId, driverValue.name, data.value).catch(function (error) {
                                            $log.debug('Error Mail: ' + error);
                                        }));
                                    }
                                    if (ctrl.controlSetting.entryInEventManager) {
                                        promises.push(WidgetData.cotrolWidgetAlarmEvent(data, driverValue).catch(function (error) {
                                            $log.debug('Error Alarm: ' + error);
                                        }));
                                    }

                                    $q.all(promises).then(function () {
                                        _currentState();
                                        Notify.success("global.notification.success.title", "room.driver.getAndSet.setValueMessage.success", 2000);
                                        isRequestInProgress = false;
                                    });
                                } else if (driver.community === "public") {
                                    Notify.warning("global.notification.warning.warn", "room.driver.getAndSet.setValueMessage.warning", 2000);
                                    isRequestInProgress = false;
                                } else {
                                    Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 2000);
                                    isRequestInProgress = false;
                                }
                            }, function (error) {
                                $log.error("Error setting OID value\n" + error);
                                Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 2000);
                                isRequestInProgress = false;
                            });
                            startTimer(driverValue);
                        } else {
                            // console.warn("default clicked");
                            OIDValueService.setValueForSnmp(driverValue, data.value).then(function (response) {
                                if(driver != null) {
                                    if ((driver.driverType === 14 || driver.community.contains("private")) && response === true) {
                                        var promises = [];

                                        if (ctrl.controlSetting.isSendMail) {
                                            promises.push(WidgetData.cotrolWidgetSendMail(ctrl.controlSetting.contactId, driverValue.name, data.value).catch(function (error) {
                                                $log.debug('Error Mail: ' + error);
                                            }));
                                        }
                                        if (ctrl.controlSetting.entryInEventManager) {
                                            promises.push(WidgetData.cotrolWidgetAlarmEvent(data, driverValue).catch(function (error) {
                                                $log.debug('Error Alarm: ' + error);
                                            }));
                                        }

                                        $q.all(promises).then(function () {
                                            _currentState();
                                            Notify.success("global.notification.success.title", "room.driver.getAndSet.setValueMessage.success", 2000);
                                        });
                                        isRequestInProgress = false;
                                    } else if (driver.community === "public") {
                                        Notify.warning("global.notification.warning.warn", "room.driver.getAndSet.setValueMessage.warning", 2000);
                                        isRequestInProgress = false;
                                    } else {
                                        Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 2000);
                                        isRequestInProgress = false;
                                    }
                                }

                            }, function (error) {
                                $log.error("Error setting OID value\n" + error);
                                Notify.error("global.notification.error.title", "room.driver.getAndSet.setValueMessage.error", 2000);
                                isRequestInProgress = false;
                            });
                        }
                    }
                }, function (error) {
                    isRequestInProgress = false;
                });
            }
        };
    }

    angular.module('emsv2App')
        .controller('WidgetControlController', WidgetControlController)
})();
