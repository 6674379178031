(function () {

    'use strict';

    /**
     * @ngdoc factory
     * @name digiChart
     * @requires $http
     */
    angular.module('emsv2App').factory('digiChart', ['$http', function ($http) {

        function getSchedulerEnabled() {
            return $http.get('api/reports/schedulerEnabled');
        }

        function getSensorData(options) {
            return $http.get('api/charts/' + options.ID, {params: options})
                .then(function (response) {
                    return {
                        data: response,
                        parameter: options
                    };
                }, function (error) {
                    return error;
                });
        }

        function getGroupingOptions() {
            return $http.get('api/charts/groupingOptions');
        }

        function getAggregationOptions() {
            return $http.get('api/charts/aggregationOptions');
        }

        function getReportFormats() {
            return $http.get('api/reports/availableFormats');
        }

        function getPaperFormats() {
            return $http.get('api/reports/availablePaperFormats');
        }

        return {
            getSensorData: getSensorData,
            getGroupingOptions: getGroupingOptions,
            getAggregationOptions: getAggregationOptions,
            getReportFormats: getReportFormats,
            getPaperFormats: getPaperFormats,
            getSchedulerEnabled: getSchedulerEnabled,
        };

    }]);

})();
