(function () {
    'use strict';

    function editUserController($log, $scope, $state, $window, $q, ArrayHelper, Authority, User, locations,
                                countries, roles, groups, groupLocations, License,
                                userLocationList, userInfo, currentUser, Notify, $translate, GenDialogService, Tools) {
        let ctrl = this;
        let editLocationRight = "ROLE_LOCATION_EDIT";
        let createLocationRight = "ROLE_LOCATION_CREATE";
        let initUser = null;
        ctrl.ischanged = false;
        ctrl.isAdminRole = false;
        ctrl.state = $state;
        ctrl.saveBtn = 'init';
        ctrl.forms = {};
        ctrl.roles = roles.filter(function (value) { return value !== "ROLE_LOCATION_VIEW"; });
        ctrl.groups = groups;
        ctrl.user = userInfo;
        ctrl.isEdit = true;
        ctrl.resetPassword = false;
        ctrl.error = null;
        ctrl.countries = countries;
        ctrl.locationList = locations; // List of all available locations
        ctrl.userLocations = userLocationList;
        ctrl.groupLocations = groupLocations;
        ctrl.placeholder = $translate.instant('global.form.select');
        ctrl.pwdForm = {
            password: '',
            confirmPassword: '',
            noMatch: ''
        };
        ctrl.roleAdmin = ctrl.user.roles.filter(function (value) { return value === "ROLE_ADMIN"; });
        ctrl.isEnabledAdmin = ctrl.roleAdmin[0] === "ROLE_ADMIN";
        ctrl.statusHostedPublicly = false;

        ctrl.$onInit = function () {
            _getHostedPublicly();
            initUser = angular.copy(ctrl.user);
            if (currentUser !== null) {
                if (currentUser.roles.includes("ROLE_ADMIN")) {
                    ctrl.isAdminRole = true;
                }
                if (ctrl.isEnabledAdmin === true && ctrl.isAdminRole === false) {
                    ctrl.isnonAdmin = true;
                }
            }
        };

        function _getHostedPublicly() {
            License.get().then(function (response) {
                ctrl.statusHostedPublicly = response['data']['data']['hostedpublicly'];
            })
        }

        ctrl.compareContactValues = function (userA, userB) {
            let aProps = Object.getOwnPropertyNames(userA);
            let bProps = Object.getOwnPropertyNames(userB);

            if (aProps.length !== bProps.length) {
                return true;
            }
            if (userA["company"] !== userB["company"]) return true;
            if (userA["country"] !== userB["country"]) return true;
            if (userA["email"] !== userB["email"]) return true;
            if (userA["fax"] !== userB["fax"]) return true;
            if (userA["firstname"] !== userB["firstname"]) return true;
            if (userA["gender"] !== userB["gender"]) return true;
            if (userA["lastname"] !== userB["lastname"]) return true;
            if (userA["location"] !== userB["location"]) return true;
            if (userA["phone"] !== userB["phone"]) return true;
            if (userA["mobile"] !== userB["mobile"]) return true;
            if (userA["street"] !== userB["street"]) return true;
            if (userA["zip"] !== userB["zip"]) return true;
        };

        ctrl.compareRoleValues = function (userA, userB) {
            let aProps = Object.getOwnPropertyNames(userA);
            let bProps = Object.getOwnPropertyNames(userB);
            if (aProps.length !== bProps.length) {
                return true;
            }
        };

        ctrl.compareUserValues = function (userA, userB) {
            if (Tools.isDefinedNotNull(initUser)) {
                let aProps = Object.getOwnPropertyNames(userA);
                let bProps = Object.getOwnPropertyNames(userB);
                if (aProps.length !== bProps.length) {
                    return true;
                }
                if (userA["login"] !== userB["login"]) return true;
                if (ctrl.compareContactValues(userA["contact"], userB["contact"])) return true;
                if (ctrl.compareRoleValues(userA["roles"], userB["roles"])) return true;
            }
            // pw und location values werden nicht geprüft
            return false;
        };

        $window.onbeforeunload = function (e) {
            if (ctrl.evalUserChange()) {
                e.preventDefault();
                return $translate.instant('location.modal.changedValues');
            }
        };

        ctrl.evalUserChange = function () {
            let currUser = angular.copy(ctrl.user);
            if (ctrl.compareUserValues(initUser, currUser)) return true;
        };

        let ignoreNextStateChange = false;
        $scope.$on("$stateChangeStart", function (event, toState, toParams) {
            if (!ignoreNextStateChange && ctrl.evalUserChange()) {
                event.preventDefault();
                GenDialogService.showDialog(false, {
                    headText: $translate.instant('global.dialog.head.warning'),
                    headIcon: 'glyphicon glyphicon-warning-sign',
                    messageText: $translate.instant('global.modal.changedValues'),
                    showClose: false,
                    textButton1: $translate.instant('global.modal.empty.yes'),
                    textButton0: $translate.instant('global.modal.empty.no'),
                    iconButton1: 'glyphicon glyphicon-trash',
                    iconButton0: 'glyphicon glyphicon-chevron-left',
                    classButton1: 'btn-danger',
                    classButton0: 'btn-default',
                    callbackButton1: function () {
                        GenDialogService.hideDialog();
                        ignoreNextStateChange = true;
                        initUser = null;
                        currentUser = null;
                        $state.go(toState.name, toParams);
                    },
                    callbackButton0: function () {
                        GenDialogService.hideDialog();
                    }
                });
            }
        });


        ctrl.toggleAuthority = function (value) {
            if (value === "ROLE_ADMIN") {
                ctrl.isEnabledAdmin = !ctrl.isEnabledAdmin;
                ArrayHelper.toggleValue(ctrl.user.roles, "ROLE_ADMIN");
                return;
            }
            if (value === editLocationRight) {
                ArrayHelper.toggleValue(ctrl.user.roles, createLocationRight);
            }
            else if (value === createLocationRight) {
                ArrayHelper.toggleValue(ctrl.user.roles, editLocationRight);
            }
            if (ctrl.isEnabledAdmin && value !== "ROLE_ADMIN") {

                ArrayHelper.toggleValue(ctrl.user.roles, value);
            }
            else {
                ArrayHelper.toggleValue(ctrl.user.roles, value);
            }
            ctrl.ischanged = true;
        };

        ctrl.resetPwd = function () {
            ctrl.resetPassword = true;
        };

        ctrl.checkPwd = function () {
            if (ctrl.pwdForm.password !== ''
                && ctrl.pwdForm.confirmPassword !== ''
                && ctrl.pwdForm.password !== ctrl.pwdForm.confirmPassword) {
                ctrl.pwdForm.noMatch = 'noMatch';
                return;
            }
            ctrl.pwdForm.noMatch = '';
        };

        ctrl.hasAuthority = function (authority) {
            if (currentUser !== null) {
                return currentUser.roles.includes(authority) || currentUser.roles.includes('ROLE_ADMIN');
            }
            return false;
        };

        ctrl.update = function () {
            ctrl.saveBtn = 'load';
            let user = angular.copy(ctrl.user);
            let roles = angular.copy(user.roles);
            let locations = angular.copy(ctrl.userLocations);

            if (ctrl.pwdForm.password !== '' && ctrl.pwdForm.password === ctrl.pwdForm.confirmPassword) {
                user.password = ctrl.pwdForm.password;
            }
            else {
                user.password = '';
            }

            delete user.sendActivationMail;
            delete user.roles;
            //delete user.locations;
            delete user.admin;

            // Update Authorities
            Authority.set(user.id, roles).then( function (){

                let promises = [];
                initUser = null;

                // Save locations
                promises.push(Authority.setUserLocations(user.id, locations).catch( function (error) {
                    $log.debug('Couldnt update location permission for: ' + user.login, error);
                    return $q.reject("global.notification.error.text");
                }));

                // Update Contact Information
                promises.push(User.update(user).then( function () {
                    return $q.resolve("User Contact Info updated successfully !!");
                }, function (error) {
                    $log.debug('Couldnt update Contact Information of User: ' + user.login, error);
                    return $q.reject("global.notification.error.text");
                }));

                $q.all(promises).then(function () {
                    ctrl.saveBtn = 'success';
                    Notify.defaultSuccess();
                    ctrl.saveBtn = 'init';
                },function (err) {
                    Notify.error("global.notification.error.title",err,2000);
                    ctrl.saveBtn = 'error';
                });
            }, function (error){
                $log.debug('Couldn\'t update Roles for: ' + user.login, error);
                Notify.error("global.notification.error.title","user.management.edit.error.lastAdmin",2000);
                ctrl.saveBtn = 'error';
            });
        };

        ctrl.isDisabled = function () {
            let w = typeof ctrl.forms.user !== 'undefined' &&
                (ctrl.emailExists || !ctrl.forms.user.$dirty || ctrl.forms.user.$invalid ||
                    (ctrl.resetPassword && ctrl.pwdForm.confirmPassword !== ctrl.pwdForm.password));

            if (ctrl.ischanged) {
                return !ctrl.ischanged;
            }
            else {
                return w;
            }
        };
    }

    angular
        .module('emsv2App')
        .controller('EditUserController', editUserController);

})();
