(function () {
    'use strict';

    var app = angular.module('emsv2App');

    app.directive('widgetanalysistable', function () {

        function controller($scope, $timeout, $element, $log, $q, $translate, $translatePartialLoader, $uibModal,
                            Tools, Location, highchart, Analysis, WidgetData, ValueFormatter, Notify, PIL, Principal, $state) {
            var ctrl = this;
            ctrl.showMenu = false;
            ctrl.dashboardLocked = false;
            ctrl.state = $state.current.name;
            ctrl.configure = _configure;
            ctrl.settings = $scope.widget.settings;
            ctrl.placeholder = $translate.instant('global.form.select');
            ctrl.table = [];
            ctrl.saves = {
                id: null,
                available: [],
                selected: null,
                devices: [],
                tableMode: false,
                showDeviceSelection: false,
                // detailedHeader: false
            };
            ctrl.chart = {
                title: '',
                config: [],
                types: angular.copy(highchart.chartTypes),
                datapoints: [],
                dateranges: []
            };
            ctrl.options = {
                configure: false,
                splitValues: [
                    {key: 0, value: 'day'},
                    {key: 1, value: 'week'},
                    {key: 2, value: 'month'},
                    {key: 3, value: 'year'}
                ]
            };

            var modalinstance = null;

            function _closeModal() {
                modalinstance.close();
                modalinstance = null;
            }

            ctrl.cancelModal = function () {
                _closeModal();
                ctrl.options.configure = !Tools.isDefinedNotNull(ctrl.saves.selected);
                _loadFromSettings();
            };

            ctrl.$onInit = function () {
                Analysis.findAllExcludeDatapoints().then(function (response) {
                    ctrl.saves.available = response.data;
                }, function (error) {
                    $log.debug('No analysis found.', error);
                });
                Principal.identity().then(function (account) {
                    if(localStorage.getItem("locked-dashboard-user-id-" + account.id) === "true") {
                        ctrl.dashboardLocked = true;
                    } else {
                        ctrl.dashboardLocked = false;
                    }
                }, function (error) {
                    $log.error('Couldn\'t load user!', error);
                });
                _loadFromSettings();
                ctrl.configure = _configure;
                //_loadFromSettings2();
                //new end
            };

            function _configure() {
                modalinstance = $uibModal.open({
                    templateUrl: 'scripts/app/dashboard/widget.templates/analysistable/widget.analysistable.modal.html',
                    animation: true,
                    windowClass: 'animated fadeInDown',
                    scope: $scope
                });
            }

            ctrl.saveSettings = function () {
                ctrl.options.configure = false;
                var settings = [];
                settings.push({key: 'analysisId', value: ctrl.saves.selected.id});
                // settings.push({key: 'detailedHeader', value: ctrl.saves.detailedHeader}); --Can be implemented, if the future improvement to toggle the detailed header should be implemented
                // settings.push({key: 'tableMode',  value: ctrl.saves.tableMode});
                if (ctrl.saves.selected.template) {
                    for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                        var dp = ctrl.chart.datapoints[i];

                        if (Tools.isDefinedNotNull(dp.device)) {
                            var devSet = dp.device.mac;
                            dp.parameter.MAC = dp.device.mac;

                            if (Tools.isDefinedNotNull(dp.device.locationName)) {
                                devSet += ';' + dp.device.locationName;
                                var pretitle = dp.title.split(")");

                                if (pretitle.length === 2) {
                                    dp.title = '(' + dp.device.locationName + ') ' + pretitle[1].trim();
                                } else {
                                    dp.title = '(' + dp.device.locationName + ') ' + pretitle[0].trim();
                                }
                            }
                            settings.push({key: dp.id, value: devSet});
                        }
                    }
                }
                _closeModal();
                $scope.widget.settings = settings;
                ctrl.settings = $scope.widget.settings;
                if ($scope.isdashboardwidget) {
                    WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                        Notify.defaultSuccess();
                    }, function (error) {
                        Notify.error("global.notification.error.title", "dashboard.widget.analysis.saveErrorMsg", 2000);
                    });
                }
                if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                    _loadAnalysis().then(function () {
                        ctrl.saves.showDeviceSelection = ctrl.saves.selected.template;
                        _generateChart();
                    });
                }
                // _generateChart();
            };

            // Check if the save button should be enabled
            ctrl.saveEnabled = function () {
                return Tools.isDefinedNotNull(ctrl.saves.selected)
                    && (!ctrl.saves.selected.template || _allDevicesSet());

                function _allDevicesSet() {
                    var result = true;
                    for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                        result = result && Tools.isDefinedNotNull(ctrl.chart.datapoints[i].device);
                    }
                    return result;
                }
            };

            // Check for template (and device selection) on analysis change
            ctrl.selectionChanged = function () {
                // if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                //     _loadAnalysis().then(function () {
                //         ctrl.saves.showDeviceSelection = ctrl.saves.selected.template;
                //     });
                // }
            };

            // Get "(Location) Title" from fixed datapoints, "Device: Title" from templates
            ctrl.getDpTitle = function (dp) {
                if (ctrl.saves.selected.template) {
                    var split = dp.title.split(')');
                    var result = (split.length == 1) ? split[0] : split[1];
                    return dp.hwtyp.stringValue + ': ' + result;
                }
                return dp.title;
            };

            ctrl.getFormatedDate = function (date) {
                return moment(date).format('DD.MM.YYYY');
            };

            // Load datapoint translation strings
            function _loadTranslation() {
                var defer = $q.defer();
                $translatePartialLoader.addPart('analysis');
                $translate.refresh().then(function () {
                    defer.resolve();
                });
                return defer.promise;
            }

            // Load selected analysis with dateranges & (translated) datapoints to chart memory
            function _loadAnalysis() {
                var defer = $q.defer();
                ctrl.chart.title = ctrl.saves.selected.title;
                ctrl.chart.config = [];
                ctrl.chart.datapoints = [];
                ctrl.chart.dateranges = [];

                var analysisId = _getFieldFromSettings("analysisId");
                if (Tools.isDefinedNotNull(analysisId)) {
                    Analysis.load(analysisId).then(function (response) {
                        if (response.data !== "") {
                            ctrl.saves.selected = response.data;
                            _loadDateRanges(ctrl.saves.selected.dateRanges);
                            _loadTranslation().then(function () {
                                var promises = [];
                                for (var j = 0; j < ctrl.saves.selected.dataPoints.length; j++) {
                                    var dp = angular.copy(ctrl.saves.selected.dataPoints[j]);
                                    promises.push(_addLoadedDataPoint(dp));
                                }

                                $q.all(promises).then(function () {
                                    defer.resolve();
                                });
                            });
                        } else {
                            Notify.info("global.notification.info.title", "dashboard.widget.analysis.info.deleted", 2000);
                        }
                    }, function (error) {
                        $log.debug('No analysis found.', error);
                    });
                }

                return defer.promise;
            }

            // Generate chart (configs)
            function _generateChart() {
                ctrl.chart.config = [];
                PIL.getTempSetting().then(function (settings) {
                    var tempSettings = settings.data;
                    highchart.buildConfiguration(tempSettings, ctrl.chart.datapoints, {
                            dateRanges: ctrl.chart.dateranges,
                            options: {
                                title: {
                                    text: $scope.active ? ctrl.chart.title : ''
                                },
                                subtitle: {
                                    text: (function () {
                                        var result = '';
                                        for (var i = 0; i < ctrl.chart.dateranges.length; i++) {
                                            var dr = ctrl.chart.dateranges[i];
                                            if (result !== '') {
                                                result += ', ';
                                            }
                                            result += dr.start.format('DD.MM.YYYY') + ' - ' + dr.end.format('DD.MM.YYYY');
                                        }
                                        return result;
                                    })()
                                },
                                options: {
                                    chart: {
                                        height: 300,
                                        animation: false
                                    },
                                    navigation: {
                                        buttonOptions: {
                                            enabled: false
                                        }
                                    },
                                    plotOptions: {
                                        series: {
                                            animation: false
                                        }
                                    }
                                }
                            }
                        },
                        function (config) {
                            ctrl.chart.config.push(config);
                            _buildTable();
                        }
                    );
                });
            }

            function _buildTable() {
                ctrl.table = [];
                PIL.getTempSetting().then(function (temp) {
                        for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                            if(ctrl.chart.config[0].series.length > 0){
                            for (var j = 0; j < ctrl.chart.config[0].series[i].data.length; j++) {
                                var currentdp = ctrl.table[j];
                                if (!Tools.isDefinedNotNull(currentdp)) {
                                    currentdp = [];
                                    currentdp.push(ctrl.chart.config[0].series[i].data[j][0]);
                                    ctrl.table.push(currentdp);
                                }
                                var result = ctrl.chart.config[0].series[i].data[j][1];
                                var parameter = ctrl.chart.datapoints[i].parameter;
                                if (Tools.isDefinedNotNull(parameter.SUFFIX) || Tools.isDefinedNotNull(parameter.FORMATLIVE)) {
                                    result = parameter.SUFFIX ? Tools.formatCustomValue(parameter.SUFFIX, result / 1000) : Tools.formatLiveValue(parameter.FORMATLIVE, result / 100, temp.data);
                                } else {
                                    var format = ctrl.chart.config[0].series[i].tooltip.format;
                                    result = ValueFormatter.format(result / 100, format, temp.data, null, Tools);
                                }
                                currentdp.push(result);
                            }
                            }
                        }


                })
            }

            // Prepare/translate a datapoint and add it to the chart memory
            function _addLoadedDataPoint(dp) {
                var defer = $q.defer();
                delete dp.children;

                if (ctrl.saves.selected.template && Tools.isDefinedNotNull(dp.device)) {
                    dp.parameter.MAC = dp.device.mac;
                }
                if (!Tools.isDefinedNotNull(dp.color)) {
                    dp.color = ctrl.colors.nextColor();
                }

                if (!Tools.isDefinedNotNull(dp.title)) {
                    $translate(dp.translation, {value: dp.translationParam}).then(function (result) {
                        dp.title = (ctrl.saves.selected.template) ? result : '(' + dp.locationName + ') ' + result;
                        ctrl.chart.datapoints.push(dp);
                        defer.resolve();
                    });
                } else {
                    dp.title = (ctrl.saves.selected.template) ? result : '(' + dp.locationName + ') ' + $translate.instant(dp.title);
                    ctrl.chart.datapoints.push(dp);
                    defer.resolve();
                }
                return defer.promise;
            }

            // Compute dateranges and add them to the chart memory
            function _loadDateRanges(ranges) {
                ctrl.chart.dateranges = [];
                if (ranges.length == 1 && Tools.isDefinedNotNull(ranges[0].dynamicRange)) {
                    var now = moment();
                    // now.set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 1});
                    var range = ctrl.options.splitValues[ranges[0].dynamicRange].value;
                    var scale = ctrl.options.splitValues[ranges[0].dynamicScale].value;
                    var i = ranges[0].dynamicValue;
                    var startDate = moment(now).subtract(i, range);

                    while (startDate.format('X') < now.format('X')) {
                        var current = moment(startDate).add(1, scale);
                        ctrl.chart.dateranges.push({
                            start: startDate,
                            end: current
                        });
                        startDate = current;
                        i--;
                    }
                } else {
                    for (var j = 0; j < ranges.length; j++) {
                        ctrl.chart.dateranges.push({
                            start: moment(ranges[j].start),
                            end: moment(ranges[j].end).add({hours: 23, minutes: 59, seconds: 59})
                        })
                    }
                }
            }

            // Get a named field from the widget settings
            function _getFieldFromSettings(fieldname) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    if ($scope.widget.settings[i].key == fieldname) {
                        return $scope.widget.settings[i].value;
                    }
                }
                return null;
            }

            // Load an analysis from the widget settings and prepare for edit/show
            function _loadFromSettings() {
                var id = _getFieldFromSettings('analysisId');
                // var tableMode = _getFieldFromSettings('tableMode');
                // if (tableMode === 'true') {
                ctrl.saves.tableMode = true;
                // }

                if (Tools.isDefinedNotNull(id)) {
                    for (var j = 0; j < ctrl.saves.available.length; j++) {
                        if (ctrl.saves.available[j].id === id) {
                            ctrl.saves.selected = ctrl.saves.available[j];
                            break;
                        }
                    }

                    if (Tools.isDefinedNotNull(ctrl.saves.selected)) {
                        ctrl.saves.showDeviceSelection = ctrl.saves.selected.template;
                        _loadAnalysis().then(function () {
                            // Set mac parameter for template datapoints
                            if (ctrl.saves.selected.template) {
                                for (var i = 0; i < ctrl.chart.datapoints.length; i++) {
                                    var dp = ctrl.chart.datapoints[i];
                                    var kv = _getFieldFromSettings(dp.id);

                                    if (Tools.isDefinedNotNull(kv)) {
                                        var kva = kv.split(";");
                                        dp.parameter.MAC = kva[0];
                                        var device = _findDeviceByMac(kva[0]);

                                        if (Tools.isDefinedNotNull(device)) {
                                            dp.device = device;
                                        }
                                        if (kva.length == 2) {
                                            dp.title = '(' + kva[1] + ') ' + dp.title;
                                        }
                                    }
                                }
                            }
                            _generateChart();
                        });
                    }
                } else {
                    ctrl.saves.selected = null;
                }

                // Find a device in the ctrl.saves.devices array and return it
                function _findDeviceByMac(mac) {
                    for (var i = 0; i < ctrl.saves.devices.length; i++) {
                        if (ctrl.saves.devices[i].mac == mac) {
                            return ctrl.saves.devices[i];
                        }
                    }
                    return null;
                }

            }

            // Load all (accessible) locations and build the location-device array in ctrl.saves.devices
            function _loadLocations() {
                var defer = $q.defer();
                Location.queryCompact().then(function (response) {
                    var locations = response.data;
                    // ctrl.locations.available = [];
                    var promises = [];
                    for (var i = 0; i < locations.length; i++) {
                        promises.push(_loadLocationDevices(locations[i]));
                    }
                    $q.all(promises).then(function () {
                        defer.resolve();
                    });

                    function _loadLocationDevices(location) {
                        return Location.getDevices(location.id).then(function (response) {
                            var devices = response.data;
                            location.devices = devices;
                            for (var i = 0; i < devices.length; i++) {
                                var dev = angular.copy(devices[i]);
                                dev.locationName = location.name;
                                ctrl.saves.devices.push(dev);
                            }
                        });
                    }
                });
                return defer.promise;
            }

            (function () {
                $scope.setConfigMethod(_configure);

                // $scope.setConfigMethod(function() {
                //     ctrl.showMenu = !ctrl.showMenu;
                // });

                // Preload locations & devices
                var locationsLoaded = _loadLocations();
                var analysisId = _getFieldFromSettings("analysisId")

                if (Tools.isDefinedNotNull(analysisId)) {
                    Analysis.load(analysisId).then(function (response) {
                        if (response.data !== "") {
                            ctrl.saves.selected = response.data;
                            // Check if we have data to load
                            if (Tools.isDefinedNotNull($scope.widget.settings) && $scope.widget.settings.length > 0) {
                                locationsLoaded.then(function () {
                                    _loadFromSettings();
                                });
                            } else {
                                ctrl.options.configure = true;
                            }
                        } else {
                            Notify.info("global.notification.info.title", "dashboard.widget.analysis.info.deleted", 2000);
                        }
                    }, function (error) {
                        $log.debug('No analysis found.', error);
                    });
                }
            })();
        }

        return {
            restrict: "E",
            controller: controller,
            controllerAs: 'analysis',
            templateUrl: 'scripts/app/dashboard/widget.templates/analysistable/widget.analysistable.template.html'
        };
    });
})();
