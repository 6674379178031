(function () {

    'use strict';

    angular.module('emsv2App')
        .controller('AccountDeleteController', function ($log, $translate, Auth, localStorageService, Principal, $state) {
            var ctrl = this;
            var defaultError = $translate.instant('settings.delete.error.default');
            ctrl.password = null;
            ctrl.error = false;
            ctrl.errorContent = defaultError;

            ctrl.deleteAccount = function () {
                ctrl.error = false;
                ctrl.errorContent = defaultError;
                Auth.deleteAccount(ctrl.password).then(function () {
                    localStorageService.remove("token");
                    localStorageService.remove("THEME");
                    Principal.setShowChangePasswordSite(false);
                    $state.go('logout', {}, {reload: true});
                }, function (error) {
                    ctrl.error = true;
                    if(error.data) {
                        var errorMessage = error.data.message.toLowerCase();
                        if (errorMessage.indexOf('last admin') !== -1) {
                            ctrl.errorContent = $translate.instant('settings.delete.error.lastAdmin');
                        }
                    }
                    $log.error("Couldn\'t delete user");
                });
            }
        });

})();
