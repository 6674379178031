(function () {

    'use strict';

    function WidgetSpeedometerController($scope, $uibModal, $q, $log, $translate, $timeout, $interval, $http, $window, $state,
                                         Notify, RoomService, WidgetData, Location, Tools, digiChart, DashboardService, PhysicalTypeService, TreeSelectionService) {

        var ctrl = this;
        var currentModal;
        var chartReady = false;
        var containerInformation = null;

        var defaultValues = {
            colors: {
                left: '#00ff00',
                mid: '#ffff00',
                right: '#ff0000'
            }
        };

        ctrl.loadDatapoint = null;
        ctrl.spinnerDisplay = false;
        ctrl.isLocationSelected = false;
        ctrl.entitySpinnerDisplay = false;
        ctrl.searchTerm = undefined;
        ctrl.dpSearchTerm = undefined;
        ctrl.roomsAvailable = false;
        ctrl.colors = {
            left: {
                color: "",
                value: null
            },
            middle: {
                color: "",
                value: null
            },
            right: {
                color: "",
                value: null
            }
        };

        ctrl.startValue = null;
        ctrl.endValue = null;

        ctrl.driverOutMin = 0;
        ctrl.driverOutMax = 0;
        ctrl.inValid = {
            startValue: ctrl.startValue !== ctrl.driverOutMin,
            endValue: ctrl.endValue !== ctrl.driverOutMax,
        };

        ctrl.unit = "";
        ctrl.unitOfMeasurement = "";
        ctrl.headline = "";
        ctrl.canSave = false;
        ctrl.haveData = false;

        ctrl.locations = null;
        ctrl.rooms = [];

        ctrl.displayData = 0;
        ctrl.liveData = 0;
        ctrl.previousLiveData = 0;
        ctrl.chart = null;

        ctrl.spOpts = {
            showInput: true,
            preferredFormat: 'hex',
            allowEmpty: false,
            clickoutFiresChanges: true,
            cancelText: $translate.instant("global.btn.cancel"),
            chooseText: $translate.instant("global.btn.choose")
        };

        ctrl.tree = {
            model: [],
            options: {
                multiSelection: false,
                nodeChildren: 'children',
                dirSelectable: false,
                injectClasses: {
                    iExpanded: 'fa fa-caret-down',
                    iCollapsed: 'fa fa-caret-right'
                }
            },
            filter: "",
            expandedNodes: [],
            selectedNode: null
        };

        var entityType = [
            {
                key: 0,
                value: "assets",
            },
            {
                key: 1,
                value: "coolings",
            },
            {
                key: 2,
                value: "ups",
            },
            {
                key: 3,
                value: "sensors",
            },
            {
                key: 4,
                value: "racks",
            },
            {
                key: 5,
                value: "blades",
            },
            {
                key: 6,
                value: "slots",
            },
            {
                key: 7,
                value: "cpus",
            },
            {
                key: 8,
                value: "floorTiles",
            }
        ];

        ctrl.preSelectedLocationId = null;
        ctrl.selectedLocationId = null;
        ctrl.selectedEntityId = null;
        ctrl.selectedEntityType = null;
        ctrl.selectedDriverValue = null;
        ctrl.selectedDriverValueId = null;
        ctrl.selectedDriverValueUniqueId = null;
        ctrl.useGradient = false;
        ctrl.displayPercentageVal = false;

        ctrl.leftValue = null;
        ctrl.middleValue = null;
        ctrl.rightValue = null;
        ctrl.dpArray = [];

        ctrl.handleTreeSelect = function (node, selected) {
            if (selected) {
                ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, node.driverValueUniqueId);
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                    ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    ctrl.startValue = ctrl.selectedDriverValue.driver !== null ? ctrl.selectedDriverValue.driver.outMin : 0;
                    ctrl.driverOutMin = angular.copy(ctrl.startValue);
                    ctrl.endValue = ctrl.selectedDriverValue.driver !== null ? ctrl.selectedDriverValue.driver.outMax : 100;
                    ctrl.driverOutMax = angular.copy(ctrl.endValue);
                    ctrl.unitOfMeasurement = $translate.instant(ctrl.selectedDriverValue.parameter.name);
                    ctrl.unit = PhysicalTypeService.getPhysTypeUnit(ctrl.selectedDriverValue.parameter.physType);
                    ctrl.colors.left.value = ctrl.startValue;
                    ctrl.colors.right.value = ctrl.endValue;
                    ctrl.colors.middle.value = ctrl.startValue + ((ctrl.endValue - ctrl.startValue) / 2);
                    ctrl.inValid.startValue = ctrl.startValue !== ctrl.driverOutMin;
                    ctrl.inValid.endValue = ctrl.endValue !== ctrl.driverOutMax;
                    setupColorValues();
                } else {
                    Notify.warning("global.notification.warning.warn", "dashboard.widget.speedometer.modal.warningAvailable", 4000);
                }

            } else {
                cleanInputs();
            }
            ctrl.canSave = inputValid();
        };

        var cleanInputs = function () {
            ctrl.colors.left.color = ctrl.colors.middle.color = ctrl.colors.right.color = "";
            ctrl.colors.left.value = ctrl.colors.middle.value = ctrl.colors.right.value = "";
            ctrl.startValue = null;
            ctrl.endValue = null;
            ctrl.unitOfMeasurement = '';
            ctrl.unit = null;
            ctrl.headline = '';
            ctrl.tree.selectedNode = null;
            ctrl.selectedDriverValueUniqueId = null;
        };

        var setupColorValues = function () {
            ctrl.colors.left.color = defaultValues.colors.left;
            ctrl.colors.middle.color = defaultValues.colors.mid;
            ctrl.colors.right.color = defaultValues.colors.right;
        };

        ctrl.entityFilterChange = function (searchTerm) {
            ctrl.getRoomEntities();
            if (searchTerm === undefined || searchTerm === null || searchTerm === "") {
                ctrl.validSearchTerm = false;
            } else {
                ctrl.validSearchTerm = true;
                ctrl.inputChanged(searchTerm);
            }
        }

        ctrl.inputChanged = function (searchTerm) {
            ctrl.tree.model = filter(ctrl.tree.model, searchTerm.toLowerCase());
        }

        function filter(array, text) {
            var getNodes = function getFilteredNodes(result, object) {
                if (object.title.toLowerCase().contains(text)) {
                    result.push(object);
                    return result;
                }
                if (Array.isArray(object.children)) {
                    var children = object.children.reduce(getNodes, []);
                    if (children.length) {
                        object.children = children;
                        result.push(object);
                    }
                }
                return result;
            };

            return array.reduce(getNodes, []);
        }


        ctrl.setupSelectedEntity = function () {
            ctrl.rooms = [];
            ctrl.tree.model = [];
            ctrl.dpSearchTerm = undefined;
            ctrl.isLocationSelected = true;
            ctrl.entitySpinnerDisplay = true;

            if (ctrl.selectedLocationId !== null) {

                DashboardService.getRoomsByLocation(ctrl.selectedLocationId).then(function (rooms) {

                    ctrl.rooms = rooms;
                    ctrl.tree.model = TreeSelectionService.createSelectionTree(ctrl.rooms, [driverValueFilter]);

                    if (ctrl.selectedDriverValueId !== null) {
                        ctrl.selectedDriverValue = RoomService.findObjectByUniqueId(ctrl.rooms, ctrl.selectedDriverValueUniqueId);
                        findNodeInTreeModel();
                    }
                    ctrl.canSave = inputValid();
                    ctrl.entitySpinnerDisplay = false;
                });
            }
        }

        var driverValueFilter = function(reference){
            return reference.uniqueId;
        }

        function findNodeInTreeModel() {
            if (ctrl.tree.expandedNodes === undefined || ctrl.tree.expandedNodes.length > 0) ctrl.tree.expandedNodes = [];
            if (ctrl.selectedDriverValueUniqueId !== null) {
                let foundNode = searchTreeByProperty(ctrl.selectedDriverValueUniqueId);
                if (Tools.isDefinedNotNull(foundNode)) {
                    ctrl.tree.selectedNode = foundNode;
                }
            }
        }

        let searchTreeByProperty = function (value) {
            let foundNode = null;
            let traverse = function (nodes) {
                let newNodes = angular.copy(nodes);
                for (var index = 0; index < newNodes.length; index++) {
                    var node = newNodes[index];
                    if (node.driverValueUniqueId === value) {
                        foundNode = node;
                    }
                    if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        traverse(node.children);
                    }
                }
            };
            traverse(ctrl.tree.model);

            if (foundNode) {
                searchChildToExpand(ctrl.tree.model, value);
            }
            return foundNode;
        }


        ctrl.toggleUseGradient = function () {
            if (Tools.isDefinedNotNull(ctrl.chart)) {
                ctrl.chart.yAxis.stops = setupColorStops(ctrl.useGradient);
            }
        };

        var getStopKey = function (value) {
            var range = ctrl.endValue - ctrl.startValue;
            var tv = value - ctrl.startValue;

            if (tv / range < 0) return 0;
            if (tv / range > 1) return 1;

            return tv / range;
        };

        var setupColorStops = function (gradient) {
            var stops = [];
            if (gradient) {
                stops.push([getStopKey(ctrl.colors.left.value), ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value), ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value), ctrl.colors.right.color]);
            } else {
                stops.push([0, ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value) - 0.0001, ctrl.colors.left.color]);
                stops.push([getStopKey(ctrl.colors.middle.value), ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value) - 0.0001, ctrl.colors.middle.color]);
                stops.push([getStopKey(ctrl.colors.right.value), ctrl.colors.right.color]);
                stops.push([1, ctrl.colors.right.color]);
            }

            return stops;
        };

        ctrl.$onInit = function () {
            $scope.$parent.setConfigMethod(ctrl._configure);
            _loadSettings();
            if(ctrl.selectedLocationId){
                ctrl.setupSelectedEntity();
            }
            setupSpeedometer();
        };

        ctrl._configure = function () {
            stopLiveDataQuery();
            openModal();
            getLocations();
            _loadSettings();
            if (ctrl.selectedLocationId !== null) {
                ctrl.setupSelectedEntity();
                ctrl.roomsAvailable = true;
                if (ctrl.selectedRoom !== null) {
                    var node = {
                        "id": ctrl.selectedEntityId,
                        "key": ctrl.selectedEntityType
                    }
                }
            }
            ctrl.preSelectedLocationId = ctrl.selectedLocationId;
            ctrl.canSave_();
        };

        function openModal() {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/speedometer/widget.speedometer.modal.html',
                animation: true,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        $scope.$on("widgetSizeChanged", function (e, param) {
            if ($scope.widget.wid === param.id) {
                _handleResize(param);
            }
        });

        var _handleResize = function (param) {
            $timeout(function () {
                containerInformation = _computeSize(param);
                if (ctrl.chart) {
                    loadSpeedometer();
                }
            }, 250);
        };
        var _computeSize = function (param) {
            var element = $('#speedometer' + $scope.widget.wid);
            var width = element.parent().parent().parent().parent().width();
            var height = element.parent().parent().parent().parent().height();
            var minSize = Math.min(width, height);
            element.css("width", width + "px");
            element.css("height", ((minSize - 48) * 1.5) + "px");
            return {
                width: width, height: (minSize - 48) * 1.5, cellWidth: param.width, cellHeight: param.height,
                cellWidthPixel: Math.round(width / param.width), cellHeightPixel: Math.round(height / param.height)
            };
        };

        var handleWindowResize = function (e) {
            if (e.originalEvent !== undefined && e.originalEvent.handleObj !== undefined) {
                if (e.originalEvent.handleObj && e.originalEvent.handleObj.type === "mousemove") return;
                _handleResize({width: $scope.widget.width, height: $scope.widget.height});
            }
        };

        $(window).on("resize", handleWindowResize);

        ctrl.closeModal = function () {
            if (currentModal) {
                currentModal.close();
                $state.go($state.current, {}, {reload: true});
            }
        };

        ctrl.cancelModal = function () {
            ctrl.closeModal();
        };

        function _loadSettings() {
            if ($scope.widget.settings !== null && $scope.widget.settings.length > 0) {
                for (var i = 0; i < $scope.widget.settings.length; i++) {
                    switch ($scope.widget.settings[i].key) {
                        case 'selectedLocationId':
                            ctrl.selectedLocationId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedEntityId':
                            ctrl.selectedEntityId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedEntityType':
                            var selectedEntityType = parseInt($scope.widget.settings[i].value);

                            if ($scope.widget.settings[i].value != null) {
                                ctrl.selectedEntityType = entityType.filter(function (entity) {
                                    return entity.key === selectedEntityType;
                                })[0].value;
                            }
                            break;
                        case 'selectedDriverValueUniqueId':
                            ctrl.selectedDriverValueUniqueId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'selectedDriverValueId':
                            ctrl.selectedDriverValueId = parseInt($scope.widget.settings[i].value);
                            break;
                        case 'left_color':
                            ctrl.colors.left.color = $scope.widget.settings[i].value;
                            break;
                        case 'left_color_value':
                            ctrl.colors.left.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'middle_color':
                            ctrl.colors.middle.color = $scope.widget.settings[i].value;
                            break;
                        case 'middle_color_value':
                            ctrl.colors.middle.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'right_color':
                            ctrl.colors.right.color = $scope.widget.settings[i].value;
                            break;
                        case 'right_color_value':
                            ctrl.colors.right.value = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'start_value':
                            ctrl.startValue = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'end_value':
                            ctrl.endValue = parseFloat($scope.widget.settings[i].value);
                            break;
                        case 'unit':
                            ctrl.unit = $scope.widget.settings[i].value;
                            break;
                        case 'unit_of_measurement':
                            ctrl.unitOfMeasurement = $scope.widget.settings[i].value;
                            break;
                        case 'headline':
                            ctrl.headline = $scope.widget.settings[i].value;
                            break;
                        case 'use_gradient':
                            ctrl.useGradient = $scope.widget.settings[i].value === "true";
                            break;
                        case 'display_percent':
                            ctrl.displayPercentageVal = $scope.widget.settings[i].value === "true";
                    }
                }
            }
        }

        var inputValid = function () {
            return (Tools.isDefinedNotNull(ctrl.startValue) || ctrl.startValue !== '' || !isNaN(ctrl.startValue)) &&
                (Tools.isDefinedNotNull(ctrl.endValue) || ctrl.endValue !== '' || !isNaN(ctrl.endValue)) &&
                (ctrl.startValue !== ctrl.endValue) &&
                (Tools.isDefinedNotNull(ctrl.selectedDriverValueId)) &&
                (Tools.isDefinedNotNull(ctrl.colors.right.value) || !isNaN(ctrl.colors.right.value)) &&
                (Tools.isDefinedNotNull(ctrl.colors.middle.value) || !isNaN(ctrl.colors.middle.value)) &&
                (Tools.isDefinedNotNull(ctrl.colors.left.value) || !isNaN(ctrl.colors.left.value));
        };

        ctrl.canSave_ = function () {
            ctrl.canSave = inputValid();
        };

        ctrl.handleBoundaryChange = function () {
            ctrl.canSave = inputValid();
            if (ctrl.canSave) {
                validateColorKey("left");
                validateColorKey("right");
                validateColorKey("middle");
                if (!ctrl.leftValue || !ctrl.middleValue || !ctrl.rightValue) {
                    ctrl.canSave = false;
                }
            }
        };
        // use this as ctrl func to validate single input fields for marking
        var validateColorKey = function (position) {
            var v = ctrl.colors[position].value;
            if (position === "left") {
                if (v > ctrl.colors.middle.value || v > ctrl.colors.right.value || v < ctrl.startValue) {
                    ctrl.leftValue = false;
                } else {
                    ctrl.leftValue = true;
                }
            }
            if (position === "right") {
                if (v < ctrl.colors.left.value || v < ctrl.colors.middle.value || v > ctrl.endValue) {
                    ctrl.rightValue = false;
                } else {
                    ctrl.rightValue = true;
                }
            }
            if (position === "middle") {
                if (v <= ctrl.colors.left.value || v >= ctrl.colors.right.value) {
                    ctrl.middleValue = false;
                } else {
                    ctrl.middleValue = true;
                }
            }
        };

        ctrl.saveSettings = function () {
            var newSettings = [];
            if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                newSettings.push({key: 'selectedLocationId', value: ctrl.selectedLocationId});
                newSettings.push({key: 'selectedEntityId', value: ctrl.selectedEntityId});
                newSettings.push({key: 'selectedEntityType', value: ctrl.selectedEntityType});
                newSettings.push({key: 'selectedDriverValueId', value: ctrl.selectedDriverValue.id});
                newSettings.push({key: 'selectedDriverValueUniqueId', value: ctrl.selectedDriverValue.uniqueId});
                newSettings.push({key: 'left_color', value: ctrl.colors.left.color});
                newSettings.push({key: 'left_color_value', value: ctrl.colors.left.value});
                newSettings.push({key: 'middle_color', value: ctrl.colors.middle.color});
                newSettings.push({key: 'middle_color_value', value: ctrl.colors.middle.value});
                newSettings.push({key: 'right_color', value: ctrl.colors.right.color});
                newSettings.push({key: 'right_color_value', value: ctrl.colors.right.value});
                newSettings.push({key: 'start_value', value: ctrl.startValue});
                newSettings.push({key: 'end_value', value: ctrl.endValue});
                newSettings.push({key: 'unit', value: ctrl.unit});
                newSettings.push({key: 'unit_of_measurement', value: ctrl.unitOfMeasurement});
                newSettings.push({key: 'headline', value: ctrl.headline});
                newSettings.push({key: "use_gradient", value: ctrl.useGradient.toString()});
                newSettings.push({key: "display_percent", value: ctrl.displayPercentageVal.toString()});
                $scope.widget.settings = newSettings;
                $log.debug("Saving Settings:", $scope.widget.settings);
            } else {
                $scope.widget.settings = newSettings;
                ctrl.selectedLocationId = null;
                ctrl.tree.model = [];
                cleanInputs();
                if (ctrl.chart !== undefined && ctrl.chart !== null) ctrl.chart.destroy();
                chartReady = false;
                $(window).off("resize", handleWindowResize);
                $log.debug("Saving Settings:", $scope.widget.settings);
            }
            if ($scope.isdashboardwidget) {
                WidgetData.saveWidgetSettings($scope.widget).then(function (response) {
                    Notify.defaultSuccess();
                    if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                        ctrl.selectedDriverValueUniqueId = ctrl.selectedDriverValue.uniqueId;
                        ctrl.selectedDriverValueId = ctrl.selectedDriverValue.id;
                    }
                    setupSpeedometer();
                    ctrl.closeModal();
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.speedometer.modal.saveErrorMsg", 2000);
                });
            }
            $state.go($state.current, {}, {reload: true});
        };

        function getLocations() {
            Location.queryCompact().then(function (response) {
                ctrl.locations = response.data;
            });
        }

        let searchChildToExpand = function (nodes, childId) {
            ctrl.tree.expandedNodes = [];
            let travers = function (nodes, childId) {
                for (const node of nodes) {
                    if (node.driverValueUniqueId === childId) {
                        return node;
                    } else if (node.hasOwnProperty("children") && Tools.isDefinedNotNull(node.children)) {
                        let child = travers(node.children, childId);
                        if (child) {
                            ctrl.tree.expandedNodes.push(child);
                            let nodeToExpand = {
                                id: node.id,
                                title: node.title,
                                children: [child]
                            };
                            return nodeToExpand;
                        }
                    }
                }
            };
            var expNodes = travers(nodes, childId);
            ctrl.tree.expandedNodes.push(expNodes);
        };

        var setupSpeedometer = function () {
            if (ctrl.selectedDriverValueId !== null) {
                $(document).ready(function () {
                    containerInformation = _computeSize({width: $scope.widget.width, height: $scope.widget.height});
                    loadSpeedometer();
                });
            }
        };

        var stopLiveDataQuery = function () {
            DashboardService.removeWidget($scope.$id);
        };

        var queryLiveData = function () {
            if (ctrl.selectedDriverValueId) {
                $http.get("api/livedata/drivervalue/" + ctrl.selectedDriverValueId).then(function (response) {
                    ctrl.liveData = response.data[ctrl.selectedDriverValueId];
                    setLiveDataForChart();
                });
            }
        };

        ctrl.refresh = queryLiveData;

        var startLiveDataQuery = function () {
            DashboardService.queueWidget($scope.$id, ctrl.refresh);
        };

        var setLiveDataForChart = function () {
            if (ctrl.chart && ctrl.chart.renderer !== undefined && !ctrl.chart.renderer.forExport) {
                var point = ctrl.chart.series[0].points[0];
                var liveValue;
                if (Tools.isDefinedNotNull(ctrl.selectedDriverValue)) {
                    if (ctrl.selectedDriverValue.driver != null) {
                        if (ctrl.selectedDriverValue.driver.driverType === 5 && ctrl.selectedDriverValue.driver.registerValueType == 1) {
                            liveValue = ctrl.liveData > -2147483632 ? ctrl.liveData : null;
                        } else {
                            liveValue = ctrl.liveData > -2147483632 ? ctrl.liveData / 1000 : null;
                        }
                    }

                    ctrl.displayData = liveValue;
                    ctrl.previousLiveData = liveValue;
                    point.update(ctrl.displayData);
                    if (ctrl.calcPercent !== (((liveValue - ctrl.startValue) / (ctrl.endValue - ctrl.startValue)) * 100).toFixed(1)) {
                        loadSpeedometer();
                    }
                }
            }
        };

        var getSubtitleSize = function () {
            var subTitleSize = 14;
            if (containerInformation.cellHeight < 3 || containerInformation.cellWidthPixel < 50 || containerInformation.cellHeightPixel < 50) subTitleSize = 11;
            return subTitleSize;
        };

        var getTitleSize = function () {
            var titleSize = 16;
            if (containerInformation.cellHeight < 3 || containerInformation.cellWidthPixel < 50 || containerInformation.cellHeightPixel < 50) titleSize = 13;
            return titleSize;
        };

        var getSubtitleOffsetY = function () {
            var posY = containerInformation.height / -2.5;
            if (ctrl.headline !== '' || containerInformation.cellHeight < 3) posY += 20;
            return posY;
        };

        var getFormattedDataLabelForSize = function () {

            var valueFontSize = 16;
            var unitFontSize = 15;
            ctrl.calcPercent = (((ctrl.displayData - ctrl.startValue) / (ctrl.endValue - ctrl.startValue)) * 100).toFixed(1);

            if (containerInformation.cellHeight < 3 || containerInformation.cellHeightPixel < 50 || containerInformation.cellWidthPixel < 50) {
                valueFontSize = 12;
                unitFontSize = 11;
            }

            var str;
            if (ctrl.displayPercentageVal) {
                str = '<div style="text-align:center"><span class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">{y:.1f}</span>' +
                    '<span class="highcharts-center-unit" style="font-size: ' + unitFontSize + 'px">' + " " + ctrl.unit + '</span><br/>' +
                    '<span" class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">' + ctrl.calcPercent + ' %' + '</span></div>';
            } else {
                str = '<div style="text-align:center"><span class="highcharts-center-value" style="font-size: ' + valueFontSize + 'px">{y:.1f}</span>' +
                    '<span class="highcharts-center-unit" style="font-size: ' + unitFontSize + 'px">' + " " + ctrl.unit + '</span><br/></div>';
            }

            return str;
        };

        var getFormattedDataLabelPositionForSize = function () {
            var posY = -55;

            if (ctrl.headline !== '') posY += 10;
            return posY;
        };

        function loadSpeedometer(callback) {
            ctrl.chart = Highcharts.chart('speedometer' + $scope.widget.wid, {
                    chart: {
                        type: 'solidgauge'
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: ctrl.headline,
                        margin: 5,
                        floating: false,
                        style: {
                            fontSize: getTitleSize()
                        }
                    },
                    pane: {
                        center: ['50%', '50%'],
                        size: '100%',
                        startAngle: -90,
                        endAngle: 90,
                        background: [
                            {
                                backgroundColor: '#EEE',
                                innerRadius: '60%',
                                outerRadius: '100%',
                                shape: 'arc',
                                borderWidth: 1
                            }
                        ]
                    },
                    yAxis: {
                        min: ctrl.startValue,
                        max: ctrl.endValue,
                        stops: setupColorStops(ctrl.useGradient),
                        lineWidth: 1,
                        minorTickInterval: null,
                        tickPixelInterval: 400,
                        tickWidth: 1,
                        tickPositions: [ctrl.startValue, ctrl.endValue],
                        tickAmount: 1,
                        labels: {
                            y: 16
                        }
                    },
                    series: [{
                        name: ctrl.unitOfMeasurement,
                        data: [ctrl.displayData],
                        dataLabels: {
                            format: getFormattedDataLabelForSize()
                        },
                        tooltip: {
                            valueSuffix: ctrl.unit
                        }
                    }],
                    exporting: {
                        enabled: false
                    },
                    subtitle: {
                        floating: true,
                        align: 'center',
                        verticalAlign: 'bottom',
                        y: getSubtitleOffsetY(),
                        text: ctrl.unitOfMeasurement,
                        style: {
                            fontSize: getSubtitleSize()
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: getFormattedDataLabelPositionForSize(),
                                borderWidth: 0,
                                useHTML: true
                            }
                        }
                    }
                },
                function (chart) {
                    ctrl.chart = chart;
                    queryLiveData();
                    startLiveDataQuery();
                    chartReady = true;
                });
        }

        $scope.$on('$destroy', function () {
            stopLiveDataQuery();
            if (ctrl.chart !== undefined && ctrl.chart !== null) ctrl.chart.destroy();
            chartReady = false;
            $(window).off("resize", handleWindowResize);
        });
    }

    angular.module('emsv2App')
        .controller('WidgetSpeedometerController', WidgetSpeedometerController)

})
();