(function () {

    'use strict';

    function deleteUserController($state, $timeout, Notify, User,
                                  groupLocations, userLocationList, userInfo, countries, roles,
                                  groups, locations, $translate, currentUser, localStorageService, Principal) {
        let ctrl = this;
        ctrl.deleteBtn = 'init';
        ctrl.isDetail = true;
        ctrl.currentUser = currentUser;
        ctrl.user = userInfo;
        ctrl.roles = roles;
        ctrl.groups = groups;
        ctrl.countries = countries;
        ctrl.locationList = locations; // List of all available locations
        ctrl.userLocations = userLocationList;
        ctrl.groupLocations = groupLocations;
        ctrl.placeholder = $translate.instant('global.form.select');

        ctrl.accept = function () {
            ctrl.deleteBtn = 'load';
            _deleteUser(ctrl.user.id);
        };

        function _deleteUser(userId) {
            User.remove(userId).then(function () {
                ctrl.deleteBtn = 'success';
                Notify.defaultSuccess();
                if(ctrl.user.id === ctrl.currentUser.id) {
                    localStorageService.remove("token");
                    localStorageService.remove("THEME");
                    $state.go('logout', {}, {reload: true});
                } else {
                    $state.go('userOverview');
                }
            }, function (err) {
                ctrl.deleteBtn = 'error';
                Notify.error("global.notification.error.title", "user.delete.error.lastAdmin", 5000);
            });
        }
    }

    angular
        .module('emsv2App')
        .controller('DeleteUserController', deleteUserController);

})();
