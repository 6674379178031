'use strict';

/**
 * @description Constructor to create new driver object
 * @param {Number} id the id to set
 * @param {Number} uniqueId the unique id to set
 * @param {Number} driverType the driver type to set
 * @constructor
 */
function Driver(id, uniqueId, driverType) {
    this.id = id !== undefined ? id : -1;
    this.uniqueId = uniqueId !== undefined ? uniqueId : Entity.getNewLocaleUniqueId();
    this.driverType = driverType !== undefined ? driverType : 0;
    this.isIdentifierInvalid = false;
}

Driver.prototype = {};
Driver.prototype.constructor = Driver;

/**
 * @description Function to validate provided object as driver object
 * @param {object} obj the object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateDriverObject = function (entity, driverVal, driver) {
    var errorList = [];
    var driverHasMinMax = driver.hasOwnProperty("inMax") && driver.hasOwnProperty("inMin") && driver.hasOwnProperty("outMax") && driver.hasOwnProperty("outMin");

    if (!driverHasMinMax) {
        errorList.push(new ErrorObject(209, entity.name, "", driverVal.uniqueId));
    }
    if (driver.inMin === undefined || driver.inMin === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.inMin", driverVal.uniqueId));
    }
    if (driver.inMax === undefined || driver.inMax === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.inMax", driverVal.uniqueId));
    }
    if (driver.outMin === undefined || driver.outMin === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.outMin", driverVal.uniqueId));
    }
    if (driver.outMax === undefined || driver.outMax === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.outMax", driverVal.uniqueId));
    }

    if ((driver.outMax === undefined || driver.outMax === null) && (driver.outMin === undefined || driver.outMin === null)) {
        errorList.push(new ErrorObject(207, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
    }

    var exists = errorList.filter(function (error) {
        return error.code === 211;
    });
    if (exists.length === 0) {
        if (driver.inMax === 0 && driver.inMin === 0 && driver.outMax === 0 && driver.outMin === 0) {
            errorList.push(new ErrorObject(207, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
        }

        if (driver.inMax === 0 && driver.inMin === 0) {
            if (driver.outMin === driver.outMax) {
                errorList.push(new ErrorObject(205, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
            }
            if (driver.outMax < 0 || driver.outMin < 0) {
                errorList.push(new ErrorObject(208, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
            }
        }

        if (driver.inMax !== 0 || driver.inMin !== 0) {
            if (driver.inMax === driver.inMin) {
                errorList.push(new ErrorObject(204, entity.name, "room.errorList.inMin_inMax", driverVal.uniqueId));
            }
            if (driver.outMax === driver.outMin) {
                errorList.push(new ErrorObject(205, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
            }
            if (driver.inMin > driver.inMax) {
                errorList.push(new ErrorObject(206, entity.name, "room.errorList.inMin_inMax", driverVal.uniqueId));
            }
            if (driver.outMin > driver.outMax) {
                errorList.push(new ErrorObject(206, entity.name, "room.errorList.outMin_outMax", driverVal.uniqueId));
            }
        }
    }
    if (driver.driverType !== 0) {
        if (driver.driverType === 12) errorList = errorList.concat(Driver.validateSnmpV1Driver(entity, driverVal, driver));
        if (driver.driverType === 13) errorList = errorList.concat(Driver.validateSnmpV2cDriver(entity, driverVal, driver));
        if (driver.driverType === 14) errorList = errorList.concat(Driver.validateSnmpV3Driver(entity, driverVal, driver));
        if (driver.driverType === 5) errorList = errorList.concat(Driver.validateModBusTCPDriver(entity, driverVal, driver));
    }
    return errorList;
};

/**
 * @description Function to validate d0 driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateD0Driver = function (obj) {
    var errorList = [];
    if (obj.d0Address === undefined || obj.d0Address === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "d0Address"));
    return errorList;
};

/**
 * @description Function to validate dbus driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateDbusDriver = function (obj) {
    var errorList = [];
    if (obj.dbusAddress === undefined || obj.dbusAddress === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "dbusAddress"));
    // if (obj.dbusIsSerial === undefined || obj.dbusIsSerial === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, this.uniqueId, "dbusIsSerial"));
    return errorList;
};

/**
 * @description Function to validate mbus driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateMbusDriver = function (obj) {
    var errorList = [];
    if (obj.mbusAddress === undefined || obj.mbusAddress === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "mbusAddress"));
    // if (obj.mbusIsSerial === undefined || obj.mbusIsSerial === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "mbusIsSerial"));
    return errorList;
};

/**
 * @description Function to validate modbus rtu driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateModBusRTUDriver = function (obj) {
    var errorList = [];
    if (obj.baudRate === undefined || obj.baudRate === null || obj.baudRate <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "baudRate"));
    if (obj.functionCode === undefined || obj.functionCode === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "functionCode"));
    if (obj.parity === undefined || obj.parity === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "parity"));
    if (obj.registerReadCount === undefined || obj.registerReadCount === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "registerReadCount"));
    if (obj.registerValueType === undefined || obj.registerValueType === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "registerValueType"));
    if (obj.registerValueSign === undefined || obj.registerValueSign === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "registerValueSign"));
    if (obj.rtuRefreshTime === undefined || obj.rtuRefreshTime === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "rtuRefreshTime"));
    if (obj.rtuRegisterAddress === undefined || obj.rtuRegisterAddress === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "rtuRegisterAddress"));
    if (obj.slaveAddress === undefined || obj.slaveAddress === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "slaveAddress"));
    if (obj.stopBits === undefined || obj.stopBits === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "stopBits"));
    return errorList;
};

/**
 * @description Function to validate passive driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validatePassiveDriver = function (obj) {
    if (obj.identifier === undefined || obj.identifier === null || obj.identifier === "") return [new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "identifier")];
    return [];
};

/**
 * @description Function to validate s0 driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateS0Driver = function (obj) {
    var errorList = [];
    if (obj.impulse === undefined || obj.impulse === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "impulse"));
    if (obj.maxConsumption === undefined || obj.maxConsumption === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "maxConsumption"));
    if (obj.s0Channel === undefined || obj.s0Channel === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "s0Channel"));
    if (obj.s0Slot === undefined || obj.s0Slot === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "s0Slot"));
    return errorList;
};

/**
 * @description Function to validate snmp driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateSnmpDriver = function (driver) {
    var errorList = [];
    if (obj.community === undefined || obj.community === null || obj.community === "") errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "community"));
    if (obj.oid === undefined || obj.oid === null || obj.oid === "") errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "oid"));
    if (obj.snmpDomain === undefined || obj.snmpDomain === null || obj.snmpDomain === "") errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "snmpDomain"));
    if (obj.snmpPort === undefined || obj.snmpPort === null || obj.snmpPort <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "snmpPort"));
    if (obj.snmpRefreshTime === undefined || obj.snmpRefreshTime === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "snmpRefreshTime"));

    if (driver.snmpRefreshTime < 5000 || driver.snmpRefreshTime > 900000) {
        addToDriverList(211, entity.name, "room.errorList.refreshTime", driverVal.uniqueId);
    }
    return errorList;
};


/**
 * @description Function to validate modbus tcp driver
 * @param {object} driver the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateModBusTCPDriver = function (entity, driverVal, driver) {
    var errorList = [];
    if (driver.tcpDomain === undefined || driver.tcpDomain === null || driver.tcpDomain === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.domain", driverVal.uniqueId));
    }
    if (driver.tcpPort === undefined || driver.tcpPort === null || driver.tcpPort < 0 || driver.tcpPort > 65535) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.port", driverVal.uniqueId));
    }
    if (driver.tcpSlave === undefined || driver.tcpSlave === null || driver.tcpSlave < 0 || driver.tcpSlave > 255) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.slaveAddr", driverVal.uniqueId));
    }
    if (driver.tcpRegisterAddress === undefined || driver.tcpRegisterAddress === null || driver.tcpRegisterAddress < 0 || driver.tcpRegisterAddress > 65535) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.register", driverVal.uniqueId));
    }
    if (driver.registerValueType === undefined || driver.registerValueType === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.dataType", driverVal.uniqueId));
    }
    if (driver.functionCode === undefined || driver.functionCode === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.functionCode", driverVal.uniqueId));
    }
    if (driver.tcpRefreshTime === undefined || driver.tcpRefreshTime === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    } else if (driver.tcpRefreshTime < 5000 || driver.tcpRefreshTime > 900000) {
        errorList.push(new ErrorObject(210, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    }
    return errorList;
};


/**
 * @description Function to validate SnmpV1 driver
 * @param {object} driver the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateSnmpV1Driver = function (entity, driverVal, driver) {
    var errorList = [];
    if (driver.snmpRefreshTime === undefined || driver.snmpRefreshTime === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    } else if (driver.snmpRefreshTime < 5000 || driver.snmpRefreshTime > 900000) {
        errorList.push(new ErrorObject(210, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    }
    if (driver.community === undefined || driver.community === null || driver.community === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.community", driverVal.uniqueId));
    }
    if (driver.oid === undefined || driver.oid === null || driver.oid === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.oid", driverVal.uniqueId));
    }
    if (driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.domain", driverVal.uniqueId));
    }
    if (driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.port", driverVal.uniqueId));
    }
    return errorList;
};

/**
 * @description Function to validate SnmpV2c driver
 * @param {object} driver the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateSnmpV2cDriver = function (entity, driverVal, driver) {
    var errorList = [];
    if (driver.snmpRefreshTime === undefined || driver.snmpRefreshTime === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    } else if (driver.snmpRefreshTime < 5000 || driver.snmpRefreshTime > 900000) {
        errorList.push(new ErrorObject(210, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    }
    if (driver.community === undefined || driver.community === null || driver.community === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.community", driverVal.uniqueId));
    }
    if (driver.oid === undefined || driver.oid === null || driver.oid === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.oid", driverVal.uniqueId));
    }
    if (driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.domain", driverVal.uniqueId));
    }
    if (driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.port", driverVal.uniqueId));
    }
    return errorList;
};

/**
 * @description Function to validate SnmpV3 driver
 * @param {object} driver the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateSnmpV3Driver = function (entity, driverVal, driver) {
    var errorList = [];
    if (driver.snmpRefreshTime === undefined || driver.snmpRefreshTime === null) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    } else if (driver.snmpRefreshTime < 5000 || driver.snmpRefreshTime > 900000) {
        errorList.push(new ErrorObject(210, entity.name, "room.errorList.refreshTime", driverVal.uniqueId));
    }
    if (driver.oid === undefined || driver.oid === null || driver.oid === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.oid", driverVal.uniqueId));
    }
    if (driver.snmpDomain === undefined || driver.snmpDomain === null || driver.snmpDomain === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.domain", driverVal.uniqueId));
    }
    if (driver.snmpPort === undefined || driver.snmpPort === null || driver.snmpPort <= 0) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.port", driverVal.uniqueId));
    }
    //Validate all necessary values for SNMPv3
    if (driver.userName === undefined || driver.userName === null || driver.userName === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.snmpv3UserName", driverVal.uniqueId));
    }
    if (driver.authenticationPassphrase === undefined || driver.authenticationPassphrase === null || driver.authenticationPassphrase === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.authenticationPassphrase", driverVal.uniqueId));
    }
    if (driver.privacyPassphrase === undefined || driver.privacyPassphrase === null || driver.privacyPassphrase === "") {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.privacyPassphrase", driverVal.uniqueId));
    }
    if (driver.authenticationPassphrase.length < 8) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.authenticationPassphrase", driverVal.uniqueId));
    }
    if (driver.privacyPassphrase.length < 8 ) {
        errorList.push(new ErrorObject(211, entity.name, "room.errorList.privacyPassphrase", driverVal.uniqueId));
    }
    // if (driver.authenticationPassphrase.length > 12) {
    //     errorList.push(new ErrorObject(211, entity.name, "room.errorList.authenticationPassphrase", driverVal.uniqueId));
    // }
    // if (driver.privacyPassphrase.length > 12) {
    //     errorList.push(new ErrorObject(211, entity.name, "room.errorList.privacyPassphrase", driverVal.uniqueId));
    // }
    return errorList;
};




/**
 * @description Function to validate webbus driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateWebbusDriver = function (obj) {
    var errorList = [];
    if (obj.webbusChannel === undefined || obj.webbusChannel === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "webbusChannel"));
    if (obj.webbusChannelType === undefined || obj.webbusChannelType === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "webbusChannelType"));
    if (obj.webbusSlot === undefined || obj.webbusSlot === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "webbusSlot"));
    return errorList;
};

/**
 * @description Function to validate wib8000 driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateWib8000Driver = function (obj) {
    var errorList = [];
    if (obj.bus === undefined || obj.bus === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "bus"));
    if (obj.globalAddress === undefined || obj.globalAddress === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "globalAddress"));
    if (obj.unit === undefined || obj.unit === null) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "unit"));
    if (obj.wib8000Domain === undefined || obj.wib8000Domain === null || obj.wib8000Domain === "") errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "wib8000Domain"));
    if (obj.wib8000Port === undefined || obj.wib8000Port === null || obj.wib8000Port <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "wib8000Port"));
    return errorList;
};

/**
 * @description Function to validate wireless driver
 * @param {object} obj the driver object to validate
 * @returns {Array} returns array of error objects
 */
Driver.validateWirelessDriver = function (obj) {
    var errorList = [];
    if (obj.gatewayId === undefined || obj.gatewayId === null || obj.gatewayId <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "gatewayId"));
    if (obj.gatewaySensorId === undefined || obj.gatewaySensorId === null || obj.gatewaySensorId <= 0) errorList.push(new ErrorObject(ErrorObject.INVALID_FIELD_VALUE, obj.uniqueId, "gatewaySensorId"));
    return errorList;
};

Driver.setDefaultValuesforSNMP = function (driverObj) {
    if (driverObj.snmpPort === undefined || driverObj.snmpPort === null) driverObj.snmpPort = 161;
    if (driverObj.community === undefined || driverObj.community === null) driverObj.community = "public";
    if (driverObj.snmpRefreshTime === undefined || driverObj.snmpRefreshTime === null) driverObj.snmpRefreshTime = 5000;
    if (driverObj.inMin === undefined || driverObj.inMin === null) driverObj.inMin = 0;
    if (driverObj.inMax === undefined || driverObj.inMax === null) driverObj.inMax = 0;
    if (driverObj.outMin === undefined || driverObj.outMin === null) driverObj.outMin = 0;
    if (driverObj.outMax === undefined || driverObj.outMax === null) driverObj.outMax = 1000;
    if (driverObj.tolerance === undefined || driverObj.tolerance === null) driverObj.tolerance = 0;
};

Driver.setDefaultValuesforSNMPv3 = function (driverObj) {
    if (driverObj.authenticationProtocol === undefined || driverObj.authenticationProtocol === null) driverObj.authenticationProtocol = 0;
    if (driverObj.privacyProtocol === undefined || driverObj.privacyProtocol === null) driverObj.privacyProtocol = 0;
};

Driver.setDefaultValuesforModBusTCP = function (driverObj) {

    if (driverObj.tcpPort === undefined || driverObj.tcpPort === null) driverObj.tcpPort = 502;
    if (driverObj.tcpSlave === undefined || driverObj.tcpSlave === null) driverObj.tcpSlave = 1;
    if (driverObj.tcpRegisterAddress === undefined || driverObj.tcpRegisterAddress === null) driverObj.tcpRegisterAddress = 0;
    if (driverObj.registerValueType === undefined || driverObj.registerValueType === null) driverObj.registerValueType = 8;
    if (driverObj.functionCode === undefined || driverObj.functionCode === null) driverObj.functionCode = 3;
    if (driverObj.tcpRefreshTime === undefined || driverObj.tcpRefreshTime === null) driverObj.tcpRefreshTime = 5000;
    if (driverObj.inMin === undefined || driverObj.inMin === null) driverObj.inMin = 0;
    if (driverObj.inMax === undefined || driverObj.inMax === null) driverObj.inMax = 0;
    if (driverObj.outMin === undefined || driverObj.outMin === null) driverObj.outMin = 0;
    if (driverObj.outMax === undefined || driverObj.outMax === null) driverObj.outMax = 1000;
};
