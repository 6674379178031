(function () {

    'use strict';

    function widgetAlarmController($scope, $timeout, $element, $translatePartialLoader, $translate, $interval, $uibModal, $log, $q,
                                   WidgetData, Alarm, Device, AlarmService, Principal, Notify, Report, $state) {
        var ctrl = this;
        // Table loading/sorting
        ctrl.data = [];
        ctrl.query = '';
        ctrl.page = 0;
        ctrl.state = $state.current.name;
        ctrl.doQuery = function () {
            return _query(false);
        };

        ctrl.hasMore = true;
        ctrl.tableModel = {
            orderBy: 'stamp',
            orderDir: false,
            head: [
                {name: 'priority', translation: 'alarm.priority'},
                {name: 'location_name', translation: 'alarm.location', sortable: true},
                // {name: 'device_name', translation: 'alarm.device_name', sortable: false},
                {name: 'stamp', translation: 'alarm.stamp'},
                {name: 'hash_text', translation: 'alarm.hash_text', sortable: false},
                {name: 'active', translation: 'alarm.active', sortable: true},
                {name: 'auto_quit', translation: 'alarm.autoquit', sortable: true},
                {name: 'mail_sent', translation: 'alarm.mailsent', sortable: true},
                {name: 'sms_sent', translation: 'alarm.smsSent', sortable: true},
                {name: 'quit', translation: 'alarm.quit', sortable: true}
            ]
        };

        if (ctrl.isprintwidget) {
            var tmp = [];
            for (var i in ctrl.tableModel.head) {
                // because of this LOC not getting Auto_Quit header
                // if (ctrl.tableModel.head[i].name === 'auto_quit') continue;
                tmp.push(ctrl.tableModel.head[i]);
            }
            ctrl.tableModel.head = tmp;
        }

        ctrl.priorityInterval = {
            infoPrioStart: 0,
            infoPrioEnd: 74,
            warnPrioStart: 75,
            warnPrioEnd: 149,
            errorPrioStart: 150
        };

        ctrl.itemsPerPage = 50;
        ctrl.pageCounts = [5, 10, 15, 20, 25, 50];
        ctrl.hideLoadMoreButton = false;

        var tableRefresh;
        var currentDevices = [];
        var currentModal;

        ctrl.$onInit = function () {
            if (!ctrl.isprintwidget) {
                tableRefresh = $interval(function () {
                    _query(true);
                }, 60000);
            }

            $translatePartialLoader.addPart('alarm');
            $translate.refresh();

            $scope.$parent.setConfigMethod(_showOptions);
            _loadSettings();
        };

        ctrl.$onDestroy = function () {
            $interval.cancel(tableRefresh);
        };

        ctrl.doSort = function (sortField, sortReverse) {
            ctrl.tableModel.orderBy = sortField;
            ctrl.tableModel.orderDir = sortReverse;
            if (!ctrl.isprintwidget) {

                var settings = [];
                settings.push({key: 'count', value: ctrl.itemsPerPage});
                settings.push({key: 'hideLoadMore', value: ctrl.hideLoadMoreButton});
                settings.push({key: 'order', value: JSON.stringify({ 'by': ctrl.tableModel.orderBy, 'dir': ctrl.tableModel.orderDir })});
                if(settingsChanged(settings)) {
                    updateAlarmSettings(settings);
                }
            }

            _query(true);
        };

        function _query(reset) {
            if(ctrl.isdashboardwidget){
            ctrl.page = reset ? 0 : ctrl.page + 1;
            var query = ctrl.query + getSorting();
                return getDashboardWidgetAlarms(query, reset);
            }
            if(ctrl.isreportwidget){
                ctrl.page = reset ? 0 : ctrl.page + 1;
                var queryReport = ctrl.query + getSorting();
                return getReportWidgetAlarms(queryReport, reset);
            }
        }

        function getDashboardWidgetAlarms(query, reset){
            return  Alarm.rsql(ctrl.page, query, ctrl.itemsPerPage).then(function(response){handleAlarms(response, reset);}, function (error) {
                $log.error('Couldn\'t load alarms!', error);
                ctrl.data = [];
            });
        }
        function getReportWidgetAlarms(query,reset){

            var params = {
                limit: ctrl.itemsPerPage,
                orderby:  ctrl.tableModel.orderBy,
                direction: (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '')
            }
            return  Alarm.rsql(ctrl.page, query, ctrl.itemsPerPage).then(function(response){handleAlarms(response, reset);}, function (error) {
                $log.error('Couldn\'t load alarms!', error);
                ctrl.data = [];
            });
        }

        function handleAlarms(response, reset){
            ctrl.hasMore = response.data.length === ctrl.itemsPerPage;
            if (!reset) {
                for (var i = 0; i < response.data.length; i++) {
                    ctrl.data.push(response.data[i]);
                }
            } else {
                ctrl.data = response.data;
                if (ctrl.isprintwidget) {
                    $timeout(function () {
                        var size = $($element).find("table.table").outerHeight() + parseInt($($element).find("h2").outerHeight()) +
                            parseInt($($element).find("h2").css("margin-top")) + parseInt($($element).find("h2").css("margin-bottom"));
                        $($element).parents().eq(3).css('height', size + "px"); // div.page
                        $($element).parents().eq(2).css('height', size + "px"); // div.widget
                    });
                }
            }
        }

        function getSorting() {
            return '?orderby=' + ctrl.tableModel.orderBy
                + '&direction=' + (ctrl.tableModel.orderBy ? (ctrl.tableModel.orderDir ? 'asc' : 'desc') : '');
        }

        ctrl.getAlarmIcon = function (value) {
            var level = parseInt(value / 75);
            switch (level) {
                case 0 :
                    return "text-info glyphicon glyphicon-info-sign"; //fa fa-info-circle
                case 1 :
                    return "text-warning glyphicon fa fa-warning";
                default:
                    return "text-danger glyphicon glyphicon-remove-sign"; //fa fa-bell
            }
        };

        ctrl.getRowDecoration = function (priority) {
            if (priority <= ctrl.priorityInterval.warnPrioEnd && priority >= ctrl.priorityInterval.warnPrioStart) {
                return 'row-warning';
            } else if (priority >= ctrl.priorityInterval.errorPrioStart) {
                return 'row-error';
            }
            return 'row-info';
        }


        ctrl.saveSettings = function () {
            var settings = [];
            settings.push({key: 'count', value: ctrl.itemsPerPage});
            settings.push({key: 'hideLoadMore', value: ctrl.hideLoadMoreButton});
            settings.push({key: 'order', value: JSON.stringify({ 'by': ctrl.tableModel.orderBy, 'dir': ctrl.tableModel.orderDir })});
            if(settingsChanged(settings)) {
                saveSettings(settings);
            } else {
                ctrl.closeModal();
            }
            _query(true);
        };

        function saveSettings(settings){
            if(ctrl.isdashboardwidget){
                saveAlarmSettings(settings);
            } else if(ctrl.isreportwidget){
                updateAlarmSettings(settings);
            }
        }

        function updateAlarmSettings(settings){
            ctrl.widget.settings = settings;
            if( ctrl.widget.wid &&  ctrl.widget.wid > 0){
                WidgetData.updateWidgetSettings(ctrl.widget).then(function (response) {
                    ctrl.closeModal();
                    _query(true);
                }, function (error) {
                    Notify.error("global.notification.error.title", "dashboard.widget.speedometer.saveErrorMsg", 2000);
                });
            } else{
                ctrl.closeModal();
            }
        }

        function saveAlarmSettings(settings){
            ctrl.widget.settings = settings;
            WidgetData.saveWidgetSettings(ctrl.widget).then(function (response) {
                if (ctrl.isdashboardwidget) {
                    Notify.defaultSuccess();
                }
                _query(true);
                ctrl.closeModal();
            }, function (error) {
                Notify.error("global.notification.error.title", "dashboard.widget.speedometer.saveErrorMsg", 2000);
            });
        }

        function settingsChanged(newSettings) {

            if (ctrl.widget.settings && newSettings) {

                if(ctrl.widget.settings.length !== newSettings.length){
                    return true;
                }

                for (var i = 0; i < ctrl.widget.settings.length; i++) {
                    for (var j = 0; j < newSettings.length; j++) {
                        var current = ctrl.widget.settings[i];
                        var next = newSettings[j];
                        if (current.key === next.key) {
                            if (current.value.toString() !== next.value.toString()) {
                                return true;
                            }
                        }
                    }
                }
            }

            return false;
        }

        function _loadSettings() {
            if (ctrl.widget.settings !== null) {
                for (var i = 0; i < ctrl.widget.settings.length; i++) {
                    var set = ctrl.widget.settings[i];
                    if (set.key === 'count') {
                        ctrl.itemsPerPage = parseInt(set.value);
                    }
                    if (set.key === 'hideLoadMore') {
                        ctrl.hideLoadMoreButton = set.value == "true";
                    }
                    if (set.key === 'order') {
                        var order = JSON.parse(set.value);
                        ctrl.tableModel.orderBy = order.by;
                        ctrl.tableModel.orderDir = order.dir;
                    }
                }
            }
            return null;
        }

        function _showOptions() {
            currentModal = $uibModal.open({
                templateUrl: 'scripts/app/dashboard/widget.templates/alarm/widget.alarm.modal.html',
                animation: false,
                windowClass: 'animated fadeInDown',
                scope: $scope
            });
        }

        ctrl.closeModal = function () {
            if (currentModal) currentModal.close();
            AlarmService.killRenderer();
        };

        // Cache devices for this controller session
        function getDevice(mac) {
            var device = currentDevices[mac];
            if (device !== null && device !== undefined) {
                return $q.when(device);
            } else {
                return Device.get(mac).then(function (response) {
                    currentDevices[response.data.mac] = response.data;
                    return response.data;
                });
            }
        }

        ctrl.showDetails = function (data) {
            var isVirtualSNMP = false;
            $log.debug("data:", data);

            getDevice(data.mac).then(function (requestedDevice) {
                if (requestedDevice !== null) {
                    if (requestedDevice.hwtyp.id === 0) {
                        isVirtualSNMP = true;
                    }
                    ctrl.closeModal();
                    currentModal = $uibModal.open({
                        animation: false,
                        component: 'alarmModal',
                        windowClass: 'animated fadeInDown',
                        size: (isVirtualSNMP) ? 'lg' : '',
                        resolve: {
                            currentDevice: function () {
                                return requestedDevice;
                            },
                            currentAlarm: function () {
                                return data;
                            },
                            isVirtualSNMP: function () {
                                return isVirtualSNMP;
                            },
                            stamp: function () {
                                return data.stamp;
                            },
                            user: Principal.identity()
                        }
                    });
                }
            }, function () {
                $log.error('Could not get Device by Mac');
            });
        };
    }

    angular.module('emsv2App')
        .controller('WidgetAlarmController', widgetAlarmController);

})();
